import React, { useState } from "react";
import { Box, Typography, Chip, Paper } from "@mui/material";
import StudentEntranceReportByReligion from "./ReportByTribeGenderReligion";
import StudentEntranceReportByProvince from "./ReportGroupByProvince";
import StudentEntranceReportByGroup from "./ReportByGroupMajor";
import StudentEntranceReportByMajor from "./RepoortByMajor";
import StudentReportPassed from "./ReportStudentPassed";

const reportTypes = [
  { label: "ລາຍງານນັກສຶກສາາຕາມສາຂາສາຍຮຽນ", value: "major" },
  { label: "ລາຍງານນັກສຶກສາາຕາມກຸ່ມສາຍຮຽນ", value: "group" },
  { label: "ລາຍງານນັກສຶກສາເສັງຜ່ານ", value: "passed" },
  { label: "ລາຍງານຕາມແຂວງ", value: "province" },
  { label: "ລາຍງານຕາມຊົນເຜົ່າ, ເພດ ແລະ ສາສະຫນາ", value: "demographic" },
];

const StudentEntranceReport = () => {
  const [selectedReport, setSelectedReport] = useState("major");

  const handleSelect = (value) => {
    setSelectedReport(value);
  };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        bgcolor: "#f5f5f5",
        p: 4,
      }}
    >
      <Box>
        <Paper
          elevation={2}
          sx={{
            p: 3,
            boxShadow: "0px 2px 8px rgba(0,0,0,0.1)",
            borderRadius: 2,
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            mb: 3,
          }}
        >
          {reportTypes.map((type) => (
            <Chip
              key={type.value}
              label={type.label}
              variant={selectedReport === type.value ? "filled" : "outlined"}
              color={selectedReport === type.value ? "primary" : "default"}
              onClick={() => handleSelect(type.value)}
              sx={{ cursor: "pointer", pt: 2, pb: 2, pl: 2, pr: 2 }}
            />
          ))}
        </Paper>

        <Box>
          {selectedReport === "demographic" && (
            <StudentEntranceReportByReligion />
          )}
          {selectedReport === "group" && <StudentEntranceReportByGroup />}
          {selectedReport === "province" && <StudentEntranceReportByProvince />}
          {selectedReport === "major" && <StudentEntranceReportByMajor />}
          {selectedReport === "passed" && <StudentReportPassed />}
        </Box>
      </Box>
    </Box>
  );
};

export default StudentEntranceReport;

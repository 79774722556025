import { Close, CloudUpload } from '@mui/icons-material';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Step, StepLabel, Stepper, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { fetchPost } from '../../common/fetchAPI';
import { toast } from 'react-toastify';

const DropOutStatus = ({ data, onRefresh }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) setSelectedFile(file);
    };

    const handleCancel = () => {
        setSelectedFile(null);
        fileInputRef.current.value = "";
    };

    const steps = ["ລໍຖ້າຢືນຢັນ", "ຢືນຢັນແລ້ວ"];

    const getCurrentStep = (status) => {
        switch (status) {
            case "PENDING":
                return 0;
            case "APPROVED":
                return 1;
            default:
                return -1; // Not applicable for Rejected
        }
    };
    const currentStep = getCurrentStep(data?.approvalStatus);
    
    const handleApprove = async () => {
        if (!selectedFile) return; // Ensure a file is selected before submitting

        setIsSubmitting(true); // Disable the button while submitting
        try {
            const formData = new FormData();
            formData.append("file", selectedFile);
            const response = await fetchPost("studentdrop/approve/" + data?._id, formData);
            if (response) {
                onRefresh();
                setConfirmDialogOpen(false);
                toast.success("ຢືນຢັນສຳເລັດ", {
                    autoClose: 3000, // Customize hide time to 5 seconds (5000ms)
                });
            } else {
                throw new Error("can't not approve");
            }
        } catch (error) {
            toast.error("ລະບົບມີບັນຫາ ຢືນຢັນບໍ່ສຳເລັດ", {
                autoClose: 3000, // Customize hide time to 5 seconds (5000ms)
            });
            console.log("error: ", error)
        } finally {
            setIsSubmitting(false);
        }
    };
    return (
        <div>
            {data?.approvalStatus === "REJECTED" ? (
                <Box>
                    <Alert severity="error" style={{ marginBottom: "16px" }}>
                        {"ຖືກປະຕີເສດ: " + data?.approvalStatus}
                    </Alert>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mt={2}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            // onClick={handleConfirmDialogOpen}
                            // disabled={loading}
                            style={{ marginRight: "16px" }}
                        >
                            ຢືນຢັນໃຫມ່ອີກຄັ້ງ
                        </Button>
                    </Box>
                </Box>
            ) : (
                <>
                    {/* Stepper for Normal Status Flow */}
                    <Stepper activeStep={currentStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    {/* Approve and Reject Buttons for Pending Status */}
                    {data?.approvalStatus === "PENDING" && (
                        <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
                            {/* File Upload Input */}
                            <Typography variant="h6" style={{ marginBottom: "16px" }}>ກະລຸນາເລືອກໄຟລກ່ອນຢືນຢັນ</Typography>
                            {/* File Upload */}
                            <div className="card border-dashed border-primary text-center p-4 w-100"
                                style={{ maxWidth: "400px", cursor: "pointer", borderStyle: "dashed" }}
                                onClick={() => fileInputRef.current?.click()}
                            >
                                <CloudUpload fontSize="large" className="text-primary" />
                                <p className="mt-2 mb-1 fw-semibold">ຄລິກເພື່ອເລືອກເບິ່ງ ຫຼື ລາກ ແລະ ວາງ</p>
                                <p className="text-muted small">ຮອງຮັບໄຟລ໌ສູງສຸດ 10MB</p>
                                <input type="file" className="d-none" onChange={handleFileChange} ref={fileInputRef} />
                            </div>

                            {/* Display selected file */}
                            {selectedFile && (
                                <div className="mt-3 d-flex align-items-center justify-content-between bg-light p-2 rounded w-100" style={{ maxWidth: "400px" }}>
                                    <span className="text-primary text-truncate" style={{ maxWidth: "250px" }}>
                                        {selectedFile.name}
                                    </span>
                                    <Button variant="text" color="error" size="small" onClick={handleCancel}>
                                        <Close />
                                    </Button>
                                </div>
                            )}

                            <Box display="flex" justifyContent="center" mt={2}>
                                <Button
                                    variant="contained"
                                    style={{ marginRight: "16px" }}
                                    onClick={() => setConfirmDialogOpen(true)}
                                    disabled={!selectedFile} // Disable until a file is uploaded
                                >
                                    ຢືນຢັນ
                                </Button>
                                {/* <Button variant="outlined" color="error">
                                    ປະຕິເສດ
                                </Button> */}
                            </Box>
                        </Box>
                    )}
                </>
            )}
            {/* Confirmation Dialog for Approve */}
            <Dialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                aria-labelledby="confirm-dialog-title"
                sx={{
                    "& .MuiDialog-paper": {
                        width: "500px", // Set your desired width
                        maxWidth: "90%", // Ensure responsiveness
                    },
                }}
            >
                <DialogTitle
                    id="confirm-dialog-title"
                    sx={{ textAlign: "center", width: "100%" }}
                >
                    {"ຢືນຢັນນັກສຶກສາ"}
                </DialogTitle>
                <DialogContent>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: "flex",
                        justifyContent: "center", // Center buttons horizontally
                        width: "100%",
                        gap: "10px", // Space between buttons
                        paddingBottom: "16px", // Add space below buttons
                    }}
                >
                    <Button onClick={() => setConfirmDialogOpen(false)} color="secondary">
                        ຍົກເລີກ
                    </Button>
                    <Button
                        onClick={handleApprove}
                        color="primary"
                        variant="contained"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : (
                            "ຢືນຢັນ"
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DropOutStatus
import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import axios from "axios";
import { NEW_API_URL, API_KEY } from "../../../common/contrant";
import QontoConnector from "./Stepper";
import Button from "@mui/material/Button";
import { USER_KEY } from "../../../consts";
import { Typography } from "@material-ui/core";
import { CustomContainer } from "../../../common";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomMessage from "../../../common/CustomStatusMessage";
import { hasPermission } from "../../../helpers/CheckPermission";

export const GradeSendingCourseGrade = () => {
  const history = useHistory();
  const { location } = useReactRouter();
  const { data } = location?.state ?? "";

  const [students, setStudents] = useState([]);
  const [courseData, setCourseData] = useState("");
  const [actionState, setActionState] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const [courseDetail, setCourseDetail] = useState();
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;
  const [showSubmit, setShowSubmit] = useState(false);
  const [studentIds, setStudentIds] = useState([]);
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (actionState.open) {
      setTimeout(() => {
        setActionState({
          open: false,
          message: "",
          severity: "info",
        });
      }, 2000);
    }
  }, [actionState]);

  useEffect(() => {
    if (courseData?.sendingGradeStatus === "FACULTY_REJECTED") {
      setDescription("ຄະເເນນຖືກປະຕິເສດຈາກຄະນະ");
    }
    if (courseData?.sendingGradeStatus === "DEPARTMENT_REJECTED") {
      setDescription("ພາກວິຊາໄດ້ປະຕິດເສດຄະເເນນຂອງວິຊານີ້ແລ້ວ");
    }
    if (courseData?.sendingGradeStatus === "ADMIN_REJECTED") {
      setDescription("ມຊ ໄດ້ປະຕິເສດຄະເເນນຈາກຄະນະ");
    }

    if (userObject.data.role === "ADMIN") {
      if (courseData?.sendingGradeStatus === "FACULTY_SUBMITED") {
        setShowSubmit(true);
      } else {
        setShowSubmit(false);
      }
    }
    if (userObject.data.role === "DEP_ADMIN") {
      if (
        courseData?.sendingGradeStatus === "TEACHER_SUBMITED" ||
        courseData?.sendingGradeStatus === "FACULTY_REJECTED"
      ) {
        setShowSubmit(true);
      } else {
        setShowSubmit(false);
      }
    }

    if (userObject.data.role === "FAC_ADMIN") {
      if (
        courseData?.sendingGradeStatus === "DEPARTMENT_SUBMITED" ||
        courseData?.sendingGradeStatus === "ADMIN_REJECTED"
      ) {
        setShowSubmit(true);
      } else {
        setShowSubmit(false);
      }
    }

    if (userObject.data.role === "ADMIN") {
      if (courseData?.sendingGradeStatus === "FACULTY_SUBMITED") {
        setShowSubmit(true);
      } else {
        setShowSubmit(false);
      }
    }
  }, [user, courseData]);

  useEffect(() => {
    if (students.length > 0) {
      const _studentIds = students.map((student) => {
        return student._id;
      });
      setStudentIds(_studentIds);
    }
  }, [students, data]);

  useEffect(() => {
    fetchStudent();
  }, []);

  const fetchStudent = async () => {
    if (data) {
      await axios
        .get(
          NEW_API_URL + `student/class/${data.accessmentYear}/${data.courseId}`,
          {
            headers: {
              api_key: API_KEY,
            },
          }
        )
        .then((res) => {
          setStudents(res.data?.students);
          setCourseDetail(res.data?.courseDetail);
          if (res.data.students?.length > 0) {
            // get score status
            setCourseData(res.data?.students[0]);
          }
        })
        .catch((err) => { });
    }
  };

  const approveGrade = async () => {
    await axios
      .put(
        NEW_API_URL + `/score/approve`,
        {
          gradeIdList: studentIds,
          role: userObject.data.role,
          // role: "FAC_ADMIN",
        },
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        fetchStudent();
        setActionState({
          open: true,
          severity: "info",
          message: "ການຢືນຢັນຄະເເນນສຳເລັດ",
        });
      })
      .catch((err) => {
        setActionState({
          open: true,
          severity: "error",
          message: "ເກິດບັນຫາໃນການຢືນຢັນຄະເເນນ",
        });
      });
  };

  const rejectGrade = async () => {
    await axios
      .put(
        NEW_API_URL + `/score/reject`,
        {
          gradeIdList: studentIds,
          role: userObject.data.role,
          // role: "DEP_ADMIN",
        },
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        fetchStudent();
        setActionState({
          open: true,
          severity: "info",
          message: "ການປະຕິເສດຄະເເນນສຳເລັດ",
        });
      })
      .catch((err) => {
        setActionState({
          open: true,
          severity: "error",
          message: "ເກີດບັນຫາໃນການປະຕິເສດຄະເເນນ​",
        });
      });
  };

  return (
    <div>
      <CustomMessage
        open={actionState.open}
        message={actionState.message}
        severity={actionState.severity}
      />
      <CustomContainer>
        <div className="text-start m-0 p-0 d-flex flex-row ">
          <IconButton
            onClick={() => {
              history.push("/grade-sending-courses-list/limit/40/skip/1");
            }}
            className="mr-3"
          >
            <ArrowBackIcon color="info" />
          </IconButton>
          <Typography className="mt-1" variant="h6">
            ກັບຄືນ
          </Typography>
        </div>

        <div className="row">
          <div className="col-12">
            <Typography variant="h5">
              {"ວິຊາ : " +
                courseDetail?.course?.course?.subject?.title +
                "(" +
                courseDetail?.classRoom?.name +
                ")"}
            </Typography>
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <Typography variant="h6">
              ຄະນະ: {courseDetail?.faculty?.name}
            </Typography>
          </div>
          <div className="col-2">
            <Typography variant="h6">
              ພາກ: {courseDetail?.department?.name}
            </Typography>
          </div>
          <div className="col-2">
            <Typography variant="h6">
              ສາຂາ: {courseDetail?.major?.name}
            </Typography>
          </div>
          <div className="col-2">
            <Typography variant="h6">
              ປິຮຽນ: {courseDetail?.classRoom?.year}
            </Typography>
          </div>
          <div className="col-2">
            <Typography variant="h6">ພາກຮຽນ: {courseData?.semester}</Typography>
          </div>
          <div className="col-2">
            <Typography variant="h6">
              ອາຈານສອນ:{" "}
              {courseDetail?.course?.teacher?.map(
                (teacher) =>
                  "ທ່ານ " +
                  (teacher?.gender === "FEMALE" ? "ນາງ​  " : "") +
                  teacher?.firstname +
                  " " +
                  teacher?.lastname +
                  ", "
              )}
            </Typography>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <Typography variant="h5">ສະຖານະການສົ່ງຄະແນນ</Typography>
          </div>
        </div>

        <div className="mb-2">
          <QontoConnector status={courseData.sendingGradeStatus} />
        </div>

        <div className="w-100 text-center">
          <Typography variant="h5" color="error">
            {description}
          </Typography>
        </div>

        <div className="text-center mt-3">
          {showSubmit && (
            <div className="row d-flex justify-content-center">
              {userObject.data.role === "ADMIN" ? null : (
                <div className="col-2">
                  {hasPermission('GRADE', 'approve') &&
                    <Button onClick={approveGrade} variant="contained">
                      ຢືນຢັນຄະເເນນ
                    </Button>
                  }
                </div>
              )}
              <div className="col-2">
                {hasPermission('GRADE', 'reject') &&
                  <Button color="error" onClick={rejectGrade} variant="contained">
                    ປະຕິເສດ
                  </Button>
                }
              </div>
            </div>
          )}
        </div>
        <table className="table table-striped mt-5">
          <thead className="text-center">
            <tr>
              <th colSpan={1} className="py-3 text-center">
                ລຳດັບ
              </th>
              <th colSpan={2} className="py-3 text-center">
                ລະຫັດນັກສຶກສາ
              </th>
              <th colSpan={3} className="py-3 text-center">
                ຊື່ ແລະ ນາມສະກຸນ
              </th>
              <th colSpan={2} className="py-3 text-center">
                ຄະເເນນ (ເກຣດ)
              </th>
              <th colSpan={2} className="py-3 text-center">
                ຄະເເນນອັບ (ເກຣດ)
              </th>
              <th colSpan={2} className="py-3 text-center">
                ຫມາຍເຫດ
              </th>
            </tr>
          </thead>
          <tbody>
            {students.length > 0 &&
              students.map((student, index) => (
                <tr
                  key={student._id}
                  className={index % 2 === 0 ? "even-row" : "odd-row"}
                >
                  <td colSpan={1} className="text-center pt-3">
                    {index + 1}
                  </td>
                  <td colSpan={2} className="text-center pt-3">
                    {student?.student?.studentNumber}
                  </td>
                  <td colSpan={3} className="text-center pt-3 text-start">
                    {student?.student?.firstNameL +
                      "  " +
                      student?.student?.lastNameL}
                  </td>
                  <td colSpan={2} className="text-center pt-3">
                    <div className="w-100 text-center d-flex justify-content-center">
                      <Typography>{student?.grade || ""}</Typography>
                    </div>
                  </td>
                  <td colSpan={2} className="text-center pt-3">
                    <div className="w-100 text-center d-flex justify-content-center">
                      <Typography>{student?.new_grade || ""}</Typography>
                    </div>
                  </td>
                  <td colSpan={2} className="text-center pt-3">
                    <Typography
                      style={{
                        color: "#e63946",
                      }}
                    >
                      {student.student?.remark || ""}
                    </Typography>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {/* </div> */}
      </CustomContainer>
    </div>
  );
};

export default GradeSendingCourseGrade;

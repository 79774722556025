import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import styled from "styled-components";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Routers from "../consts/router";
import Login from "../pages/login/Login";

import TabComponenttest from "../pages/scholarshipAndJob/TabComponent";
// CustomSideNav
import { CustomSideNav, CustomNavbar } from "../common";

// Course
import CourseList from "../pages/courseList/CourseList";
import CourseAdd from "../pages/courseAdd/CourseAdd";
import CourseDetail from "../pages/courseDetail/CourseDetail";
import CourseDocList from "../pages/courseDocList/CourseDocList";

// Teacher
import TeacherList from "../pages/teacherList/TeacherList";
import TeacherDetail from "../pages/teacherDetail/TeacherDetail";
import TeacherAdd from "../pages/teacherAdd/TeacherAdd";
import TeacherFormStatistic from "../pages/teacherAdd/TeacherFormStatistic";
import TeacherFormPlaceOfBirth from "../pages/teacherAdd/TeacherFormPlaceOfBirth";

// Student
import StudentDetail from "../pages/studentDetail/StudentDetail";
import StudentAdd from "../pages/studentAdd/StudentAdd";
import StudentEdit from "../pages/studentEdit/StudentEdit";

//assign student
import AssignStudentList from "../pages/assignList/AssignStudentList";
import AssignAdd from "../pages/assignAdd/AssignAdd";
import AssignDetail from "../pages/assignDetail/AssignDetail";
import AssignEdit from "../pages/assignEdit/AssignEdit";

// Setting
import SettingList from "../pages/setting/SettingList";
import SettingDepartment from "../pages/setting/settingDepartment/SettingDepartment";
import FacultyList from "../pages/faculty/FacultyList";

// Notice
import AddNotice from "../pages/notice/AddNotice";
import EditNotice from "../pages/notice/EditNotice";
import DetailNotice from "../pages/notice/DetailNotice";

// Preregistration

import PreregistrationStudentDetail from "../pages/preregistrationStudent/PreregistrationStudentDetail";
import PreregistrationStudentDetailFacultyList from "../pages/preregistrationStudent/PreregistrationStudentDetailFacultyList";
import PreregistrationEdit from "../pages/preregistrationStudent/PreregistrationEdit";
import PreregistrationEditEn from "../pages/preregistrationStudent/PreregistrationEditEn";

// Code preregistration
import CodePreregistrationList from "../pages/codePreregistration/CodePreregistrationList";
import CodePreregistrationAdd from "../pages/codePreregistration/CodePreregistrationAdd";
import CodePreregistrationEdit from "../pages/codePreregistration/CodePreregistrationEdit";

import SettingsTribes from "../pages/setting/settingsTribes/SettingsTribes";
import SettingMasterData from "../pages/setting/SettingMasterData";

// grade
import GradesIndex from "../pages/grades/index";
import AddGradeForStudent from "../pages/grades/saveGrades/AddGradeForStudent";
import UpGradeforStudent from "../pages/grades/saveGrades/UpGradeforStudent";
import AllCourseDetial from "../pages/grades/sendGrades/AllCourse";
import StudentListForGrade from "../pages/grades/gradeList/StudentListForGrade";
import GradesList from "../pages/grades/gradeList/GradesList";
import GradesToPrint from "../pages/grades/gradeList/GradesToPrint";
import GradesCourseDetial from "../pages/grades/saveGrades/GradesCourseDetial";
import TapGrade from "../pages/grades/saveGrades/TapGrade";
import HistoryReceiveGrade from "../pages/grades/sendGrades/historyReceiveGrade";
import SentGradesDetail from "../pages/grades/sendGrades/sentGradedetail";
import HistoryUpdateGrade from "../pages/grades/historyUpdateGrade";
import StudentRegisterUpgrade from "../pages/grades/Upgrade/StudentRegisterUpgrade";
import HistoryReceiveGrdaedetail from "../pages/grades/historyUpdateGradeDetail";
import RegisterGradeCorrection from "../pages/grades/Upgrade/RegisterGradeCorrection";
import UpgradeStudentHistory from "../pages/grades/Upgrade/UpgradeStudentHistory";

import Adminhistory from "../pages/setting/Adminhistory";
import Facadminhistory from "../pages/setting/Facadminhistory";
import Depadminhistory from "../pages/setting/Depadminhistory";
import Teacheradminhistory from "../pages/setting/Teacheradminhistory";
import Policy from "../pages/setting/Policy";
import GradeCourseList from "../pages/grades/saveGrades/GradeCourseList";

/**
 *
 * Dashboard
 *
 */

import Dashboard from "../pages/dashboard/Dashboard";
import Price from "../pages/setting/priceSetting/prices";
import AssessmentYearSetting from "../pages/setting/assessmentYear/assessmentYear";

import TimeStudy from "../pages/setting/timeStudy/TimeStudy";

import NewStudentList from "../pages/studentList/newStudentList";
import UpdatedRegister from "../pages/updatedRegister/updatedRegister";
import { TimeTable } from "../pages/timeTable/timeTable";
import { ScopusPage } from "../pages/scopus";
import StatisticPage from "../pages/statistic/Index";
import GradeClassList from "../pages/grades/gradeclass/GradeClassLists";
import GradeSendingCourseList from "../pages/grades/gradeSending/GradeSendingCourseLists";
import GradeSendingCourseDetail from "../pages/grades/gradeSending/GradeSendingCourseDetail";
import SubjectLists from "../pages/subjects/SubjectList";
import PaymentList from "../pages/payment/PaymentList";

import GradeClassDetail from "../pages/grades/gradeclass/GradeClassDetail";
import GradeSendingCourseGrade from "../pages/grades/gradeSending/GradeSendingCourseGrade";
import PrereStudentList from "../pages/prereStudent";
import LogList from "../pages/logList";
import Docnotice from "../pages/scholarshipAndJob/Docnotice";

import AddEvaluationTemplate from "../pages/evaluation/addEvaluationTemplate";
import EvaluateTab from "../pages/evaluation";
import ElearningReport from "../pages/elearning/reports";
import ETeacherlist from "../pages/elearning";
import TCourseDetail from "../pages/elearning/courseDetail/CourseDetail";
import ELearningPage from "../pages/elearning/elearninnPage";
import ExamAdd from "../pages/elearning/courseDetail/ExamAdd";
import HomeworkAdd from "../pages/elearning/courseDetail/HomeWorkAdd";
import HomeworkSubmit from "../pages/elearning/courseDetail/HomeWorkSubmmit";
import SubmitStudentExam from "../pages/elearning/courseDetail/submitStudentExam";
import EntrancePage from "../pages/entrance/EntrancePage";
import EntranceStudentDetail from "../pages/entrance/EntranceStudentDetail";
import StudentGraduatedPage from "../pages/graduated";
import PermissionList from "../pages/setting/usergroup";
import UserGroupList from "../pages/setting/usergroup/userGrouplist";
import NewPromoteToNextGrade from "../pages/setting/promoteToNextGrade/newPromoteToNextGrade";
import GradeUpgradeList from "../pages/grades/Upgrade/GradeUpgradeList";
import UpgradeHistory from "../pages/grades/Upgrade/UpgradeHistory";
import PlaceStudyList from "../pages/setting/placeStudy/placeStudyList";
import StudentHistoryList from "../pages/studentList/StudentHistoryList";
import PlaceStudyDetail from "../pages/setting/placeStudy/placeStudyDetail";
import StudentListClass from "../pages/graduated/studentClass";
import DropOutList from "../pages/dropout/DropOutList";
import DropOutDetail from "../pages/dropout/DropOutDetail";
import ScholarshipSetting from "../pages/setting/scholarship/scholarshipSetting";
import EntranceStudentAssignOne from "../pages/entrance/EntranceStudentAssignOne";
import AddStudentToClassList from "../pages/assignList/AddStudentToClassList";
const Main = styled.main`
  /* position: relative; */
  overflow: hidden;
  transition: all 0.15s;
  padding: 0 8px;
  margin-left: ${(props) => (props.expanded ? 240 : 64)}px;
`;
function Routes() {
  return (
    <Router>
      <Switch>
        {/* Before login routes */}
        <PublicRoute exact path="/" component={Login} />

        <PublicRoute exact path="/admindata" component={TabComponenttest} />

        {/* After login routes (has SideNav and NavBar) */}
        <Route
          render={({ location, history }) => (
            <React.Fragment>
              {/* sidenav */}
              <CustomSideNav location={location} history={history} />

              <Main>
                {/* navbar */}
                <CustomNavbar />

                {/* Contents */}
                <div
                  style={{
                    marginTop: 60,
                    backgroundColor: "#eee",
                    minHeight: "100vh",
                  }}
                >
                  {/* private routes */}
                  <PrivateRoute
                    path={Routers.COURSE_LIST + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <CourseList />}
                  />
                  <PrivateRoute
                    path={Routers.COURSE_ADD}
                    component={(props) => <CourseAdd />}
                  />
                  <PrivateRoute
                    path={Routers.COURSE_DETAIL}
                    component={(props) => <CourseDetail />}
                  />
                  <PrivateRoute
                    path={Routers.COURSE_DETAIL_DOC_LIST + "/id/:id"}
                    component={(props) => <CourseDocList />}
                  />
                  {/* Assign student */}
                  <PrivateRoute
                    path={
                      Routers.ASSIGN_STUDENT_LIST + "/limit/:limit/skip/:skip"
                    }
                    exact
                    component={(props) => <AddStudentToClassList />}
                  />
                  <PrivateRoute
                    path={"/policies"}
                    exact
                    component={(props) => <Policy />}
                  />
                  <PrivateRoute
                    path={
                      Routers.ASSIGN_STUDENT_ADD + "/limit/:limit/skip/:skip"
                    }
                    component={(props) => <AssignAdd />}
                  />
                  <PrivateRoute
                    path={Routers.ASSIGN_STUDENT_DETAIL + "/id/:id"}
                    component={(props) => <AssignDetail />}
                  />
                  <PrivateRoute
                    path={Routers.ASSIGN_STUDENT_EDIT + "/id/:id"}
                    component={(props) => <AssignEdit />}
                  />
                  {/* Teacher PrivateRoutes */}
                  <PrivateRoute
                    path={Routers.TEACHER_LIST}
                    component={(props) => <TeacherList />}
                  />
                  <PrivateRoute
                    path={Routers.TEACHER_ADD}
                    component={(props) => <TeacherAdd />}
                  />
                  <PrivateRoute
                    path={Routers.TEACHER_FORM_STATISTIC}
                    component={(props) => <TeacherFormStatistic />}
                  />
                  <PrivateRoute
                    path={Routers.TEACHER_FORM_PLACE_OF_BIRTH}
                    component={(props) => <TeacherFormPlaceOfBirth />}
                  />
                  <PrivateRoute
                    path={Routers.TEACHER_DETAIL}
                    component={(props) => <TeacherDetail />}
                  />
                  <PrivateRoute
                    path={Routers.STUDENT_LIST + "/limit/:limit/skip/:skip"}
                    component={(props) => <NewStudentList />}
                  />
                  <PrivateRoute
                    path={Routers.STUDENT_HISTORY_LIST}
                    component={(props) => <StudentHistoryList />}
                  />
                  <PrivateRoute
                    path={Routers.STUDENT_DETAIL + "/id/:id"}
                    component={(props) => <StudentDetail />}
                  />
                  <PrivateRoute
                    path={Routers.STUDENT_ADD}
                    component={(props) => <StudentAdd />}
                  />
                  <PrivateRoute
                    path={Routers.STUDENT_EDIT + "/id/:id"}
                    component={(props) => <StudentEdit />}
                  />
                  {/* New code =========================================== */}
                  <PrivateRoute
                    path={Routers.SUBJECT_LIST}
                    component={(props) => <SubjectLists />}
                  />

                  <PublicRoute
                    exact
                    path={"/evaluation"}
                    component={(props) => <EvaluateTab />}
                  />

                  <PublicRoute
                    exact
                    path={"/entrance"}
                    component={(props) => <EntrancePage />}
                  />
                  <PublicRoute
                    exact
                    path={"/graduated"}
                    component={(props) => <StudentGraduatedPage />}
                  />

                  <PublicRoute
                    exact
                    path={"/entrance/:id"}
                    component={(props) => <EntranceStudentDetail />}
                  />

                  <PublicRoute
                    exact
                    path={"/entrance-student-asign-one"}
                    component={(props) => <EntranceStudentAssignOne />}
                  />

                  <PublicRoute
                    path={"/evaluation/add/"}
                    component={(props) => <AddEvaluationTemplate />}
                  />

                  <PublicRoute
                    path={"/studentclass/"}
                    component={(props) => <StudentListClass />}
                  />

                  <PublicRoute
                    path={"/scholarship-setting"}
                    component={(props) => <ScholarshipSetting />}
                  />

                  <PrivateRoute
                    path={Routers.PAYMENT_LIST}
                    component={(props) => <PaymentList />}
                  />

                  <PrivateRoute
                    path={"/elearning/report"}
                    component={(props) => <ElearningReport />}
                  />
                  <PrivateRoute
                    exact
                    path={"/elearning/"}
                    component={(props) => <ETeacherlist />}
                  />
                  <PrivateRoute
                    exact
                    path={"/elearning/teacher/course/:id"}
                    component={(props) => <TCourseDetail />}
                  />
                  <PrivateRoute
                    exact
                    path={"/elearning/teacher/:id"}
                    component={(props) => <ELearningPage />}
                  />

                  <PrivateRoute
                    path="/elearning/examadd/:id"
                    exact
                    component={() => <ExamAdd />}
                  />
                  <PrivateRoute
                    path="/elearning/homeworkadd/:id"
                    exact
                    component={() => <HomeworkAdd />}
                  />

                  <PrivateRoute
                    path="/elearning/homeworksubmit/:id"
                    exact
                    component={() => <HomeworkSubmit />}
                  />
                  <PrivateRoute
                    path="/elearning/examsubmit/:id"
                    exact
                    component={() => <SubmitStudentExam />}
                  />

                  <PrivateRoute
                    path={Routers.DROP_OUT_LIST}
                    exact
                    component={() => <DropOutList />}
                  />
                  <PrivateRoute
                    path={Routers.DROP_OUT_DETAIL}
                    exact
                    component={() => <DropOutDetail />}
                  />

                  {/* <PrivateRoute
                    path="/statistic/prerestudent"
                    component={(props) => <PrereStudent />}
                  />

                  <PrivateRoute
                    path="/statistic/prerestudent"
                    component={(props) => <PrereStudent />}
                  />
                  <PrivateRoute
                    path="/statistic/inplanstudent"
                    component={(props) => <InplanStudent />}
                  />
                  <PrivateRoute
                    path="/statistic/outplanstudent"
                    component={(props) => <AllOutplanStudent />}
                  />
                  <PrivateRoute
                    path="/statistic/allstudent"
                    component={(props) => <AllStudent />}
                  />
                  <PrivateRoute
                    path="/statistic/studentbyageinplan"
                    component={(props) => <StudentByAgeInplan />}
                  />
                  <PrivateRoute
                    path="/statistic/studentbyageoutplan"
                    component={(props) => <StudentByAgeOutplan />}
                  />
                  <PrivateRoute
                    path="/statistic/studentbyageoutplancontunue"
                    component={(props) => <StudentByAgeOutplanContunue />}
                  />
                  <PrivateRoute
                    path="/statistic/studentbyageinplancontinue"
                    component={(props) => <StudentByAgeInplanContunue />}
                  /> */}
                  <PrivateRoute
                    path="/statistic"
                    component={(props) => <StatisticPage />}
                  />
                  <PrivateRoute
                    path="/pricessetting"
                    component={(props) => <Price />}
                  />
                  <PrivateRoute
                    path="/newregister"
                    exact
                    component={(props) => <UpdatedRegister />}
                  />
                  <PrivateRoute
                    path="/scopus"
                    exact
                    component={(props) => <ScopusPage />}
                  />
                  <PrivateRoute
                    path="/timetable"
                    exact
                    component={(props) => <TimeTable />}
                  />
                  {/* <PrivateRoute
                    path="/timetable/edit/:id"
                    exact
                    component={(props) => <TimeTableEdit />}
                  /> */}

                  <PrivateRoute
                    path="/timestudy"
                    exact
                    component={(props) => <TimeStudy />}
                  />
                  <PrivateRoute
                    path={Routers.ASSEMENT_YEAR_SETTING}
                    exact
                    component={(props) => <AssessmentYearSetting />}
                  />
                  {/* New code =========================================== */}
                  {/* setting */}
                  <PrivateRoute
                    path="/setting-list"
                    exact
                    component={(props) => <SettingList />}
                  />
                  <PrivateRoute
                    path={
                      Routers.PROMOTE_TO_NEXT_GRADE + "/limit/:limit/skip/:skip"
                    }
                    exact
                    // component={(props) => <PromoteToNextGrade />}
                    component={(props) => <NewPromoteToNextGrade />}
                  />

                  <PrivateRoute
                    path="/setting-permission"
                    exact
                    component={(props) => <PermissionList />}
                  />
                  <PrivateRoute
                    path="/setting-usergroup"
                    exact
                    component={(props) => <UserGroupList />}
                  />

                  <PrivateRoute
                    path={Routers.PLACE_STUDY}
                    exact
                    component={(props) => <PlaceStudyList />}
                  />
                  <PrivateRoute
                    path={Routers.PLACE_STUDY_DETAIL}
                    exact
                    component={(props) => <PlaceStudyDetail />}
                  />

                  {/* end registration path */}
                  <PrivateRoute
                    path={Routers.LOGIN_HISTORY + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <LogList />}
                  />
                  <PrivateRoute
                    path="/faculty-list"
                    exact
                    component={(props) => <FacultyList />}
                  />
                  <PrivateRoute
                    path={Routers.Notice_List + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <Docnotice />}
                  />
                  {/* <PrivateRoute
                    path={Routers.Notice_List + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <NoticeList />}
                  /> */}
                  <PrivateRoute
                    path="/notice-add"
                    exact
                    component={(props) => <AddNotice />}
                  />
                  <PrivateRoute
                    path="/notice-edit/:id"
                    exact
                    component={(props) => <EditNotice />}
                  />
                  <PrivateRoute
                    path="/notice-detail/:id/:type"
                    exact
                    component={(props) => <DetailNotice />}
                  />
                  {/* grades */}
                  <PrivateRoute
                    path={Routers.GRADE_INDEX + "/:page"}
                    exact
                    component={(props) => <GradesIndex />}
                  />
                  <PrivateRoute
                    path="/grades-list/limit/:limit/skip/:skip"
                    exact
                    component={(props) => <GradesList />}
                  />
                  <PrivateRoute
                    path={
                      Routers.GRADE_COURSE_LIST + "/limit/:limit/skip/:skip"
                    }
                    exact
                    component={(props) => <GradeCourseList />}
                  />
                  <PrivateRoute
                    path="/add-grade-for-student/:page"
                    component={(props) => <AddGradeForStudent />}
                  />
                  <PrivateRoute
                    path="/up-grade-for-student/:page"
                    component={(props) => <UpGradeforStudent />}
                  />
                  <PrivateRoute
                    path={Routers.GRADE_SENDING_COURSE_DETAIL}
                    component={(props) => <GradeSendingCourseDetail />}
                  />
                  <PrivateRoute
                    path={
                      Routers.GRADE_SENDING_COURSE_LIST +
                      "/limit/:limit/skip/:skip"
                    }
                    component={(props) => <GradeSendingCourseList />}
                  />
                  <PrivateRoute
                    path={Routers.GRADE_CLASS_LIST + "/limit/:limit/skip/:skip"}
                    component={(props) => <GradeClassList />}
                  />
                  <PrivateRoute
                    path={Routers.GRADE_CLASS_DETAIL}
                    component={(props) => <GradeClassDetail />}
                  />
                  <PrivateRoute
                    path={Routers.GRADE_SENDING_COURSE_GRADE}
                    component={(props) => <GradeSendingCourseGrade />}
                  />
                  {/* <PrivateRoute
                    path="/sent-grades-list/:page"
                    component={(props) => <SentGradesList />}
                  /> */}
                  <PrivateRoute
                    path={Routers.GRADE_TAPS + "/:id/"}
                    exact
                    component={(props) => <TapGrade />}
                  />
                  <PrivateRoute
                    path={Routers.GRADE_COURSE_DETAIL + ":id/"}
                    component={(props) => <GradesCourseDetial />}
                  />
                  <PrivateRoute
                    path="/send-grades/:page"
                    component={(props) => <AllCourseDetial />}
                  />
                  <PrivateRoute
                    path="/send-grades-detail/:page"
                    component={(props) => <HistoryReceiveGrade />}
                  />
                  <PrivateRoute
                    path="/grades-student-list/:page"
                    component={(props) => <StudentListForGrade />}
                  />
                  <PrivateRoute
                    path="/grades-to-report"
                    component={(props) => <GradesToPrint />}
                  />
                  <PrivateRoute
                    path="/grades-sent-detail/:id"
                    component={(props) => <SentGradesDetail />}
                  />
                  {/* <PrivateRoute
                    path="/grades-course-history/:page"
                    component={(props) => <AllCourseForHistory/>}
                  /> */}
                  <PrivateRoute
                    path="/grades-update-history/:page"
                    component={(props) => <HistoryUpdateGrade />}
                  />
                  <PrivateRoute
                    path="/grades-update-detail-history/:page"
                    component={(props) => <HistoryReceiveGrdaedetail />}
                  />
                  <PrivateRoute
                    path={Routers.GRADE_UPGRADE_LIST}
                    component={(props) => <GradeUpgradeList />}
                  />
                  <PrivateRoute
                    path={Routers.UPGRADE_HISTORY}
                    component={(props) => <UpgradeHistory />}
                  />
                  <PrivateRoute
                    path="/grades-upgrade-list/:page"
                    component={(props) => <StudentRegisterUpgrade />}
                  />
                  <PrivateRoute
                    path="/register_grade_correction/:page"
                    component={(props) => <RegisterGradeCorrection />}
                  />
                  <PrivateRoute
                    path="/upgrade-student-history/:page"
                    component={(props) => <UpgradeStudentHistory />}
                  />
                  {/* <PrivateRoute
                    path="/registration_student/:page"
                    exact
                    component={(props) => <RegistrationStudent />}
                  /> */}
                  <PrivateRoute
                    path={"/students-prere"}
                    exact
                    component={(props) => <PrereStudentList />}
                  />
                  <PrivateRoute
                    path={"/loglist"}
                    exact
                    component={(props) => <LogList />}
                  />

                  <PrivateRoute
                    path="/preregistration_student-detail"
                    exact
                    component={(props) => <PreregistrationStudentDetail />}
                  />
                  <PrivateRoute
                    path="/preregistration-student-detail-faculty-list"
                    exact
                    component={(props) => (
                      <PreregistrationStudentDetailFacultyList />
                    )}
                  />
                  <PrivateRoute
                    path="/preregistration_edit"
                    exact
                    component={(props) => <PreregistrationEdit />}
                  />
                  <PrivateRoute
                    path="/preregistration_edit_en"
                    exact
                    component={(props) => <PreregistrationEditEn />}
                  />
                  {/*Close V1 */}
                  {/* code preregistration */}
                  <PrivateRoute
                    path={Routers.CODE_PRERE_LIST + "/limit/:limit/skip/:skip"}
                    component={(props) => <CodePreregistrationList />}
                  />
                  <PrivateRoute
                    path={Routers.CODE_PRERE_ADD}
                    component={(props) => <CodePreregistrationAdd />}
                  />
                  <PrivateRoute
                    path={Routers.CODE_PRERE_EDIT + "/id/:id"}
                    component={(props) => <CodePreregistrationEdit />}
                  />
                  <PrivateRoute
                    path="/setting-tribes"
                    exact
                    component={(props) => <SettingsTribes />}
                  />
                  <PrivateRoute
                    path="/setting-master-data/:content/limit/:limit/skip/:skip"
                    exact
                    component={(props) => <SettingMasterData />}
                  />
                  <PrivateRoute
                    path="/setting-department"
                    exact
                    component={(props) => <SettingDepartment />}
                  />
                  <PrivateRoute
                    path={Routers.ADMIN_HISTORY + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <Adminhistory />}
                  />
                  <PrivateRoute
                    path={Routers.FACULTY_HISTORY + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <Facadminhistory />}
                  />
                  <PrivateRoute
                    path={
                      Routers.DEPARTMENT_HISTORY + "/limit/:limit/skip/:skip"
                    }
                    exact
                    component={(props) => <Depadminhistory />}
                  />
                  <PrivateRoute
                    path={Routers.TEACHER_HISTORY + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <Teacheradminhistory />}
                  />
                  {/* <PrivateRoute
                    path={Routers.STUDENT_HISTORY + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <Studentdminhistory />}
                  /> */}
                  {/**
                   *
                   * Dashboard
                   *
                   */}
                  <PrivateRoute
                    path="/dashboard-page"
                    exact
                    component={(props) => <Dashboard />}
                  />
                </div>
              </Main>
            </React.Fragment>
          )}
        />
      </Switch>
    </Router>
  );
}

export default Routes;

import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import Switch from '@material-ui/core/Switch'
import { CustomButton } from '../../../common'
import { Form, Modal, } from 'react-bootstrap'
import { Formik } from 'formik'
import { UPDATE_FACULTY } from '../../../apollo/faculty/index'
import { toDayDash, formatDateDash, valiDate, alertSuccess, alertWarning } from '../../../common/super'

const EditFaculty = ({ showSearchView, _handleSearchViewClose, allData }) => {
  const [checkStatus, setCheckStatus] = useState()
  const [assessmentYear, setAssessmentYear] = useState([]);
  useEffect(() => {
    let year = [];
    const NOW = new Date().getFullYear() + 6;
    for (var i = NOW - 0; i >= 2014; i--) {
      year.push(i + " - " + (parseInt(i) + 1));
    }
    setAssessmentYear(year);
    if (allData?.status == 'ENABLE') {
      setCheckStatus(true)
    } else {
      setCheckStatus(false)
    }
  }, [allData])
  const handleChangeStatus = async () => {
    setCheckStatus(!checkStatus)
  }

  const [updateFaculty] = useMutation(UPDATE_FACULTY)
  const _saveUpdateFaculty = data => {
    // data.assessmentYear = data.assessmentYear[0]
    updateFaculty({
      variables: {
        data: {
          ...data
        },
        where: { id: allData?.id }
      }
    })
      .then(async () => {
        _handleSearchViewClose()
        alertSuccess('ການແກ້ໄຂ')
        // window.location.reload(true)
      })
      .catch(err => {
        _handleSearchViewClose()
        alertWarning('ການແກ້ໄຂລົ້ມແຫຼວ')
      })
  }
  return (
    <Modal show={showSearchView} onHide={_handleSearchViewClose} size='lg'>
      <Formik
        initialValues={{
          name: allData?.name,
          description: allData?.description,
          enName: allData?.enName,
          enDescription: allData?.enDescription,
          note: allData?.note,
          assessmentYear: allData?.assessmentYear,
        }}
        validate={values => {
          const errors = {}
          if (!values.name) {
            errors.name = 'Required'
          }
          // if (!values.assessmentYear) {
          //   errors.assessmentYear = 'ກະລຸນາເລືອກສົກສຶກສາ'
          // }
          // let arr = allData?.assessmentYear
          // if (arr.indexOf(values.assessmentYear) !== -1) {
          //   errors.assessmentYear = 'ສົກສຶກສານີ້ມີຢູ່ແລ້ວ'
          // }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          let aa = allData?.status
          if (aa == 'ENABLE' && !checkStatus) {
            aa = 'DISABLE'
          } else if ((aa = 'ENABLE' && checkStatus)) {
            aa = 'ENABLE'
          } else if ((aa = 'DISABLE' && checkStatus)) {
            aa = 'DISABLE'
          } else {
            aa = 'DISABLE'
          }
          let arr = allData?.assessmentYear
          let newArr = []
          if (values.assessmentYear === arr) {
            newArr.push(...arr)
          } else {
            // check year is exist 
            let checkAssessmentYear = arr.filter((item, index) => item == values.assessmentYear)
            if (checkAssessmentYear.length >= 1) {
              newArr.push(...arr)
            } else {
              newArr.push(...arr, values.assessmentYear)
            }

          }

          let data = {
            name: values.name,
            description: values.description,
            enName: values.enName,
            enDescription: values.enDescription,
            note: values.note,
            assessmentYear: newArr
          }
          _saveUpdateFaculty(data)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
          /* and other goodies */
        }) => (
          <div>
            <Modal.Title style={{ textAlign: 'center', paddingTop: 20, fontWeight: 700 }}>
              ແກ້ໄຂຄະນະ
            </Modal.Title>
            <Modal.Body
              style={{ marginLeft: 50, marginRight: 50, padding: 50 }}
            >
              <div className='modal-body'>
                <label>ຊື່ຄະນະ(ລາວ) {valiDate()}</label>
                <input
                  type='text'
                  className='form-control'
                  name='name'
                  onChange={handleChange}
                  value={values.name}
                />
                <label>ຕົວຫຍໍ້(ລາວ)</label>
                <Form.Control
                  className='form-control'
                  type='text'
                  name='description'
                  onChange={handleChange}
                  value={values.description}
                  placeholder='ກະລຸນາປ້ອນຕົວຫຍໍ້(ຖ້າມີ)'
                />
                <label>ຊື່ຄະນະ(ອັງກິດ) {valiDate()}</label>
                <input
                  type='text'
                  className='form-control'
                  name='enName'
                  onChange={handleChange}
                  value={values.enName}
                  placeholder='ກະລຸນາປ້ອນຊື່ຄະນະ(ອັງກິດ)'
                />
                <label>ຕົວຫຍໍ້(ອັງກິດ)</label>
                <Form.Control
                  className='form-control'
                  name='enDescription'
                  onChange={handleChange}
                  value={values.enDescription}
                  placeholder='ກະລຸນາປ້ອນຕົວຫຍໍ້(ຖ້າມີ)'
                />
                <label>ສົກສຶກສາ {valiDate()}</label>
                <Form.Control
                  as="select"
                  className='form-control'
                  name="assessmentYear"
                  value={values.assessmentYear}
                  onChange={(e) => { handleChange(e); }}
                  isInvalid={!!errors.assessmentYear}
                >
                  <option disabled={true} value="">
                    ---ກະລຸນາປ້ອນສົກຮຽນ---
                  </option>
                  {assessmentYear.map((x, assesIndex) => {
                    return <option key={assesIndex} value={x}>{x}</option>;
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.assessmentYear}
                </Form.Control.Feedback>
                <label>ໝາຍເຫດ</label>
                <textarea
                  className='form-control'
                  name='note'
                  onChange={handleChange}
                  value={values.note}
                  placeholder='ກະລຸນາປ້ອນໝາຍເຫດ(ຖ້າມີ)'
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <CustomButton title='ຍົກເລິກ' onClick={_handleSearchViewClose} />
              <CustomButton onClick={handleSubmit} title='ບັນທຶກ' confirm />
            </Modal.Footer>
          </div>
        )}
      </Formik>
    </Modal>
  )
}

export default EditFaculty

const getStudentNumber = ({ faculty, degree, studentType }) => {
  let studnetNumber = "";

  if (faculty && degree && studentType) {
    switch (faculty) {
      case "636c8fd9cb5e606698a236bc":
        studnetNumber += "11";
        break;
      case "636c9e60cb5e606698a239a5":
        studnetNumber += "12";
        break;
      case "636ca622cb5e606698a23b58":
        studnetNumber += "13";
        break;
      case "636ca8b1cb5e606698a23d83":
        studnetNumber += "14";
        break;
      case "636cab01cb5e606698a2404a":
        studnetNumber += "15";
        break;
      case "636cac1dcb5e606698a2428e":
        studnetNumber += "16";
        break;
      case "636ca2accb5e606698a23aa6":
        studnetNumber += "17";
        break;
      case "636ca741cb5e606698a23c04":
        studnetNumber += "18";
        break;
      case "636cab55cb5e606698a240c7":
        studnetNumber += "19";
        break;
      case "636cad7dcb5e606698a243e4":
        studnetNumber += "20";
        break;
      case "636caf5ccb5e606698a245ff":
        studnetNumber += "21";
        break;
      case "636caee9cb5e606698a24568":
        studnetNumber += "22";
        break;
      case "636cb0e5cb5e606698a24824":
        studnetNumber += "23";
        break;
    }
    switch (degree) {
      case "DEPLOMA":
        studnetNumber += "2";
        break;
      case "BACHELOR_DEGREE":
        studnetNumber += "5";
        break;
      case "BACHELOR_CONTINUING":
        studnetNumber += "4";
        break;
    }

    switch (studentType) {
      case "IN_PLAN":
        studnetNumber += "Q";
        break;
      case "OUT_PLAN":
        studnetNumber += "N";
        break;
      case "IN_PLAN_EN":
        studnetNumber += "FQ";
        break;
      case "OUT_PLAN_EN":
        studnetNumber += "FN";
        break;
    }
  }

  return studnetNumber;
};

export default getStudentNumber;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_KEY, NEW_API_URL } from "../../../common/contrant";
import { useSelector } from "react-redux";
import { hasPermission } from "../../../helpers/CheckPermission";
import {
    Select,
    InputLabel,
    MenuItem,
    FormControl,
    Grid,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Box,
    IconButton,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import {
    fetchDelete,
    fetchGet,
    fetchPost,
    fetchPut,
} from "../../../common/fetchAPI";
import AlertMessage from "../../../common/AlertMessage";
import ConfirmModal from "../../../common/ConfirmDialog";
import { set } from "lodash";
import { CustomContainer } from "../../../common";

const EntrancePrice = () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    const accessmentRedux = useSelector((state) => state.accessment.value);
    const url = "entrance/price";

    const [actionStatus, setActionStatus] = useState({
        open: false,
        message: "",
        type: "",
    });

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [priceList, setPricelist] = useState([]);
    const [faculty, setFaculty] = useState(null);
    const [selectID, setSelectID] = useState("");
    const [showDelete, setShowDelete] = useState(false);

    useEffect(() => {
        if (actionStatus.open) {
            setTimeout(() => {
                setActionStatus({ ...actionStatus, open: false });
            }, 3000);
        }
    }, [actionStatus]);

    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        if (userData) {
            setFaculty(userData?.data?.faculty?._id);
        }
    }, []);

    const fetchPriceList = async () => {
        try {
            const response = await fetchGet(
                `entrance/price?assessmentYear=${accessmentRedux}`
            );
            setPricelist(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchPriceList();
    }, [faculty, accessmentRedux]);

    const [validateData, setValidateData] = useState({
        title: "",
        description: "",
        price: "",
    });

    const isDataValid = () => {
        let _validate = {
            title: "",
            description: "",
            price: "",
        };

        if (title === "" || title === null) {
            _validate = {
                ..._validate,
                title: "ກະລຸນາປ້ອນຫົວຂໍ້",
            };
        } else if (description === "" || description === null) {
            _validate = {
                ..._validate,
                description: "ກະລຸນາປ້ອນຄຳອະທີບາຍ",
            };
        } else if (price === "" || price === null) {
            _validate = {
                ..._validate,
                price: "ກະລຸນາປ້ອນລາຄາ",
            };
        }

        if (
            _validate.title == "" &&
            _validate.description == "" &&
            _validate.price == ""
        ) {
            setValidateData(_validate);
            return true;
        }
        setValidateData(_validate);
        return false;
    };

    const handleSubmit = async () => {
        if (isDataValid()) {
            const newData = {
                title: title,
                description: description,
                amount: price,
                assesmentYear: accessmentRedux,
            };
            const response = await (selectID
                ? fetchPut(`${url}/${selectID}`, newData)
                : fetchPost(url, newData));

            if (response) {
                setActionStatus({
                    open: true,
                    message: selectID ? "ແກ້ໄຂສຳເລັດ" : "ເພີ່ມສຳເລັດ",
                    type: "success",
                });
                fetchPriceList();
                setShowSuccess(true);
                setTitle("");
                setDescription("");
                setPrice("");
                setSelectID("");
                fetchPriceList();
            } else {
                setActionStatus({
                    open: true,
                    message: "ລະບົບມີບັນຫາ",
                    type: "error",
                });
            }
        }
    };

    const handleClearData = () => {
        setTitle("");
        setDescription("");
        setPrice("");
        setSelectID("");
    };

    const deletePriceItem = async () => {
        const response = await fetchDelete("entrance/price/" + selectID);
        if (response) {
            setActionStatus({
                open: true,
                message: "ລົບສຳເລັດ",
                type: "success",
            });
            fetchPriceList();
        } else {
            setActionStatus({
                open: true,
                message: "ມິບັນຫາໃນການລົບ",
                type: "error",
            });
        }
        setShowDelete(false);
    };

    return (
        <CustomContainer style={{ marginTop: 1 }}>
            <AlertMessage
                handleClose={() => {
                    setActionStatus({
                        ...actionStatus,
                        open: false,
                    });
                }}
                type={actionStatus.type}
                open={actionStatus.open}
                message={actionStatus.message}
            />
            <Grid container spacing={2}>
                {/* Form Section */}
                <Grid item xs={4}>
                    {hasPermission("PAYMENT_LIST", "create") && (
                        <Grid container spacing={2}>
                            <Typography
                                variant="h5"
                                align="center"
                                sx={{ lineHeight: 1.2, mb: 2 }}
                            >
                                ລາຍການຄ່າລົງທະບຽນ Entrance
                            </Typography>

                            <Grid item xs={12}>
                                <TextField
                                    error={validateData.title ? true : false}
                                    helperText={validateData.title}
                                    fullWidth
                                    label="ຫົວຂໍ້"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={validateData.description ? true : false}
                                    helperText={validateData.description}
                                    fullWidth
                                    label="ຄຳອະທິບາຍ"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={validateData.price ? true : false}
                                    helperText={validateData.price}
                                    fullWidth
                                    label="ລາຄາ"
                                    type="number"
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Box sx={{ display: "flex", gap: "20px" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        onClick={handleSubmit}
                                    >
                                        {selectID ? "ບັນທືກ" : "ເພີ່ມ"}
                                    </Button>

                                    <Button
                                        size="large"
                                        variant="contained"
                                        color="warning"
                                        fullWidth
                                        onClick={handleClearData}
                                    >
                                        ຍົກເລີກ
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                {/* Table Section */}
                <Grid item xs={7}>
                    <TableContainer
                        sx={{
                            marginTop: "43px",
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            backgroundColor: "#e9ecef",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        ລຳດັບ
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            backgroundColor: "#e9ecef",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        ຫົວຂໍ້
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            backgroundColor: "#e9ecef",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        ຄຳອະທິບາຍ
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            backgroundColor: "#e9ecef",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        ລາຄາ
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            backgroundColor: "#e9ecef",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        ຈັດການ
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {priceList.length === 0 && (
                                    <TableRow>
                                        <TableCell rowSpan={10} colSpan={7} align="center">
                                            ບໍ່ມີຂໍ້ມູນ
                                        </TableCell>
                                    </TableRow>
                                )}
                                {priceList.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell
                                            sx={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {index + 1}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {item?.title}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {item?.description}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {item.amount}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                whiteSpace: "nowrap", // Prevents text wrapping
                                                minWidth: "120px", // Ensures enough space for buttons
                                                textAlign: "center", // Centers buttons horizontally
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    gap: 1,
                                                }}
                                            >
                                                <IconButton
                                                    onClick={() => {
                                                        setSelectID(item._id);
                                                        setTitle(item.title);
                                                        setDescription(item.description);
                                                        setPrice(item.amount);
                                                    }}
                                                >
                                                    <Edit color="primary" fontSize="medium" />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => {
                                                        setShowDelete(true);
                                                        setSelectID(item._id);
                                                    }}
                                                >
                                                    <Delete color="error" fontSize="medium" />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <ConfirmModal
                open={showDelete}
                onCancel={() => setShowDelete(false)}
                onSubmit={deletePriceItem}
                title="ລົບຂໍ້ມຸນລາຍການຈ່າຍຄ່າລົງທະບຽນ"
                message="ທ່ານຕ້ອງການລົບແທ້ບໍ່?"
            />
        </CustomContainer>
    );
};

export default EntrancePrice;
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { USER_KEY } from '../../consts';
import { useSelector } from 'react-redux';
import { fetchGet } from '../../common/fetchAPI';
import { Breadcrumb } from 'react-bootstrap';
import { CustomContainer } from '../../common';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { setFilter } from '../../redux/filtrSlice';
import { convertGenderNam, convertGenderNamEN } from '../../helpers/user';
import { convertDropTypeL, formatDateDashDMY } from '../../common/super';
import useReactRouter from 'use-react-router';
import Routers from '../../consts/router';
import DropOutAddModal from './DropOutAddModal';
const DropOutList = () => {
    const user = localStorage.getItem(USER_KEY);
    const userObject = JSON.parse(user);

    const filterData = useSelector((state) => state.filter);
    const accessmentRedux = useSelector((state) => state.accessment.value);
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const [students, setStudents] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [majors, setMajors] = useState([]);
    const [classRooms, setClassRoom] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [count, setCount] = useState(0);
    const [formData, setFormData] = useState({
        faculty: filterData.faculty || "",
        department: filterData.department || "",
        major: filterData.major || "",
        year: "",
        assementYear: accessmentRedux,
        classRoom: "",
        type: "",
    });
    const [openDialog, setOpenDialog] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    // Fetch data from the API
    const fetchData = async () => {
        try {
            // Create a new URLSearchParams object and append parameters
            const apiUrl = `studentdrop?limit=${rowsPerPage}&page=${page + 1}&faculty=${formData.faculty}&department=${formData.department}&major=${formData?.major}&yearLevel=${formData.year}&classroom=${formData.classRoom}&assessmentYear=${accessmentRedux}&dropType=${formData.type}`;
            const response = await fetchGet(apiUrl);
            setCount(response?.total || 0);
            setStudents(response?.drops || []);
        } catch (error) {
            setStudents([]);
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [formData, rowsPerPage, page, accessmentRedux]); // Empty dependency array means this effect runs once on component mount

    const fetchFaculty = async () => {
        const _faculties = await fetchGet(`/faculty`);
        setFaculties(_faculties?.data || []);
    };
    const fetchDepartment = async () => {
        if (formData?.faculty) {
            const _department = await fetchGet(
                `/department?faculty=${formData?.faculty}&assessmentYear=${accessmentRedux}`
            );
            setDepartments(_department || []);
        }
    };
    const fetchMajor = async () => {
        const _major = await fetchGet("getmajo/" + formData.department)
        setMajors(_major?.data || []);
    };
    const fetchClass = async () => {
        const _classroom = await fetchGet("class/" + formData.major + "/" + formData.year)
        setClassRoom(_classroom?.data || []);
    };
    useEffect(() => {
        let _filterData = {};
        if (formData.faculty !== "") {
            _filterData.faculty = formData.faculty;
        }
        // Check and set department if not null
        if (formData.department !== "") {
            _filterData.department = formData.department;
        }

        // Check and set major if not null
        if (formData.major !== "") {
            _filterData.major = formData.major;
        }
        // Dispatch the setFilter action with the filterData payload
        dispatch(setFilter(_filterData));
        // Fetch faculties
        fetchFaculty()

        fetchDepartment();
        if (formData.department) {
            fetchMajor();
        }
        // Fetch majors
        if (formData.major && formData.year) {
            fetchClass();
        }
    }, [formData]);

    const handleClose = () => {
        setOpenDialog(false);
    }

    const statusL = (status) => {
        switch (status) {
            case "PENDING":
                return "ລໍຖ້າການຢືນຢັນ";
            case "APPROVED":
                return "ຢືນຢັນແລ້ວ";
            default:
                return "";
        }
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item active>
                    ລາຍການນັກສຶກສາໂຈະການຮຽນ
                </Breadcrumb.Item>
            </Breadcrumb>
            <CustomContainer style={{ marginTop: -8 }}>
                <Paper elevation={0} className="bg-white mt-1 pb-2">
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xs={3}>
                            <FormControl fullWidth size="small" margin="normal">
                                <InputLabel htmlFor="faculty">ຄະນະ</InputLabel>
                                <Select
                                    disabled={
                                        userObject?.data?.role === "FAC_ADMIN" ||
                                        userObject?.data?.role === "DEP_ADMIN"
                                    }
                                    label="ຄະນະ"
                                    name="faculty"
                                    value={formData.faculty || ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem key={""} value={""}>
                                        ທັງຫມົດ
                                    </MenuItem>
                                    {faculties.map((faculty) => (
                                        <MenuItem key={faculty._id} value={faculty._id}>
                                            {faculty.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth size="small" margin="normal">
                                <InputLabel htmlFor="department">ພາກ</InputLabel>
                                <Select
                                    disabled={userObject?.data?.role === "DEP_ADMIN"}
                                    label="ພາກ"
                                    name="department"
                                    value={formData.department || ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem key={""} value={""}>
                                        ທັງຫມົດ
                                    </MenuItem>
                                    {departments.map((department) => (
                                        <MenuItem key={department?._id} value={department?._id}>
                                            {department?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth size="small" margin="normal">
                                <InputLabel htmlFor="department">ສາຂາ</InputLabel>
                                <Select
                                    label="ສາຂາ"
                                    name="major"
                                    value={formData.major || ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem key={""} value={""}>
                                        ທັງຫມົດ
                                    </MenuItem>
                                    {majors.map((major) => (
                                        <MenuItem key={major._id} value={major._id}>
                                            {major.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth size="small" margin="normal">
                                <InputLabel htmlFor="faculty">ປີຮຽນ</InputLabel>
                                <Select
                                    size="small"
                                    label="ປີຮຽນ"
                                    name="year"
                                    value={formData.year}
                                    onChange={handleChange}
                                >
                                    <MenuItem key={""} value={""}>
                                        {"ທັງຫມົດ"}
                                    </MenuItem>
                                    {[1, 2, 3, 4, 5, 6].map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth size="small" margin="normal">
                                <InputLabel htmlFor="classRoom">ຫ້ອງຮຽນ</InputLabel>
                                <Select
                                    label="ຫ້ອງຮຽນ"
                                    name="classRoom"
                                    value={formData.classRoom}
                                    onChange={handleChange}
                                >
                                    <MenuItem key={""} value={""}>
                                        ທັງຫມົດ
                                    </MenuItem>
                                    {classRooms.map((classRoom) => (
                                        <MenuItem key={classRoom?._id} value={classRoom?._id}>
                                            {classRoom?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth size="small" margin="normal">
                                <InputLabel htmlFor="type">ປະເພດການໂຈະ</InputLabel>
                                <Select
                                    label="ປະເພດການໂຈະ"
                                    name="type"
                                    value={formData.type}
                                    onChange={handleChange}
                                >
                                    <MenuItem key={""} value={""}>
                                        ທັງຫມົດ
                                    </MenuItem>
                                    <MenuItem value="TEMPORARY">
                                        ໂຈະການຮຽນຊົ່ວຄາວ
                                    </MenuItem>
                                    <MenuItem value="PERMANENT">
                                        ໂຈະການຮຽນຖາວອນ
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </CustomContainer>

            <CustomContainer>
                <Box className="d-flex justify-content-between align-items-center mb-3">
                    <Typography variant="h6">ຈຳນວນທັງຫມົດ {count}</Typography>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => setOpenDialog(true)}
                    >
                        ເພີ່ມ
                    </Button>
                </Box>
                <Paper elevation={0} className="bg-white pb-3">
                    <TableContainer>
                        <Table sx={{ minWidth: 2300 }} size="small" aria-label="simple table">
                            <TableHead>
                                <TableRow className="thead-primary">
                                    <TableCell align="center"
                                        width={70}
                                        className="tablecell-head"
                                    >
                                        ລຳດັບ
                                    </TableCell>
                                    <TableCell align="center"
                                        width={150}
                                        className="tablecell-head"
                                    >
                                        ລະຫັດນັກສຶກສາ
                                    </TableCell>
                                    <TableCell align="center"
                                        width={150}
                                        className="tablecell-head"
                                    >
                                        ຊື່
                                    </TableCell>
                                    <TableCell align="center"
                                        width={150}
                                        className="tablecell-head"
                                    >
                                        ນາມສະກຸນ
                                    </TableCell>
                                    <TableCell align="center"
                                        width={200}
                                        className="tablecell-head"
                                    >
                                        ຄະນະ
                                    </TableCell>
                                    <TableCell align="center"
                                        width={200}
                                        className="tablecell-head"
                                    >
                                        ພາກ
                                    </TableCell>
                                    <TableCell align="center"
                                        width={200}
                                        className="tablecell-head"
                                    >
                                        ສາຂາ
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className="tablecell-head"
                                    >
                                        ປີຮຽນ
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className="tablecell-head"
                                    >
                                        ຫ້ອງຮຽນ
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className="tablecell-head"
                                        width={150}
                                    >
                                        ປະເພດການໂຈະ
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className="tablecell-head"
                                        width={150}
                                    >
                                        ສາເຫດ
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className="tablecell-head"
                                        width={150}
                                    >
                                        ວັນທີ່ເລິ່ມໂຈະ
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className="tablecell-head"
                                        width={150}
                                    >
                                        ວັນທີ່ສິ້ນສຸດການໂຈະ
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className="tablecell-head"
                                    >
                                        ສະຖານະ
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className="tablecell-head"
                                        width={130}
                                    >
                                        ຈັດການ
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {students.length > 0 &&
                                    students.map((item, index) => (
                                        <TableRow key={item?._id}>
                                            <TableCell align="center">
                                                {index + 1}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                            >
                                                {item?.student?.studentNumber}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                            >
                                                {!item?.student?.firstNameL || item?.student?.firstNameL === ""
                                                    ? convertGenderNamEN(item?.student?.gender) +
                                                    " " +
                                                    item?.student?.firstNameE
                                                    : convertGenderNam(item?.student?.gender) +
                                                    " " +
                                                    item?.student?.firstNameL
                                                }
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                            >
                                                {item?.student?.lastNameL || item?.student?.lastNameE}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                            >
                                                {item?.faculty?.name}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                            >
                                                {item?.department?.name}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                            >
                                                {item?.major?.name}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                            >
                                                {item?.yearLevel}
                                            </TableCell>
                                            <TableCell
                                                align="center"

                                            >
                                                {item?.classRoom?.name}
                                            </TableCell>
                                            <TableCell
                                                align="center"

                                            >
                                                {convertDropTypeL(item?.dropType)}
                                            </TableCell>
                                            <TableCell
                                                align="center"

                                            >
                                                {item?.reason}
                                            </TableCell>
                                            <TableCell
                                                align="center"

                                            >
                                                {formatDateDashDMY(item?.leaveStartDate)}
                                            </TableCell>
                                            <TableCell
                                                align='center'
                                            >
                                                {formatDateDashDMY(item?.leaveEndDate)}
                                            </TableCell>
                                            <TableCell
                                                align='center'
                                                style={{ color: item?.approvalStatus === "PENDING" ? "red" : "green" }}
                                            >
                                                {statusL(item?.approvalStatus)}
                                            </TableCell>
                                            <TableCell
                                                align='center'
                                            >
                                                <IconButton
                                                    color="primary"
                                                    size="small"
                                                    aria-label="Delete"
                                                    onClick={() => history.push(Routers.DROP_OUT_DETAIL, item?._id)}
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>

                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50]}
                                colSpan={15} // Adjusted colSpan to include the Actions column
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                elevation={0}
                                SelectProps={{
                                    inputProps: { "aria-label": "rows per page" },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Table>
                    </TableContainer>
                </Paper>
            </CustomContainer>
            <DropOutAddModal
                open={openDialog}
                handleClose={handleClose}
                fetchData={fetchData}
            />
        </div>
    )
}

export default DropOutList
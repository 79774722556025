import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { API_KEY, NEW_API_URL } from "../../../common/contrant";
import { USER_KEY } from "../../../consts";
import { Title } from "../../../common";
import { useSelector } from "react-redux";
import BlockComponent from "../../../common/BlockComponent";

const headCells = [
  {
    id: "ລະຫັດ",
    numeric: false,
    disablePadding: true,
    label: "ລະຫັດ",
  },
  {
    id: "ຊື່ວິຊາຮຽນ (ພາສາລາວ)",
    numeric: true,
    disablePadding: false,
    label: "ຊື່ວິຊາຮຽນ (ພາສາລາວ)",
  },
  {
    id: "ໜ່ວຍກິດ",
    numeric: true,
    disablePadding: false,
    label: "ໜ່ວຍກິດ",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            sx={{ color: "white", fontWeight: "bold" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, setSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          ເລືອກແລ້ວ {numSelected} ວິຊາ
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="subtitle1"
          id="tableTitle"
          component="div"
        >
          ຍັງບໍ່ໄດ້ເລືອກວິຊາ
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton sx={{ color: "red" }} onClick={() => setSelected([])}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default function CourseAddTable({
  formData,
  subjectType,
  location,
  selected,
  setSelected,
  onSuccess,
  setOnSuccess,
}) {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;
  const [subjects, setSubjects] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const accessmentRedux = useSelector((state) => state.accessment.value);

  const [permissiondenied, setPermissiondenied] = useState(false);
  useEffect(() => {
    const apiUrl = `${NEW_API_URL}subject?limit=${rowsPerPage}&type=${subjectType}&faculty=${
      location.faculty
    }&department=${formData.department}&major=${location._id}&title=${
      formData.title
    }&assessmentYear=${accessmentRedux} &educationLevel=${
      formData.educationLevel
    }&page=${page + 1}`;

    axios
      .get(apiUrl, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setSubjects(response.data || []);
        setOnSuccess(false);
      })
      .catch((error) => {
        // check if error 401
        if (error.response.status === 401) {
          setPermissiondenied(true);
        }
      });
  }, [
    formData,
    subjectType,
    location,
    rowsPerPage,
    page,
    onSuccess,
    accessmentRedux,
  ]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = subjects?.data.map((n) => n);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - subjects?.count) : 0;

  return (
    <>
      {permissiondenied ? (
        <BlockComponent />
      ) : (
        <Box sx={{ width: "100%" }}>
          <div className="d-flex align-items-center">
            <Title text={"ຫຼັກສູດທັງໝົດ "} />
            <span style={{ marginTop: 8, marginBottom: 20, marginLeft: 10 }}>
              {" "}
              ( {subjects?.count} ລາຍການ )
            </span>
          </div>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              setSelected={setSelected}
            />
            <TableContainer>
              <Table
                // sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={subjects?.count || 0}
                />
                <TableBody>
                  {subjects?.data?.map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.code}
                        </TableCell>
                        <TableCell align="center" sx={{ whiteSpace: "wrap" }}>
                          {row.title}
                        </TableCell>
                        <TableCell align="center">{row.credit}</TableCell>
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
              <TableRow
                style={{
                  height: emptyRows,
                }}
              >
                <TableCell colSpan={4} />
              </TableRow>
            )} */}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[
                5,
                10,
                25,
                { label: "All", value: subjects?.count },
              ]}
              component="div"
              count={subjects?.count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import { fetStatistichData } from "../Api";
import Loading from "../../../common/Loading";
import { Button, Typography } from "@mui/material";
import { SaveAlt as SaveAltIcon } from "@mui/icons-material";
import * as XLSX from "xlsx";
import "../index.css";
import { useSelector } from "react-redux";
import { hasPermission } from "../../../helpers/CheckPermission";

const StudentByCountry = ({ faculty, department, studentType }) => {
  const [data, setData] = useState(null);
  const accessmentRedux = useSelector((state) => state.accessment.value);

  const getData = async () => {
    setData(null);
    try {
      const result = await fetStatistichData(
        `fstudents?faculty=${faculty}&department=${department}&assessmentYear=${accessmentRedux}&studentType=${studentType}`
      );
      setData(result);
    } catch (error) { }
  };

  useEffect(() => {
    getData();
  }, [faculty, department, accessmentRedux, studentType]);

  const cellStyle = {
    width: "100px",
    height: "40px",
    textAlign: "center",
  };

  const generateMerges = () => {
    let merges = [
      { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } }, // merge rows for column 1
      { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } }, // merge rows for column 2
    ];

    for (let i = 0; i < 5; i++) {
      merges.push(
        { s: { r: 0, c: 2 + i * 3 }, e: { r: 0, c: 4 + i * 3 } } // merge cols for each year header
      );
    }

    merges.push(
      { s: { r: 0, c: 17 }, e: { r: 0, c: 19 } } // merge total header
    );

    return merges;
  };

  const formatDataForExcel = () => {
    const headers = [
      [
        "ລຳດັບ",
        "ປະເທດ",
        ...Array.from({ length: 5 }).flatMap((_, i) => [`ປີ ${i + 1}`, "", ""]),
        "ລວມ",
      ],
      [
        "",
        "",
        ...Array.from({ length: 5 }).flatMap(() => ["ລວມ", "ຍິງ", "ຊາຍ"]),
        "ລວມ",
        "ຍິງ",
        "ຊາຍ",
      ],
    ];

    const body = Object.entries(data.data).map(([key, value], index) => {
      const row = [index + 1, key];
      for (let i = 1; i <= 5; i++) {
        const yearData = value[i.toString()] || {
          total: 0,
          female: 0,
          male: 0,
        };
        row.push(yearData.total, yearData.female, yearData.male);
      }
      row.push(value.total || 0, value.female || 0, value.male || 0);
      return row;
    });

    return [...headers, ...body];
  };

  const exportToExcel = () => {
    const dataForExcel = formatDataForExcel();
    const ws = XLSX.utils.aoa_to_sheet(dataForExcel);
    const merges = generateMerges();
    ws["!merges"] = merges;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "StudentsByCountry");
    XLSX.writeFile(wb, "StudentsByCountry.xlsx");
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div>
          <Typography variant="h5">{`ຈຳຫນວນນັກສຶກສາຄົນຕ່າງປະເທດ ຕາມປີຮຽນ ແລະ ຕາມປະເທດເກີດ ${studentType === "OUT_PLAN" || studentType === "OUT_PLAN_EN"
            ? "(ນອກເເຜນ)"
            : "(ໃນເເຜນ)"
            }`}</Typography>
        </div>
        <div>
          {hasPermission('STATISTIC', 'export') &&
            <Button
              variant="contained"
              color="success"
              startIcon={<i className="fa fa-file-excel"></i>}
              sx={{ textTransform: 'none' }}
              onClick={exportToExcel}
            >
              Export
            </Button>
          }
        </div>
      </div>

      {data ? (
        <div style={{ overflowX: "auto" }}>
          <div
            style={{
              maxHeight: "80vh",
              overflowY: "auto",
              paddingBottom: "100px",
            }}
          >
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1,
                }}
              >
                <tr>
                  <th rowSpan={2} style={{ width: "100px" }}>
                    ລຳດັບ
                  </th>
                  <th rowSpan={2} style={{ width: "100px" }}>
                    ປະເທດ
                  </th>
                  {Array.from({ length: 5 }).map((_, i) => (
                    <th colSpan={3} style={cellStyle} key={i}>
                      ປີ {i + 1}
                    </th>
                  ))}
                  <th colSpan={3} style={{ width: "300px" }}>
                    ລວມ
                  </th>
                </tr>

                <tr>
                  {Array.from({ length: 6 }).map((_, i) => (
                    <React.Fragment key={i}>
                      <th style={cellStyle}>ລວມ</th>
                      <th style={cellStyle}>ຍິງ</th>
                      <th style={cellStyle}>ຊາຍ</th>
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.entries(data.data).map(([key, value], index) => {
                  return (
                    <tr key={key}>
                      <td style={cellStyle}>{index + 1}</td>
                      <td style={cellStyle}>{key}</td>
                      {Array.from({ length: 5 }).map((_, i) => {
                        // Get the data for the year i+1
                        const yearData = value[(i + 1).toString()];
                        if (yearData) {
                          return (
                            <React.Fragment key={i}>
                              <td style={cellStyle}>{yearData.total}</td>
                              <td style={cellStyle}>{yearData.female}</td>
                              <td style={cellStyle}>{yearData.male}</td>
                            </React.Fragment>
                          );
                        } else {
                          // If there is no data for the year, return empty cells
                          return (
                            <React.Fragment key={i}>
                              <td style={cellStyle}>0</td>
                              <td style={cellStyle}>0</td>
                              <td style={cellStyle}>0</td>
                            </React.Fragment>
                          );
                        }
                      })}
                      <td style={cellStyle}>{value.total}</td>
                      <td style={cellStyle}>{value.female}</td>
                      <td style={cellStyle}>{value.male}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="bg-white">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default StudentByCountry;

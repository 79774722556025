import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Divider,
  Button,
  Box,
} from "@mui/material";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import ConfirmModal from "../../../common/ConfirmDialog";
import AlertMessage from "../../../common/AlertMessage";
import { fetchDelete, fetchGet } from "../../../common/fetchAPI";
import LoadingDialog from "../../../common/LoadingDialog";
import EmptyCmomponent from "../../../common/EmptyScreen";
import AddIcon from "@mui/icons-material/Add";
import ReplayIcon from "@mui/icons-material/Replay";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./ExamList.css"; // Add your CSS here for animation
import useReactRouter from "use-react-router";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCourseDetail } from "../../../redux/tCourseSlice";
import { hasPermission } from "../../../helpers/CheckPermission";

const HomeworkList = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { history } = useReactRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "error",
  });
  const [showDelete, setShowDelete] = useState(false);
  const [examList, setExamList] = useState([]);
  const [selectedExamItem, setSelectedExamList] = useState({});

  // Set a maximum of two columns
  const itemWidth = 6; // This makes each item take half the width (12 / 2)

  const fetchExamList = async () => {
    const _examList = await fetchGet("/thomework/course/" + id);
    if (_examList) {
      setExamList(_examList);
    } else {
      setActionStatus({
        open: true,
        message: "ບໍ່ມີຂໍ້ມູນ",
        type: "error",
      });
    }
  };

  const deleteExam = async () => {
    if (selectedExamItem) {
      setIsLoading(true);
      const _deleteExam = await fetchDelete(
        "/thomework/" + selectedExamItem._id
      );
      setIsLoading(false);
      setShowDelete(false);
      if (_deleteExam) {
        setActionStatus({
          open: true,
          message: "ລົບວຽກບ້ານສຳເລັດ",
          type: "success",
        });
        // Animate the removal from the list
        setExamList((prevList) =>
          prevList.filter((exam) => exam._id !== selectedExamItem._id)
        );
      } else {
        setActionStatus({
          open: true,
          message: "ມີບັນຫາໃນການລົບວຽກບ້ານ",
          type: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus({ ...actionStatus, open: false });
      }, 3000);
    }
  }, [actionStatus]);

  useEffect(() => {
    fetchExamList();
  }, []);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        paddingLeft: 3,
        paddingRight: 3,
      }}
      justifyContent="center"
    >
      <AlertMessage
        open={actionStatus.open}
        message={actionStatus.message}
        type={actionStatus.type}
      />
      <LoadingDialog open={isLoading} />
      <ConfirmModal
        open={showDelete}
        message={"ທ່ານຕ້ອງການລົບວຽກບ້ານນີ້ເເທ້ບໍ່?"}
        title={"ລົບວຽກບ້ານ!"}
        onCancel={() => {
          setShowDelete(false);
        }}
        onSubmit={deleteExam}
      />

      <Box
        sx={{
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          marginTop: 2,
          marginBottom: 1,
          backgroundColor: "white",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Typography variant="h6">ລາຍການວຽກບ້ານ</Typography>

        <Box>
          {hasPermission('E_LEARNING', 'edit') &&
            <Button
              sx={{
                margin: 1,
                borderRadius: 3,
              }}
              onClick={() => {
                dispatch(
                  setCourseDetail({
                    tabIndex: 4,
                  })
                );
                history.push({
                  pathname: "/elearning/homeworkadd/" + id,
                });
              }}
              startIcon={<AddIcon />}
              variant="contained"
            >
              ເພີ່ມວຽກບ້ານໃຫມ່
            </Button>
          }
          <IconButton onClick={fetchExamList} color="primary">
            <ReplayIcon />
          </IconButton>
        </Box>
      </Box>

      {examList.length === 0 ? (
        <EmptyCmomponent />
      ) : (
        <TransitionGroup component={Grid} container spacing={1}>
          {examList.map((exam) => (
            <CSSTransition key={exam._id} timeout={500} classNames="fade">
              <Grid item xs={12} sm={itemWidth} md={itemWidth} key={exam._id}>
                <Card
                  sx={{
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "white",
                    border: "1px solid #e0e0e0",
                    padding: 2,
                    borderRadius: 3,
                  }}
                >
                  <CardContent>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                          {exam.title.length > 30
                            ? `${exam.title.substring(0, 30)}...`
                            : exam.title}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <IconButton color="primary">
                              <Visibility />
                            </IconButton>
                          </Grid>
                          {hasPermission('E_LEARNING', 'edit') &&
                            <Grid item>
                              <IconButton
                                onClick={() => {
                                  dispatch(
                                    setCourseDetail({
                                      tabIndex: 4,
                                    })
                                  );
                                  history.push({
                                    pathname: "/elearning/homeworkadd/" + id,
                                    state: exam._id,
                                  });
                                }}
                                color="secondary"
                              >
                                <Edit />
                              </IconButton>
                            </Grid>
                          }
                          {hasPermission('E_LEARNING', 'delete') &&
                            <Grid item>
                              <IconButton
                                onClick={() => {
                                  setSelectedExamList(exam);
                                  setShowDelete(true);
                                }}
                                color="error"
                              >
                                <Delete />
                              </IconButton>
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    </Grid>

                    <Divider sx={{ borderBottomWidth: 2 }} />
                    <Grid marginTop={2} container spacing={1}>
                      <Grid item xs={6}>
                        <Typography variant="body1" color="textSecondary">
                          ຊ່ວງເວລາເລີ່ມເປີດວຽກບ້ານ
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ color: "#1976d2", fontWeight: "bold" }}
                        >
                          {new Date(
                            exam.startExamDatetime
                          ).toLocaleDateString() +
                            " ເວລາ " +
                            new Date(
                              exam.startExamDatetime
                            ).toLocaleTimeString()}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="body1" color="textSecondary">
                          ຊ່ວງເວລາປິດການສົ່ງວຽກບ້ານ
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ color: "#ef233c", fontWeight: "bold" }}
                        >
                          {new Date(exam.endExamDatetime).toLocaleDateString() +
                            " ເວລາ " +
                            new Date(exam.endExamDatetime).toLocaleTimeString()}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" color="textSecondary">
                          ຫ້ອງຮຽນ
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ color: "#1976d2", fontWeight: "bold" }}
                        >
                          {exam.classRoom.length > 0
                            ? exam.classRoom.map(
                              (_class) => _class?.name + ", "
                            )
                            : "No class"}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="body1" color="textSecondary">
                          ຈຳນວນຄຳຖາມ
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ color: "#1976d2", fontWeight: "bold" }}
                        >
                          ປາລະໄນ: {exam.questionCount["multiple-choice"]},
                          ອັດຕະໄນ: {exam.questionCount["text-based"]}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="body1" color="textSecondary">
                          ຄະເເນນສູງສຸດ
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ color: "#1976d2", fontWeight: "bold" }}
                        >
                          {exam.totalMaxScore}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </CSSTransition>
          ))}
        </TransitionGroup>
      )}
    </Grid>
  );
};

export default HomeworkList;

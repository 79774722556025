import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import BillDetailComponent from "./BillDetailComponent";
import { NEW_API_URL, API_KEY } from "../../common/contrant";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CustomMessage from "../../common/CustomStatusMessage";
import { useSelector, useDispatch } from "react-redux";
import { setFilter } from "../../redux/filtrSlice";
import { useHistory } from "react-router-dom";
import { USER_KEY } from "../../consts";
import { Breadcrumb } from "react-bootstrap";
import { CustomContainer, Title } from "../../common";
import ExportUpdatedRegister from "./common/ExportUpdatedRegister";
import { convertGenderNam } from "../../helpers/user";
import { Print } from "@mui/icons-material";
import { BillTermComponent } from "../payment/common/BillTermComponent";
import { useReactToPrint } from "react-to-print";
import { currency } from "../../common/super";
import { hasPermission } from "../../helpers/CheckPermission";
import { fetchGet, fetchGetV2, fetchPut } from "../../common/fetchAPI";
import ConfirmModal from "../../common/ConfirmDialog";

const UpdatedRegister = () => {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);

  const filterData = useSelector((state) => state.filter);
  const accessmentRedux = useSelector((state) => state.accessment.value);

  const dispatch = useDispatch();
  const history = useHistory();
  const componentRef = useRef();

  const [studentList, setStudentList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [majors, setMajors] = useState([]);

  const [faculties, setFaculties] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);

  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [studentDetail, setStudentDetail] = useState();
  const [count, setCount] = useState(0);
  const [isGetDetailError, setIsGetDetailError] = useState(false);

  const [showConfirmDelete, setShowconfirmDelete] = useState(false);

  const [formData, setFormData] = useState({
    faculty: filterData.faculty || "",
    department: filterData.department || "",
    major: filterData.major || "",
    title: "",
    year: 2,
    isRegister: "",
    assementYear: accessmentRedux,
    educationLevel: filterData.educationLevel || "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  // Fetch data from the API
  const fetchData = async () => {
    try {
      // Create a new URLSearchParams object and append parameters
      const apiUrl = `${NEW_API_URL}/register/students?limit=${rowsPerPage}&faculty=${formData.faculty
        }&department=${formData.department}&title=${formData.title
        }&educationLevel=${formData.educationLevel}&page=${page + 1}&keyword=${formData.title
        }&major=${formData?.major}&year=${formData.year}&isRegister=${formData.isRegister
        }&assementYear=${accessmentRedux}`;
      const response = await fetchGet(apiUrl);
      setCount(response?.count || 0);
      setStudentList(response?.data);
    } catch (error) {
      setStudentList([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [formData, rowsPerPage, page, accessmentRedux]); // Empty dependency array means this effect runs once on component mount

  useEffect(() => {
    let _filterData = {};
    if (formData.faculty !== "") {
      _filterData.faculty = formData.faculty;
    }

    // Check and set department if not null
    if (formData.department !== "") {
      _filterData.department = formData.department;
    }

    // Check and set major if not null
    if (formData.major !== "") {
      _filterData.major = formData.major;
    }
    // Dispatch the setFilter action with the filterData payload
    dispatch(setFilter(_filterData));
    // Fetch faculties
    axios
      .get(NEW_API_URL + "faculty", {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setFaculties(response.data?.data || []))
      .catch((error) => console.error(error));

    // Fetch departments
    axios
      .get(NEW_API_URL + "department/" + formData.faculty, {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setDepartments(response.data?.data || []))
      .catch((error) => console.error(error));

    // Fetch education levels
    axios
      .get(NEW_API_URL + "educationlevel", {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setEducationLevels(response.data))
      .catch((error) => console.error(error));

    // Fetch majors
    if (formData.department) {
      axios
        .get(NEW_API_URL + "getmajo/" + formData.department, {
          headers: {
            api_key: API_KEY,
          },
        })
        .then((response) => setMajors(response.data?.data || []))
        .catch((error) => console.error(error));
    }
  }, [formData]);

  useEffect(() => {
    if (snackbarOpen) {
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 5000);
    }
  }, [snackbarOpen]);

  const cancelRegister = async (id, year, assessmentYear) => {
    console.log("selected item", selectedItem);
    if (!id || !year || !assessmentYear) {
      setIsError(true);
      return;
    }
    const response = await fetchPut(
      "register/status?id=" +
      id +
      "&status=" +
      false +
      "&year=" +
      year +
      "&assessmentYear=" +
      assessmentYear
    );
    if (response) {
      fetchData();
      setIsSuccess(true);
      setShowconfirmDelete(false);
    } else {
      setIsError(true);
    }
  };

  const fetchStudentDetail = async (id, clickType) => {
    const response = await fetchGetV2("course/subject/" + id);
    if (response.status !== "error") {
      setIsGetDetailError(false);
      setStudentDetail(response);
      if (clickType === "print") {
        openPrint();
      } else {
        setOpen(true);
      }
    } else if (response.code === 404) {
      setSnackbarMessage("ຍັງບໍ່ມີຂໍ້ມຸນລາຍການຈ່າຍຄ່າຮຽນ ຫລື ລາຍວິຊາຮຽນເທື່ອ");
      setIsGetDetailError(true);
    } else {
      setIsGetDetailError(true);
    }
  };

  const openPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (isGetDetailError) {
      setTimeout(() => {
        setIsGetDetailError(false);
      }, 3000);
    }
    if (isError) {
      setTimeout(() => {
        setIsError(false);
      }, 3000);
    }
    if (isSuccess) {
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    }
  }, [isGetDetailError, isError, isSuccess]);

  return (
    <div>
      <CustomMessage
        severity={"success"}
        open={isSuccess}
        message={"ການດຳເນີນການສຳເລັດ"}
      />
      <CustomMessage
        severity={"error"}
        open={isError}
        message={"ການດຳເນີນການບໍ່ສຳເລັດ!"}
      />
      <ConfirmModal
        message={"ທ່ານຕ້ອງການຍົກເລີກການລົງທະບຽນແທ້ບໍ່?"}
        title={"ຍົກເລີກການລົງທະບຽນ"}
        onSubmit={() => {
          cancelRegister(
            selectedItem?._id,
            selectedItem?.yearLevel,
            selectedItem?._assessmentYear
          );
        }}
        open={showConfirmDelete}
      />
      {open && (
        <BillDetailComponent
          data={studentDetail}
          onFailed={() => {
            setIsError(true);
          }}
          onCancel={() => setOpen(false)}
          onSuccess={() => {
            setIsSuccess(true);
            fetchData();
            setOpen(false);
          }}
        />
      )}
      <CustomMessage
        open={isGetDetailError}
        message={"ມີບັນຫາໃນການລົງທະບຽນ " + snackbarMessage}
        severity={"error"}
      />
      <Breadcrumb>
        <Breadcrumb.Item active>ລົງທະບຽນນັກສຶກສາປີ 2 ຂຶ້ນໄປ</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <Title text={"ລາຍການລົງທະບຽນນັກສຶກສາປີ 2 ຂຶ້ນໄປ"} />
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="faculty">ຄະນະ</InputLabel>
              <Select
                disabled={
                  userObject?.data?.role === "FAC_ADMIN" ||
                  userObject?.data?.role === "DEP_ADMIN"
                }
                label="ຄະນະ"
                name="faculty"
                value={formData.faculty || ""}
                onChange={handleChange}
              >
                <MenuItem key={""} value={""}>
                  ທັງຫມົດ
                </MenuItem>
                {faculties.map((faculty) => (
                  <MenuItem key={faculty._id} value={faculty._id}>
                    {faculty.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="department">ພາກ</InputLabel>
              <Select
                disabled={userObject?.data?.role === "DEP_ADMIN"}
                label="ພາກ"
                name="department"
                value={formData.department || ""}
                onChange={handleChange}
              >
                <MenuItem key={""} value={""}>
                  ທັງຫມົດ
                </MenuItem>
                {departments.map((department) => (
                  <MenuItem key={department._id} value={department._id}>
                    {department.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="major">ສາຂາ</InputLabel>
              <Select
                label="ສາຂາ"
                name="major"
                value={formData.major}
                onChange={handleChange}
              >
                {majors.map((major) => (
                  <MenuItem key={major._id} value={major._id}>
                    {major.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={1}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="educationLevel">ລະດັບການສຶກສາ</InputLabel>
              <Select
                label="ລະດັບການສຶກສາ"
                name="educationLevel"
                value={formData.educationLevel}
                onChange={handleChange}
              >
                <MenuItem key={""} value={""}>
                  ທັງຫມົດ
                </MenuItem>
                {educationLevels.map((educationLevel) => (
                  <MenuItem key={educationLevel._id} value={educationLevel._id}>
                    {educationLevel.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="faculty">ປີຮຽນ</InputLabel>
              <Select
                size="small"
                label="ປີຮຽນ"
                name="year"
                value={formData.year}
                onChange={handleChange}
              >
                {/* <MenuItem value="">ທັງຫມົດ</MenuItem> */}
                {[2, 3, 4, 5, 6].map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="isRegister">ສະຖານະ</InputLabel>
              <Select
                size="small"
                label="ສະຖານະ"
                name="isRegister"
                value={formData.isRegister}
                onChange={handleChange}
              >
                {[
                  { label: "ທັງຫມົດ", value: null },
                  { label: "ລົງທະບຽນແລ້ວ", value: "true" },
                  { label: "ຍັງບໍ່ໄດ້ລົງທະບຽນ", value: "false" },
                ].map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth size="small" margin="normal">
              <TextField
                fullWidth
                name="title"
                onChange={handleChange}
                type="text"
                className="mt-2"
                variant="standard"
                placeholder="ຄົ້ນຫານັກສຶກສາ"
                InputProps={{
                  style: {
                    height: "33px",
                  },
                  startAdornment: <SearchIcon />,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className="d-flex align-items-center justify-content-between mt-3">
          <div className="d-flex align-items-center">
            <Title text={"ລາຍການນັກສຶກສາທັງໝົດ"} />
            <span style={{ marginTop: 10, marginBottom: 20, marginLeft: 10 }}>
              {" "}
              ({currency(count)} ລາຍການ)
            </span>
          </div>
          <div
            className={count > 0 ? "d-flex align-items-center mt-2" : "d-none"}
          >
            {hasPermission("REGISTER_YEAR_TWO_UP", "export") && (
              <ExportUpdatedRegister data={studentList} />
            )}
          </div>
        </div>
        <TableContainer>
          <Table>
            <TableHead style={{ backgroundColor: "#e9ecef" }}>
              <TableRow color="info">
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold", width: 60 }}
                >
                  ລຳດັບ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ລະຫັດນັກສຶກສາ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ຊື່ ແລະ ນາມສະກູນ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ຄະນະ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ພາກ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ສາຂາ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ລະດັບການສຶກສາ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold", width: 60 }}
                >
                  ປີຮຽນ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ສະຖານະ
                </TableCell>
                {userObject.data.role === "ADMIN" ? (
                  <></>
                ) : (
                  <TableCell
                    align="center"
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      width: "170px",
                    }}
                  >
                    ຈັດການ
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {studentList.map((student, index) => (
                <TableRow key={student._id}>
                  <TableCell align="center">
                    {page * rowsPerPage + index + 1}
                  </TableCell>
                  <TableCell align="center">{student?.studentNumber}</TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "wrap" }}>
                    {convertGenderNam(student?.gender) +
                      " " +
                      student?.firstNameL +
                      " " +
                      student?.lastNameL}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "wrap" }}>
                    {student.faculty?.name}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "wrap" }}>
                    {student.department?.name}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "wrap" }}>
                    {student.major?.name}
                  </TableCell>
                  <TableCell align="center">
                    {student?.educationLevel?.name}
                  </TableCell>
                  <TableCell align="center">{student?.yearLevel}</TableCell>
                  <TableCell align="center">
                    {student?.isRegister?.firstSemester === false ||
                      student?.isRegister?.firstSemester === false ? (
                      <Typography color={"red"}>ຍັງບໍ່ໄດ້ລົງທະບຽນ</Typography>
                    ) : (
                      <Typography color={"green"}>ລົງທະບຽນແລ້ວ</Typography>
                    )}
                  </TableCell>
                  {userObject.data.role === "ADMIN" ? (
                    <></>
                  ) : (
                    <TableCell
                      align="center"
                      sx={{
                        paddingLeft: "0 !important",
                        paddingRight: "0 !important",
                      }}
                    >
                      {student?.isRegister?.firstSemester === true &&
                        student?.isRegister?.firstSemester === true ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            width: "100%",
                          }}
                        >
                          <Button
                            color="success"
                            variant="contained"
                            sx={{ textTransform: "none" }}
                            onClick={() =>
                              fetchStudentDetail(student?._id, "print")
                            }
                          >
                            <Print />
                          </Button>
                          {hasPermission("REGISTER_YEAR_TWO_UP", "cancel") && (
                            <Button
                              color="warning"
                              onClick={() => {
                                setShowconfirmDelete(true);
                                setSelectedItem(student);
                              }}
                              variant="contained"
                            >
                              ຍົກເລີກ
                            </Button>
                          )}
                        </div>
                      ) : (
                        hasPermission("REGISTER_YEAR_TWO_UP", "register") && (
                          <>
                            <Button
                              onClick={() => {
                                fetchStudentDetail(student._id, "register");
                              }}
                              variant="contained"
                            >
                              ລົງທະບຽນ
                            </Button>
                            <Button disabled></Button>
                          </>
                        )
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>

            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100, 500, 1000, 5000]}
              colSpan={userObject.data.role === "ADMIN" ? 9 : 10}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              elevation={0}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Table>
        </TableContainer>
      </CustomContainer>
      {studentDetail && (
        <BillTermComponent ref={componentRef} studentDetail={studentDetail} />
      )}
    </div>
  );
};

export default UpdatedRegister;

import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../../common/Tabs";
import { Paper } from "@mui/material";
import PrereStudents from "./PrereStudentList";
import { USER_KEY } from "../../consts";
import PreregistrationStudentStatistic from "../../pages/preregistrationStudent/PreregistrationStudentStatistic";
import RegistrationStudentStatisticTribe from "../../pages/preregistrationStudent/PreregistrationStudentStatisticTribe";
import { hasPermission } from "../../helpers/CheckPermission";

export default function PrereStudentList() {
  const [value, setValue] = useState(0);
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const role = userObject?.data?.role;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  if (!hasPermission('PRERE_STUDENT', 'access'))
     return (<div
     style={{height: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center'}}
     >ທ່ານບໍ່ສິດເຂົ້າເຖິງໜ້ານີ້</div>)
  
  return (
    <div style={{ backgroundColor: "white" }}>
      {role === "ADMIN" ? (
        <>
          <Paper>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="ນັກສຶກສາທັງຫມົດທິລົງທະບຽນຈາກ ມຊ" />
              <Tab label="ນັກສຶກສາທິຖືກຢືນຢັນຈາກ ມຊ" />
              <Tab label="ນັກສຶກສາທິຖືກປະຕິເສດ" />
              <Tab label="ນັກສຶກສາລົງທະບຽນສຳເລັດ" />
              {/* <Tab label="ຂໍ້ມຸນສະຖິຕິ" />
              <Tab label="ສະຖິຕິຊົນເຜົ່າ" /> */}
            </Tabs>
          </Paper>
          <TabPanel value={value} index={0}>
            <PrereStudents status={"ALL"} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PrereStudents status={"ADMIN_APPROVED"} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <PrereStudents status={"REJECTED"} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PrereStudents status={"APPROVED"} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <PreregistrationStudentStatistic />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <RegistrationStudentStatisticTribe />
          </TabPanel>
        </>
      ) : (
        <>
          <Paper>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="ນັກສຶກສາທິຖືກຢືນຢັນຈາກ ມຊ" />
              <Tab label="ນັກສຶກສາທິຖືກປະຕິເສດ" />
              <Tab label="ນັກສຶກສາລົງທະບຽນສຳເລັດ" />
              {/* <Tab label="ຂໍ້ມຸນສະຖິຕິ" />
              <Tab label="ສະຖິຕິຊົນເຜົ່າ" /> */}
            </Tabs>
          </Paper>
          <TabPanel value={value} index={0}>
            <PrereStudents status={"ADMIN_APPROVED"} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PrereStudents status={"REJECTED"} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <PrereStudents status={"APPROVED"} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PreregistrationStudentStatistic />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <RegistrationStudentStatisticTribe />
          </TabPanel>
        </>
      )}
    </div>
  );
}

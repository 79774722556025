import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Menu, MenuItem, Select, TextField, Typography } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from 'react'
import { fetchGetV2, fetchPostV2 } from '../../common/fetchAPI';
import { toast } from 'react-toastify';
import { convertGenderNam, convertGenderNamEN } from '../../helpers/user';

const DropOutAddModal = ({ open, handleClose, fetchData }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [matchedData, setMatchedData] = useState();
    const [loading, setLoading] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [isSumitted, setIsSumitted] = useState(false);
    const [dropType, setDropType] = useState("");
    const [reason, setReason] = useState("");
    const [description, setDescription] = useState("");
    const [leaveStartDate, setLeaveStartDate] = useState(null);
    const [leaveEndDate, setLeaveEndDate] = useState(null);

    // Validation errors
    const [errors, setErrors] = useState({
        student: "",
        dropType: "",
        reason: "",
        description: "",
        leaveStartDate: "",
        leaveEndDate: "",
    });

    const handleClickSearch = async (event) => {
        setAnchorEl(event.currentTarget);
        setLoading(true);
        try {
            const getStudent = await fetchGetV2(`student/studentnumber?studentNumber=${searchTerm}`)
            setMatchedData(getStudent);

        } catch (error) {
            setMatchedData();
            setSelectedStudent(null)
            console.log("error", error)
        } finally {
            setLoading(false);
        }
    };

    const handleSelectStudent = (student) => {
        setSelectedStudent(student);
        setAnchorEl(null);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCloseDialog = () => {
        handleClose();
        setAnchorEl(null);
        setSearchTerm("");
        setMatchedData();
        setSelectedStudent(null);
        setErrors({});
        setIsSumitted(false);
        setDropType("");
        setReason("");
        setDescription("");
        setLeaveStartDate(null)
        setLeaveEndDate(null);
        fetchData();
    };

    const handleSubmit = async () => {
        let newErrors = {};

        if (!selectedStudent) {
            newErrors.student = "ບໍ່ມີຂໍ້ມູນນັກສຶກສາ";
        }
        if (!dropType) {
            newErrors.dropType = "ກະລຸນາເລືອກປະເພດການໂຈະ";
        }
        if (!reason) {
            newErrors.reason = "ກະລຸນາປ້ອນເຫດຜົນ";
        }
        if (!leaveStartDate) {
            newErrors.leaveStartDate = "ກະລຸນາເລືອກວັນທີ່ເລີ່ມໂຈະ";
        }
        if (!leaveEndDate) {
            newErrors.leaveEndDate = "ກະລຸນາເລືອກວັນທີ່ສິ້ນສຸດການໂຈະ";
        }


        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setIsSumitted(true);

        const postData = {
            student: selectedStudent?._id,
            dropType: dropType,
            reason: reason,
            description: description,
            leaveStartDate: leaveStartDate,
            leaveEndDate: leaveEndDate
        }

        try {
            await fetchPostV2("studentdrop/request/", postData)
            toast.success("ສຳເລັດ ເພິ່ມນັກສຶກສາໂຈະການຮຽນແລ້ວ", {
                autoClose: 3000, // Customize hide time to 5 seconds (5000ms)
            });
            handleCloseDialog();
        } catch (error) {
            console.log("error::", error.response)
            if (error.response.data.message === "Leave period must be at least 3 months.") {
                toast.error("ຂໍຜິດພາດ! ເພີ່ມນັກສຶກສາໂຈະບໍ່ສຳເລັດໄລຍະເວລາການໃນການໂຈະຕ້ອງຫຼາຍກວ່າ 3 ເດືອນຂຶ້ນໄປ", {
                    autoClose: 5000, // Customize hide time to 5 seconds (5000ms)
                });
            } else if (error.response.data.message === "A drop request already exists for this student in the same period.") {
                toast.error("ຂໍຜິດພາດ! ນັກສຶກສາທີ່ເລືອກມີຢູ່ໃນລາຍການໂຈະແລ້ວ", {
                    autoClose: 5000, // Customize hide time to 5 seconds (5000ms)
                });
            } else {
                toast.error("ລະບົບມີບັນຫາ ເພີ່ມນັກສຶກສາໂຈະບໍ່ສຳເລັດ ກະລຸນາລອງໃໝ່", {
                    autoClose: 5000, // Customize hide time to 5 seconds (5000ms)
                });
            }
        } finally {
            setIsSumitted(false);
        }
    };

    return (
        <Dialog open={open} onClose={() => handleCloseDialog()} fullWidth maxWidth="sm">
            <DialogTitle>
                <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "#1976d2" }}
                >
                    ເພີ່ມນັກສຶກສາທີ່ຕ້ອງການໂຈະ
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Box className="d-flex gap-2" sx={{ alignItems: "center" }}>
                    <TextField
                        margin="dense"
                        name="title"
                        label="ຄົ້ນຫາດ້ວຍລະຫັດນັກສຶກສາ"
                        fullWidth
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setErrors((prev) => ({ ...prev, searchTerm: "" })); // Clear error when typing
                        }}
                    />

                    <Button
                        variant='outlined'
                        color='primary'
                        sx={{ padding: 2 }}
                        startIcon={<SearchIcon />}
                        onClick={handleClickSearch}
                    >
                        ຄົ້ນຫາ
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                        PaperProps={{ style: { maxHeight: 200 } }} // Limit dropdown height
                    >
                        {loading ? (
                            <MenuItem disabled>ກຳລັງໂຫຼດ...</MenuItem>
                        ) : matchedData !== undefined ? (
                            <MenuItem onClick={() => handleSelectStudent(matchedData)}>
                                {matchedData?.studentNumber + " "}
                                {!matchedData?.firstNameL || matchedData?.firstNameL === ""
                                    ? convertGenderNamEN(matchedData?.gender) + " " + matchedData?.firstNameE + " " + matchedData?.lastNameE
                                    : convertGenderNam(matchedData?.gender) + " " + matchedData?.firstNameL + " " + matchedData?.lastNameL}
                            </MenuItem>
                        ) : (
                            <MenuItem disabled>ບໍ່ພົບຂໍ້ມູນນັກສຶກສາ</MenuItem>
                        )}
                    </Menu>
                </Box>
                {selectedStudent &&
                    <Box
                        className="d-flex gap-2 py-2"
                        sx={{
                            borderBottom: "2px solid #f1f1f1",
                            cursor: "pointer",
                            background: "#f2f2f2",
                        }}
                    >
                        <Typography variant="body2">
                            {selectedStudent?.studentNumber + " "}
                            {!selectedStudent?.firstNameL || selectedStudent?.firstNameL === ""
                                ? convertGenderNamEN(selectedStudent?.gender) +
                                " " +
                                selectedStudent?.firstNameE + " " + selectedStudent?.lastNameE
                                : convertGenderNam(selectedStudent?.gender) +
                                " " +
                                selectedStudent?.firstNameL + " " + selectedStudent?.lastNameL}
                        </Typography>

                    </Box>
                }
                <Typography variant='caption' color="error">
                    {errors.student}
                </Typography>

                <FormControl fullWidth margin="dense" error={!!errors.dropType}>
                    <InputLabel>ເລືອກປະເພດການໂຈະ</InputLabel>
                    <Select
                        label="ເລືອກປະເພດການໂຈະ"
                        name="dropType"
                        value={dropType}
                        onChange={(e) => {
                            setDropType(e.target.value);
                            setErrors((prev) => ({ ...prev, dropType: "" })); // Clear error when selecting
                        }}
                    >
                        <MenuItem key={""} value={""}>
                            ທັງຫມົດ
                        </MenuItem>
                        <MenuItem value="TEMPORARY">
                            ໂຈະການຮຽນຊົ່ວຄາວ
                        </MenuItem>
                        <MenuItem value="PERMANENT">
                            ໂຈະການຮຽນຖາວອນ
                        </MenuItem>
                    </Select>
                </FormControl>
                {errors.dropType && (
                    <Typography variant="caption" color="error">
                        {errors.dropType}
                    </Typography>
                )}

                <TextField
                    margin="dense"
                    name="title"
                    label="ເຫດຜົນ"
                    fullWidth
                    variant="outlined"
                    value={reason}
                    onChange={(e) => {
                        setReason(e.target.value);
                        setErrors((prev) => ({ ...prev, reason: "" })); // Clear error when typing
                    }}
                    error={Boolean(errors.reason)}
                />
                <Typography variant='caption' color="error">
                    {errors.reason}
                </Typography>

                <TextField
                    margin="dense"
                    name="title"
                    label="ລາຍລະອຽດ"
                    fullWidth
                    variant="outlined"
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value);
                        setErrors((prev) => ({ ...prev, description: "" })); // Clear error when typing
                    }}
                />
                <TextField
                    sx={{ mt: 2 }}
                    label="ເລືອກວັນທີ່ເລີ່ມໂຈະ"
                    type="date"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }} // Keeps label from disappearing
                    value={leaveStartDate}
                    onChange={(e) => {
                        setLeaveStartDate(e.target.value);
                        setErrors((prev) => ({ ...prev, leaveStartDate: "" })); // Clear error when typing
                    }}
                    error={Boolean(errors.leaveStartDate)}
                />
                <Typography variant='caption' color="error">
                    {errors.leaveStartDate}
                </Typography>

                <TextField
                    sx={{ mt: 2 }}
                    label="ເລືອກວັນທີ່ສິ້ນສຸດໂຈະ"
                    type="date"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }} // Keeps label from disappearing
                    value={leaveEndDate}
                    onChange={(e) => {
                        setLeaveEndDate(e.target.value);
                        setErrors((prev) => ({ ...prev, leaveEndDate: "" })); // Clear error when typing
                    }}
                    error={Boolean(errors.leaveEndDate)}
                />
                <Typography variant='caption' color="error">
                    {errors.leaveEndDate}
                </Typography>

            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button
                    sx={{ borderRadius: 3 }}
                    onClick={() => handleCloseDialog()}
                    variant="outlined"
                    color="secondary"
                >
                    ຍົກເລີກ
                </Button>
                <Button
                    sx={{ borderRadius: 3 }}
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={isSumitted}
                >
                    ເພີ່ມ
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DropOutAddModal
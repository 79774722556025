import React, { useCallback, useEffect, useState } from "react";
import { IconButton, Typography, Checkbox, Button, Box } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIos";
import { useSelector } from "react-redux";
import { USER_KEY } from "../../../consts";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
} from "@mui/material";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import { fetchPost, fetchPostV2, fetchPutV2 } from "../../../common/fetchAPI";
import AlertMessage from "../../../common/AlertMessage";
// import { hasPermission } from "../../../helpers/CheckPermission";
import NextGradeFilter from "./filter/NextGradeFilter";
import { generateAcademicYears } from "../../../helpers/GenerateAcadimicYears";
import { messageWarning } from "../../../common/super";

const NewPromoteToNextGrade = () => {
    const user = localStorage.getItem(USER_KEY);
    const AssesmentYears = generateAcademicYears();
    const userObject = JSON.parse(user);
    const [facId, setFacId] = useState(userObject.data.faculty.id);
    const [depId, setDepId] = useState("");
    const [disableDep, setDisableDep] = useState(false);
    const [disableFact, setDisableFact] = useState(false);
    const [majorName, setMajorName] = useState("")

    const [filter, setFilter] = useState({});
    const [studentList, setStudentList] = useState([]);
    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [selectedStudentIds, setSelectedStudentIds] = useState([]);

    const accessmentRedux = useSelector((state) => state.accessment.value);
    const [showConfirm, setShowConfirm] = useState(false);
    const [selectedAssesmentYear, setSelectedAssesmentYaer] = useState(accessmentRedux);
    const [selectedYear, setSelectedYear] = useState(filter?.year);
    const [classroomList, setClassroomList] = useState([]);
    const [selectedClassroom, setSelectedClassroom] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [actionStatus, setActionStatus] = useState({
        open: false,
        message: "",
        type: "error",
    });

    useEffect(() => {
        if (actionStatus.open) {
            setTimeout(() => {
                setActionStatus({ ...actionStatus, open: false });
            }, 3000);
        }
    }, [actionStatus]);

    useEffect(() => {
        setSelectedStudentIds([]);
    }, [filter]);

    useEffect(() => {
        const user = localStorage.getItem(USER_KEY);
        const userObject = JSON.parse(user);

        if (userObject) {
            if (userObject.data.role === "DEP_ADMIN") {
                setDisableDep(true);
                setDepId(userObject.data.department.id);
                setFacId(userObject.data.faculty.id);
                setDisableFact(true);
            }
            if (userObject.data.role === "FAC_ADMIN") {
                setDisableDep(false);
                setDepId("");
                setFacId(userObject.data.faculty.id);
                setDisableFact(true);
            }
            if (userObject.data.role === "ADMIN") {
                setDisableDep(false);
                setDisableFact(false);
                setDepId("");
                setFacId("");
            }
        }
    }, [user]);

    useEffect(() => {
        if (showConfirm === true) {
            fetchClassRoom();
        }
    }, [showConfirm]);

    const fetchClassRoom = async () => {
        const postData = {
            major: filter?.major,
            year: Number(filter?.year) + 1,
        };
        const fetchGetClass = await fetchPost("classroom", postData)
        if (fetchGetClass) {
            setClassroomList(fetchGetClass?.data);
            setSelectedClassroom(fetchGetClass?.data[0]);
        } else {
            setActionStatus({
                open: true,
                message: "ບໍ່ມີຂໍ້ມູນຫ້ອງຮຽນ",
                type: "error",
            });
            setClassroomList([]);
            setSelectedClassroom();
        }

    }

    const fetchStudent = async (limit) => {
        const body = {
            assessmentYear: accessmentRedux,
            faculty: filter?.faculty || facId,
            department: filter?.department,
            major: filter?.major,
            classroom: filter?.class,
            year: filter?.year,
            limit: limit,
            page: currentPage,
            keyword: keyword,
        };

        try {
            const response = await fetchPutV2("/student/current", body);
            if (response.success) {
                setStudentList(response.data);
                setTotalPage(response.totalPageCount);
            } else {
                setStudentList([]);
            }
        } catch (err) {
            setStudentList([]);
        }
    };

    useEffect(() => {
        fetchStudent(20);
    }, [filter, accessmentRedux, facId, currentPage, keyword]);

    useEffect(() => {
        if (!isSubmitting) return; // Prevents unnecessary effects when not submitting

        let tempErrors = { ...errors };

        if (filter.faculty) delete tempErrors.faculty;
        else tempErrors.faculty = "ກະລຸນາເລືອກຄະນະ";

        if (filter.department) delete tempErrors.department;
        else tempErrors.department = "ກະລຸນາເລືອກພາກ";

        if (filter.major) delete tempErrors.major;
        else tempErrors.major = "ກະລຸນາເລືອກສາຂາ";

        if (filter.year) delete tempErrors.year;
        else tempErrors.year = "ກະລຸນາເລືອກປີ";

        if (filter.class) delete tempErrors.class;
        else tempErrors.class = "ກະລຸນາເລືອກຫ້ອງຮຽນ";

        setErrors(tempErrors);
        // setIsSubmitting(false); // Reset flag after effect runs
    }, [filter, isSubmitting]); // Watch both filter and isSubmitting

    const validateForm = useCallback(() => {
        let newErrors = {};

        if (!filter.faculty) newErrors.faculty = "ກະລຸນາເລືອກຄະນະວິຊາ";
        if (!filter.department) newErrors.department = "ກະລຸນາເລືອກພາກວິຊາ";
        if (!filter.major) newErrors.major = "ກະລຸນາເລືອກສາຂາວິຊາ";
        if (!filter.class) newErrors.class = "ກະລຸນາເລືອກຫ້ອງຮຽນ";
        if (!filter.year) newErrors.year = "ກະລຸນາເລືອກປີ";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Returns true if no errors
    }, [filter]);

    const handleRowSelection = (studentId) => {
        setSelectedStudentIds((prevSelectedIds) =>
            prevSelectedIds.includes(studentId)
                ? prevSelectedIds.filter((id) => id !== studentId)
                : [...prevSelectedIds, studentId]
        );
    };

    const handleAssesmentYearChange = (event) => {
        setSelectedAssesmentYaer(event.target.value);
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const handleClassChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue) {
            const selectedObject = classroomList.find(
                (classroom) => classroom._id === selectedValue
            );
            setSelectedClassroom(selectedObject);
        }
    };

    //   const showConfirm = () => setShowConfirm(true);
    const closeConfirm = () => setShowConfirm(false);


    const handleSelectAll = () => {
        const allIds = studentList
            .filter((student) => student.graduatedStatus?.status === "STUDYING") // Only include students with "STUDYING" status
            .map((student) => student._id); // Map to their IDs
        setSelectedStudentIds(allIds);
    };

    const handleClearAll = () => {
        setSelectedStudentIds([]);
    };

    const _registration = async () => {

        setIsLoading(true);

        if (selectedStudentIds.length === 0) {
            messageWarning("ຍັງບໍ່ໄດ້ເລືອກນັກຮຽນ!");
            setIsLoading(false); // Stop loading
            return; // Exit early if no students are selected
        }

        if (!selectedClassroom._id) {
            messageWarning("ຍັງບໍ່ໄດ້ເລືອກຫ້ອງ");
            console.error("No selected classroom!");
            setIsLoading(false); // Stop loading
            return; // Exit early if no classroom is selected
        }

        try {
            const user = localStorage.getItem(USER_KEY);
            const userObject = JSON.parse(user);

            const requestBody = {
                assessmentYear: nextAssessmentYear,
                studentIdList: selectedStudentIds,
                year: Number(filter?.year) + 1,
                classRoom: selectedClassroom._id,
                user: userObject?.data?._id,
            };
            const response = await fetchPostV2("register/students", requestBody);

            if (response?.success) {
                setActionStatus({
                    open: true,
                    message: "ເລື່ອນຊັ້ນສຳເລັດ",
                    type: "success",
                });
                console.log("sussss")
                setIsSubmitting(false)
                setShowConfirm(false);
                setIsLoading(false);
                await fetchStudent();
            } else {
                setActionStatus({
                    open: true,
                    message: "ລະບົບມີບັນຫາ",
                    type: "error",
                });
                setIsLoading(false);
            }
            
        } catch (error) {
            console.error("Registration failed:", error);
        }
    };

    const onShowComfirm = () => {
        setIsSubmitting(true); // Set the flag before validating
        if (!validateForm()) {
            // setIsSubmitting(false); // Reset the flag if validation fails
            return
        } else {
            setShowConfirm(true);
        }
    };

    const nextAssessmentYear = (accessmentRedux || "")
    .split(" - ") // Split into an array
    .map(year => (isNaN(year) ? 0 : Number(year) + 1)) // Convert to number and add 1
    .join(" - "); // Join back into "YYYY - YYYY"

    const RowData = ({
        id,
        queue,
        code,
        name,
        lastname,
        faculty,
        department,
        major,
        classroom,
        year,
        gender,
        status,
    }) => {
        return (
            <TableRow>
                <TableCell align="center">
                    <Checkbox
                        disabled={status?.status === "GRADUATED"}
                        checked={selectedStudentIds.includes(id)}
                        onChange={() => handleRowSelection(id)}
                    />
                </TableCell>
                <TableCell align="center">{queue + 20 * (currentPage - 1)}</TableCell>
                <TableCell align="center">{code}</TableCell>
                <TableCell align="center">
                    {gender === "FEMALE" ? "ຍິງ" : "ຊາຍ"}
                </TableCell>
                <TableCell>
                    {(gender === "MALE" ? "ທ້າວ " : "ນາງ ") + name + " " + lastname}
                </TableCell>
                <TableCell align="center">{faculty}</TableCell>
                <TableCell align="center">{department}</TableCell>
                <TableCell align="center">{major}</TableCell>
                <TableCell align="center">{classroom}</TableCell>
                <TableCell align="center">{year}</TableCell>
                <TableCell align="center">
                    {status?.status === "STUDYING" ? (
                        <Typography
                            sx={{
                                color: "green",
                                fontWeight: "bold",
                            }}
                        >
                            ກຳລັງສຶກສາ
                        </Typography>
                    ) : (
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                color: "orange",
                            }}
                        >
                            ສຳເລັດການສຶກສາແລ້ວ
                        </Typography>
                    )}
                </TableCell>
            </TableRow>
        );
    };

    return (
        <div className="px-3 pt-3">
            <div className="col-12">
                <AlertMessage
                    message={actionStatus.message}
                    open={actionStatus.open}
                    type={actionStatus.type}
                />
                <NextGradeFilter
                    depId={depId}
                    disableDep={disableDep}
                    factId={facId}
                    disableFact={disableFact}
                    setMajorName={setMajorName}
                    onSelected={setFilter}
                    errors={errors}
                />
            </div>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between", // Separate items to the left and right
                    alignItems: "center",
                    marginBottom: "20px",
                }}
            >
                {/* Buttons on the left */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px", // Adds spacing between the buttons
                    }}
                >
                    <Button
                        onClick={handleSelectAll}
                        startIcon={<DoneOutlineIcon />}
                        variant="contained"
                        color="primary"
                    >
                        ເລືອກທັງຫມົດ
                    </Button>
                    <Button
                        startIcon={<ClearIcon />}
                        onClick={handleClearAll}
                        variant="contained"
                        color="error"
                    >
                        ຍົກເລີກ
                    </Button>
                    <Typography mt={1}>
                        ຈຳນວນນັກສຶກສາທີເລືອກແລ້ວ{" "}
                        <Typography
                            component="span"
                            sx={{
                                color: "#1976d2",
                                fontWeight: "bold",
                                fontSize: 20,
                            }}
                        >
                            {selectedStudentIds.length}
                        </Typography>
                    </Typography>
                </Box>

                {/* Save button on the right */}
                {/* {hasPermission('GRADUATED', 'approve') && */}
                <Button
                    onClick={onShowComfirm}
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    disabled={selectedStudentIds.length === 0 || Object.keys(errors).length > 0}
                >
                ເລື່ອນຊັ້ນນັກສຶກສາ
                </Button>
                {/* } */}
            </Box>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align="center"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                            >
                                Select
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                            >
                                ລຳດັບ
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                            >
                                ລະຫັດ
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                            >
                                ເພດ
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                            >
                                ຊື່ ແລະ ນາມສະກຸນ
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                            >
                                ຄະນະ
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                            >
                                ພາກວິຊາ
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                            >
                                ສາຂາ
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                            >
                                ຫ້ອງຮຽນ
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                            >
                                ປີຮຽນ
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                            >
                                ສະຖານະ
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {studentList.map((student, index) => (
                            <RowData
                                key={student._id}
                                id={student._id}
                                queue={index + 1}
                                code={student?.userId}
                                name={student?.firstNameL || student?.firstNameE}
                                lastname={student?.lastNameL || student?.lastNameE}
                                faculty={student?.faculty?.name}
                                department={student?.department?.name}
                                major={student?.major?.name}
                                classroom={student?.classRoom?.name}
                                year={student.yearLevel}
                                gender={student?.gender}
                                status={student?.graduatedStatus}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <div className="row w-100">
                <div className="col-5 text-end">
                    <IconButton
                        onClick={() => {
                            if (currentPage > 1) setCurrentPage((page) => page - 1);
                        }}
                    >
                        <ArrowBackIosNewIcon />
                    </IconButton>
                </div>
                <div className="col-2 text-center pt-2">
                    <Typography>{currentPage + " ໃນ " + totalPage}</Typography>
                </div>
                <div className="col-5">
                    <IconButton
                        onClick={() => {
                            if (currentPage < totalPage) setCurrentPage((page) => page + 1);
                        }}
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                </div>
            </div>
            {showConfirm && (
                <div className="my-modal">
                    <div className="modal-content w-50">
                        <h3>ທ່ານຕ້ອງການເລື່ອນຊັ້ນນັກສຶກສາແທ້ບໍ່?</h3>
                        <p>(ຫລັງຈາກນັກສຶກສາຖືກເລຶ່ອນແລ້ວຈະບໍ່ສາມາດຍ້ອນກັບໄດ້)</p>
                        <div className="row w-100">
                            <div className="col-5 text-center">
                                <h4>ຈາກ</h4>
                                <div className="text-start ps-3 pt-2">
                                    <p>
                                        ນັກສຶກສາຈຳນວນ <strong>{selectedStudentIds.length}</strong> ຄົນ{" "}
                                    </p>
                                    <p>ສົກຮຽນ : {accessmentRedux}</p>
                                    <p>ປີຮຽນ : {filter.year}</p>
                                    <p>
                                        ສາຂາວິຊາ :{" "}
                                        {majorName}
                                    </p>

                                    {/* <p>ສາຂາວິຊາ : {majorData?.majors?.data[0].name}</p> */}
                                </div>
                            </div>
                            <div className="col-2 text-start">
                                <h4>-----------&gt;</h4>
                            </div>
                            <div className="col-5 text-center">
                                <h4>ເປັນ</h4>
                                <div className="text-start ps-3 pt-2">
                                    <p>
                                        ນັກສຶກສາຈຳນວນ <strong>{selectedStudentIds.length}</strong> ຄົນ{" "}
                                    </p>
                                    <div className="mb-2">
                                        <select
                                            className="p-1 px-3"
                                            value={nextAssessmentYear}
                                            onChange={handleAssesmentYearChange}
                                            disabled={true}
                                        >
                                            <option value={""}>ເລືອກສົກຮຽນ</option>
                                            {AssesmentYears.map((year, i) => (
                                                <option key={i} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                            {/* // <option value="2019 - 2020">{AssesmentYears}</option> */}
                                        </select>
                                    </div>
                                    <div className="mb-2">
                                        <select
                                            className="p-1 px-4"
                                            value={Number(filter?.year) + 1}
                                            onChange={handleYearChange}
                                            disabled={true}
                                        >
                                            <option value={""}>ເລືອກປີຮຽນ</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                            <option value={6}>6</option>
                                        </select>
                                    </div>
                                    <div className="mb-2">
                                        <select
                                            className="p-1 px-3"
                                            value={selectedClassroom ? selectedClassroom._id : ""}
                                            onChange={handleClassChange}
                                        >
                                            <option value={""}>ເລືອກຫ້ອງຮຽນ</option>
                                            {classroomList &&
                                                classroomList.map((classroom, index) => (
                                                    <option
                                                        key={classroom?._id}
                                                        value={classroom?._id}
                                                        selected={index === 0}
                                                    >
                                                        {classroom?.name}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="col">
                                        <p>
                                            ສາຂາວິຊາ :{" "}{majorName}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <button
                                    onClick={() => closeConfirm()}
                                    type="button"
                                    className="btn btn-danger rounded py-2 px-5"
                                >
                                    ຍົກເລີກ
                                </button>
                            </div>
                            <div className="col-6 w-50">
                                <button
                                    disabled={isLoading}
                                    onClick={() => _registration()}
                                    type="button"
                                    className="btn btn-primary rounded py-2 px-5"
                                >
                                    ຢືນຢັນ
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewPromoteToNextGrade;
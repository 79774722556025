import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Autocomplete } from "@mui/material";
import Modal from "@mui/material/Modal";
import getDayAsLao from "./getDayAsLao";
import axios from "axios";
import { API_KEY, NEW_API_URL } from "../../common/contrant";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import { useSelector } from "react-redux";
import { USER_KEY } from "../../consts";
import { fetchGet, fetchGetV2 } from "../../common/fetchAPI";
const user = localStorage.getItem(USER_KEY);
const userObject = JSON.parse(user);
const token = userObject?.accessToken;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  height: "100vh", // Make the Paper component take up 100% of the screen height
  overflowY: "auto", // Enable vertical scrolling
  padding: "16px", // Add padding to the content inside Paper
  p: 4,
  paddingBottom: 5,
};

export default function AddCourseModal({
  open,
  onSubmit,
  onCancel,
  data,
  depId,
  factId,
  major,
  semester,
  year,
}) {
  const accessmentRedux = useSelector((state) => state.accessment.value);

  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [facultyList, setFacultyList] = useState([]);
  const [faculty, setFaculty] = useState(factId);
  const [departmentList, setDepartmentList] = useState([]);
  const [department, setDepartment] = useState(depId);
  const [teacherList, setTeacherList] = useState([]);
  const [placeStudyList, setPlaceStudyList] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [selectedPlaceStudy, setSelectedPlaceStudy] = useState();
  const [week, setWeek] = useState("all");
  const [studyType, setStudyType] = useState("");
  const [formErrors, setFormErrors] = useState({
    subject: "",
    teacher: "",
    week: "",
    studyType: "",
  });

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "subject":
        error = !value ? "ກະລຸນາເລືອກວິຊາ" : "";
        break;
      case "studyType":
        error = !value ? "ກະລຸນາເລືອກຮູບເເບບການສອນ" : "";
        break;
      case "teacher":
        error = !value.length ? "ກະລຸນາເລືອກອາຈານ" : "";
        break;
      case "week":
        error = !value ? "ກະລຸນາອາທິດຮຽນ" : "";
        break;
      default:
        break;
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  // Handle field changes
  const handleChangeSubject = (value) => {
    setSelectedSubject(value);
    validateField("subject", value);
  };

  const handleChangeStudyType = (value) => {
    setStudyType(value);
    validateField("studyType", value);
  };

  const handleChangeTeachers = (value) => {
    setSelectedTeachers(value);
    validateField("teacher", value);
  };

  const handleChangeWeek = (value) => {
    setWeek(value);
    validateField("week", value);
  };

  const handleChangeFaculty = (value) => {
    setFaculty(value);
  };

  const handleChangeDepartment = (value) => {
    setDepartment(value);
  };

  const fetchFilterData = async () => {
    try {
      const _facultyList = await fetchGetV2("faculty");
      setFacultyList(_facultyList?.data || []);
    } catch (error) {
      console.log("error fetching faculty:", error?.response?.data?.message);
    }

    if (faculty) {
      try {
        const _departmentList = await fetchGet("department/" + faculty);
        setDepartmentList(_departmentList?.data || []);
      } catch (error) {
        console.log("error fetching department:", error?.response?.data?.message);
      }

    }
  };

  const fetchTeacher = async () => {
    try {
      if (department) {
        const response = await fetchGetV2("userteacher/" + department);
        setTeacherList(response);
      }
    } catch (error) {
      console.error("Error fetching teachers:", error?.response?.data?.message);
    }
  };

  const fetchPlaceStudy = async () => {
    try {
      const response = await fetchGetV2("placestudy?faculty=" + factId);
      if (response?.data) {
        setPlaceStudyList(response?.data || []);
      }
    } catch (error) {
      console.log("error fetching place:", error?.response?.data?.message);
    }

  };

  useEffect(() => {
    fetchFilterData();
    fetchTeacher();
    fetchPlaceStudy();
  }, [factId, depId, faculty, department]);

  const fetchSubjects = async () => {
    try {
      const response = await axios.get(
        NEW_API_URL +
        `subject/course?major=${major}&year=${year}&semester=${semester}&assessmentYear=${accessmentRedux}`,
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSubjects(response.data?.data || []);
    } catch (err) {
      console.error("Error fetching subjects:", err);
    }
  };

  useEffect(() => {
    setSelectedSubject("");
    setSelectedTeachers([]);
    setWeek("");
    fetchSubjects();
    setFormErrors({
      subject: "",
      teacher: "",
      week: "",
      studyType: "",
    });
    setSelectedPlaceStudy();
    setDepartment(depId);
    setFaculty(factId);
  }, [open]);

  const handleChangePlaceStudy = (selectedPlaceStudyId) => {
    setSelectedPlaceStudy(selectedPlaceStudyId);
  };

  const handleSubmit = () => {

    // Create a local errors object
    const errors = {
      subject: selectedSubject ? "" : "ກະລຸນາເລືອກວິຊາ",
      studyType: studyType ? "" : "ກະລຸນາເລືອກຮູບເເບບການສອນ",
      teacher: selectedTeachers.length ? "" : "ກະລຸນາເລືອກອາຈານ",
      week: week ? "" : "ກະລຸນາອາທິດຮຽນ",
    };

    // Update state with errors
    setFormErrors(errors);

    // Check if there are any errors
    if (Object.values(errors).some((err) => err !== "")) {
      return; // Stop submission if validation fails
    }

    const dataBody = {
      course: selectedSubject,
      teacher: selectedTeachers,
      timesStudy: [
        {
          address: selectedPlaceStudy,
          studyType: studyType,
          oddWeek: week === "odd" ? true : false,
          evenWeek: Boolean === "even" ? true : false,
          allWeek: Boolean === "all" ? true : false,
          day: getDayAsLao(data?.day),
          times: [
            {
              time: data?.time?.time,
              hour: data?.time.hour,
            },
          ],
        },
      ],
    }
    // delete address is undefined
    if (!selectedPlaceStudy) {
      delete dataBody?.timesStudy[0]?.address
    }
    console.log("databody::", dataBody)
    // return
    onSubmit(dataBody);
  };

  return (
    <Modal open={open} onClose={onCancel}>
      <Box sx={style}>
        <div className="w-100 text-center">
          <Typography variant="h5" component="h2">
            ເພີ່ມຕາຕະລາງຮຽນ
          </Typography>
        </div>
        <div className="row">
          <div className="col-12">
            <div>
              <Typography sx={{ fontWeight: "bold" }}>
                {"ວັນ ແລະ ເວລາສອນ"}
              </Typography>
              <Typography sx={{ marginTop: "12px" }}>
                {`ວັນ${getDayAsLao(data?.day)} ຊົ່ວໂມງທີ ${data?.time?.hour
                  } ເວລາ  ${data?.time?.time}`}
              </Typography>
            </div>
          </div>

          <div className="col-12 mt-2">
            <div>
              <Typography sx={{ fontWeight: "bold" }}>
                {"ເລືອກອາທິດຮຽນ"}
              </Typography>
              <FormControl sx={{ marginTop: "20px" }} fullWidth>
                <InputLabel size="small" id="week-select-label">
                  ອາທິດຮຽນ
                </InputLabel>
                <Select
                  size="small"
                  value={week}
                  label="ອາທິດຮຽນ"
                  onChange={(e) => handleChangeWeek(e.target.value)}
                >
                  <MenuItem key={"all"} value={"all"}>
                    ທຸກອາທິດ
                  </MenuItem>
                  <MenuItem key={"odd"} value={"odd"}>
                    ອາທິດຄີກ
                  </MenuItem>
                  <MenuItem key={"even"} value={"even"}>
                    ອາທິດຄູ່
                  </MenuItem>
                </Select>
                {formErrors.week && (
                  <Typography color="error" variant="caption">
                    {formErrors.week}
                  </Typography>
                )}
              </FormControl>
            </div>
          </div>

          <div className="col-12 mt-2">
            <div>
              <Typography sx={{ fontWeight: "bold" }}>
                {"ເລືອກຮູບເເບບການສອນ"}
              </Typography>
              <FormControl sx={{ marginTop: "20px" }} fullWidth>
                <InputLabel size="small" id="week-select-label">
                  ຮູບເເບບການສອນ
                </InputLabel>
                <Select
                  size="small"
                  value={studyType}
                  label="ຮູບເເບບການສອນ"
                  onChange={(e) => handleChangeStudyType(e.target.value)}
                >
                  <MenuItem key={"all"} value={"LECTURE"}>
                    ທິດສະດີ
                  </MenuItem>
                  <MenuItem key={"odd"} value={"LAB"}>
                    ປະຕິບັດ
                  </MenuItem>
                </Select>
                {formErrors.studyType && (
                  <Typography color="error" variant="caption">
                    {formErrors.studyType}
                  </Typography>
                )}
              </FormControl>
            </div>
          </div>
          <div className="col-12 mt-2">
            <div>
              <Typography sx={{ fontWeight: "bold" }}>
                {"ສະຖານທິສອນ"}
              </Typography>
              <FormControl sx={{ marginTop: "20px" }} fullWidth>
                <Autocomplete
                  options={placeStudyList}
                  getOptionLabel={(placeStudy) => placeStudy?.title}
                  value={placeStudyList.find((placeStudy) => placeStudy._id === selectedPlaceStudy) || null}
                  onChange={(event, newValue) => {
                    handleChangePlaceStudy(newValue ? newValue._id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="ສະຖານທິສອນ"
                      size="small"
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>

          <div className="col-12 mt-4">
            <div>
              <Typography sx={{ fontWeight: "bold" }}>ເລຶອກວິຊາ</Typography>
              <FormControl sx={{ marginTop: "20px" }} fullWidth>
                <InputLabel size="small" id="subject-select-label">
                  ວິຊາ
                </InputLabel>
                <Select
                  size="small"
                  value={selectedSubject}
                  label="ວິຊາ"
                  onChange={(e) => handleChangeSubject(e.target.value)}
                >
                  {subjects.map((subject) => (
                    <MenuItem key={subject._id} value={subject._id}>
                      {subject?.subject?.title}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.subject && (
                  <Typography color="error" variant="caption">
                    {formErrors.subject}
                  </Typography>
                )}
              </FormControl>
            </div>
          </div>

          <div className="col-12 mt-4">
            <Typography sx={{ fontWeight: "bold" }}>ເລືອກອາຈານສອນ</Typography>
            <div>
              <FormControl sx={{ marginTop: "20px" }} fullWidth>
                <InputLabel size="small" id="faculty-select-label">
                  ຄະນະ
                </InputLabel>
                <Select
                  size="small"
                  labelId="faculty-select-label"
                  value={faculty}
                  label={"ຄະນະ"}
                  onChange={(e) => {
                    handleChangeFaculty(e.target.value);
                    setDepartment("");
                  }}
                >
                  {facultyList.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.faculty && (
                  <Typography color="error" variant="caption">
                    {formErrors.faculty}
                  </Typography>
                )}
              </FormControl>
            </div>
          </div>

          <div className="col-12 mt-1">
            <div>
              <FormControl sx={{ marginTop: "20px" }} fullWidth>
                <InputLabel size="small" id="department-select-label">
                  ພາກ
                </InputLabel>
                <Select
                  label={"ພາກ"}
                  size="small"
                  labelId="department-select-label"
                  value={department}
                  onChange={(e) => handleChangeDepartment(e.target.value)}
                >
                  {departmentList.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.department && (
                  <Typography color="error" variant="caption">
                    {formErrors.department}
                  </Typography>
                )}
              </FormControl>
            </div>
          </div>

          <div className="col-12 mt-1">
            <FormControl sx={{ marginTop: "20px" }} fullWidth>
              <Autocomplete
                multiple
                options={teacherList}
                getOptionLabel={(teacher) =>
                  teacher.firstname + " " + teacher.lastname
                }
                value={selectedTeachers.map((teacherId) =>
                  teacherList.find((teacher) => teacher._id === teacherId)
                )}
                onChange={(event, newValue) => {
                  handleChangeTeachers(newValue.map((teacher) => teacher._id));
                }}
                renderTags={(selected, getTagProps) =>
                  selected.map((option, index) => (
                    <Chip
                      key={index}
                      label={option.firstname + " " + option.lastname}
                      {...getTagProps({ index })}
                      style={{ margin: 2 }}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="ອາຈານ"
                    size="small"
                    error={!!formErrors.teacher}
                    helperText={formErrors.teacher}
                  />
                )}
              />
            </FormControl>
          </div>
        </div>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            ບັນທຶກ
          </Button>
          <Button onClick={onCancel} sx={{ ml: 2 }}>
            ຍົກເລີກ
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { CustomContainer, Title } from "../../common";
import { Breadcrumb } from "react-bootstrap";
import PaymentListlFilter from "./common/PaymentListlFilter";
import { USER_KEY } from "../../consts";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { API_KEY, NEW_API_URL } from "../../common/contrant";
import axios from "axios";
import { setFilter } from "../../redux/filtrSlice";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { convertGenderNam } from "../../helpers/user";
import { currency, formatDate } from "../../common/super";
import { Pages, Print } from "@mui/icons-material";
import { BillTermComponent } from "./common/BillTermComponent";
import { useReactToPrint } from "react-to-print";
import ExportPaymentList from "./common/ExportPaymentList";
import ConfirmPaymentDialog from "./common/ConfirmPaymentDialog";
import { hasPermission } from "../../helpers/CheckPermission";

const PaymentList = () => {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;
  const filterData = useSelector((state) => state.filter);
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [faculties, setFaculties] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [majors, setMajors] = useState([]);
  const [classRooms, setClassRooms] = useState([]);
  const [payment, setPayment] = useState([]);
  const [count, setCount] = useState();
  const [educationLevels, setEducationLevels] = useState([]);
  const [onSuccess, setOnSuccess] = useState(false);
  const [studentDetail, setStudentDetail] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [paymentID, setPaymentId] = useState();
  const [amount, setAmount] = useState(0);
  const [totalRemain, setTotalRemain] = useState(0);
  const [formData, setFormData] = useState({
    status: "",
    date: "",
    firstName: "",
    educationLevel: filterData.educationLevel || "",
    faculty: filterData.faculty || "",
    department: filterData.department || "",
    major: filterData.major || "",
    year: "",
    classRoom: "",
    assementYear: accessmentRedux,
  });
  const componentRef = useRef();

  const openPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "faculty") {
      // If the faculty is changed, reset department to empty string
      setFormData({
        ...formData,
        [name]: value,
        department: "",
        major: "",
      });
    } else if (name === "department") {
      // If the faculty is changed, reset department to empty string
      setFormData({
        ...formData,
        [name]: value,
        major: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  useEffect(() => {
    let _filterData = {};
    if (formData.faculty !== "") {
      _filterData.faculty = formData.faculty;
    }

    // Check and set department if not null
    if (formData.department !== "") {
      _filterData.department = formData.department;
    }

    // Check and set major if not null
    if (formData.major !== "") {
      _filterData.major = formData.major;
    }
    // Dispatch the setFilter action with the filterData payload
    dispatch(setFilter(_filterData));
    // Fetch faculties
    axios
      .get(NEW_API_URL + "faculty", {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setFaculties(response.data?.data || []))
      .catch((error) => console.error(error));

    // Fetch departments
    axios
      .get(NEW_API_URL + "department/" + formData.faculty, {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setDepartments(response.data?.data || []))
      .catch((error) => console.error(error));

    // Fetch education levels
    axios
      .get(NEW_API_URL + "educationlevel", {
        headers: {
          api_key: API_KEY,
        },
      })

      .then((response) => setEducationLevels(response.data))
      .catch((error) => console.error(error));

    // Fetch majors
    if (formData.department) {
      axios
        .get(NEW_API_URL + "getmajo/" + formData.department, {
          headers: {
            api_key: API_KEY,
          },
        })
        .then((response) => setMajors(response.data?.data || []))
        .catch((error) => console.error(error));
    }
    if (formData.major && formData.year) {
      axios
        .get(NEW_API_URL + "class/" + formData.major + "/" + formData.year, {
          headers: {
            api_key: API_KEY,
          },
        })
        .then((response) => setClassRooms(response.data?.data || []))
        .catch((error) => console.error(error));
    }
  }, [formData]);

  useEffect(() => {
    let _status = "";
    if (formData.status === "paid") {
      _status = "&isPaid=paid";
    } else if (formData.status === "notPaid") {
      _status = "&isPaid=notPaid";
    } else {
      _status = "";
    }
    axios
      .get(
        NEW_API_URL +
          "register/payment?" +
          "limit=" +
          rowsPerPage +
          "&page=" +
          (page + 1) +
          "&faculty=" +
          formData.faculty +
          "&department=" +
          formData.department +
          "&major=" +
          formData.major +
          "&year=" +
          formData.year +
          "&classRoom=" +
          formData.classRoom +
          "&date=" +
          formData.date +
          "&firstName=" +
          formData.firstName +
          "&assessmentYear=" +
          accessmentRedux +
          "&educationLevel=" +
          formData.educationLevel +
          _status,
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setPayment(response.data?.data || []);
        setCount(response.data?.count);
        setOnSuccess(false);
      })
      .catch((error) => console.error(error));
  }, [formData, rowsPerPage, page, onSuccess, accessmentRedux]);

  const handlePay = (id) => {
    setShowConfirm(true);
    setPaymentId(id);
  };

  const handlePrint = async (id) => {
    if (!id) return;
    try {
      const response = await axios.get(NEW_API_URL + "course/subject/" + id, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });

      // setIsGetDetailError(false);
      if (response.status === 200) {
        setStudentDetail(response.data);
        openPrint();
        // setOpen(true);
      }
    } catch (err) {
      // setIsGetDetailError(true);
      console.log(err);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item active>ຈັດການການຈ່າຍເງິນ</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <Title text={`ຄົ້ນຫານັກສຶກສາມາສະເໜີຕົວ`} />
        <PaymentListlFilter
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
          educationLevels={educationLevels}
          faculties={faculties}
          departments={departments}
          majors={majors}
          classRooms={classRooms}
        />
      </CustomContainer>
      <CustomContainer>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <Title text={"ຫຼັກສູດທັງໝົດ"} />
            <span style={{ marginTop: 8, marginBottom: 20, marginLeft: 10 }}>
              {" "}
              ({currency(count)} ລາຍການ)
            </span>
          </div>
          {hasPermission("REGISTER_PAYMENT", "export") && (
            <div className="d-flex ">
              <ExportPaymentList data={payment} />
            </div>
          )}
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow color="info">
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold", width: 60 }}
                >
                  ລຳດັບ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ລະຫັດນັກສຶກສາ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ຊື່ ແລະ ນາມສະກຸນ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ວັນທີ່ຊຳລະ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ຈຳນວນທີຈ່າຍ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ຈຳນວນທີຍັງຄ້າງຈ່າຍ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ຄ່າຮຽນ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold", width: 70 }}
                >
                  ປີຮຽນ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ສະຖານະຊໍາລະ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ຢືນຢັນໂດຍ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ຈັດການ
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {payment?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {item?.student?.studentNumber}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "wrap" }}>
                    {convertGenderNam(item?.student?.gender)}{" "}
                    {item?.student?.firstNameL} {item?.student?.lastNameL}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(item?.createdAt)}
                  </TableCell>
                  <TableCell align="center">{item?.currentPaid}</TableCell>
                  <TableCell align="center">
                    <Typography
                      sx={{
                        color:
                          item?.totalPaid - item?.currentPaid === 0
                            ? "green"
                            : "red",
                      }}
                    >
                      {item?.totalPaid - item?.currentPaid}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {item?.totalPaid.toLocaleString()} ກີບ
                  </TableCell>
                  <TableCell align="center">{item?.year}</TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: item?.isPaid === false ? "red" : "green" }}
                  >
                    {item?.isPaid === false ? "ຍັງບໍ່ທັນຊໍາລະ" : `ຊໍາລະສໍາເລັດ`}
                  </TableCell>
                  <TableCell align="center">
                    {item?.createdBy &&
                      `ທ່ານ ${
                        item?.createdBy?.gender === "FEMALE" ? "ນາງ" : ""
                      } ${item?.createdBy?.firstname} ${
                        item?.createdBy?.lastname
                      }`}
                  </TableCell>
                  <TableCell align="center">
                    {item?.isPaid === false ? (
                      hasPermission("REGISTER_PAYMENT", "register") && (
                        <Button
                          color="info"
                          variant="contained"
                          onClick={() => {
                            setAmount(item?.totalPaid);
                            handlePay(item?._id);
                            setTotalRemain(item?.totalPaid - item?.currentPaid);
                          }}
                        >
                          ຊຳລະເງິນ
                        </Button>
                      )
                    ) : (
                      <Button
                        color="success"
                        variant="contained"
                        sx={{ textTransform: "none" }}
                        onClick={() => handlePrint(item?.student?._id)}
                      >
                        Print <Print />
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            <TablePagination
              rowsPerPageOptions={[10, 20, 50, { label: "All", value: count }]}
              colSpan={8} // Adjusted colSpan to include the Actions column
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              elevation={0}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Table>
        </TableContainer>
      </CustomContainer>
      <ConfirmPaymentDialog
        totalRemain={totalRemain}
        amount={amount}
        setOnSuccess={setOnSuccess}
        setShowConfirm={setShowConfirm}
        showConfirm={showConfirm}
        paymentID={paymentID}
      />
      {studentDetail && (
        <BillTermComponent ref={componentRef} studentDetail={studentDetail} />
      )}
    </div>
  );
};

export default PaymentList;

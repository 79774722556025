import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { fetchPost } from "../../common/fetchAPI"; // Replace with your API call function

const PasswordStrengthMeter = ({ password }) => {
  const getPasswordStrength = (pwd) => {
    if (pwd.length < 6) return { label: "ອ່ອນຫວານ", color: "red" };
    if (pwd.length < 8) return { label: "ປານກາງ", color: "orange" };
    if (pwd.match(/[A-Z]/) && pwd.match(/[0-9]/) && pwd.match(/[@$!%*?&]/)) {
      return { label: "ແຂງແຮງ", color: "green" };
    }
    return { label: "ກາງ", color: "blue" };
  };

  const { label, color } = getPasswordStrength(password);

  return (
    <Typography
      variant="body2"
      sx={{ color, fontWeight: "bold", mt: 1, textAlign: "center" }}
    >
      ລະດັບຄວາມປອດໄພຂອງອະຫັດ: {label}
    </Typography>
  );
};

const UpdatePasswordDialog = ({ open, onClose, studentNumber }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      setError("ກະລຸນາປ້ອນລະຫັດຜ່ານ");
      return;
    }
    setError("");
    setLoading(true);

    try {
      const response = await fetchPost("student/password", {
        studentNumber,
        newPassword: password,
      });
      if (response.success) {
        onClose(); // Close the dialog on success
      } else {
        setError(response.message || "ເກີດຂໍ້ຜິດພາດໃນການປ່ຽນລະຫັດ");
      }
    } catch (err) {
      setError("ເກີດຂໍ້ຜິດພາດໃນການປ່ຽນລະຫັດ.");
    }

    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: { borderRadius: 3, p: 2 },
      }}
    >
      <DialogTitle sx={{ fontWeight: "bold", textAlign: "center" }}>
        ປ່ຽນລະຫັດຜ່ານ
      </DialogTitle>

      <DialogContent>
        <Stack spacing={2} mt={1}>
          <Typography variant="body2" color="text.secondary" textAlign="center">
            ກະລຸນາປ້ອນລະຫັດຜ່ານໃຫມ່ຂອງທ່ານ.
          </Typography>

          <TextField
            type="password"
            label="ລະຫັດຜ່ານໃຫມ່ (ຂັ້ນຕ່ຳ 6 ຕົວອັກສອນ)"
            placeholder="ປ້ອນລະຫັດຜ່ານ"
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <PasswordStrengthMeter password={password} />

          <TextField
            type="password"
            label="ຢືນຢັນລະຫັດຜ່ານ"
            placeholder="ປ້ອນລະຫັດຜ່ານອີກຄັ້ງ"
            fullWidth
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={confirmPassword && password !== confirmPassword}
            helperText={
              confirmPassword && password !== confirmPassword
                ? "ລະຫັດຜ່ານບໍ່ຄືກັນ"
                : ""
            }
          />
        </Stack>
        {/* Error Message */}
        {error && (
          <Typography color="error" sx={{ mt: 1, textAlign: "center" }}>
            {error}
          </Typography>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between", px: 3, pb: 2 }}>
        <Button
          variant="outlined"
          color="inherit"
          onClick={onClose}
          sx={{ flex: 1, mr: 1 }}
        >
          ຍົກເລີກ
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ flex: 1, ml: 1 }}
          disabled={loading || password.length < 6}
        >
          {loading ? "ກຳລັງອັບເດດ..." : "ຢືນຢັນ"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdatePasswordDialog;

import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Paper,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import ChairIcon from "@mui/icons-material/Chair";
import { fetchGet } from "../../common/fetchAPI";
import { toast } from "react-toastify";

const AssignOneDialog = ({ open, onClose, onSelect, assessmentYear, student, isSubmitted }) => {
    const accessmentRedux = useSelector((state) => state.accessment.value);
    const [placeList, setPlaceList] = useState([]);
    const [buildingList, setBuildingList] = useState([]);
    const [classRoomList, setClassRoomList] = useState([]);
    const [roomDetail, setRoomDetail] = useState(null);
    const [formData, setFormData] = useState({
        building: "",
        place: "",
        classroom: "",
    });

    const fetchPlaceList = async () => {
        const response = await fetchGet(`entrance/place?assessmentYear=${assessmentYear}`);
        if (response) setPlaceList(response);
    };

    const fetchRoomDetail = async () => {
        if (formData.classroom) {
            const response = await fetchGet(`entrance/room/${formData.classroom}`);
            if (response) setRoomDetail(response);
        } else {
            setRoomDetail(null);
        }
    };

    const fetchBuildingList = async () => {
        const response = await fetchGet(`entrance/building?place=${formData?.place === "All" ? "" : formData?.place}`);
        if (response) setBuildingList(response);
    };

    const fetchClassRoomList = async () => {
        const response = await fetchGet(`entrance/room?building=${formData?.building === "All" ? "" : formData?.building}&group=${student?.group}`);
        if (response) setClassRoomList(response);
    };

    useEffect(() => {
        if (formData?.place) {
            fetchBuildingList();
        }
    }, [formData?.place, accessmentRedux]);

    useEffect(() => {
        setClassRoomList([]);
        setRoomDetail(null);
        if (formData?.building) fetchClassRoomList();
    }, [formData?.building]);

    useEffect(() => {
        fetchRoomDetail();
    }, [formData.classroom]);

    useEffect(() => {
        fetchPlaceList();
        setFormData({
            building: "",
            place: "",
            classroom: "",
        });
    }, [open]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSelect = () => {
        if (roomDetail?.details?.availableSeats <= 0) {
            toast.warn("ຈຳນວນບ່ອນນັ່ງບໍ່ພຽງພໍ! ກະລຸນາໄປເພິ່ມຈຳນວນບ່ອນນັ່ງໃສ່ຫ້ອງກ່ອນ", {
                autoClose: 5000, // Customize hide time to 5 seconds (5000ms)
            });
        } else {
            if (onSelect) onSelect(formData);
        }

    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>ຍ້າຍຫ້ອງໃຫ້ນັກສຶກສາ</DialogTitle>
            <DialogContent>
                <Paper elevation={0}>
                    <Grid container pt={2} justifyContent="center" spacing={2}>
                        {/* Place Selector */}
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel size="small">ເລືອກສະຖານທີ</InputLabel>
                                <Select size="small" name="place" value={formData?.place} onChange={handleChange}>
                                    {placeList.map((place) => (
                                        <MenuItem key={place._id} value={place._id}>
                                            {place.place}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Building Selector */}
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ mb: 2 }} disabled={!formData?.place}>
                                <InputLabel size="small">ເລືອກຕຶກ</InputLabel>
                                <Select size="small" name="building" value={formData?.building} onChange={handleChange}>
                                    {buildingList.map((building) => (
                                        <MenuItem key={building._id} value={building._id}>
                                            {building.building}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{ mb: 3 }} disabled={!formData?.building}>
                                <InputLabel size="small">ເລືອກຫ້ອງເສັງ</InputLabel>
                                <Select size="small" name="classroom" value={formData?.classroom} onChange={handleChange}>
                                    {classRoomList.map((room) => (
                                        <MenuItem key={room._id} value={room._id}>
                                            {room.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* Details Section */}
                            {roomDetail && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" alignItems="center">
                                            <PersonIcon color="primary" sx={{ mr: 1 }} />
                                            <Typography variant="body1">
                                                <strong>ນັກສຶກສາທີ່ຈັດແລ້ວ:</strong>{" "}
                                                <span style={{ fontWeight: 600, fontSize: "1.1rem", color: "#1976d2" }}>
                                                    {roomDetail?.details?.assignedStudents || "0"}
                                                </span>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" alignItems="center">
                                            <ChairIcon color="secondary" sx={{ mr: 1 }} />
                                            <Typography variant="body1">
                                                <strong>ບ່ອນນັ່ງທີ່ເຫລືອ:</strong>{" "}
                                                <span style={{ fontWeight: 600, fontSize: "1.1rem", color: "#388e3c" }}>
                                                    {roomDetail?.details?.availableSeats || "0"}
                                                </span>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    ຍົກເລີກ
                </Button>
                <Button
                    onClick={handleSelect}
                    color="primary"
                    disabled={formData?.classroom === "" || isSubmitted}
                >
                    ບັນທຶກ
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AssignOneDialog
import {
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Divider,
  Grid,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PaymentsIcon from "@mui/icons-material/Payments";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import WarningIcon from "@mui/icons-material/Warning";
import React, { useState, useEffect } from "react";
import AlertMessage from "../../common/AlertMessage";
import ConfirmModal from "../../common/ConfirmModal";
import { fetchPost, fetchPut } from "../../common/fetchAPI";

const PaymentDetailsModal = ({ open, handleClose, student }) => {
  const [paymentPayload, setPaymentPayload] = useState(null);
  const [showPaymentConfirmModal, setShowPaymentConfirmModal] = useState(false);
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
  const [paymentIsLoading, setPaymentIsLoading] = useState(false);
  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "error",
  });

  useEffect(() => {
    if (actionStatus.open) {
      const timeout = setTimeout(() => {
        setActionStatus((prev) => ({ ...prev, open: false }));
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [actionStatus]);

  if (!student) return null;

  const totalDue = student?.priceList?.reduce(
    (sum, price) => sum + (price?.entrancePriceList?.amount || 0),
    0
  );
  const totalPaid = student?.priceList?.reduce(
    (sum, price) => sum + price.amount,
    0
  );

  const isUnPaid = student?.priceList?.some(
    (item) => item.status === "PENDING" || item.status === "CANCELLED"
  );

  const isPaid = student?.priceList?.some((item) => item.status === "PAID");

  let remainingBalance = totalDue;
  if (isPaid) {
    remainingBalance = totalDue - totalPaid;
  }

  const handleShowDialog = () => {
    const selectedPriceList = student?.priceList
      .filter(
        (price) => price.status === "PENDING" || price.status === "CANCELLED"
      )
      .map((price) => ({
        entrancePriceList: price?.entrancePriceList?._id,
        amount: price.amount,
      }));

    const payload = {
      paymentId: student._id,
      studentId: student?.student?._id,
      priceList: selectedPriceList,
      totalAmount: selectedPriceList.reduce((sum, p) => sum + p.amount, 0),
      paymentMethod: "CASH", // default to CASH, update as needed
      transactionId: student.transactionId || "",
    };

    setPaymentPayload(payload);
    setShowPaymentConfirmModal(true);
  };

  const handleCancePayment = async (reason) => {
    setPaymentIsLoading(true);
    const response = await fetchPut("/entrance/payment/" + student?._id, {
      cancelReason: reason,
    });
    setPaymentIsLoading(false);
    setShowCancelConfirmModal(false);
    if (response?.success) {
      setActionStatus({
        open: true,
        message: "ຍົກເລີກການຈ່າຍເງິນສຳເລັດ",
        type: "success",
      });
      setTimeout(() => {
        handleClose();
      }, 1000);
    } else {
      setActionStatus({
        open: true,
        message: "ຍົກເລີກການຈ່າຍເງິນບໍ່ສຳເລັດ",
        type: "error",
      });
    }
  };

  const makePayment = async () => {
    setPaymentIsLoading(true);
    const response = await fetchPost("/entrance/payment/admin", paymentPayload);
    if (response?.success) {
      setActionStatus({
        open: true,
        message: "ການຊຳລະເງິນສຳເລັດແລ້ວ",
        type: "success",
      });
      setTimeout(() => {
        handleClose();
      }, 1000);
    } else {
      setActionStatus({
        open: true,
        message: "ການຊຳລະເງິນບໍ່ສຳເລັດ",
        type: "error",
      });
    }

    setShowPaymentConfirmModal(false);
    setPaymentIsLoading(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <>
        <AlertMessage
          message={actionStatus.message}
          open={actionStatus.open}
          type={actionStatus.type}
        />
        <ConfirmModal
          onCancel={() => setShowPaymentConfirmModal(false)}
          onSubmit={async () => {
            await makePayment();
            setShowPaymentConfirmModal(false);
          }}
          open={showPaymentConfirmModal}
          title="ຢືນຢັນການຈ່າຍເງິນ"
          message="ທ່ານແນ່ໃຈບໍ່ວ່າຈະຊຳລະເງິນ?"
          loading={paymentIsLoading}
        />
        <ConfirmModal
          onCancel={() => setShowCancelConfirmModal(false)}
          onSubmit={async (reason) => {
            await handleCancePayment(reason);
          }}
          open={showCancelConfirmModal}
          hasInput
          placeholder="ເຫດຜົນການຍົກເລີກ"
          title="ຢືນຢັນການຍົກເລີກການຈ່າຍເງິນ"
          message="ທ່ານແນ່ໃຈບໍ່ວ່າຈະຍົກເລີກການຈ່າຍເງິນ?"
          loading={paymentIsLoading}
        />

        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 3,
            minWidth: 650,
            maxWidth: "90%",
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              <PaymentsIcon fontSize="small" sx={{ mr: 1 }} />
              ລາຍລະອຽດນັກສຶກສາ & ການຈ່າຍເງິນ
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Student Info */}
          <Box sx={{ mb: 3, p: 2, bgcolor: "#f5f5f5", borderRadius: 2 }}>
            <Typography fontWeight="bold" mb={1}>
              <AccountCircleIcon fontSize="small" sx={{ mr: 1 }} />
              ຂໍ້ມູນນັກສຶກສາ
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography>
                  <strong>ຊື່:</strong> {student?.student?.firstNameL}{" "}
                  {student?.student?.lastNameL}
                </Typography>
                <Typography>
                  <strong>ລະຫັດ:</strong> {student?.student?.code}
                </Typography>
                <Typography>
                  <strong>ເບີໂທ:</strong> {student?.student?.phoneMobile}
                </Typography>
                <Typography>
                  <strong>ສາຂາ:</strong> {student?.student?.major?.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>ພະແນກ:</strong> {student?.student?.department?.name}
                </Typography>
                <Typography>
                  <strong>ຄະນະ:</strong> {student?.student?.faculty?.name}
                </Typography>
                <Typography>
                  <strong>ວັນສ້າງ:</strong>{" "}
                  {new Date(student?.createdAt).toLocaleString()}
                </Typography>
                <Typography>
                  <strong>ອັບເດດ:</strong>{" "}
                  {new Date(student?.updatedAt).toLocaleString()}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* Payment Summary (2-Column Layout) */}
          <Box sx={{ mb: 3, p: 2, bgcolor: "#f5f5f5", borderRadius: 2 }}>
            <Typography fontWeight="bold" mb={1}>
              <CreditCardIcon fontSize="small" sx={{ mr: 1 }} />
              ສະຫຼຸບການຈ່າຍເງິນ
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography>
                  <ErrorIcon fontSize="small" sx={{ color: "red", mr: 1 }} />
                  ຈຳນວນທັງຫມົດທີ່ຕ້ອງຈ່າຍ:{" "}
                  <strong style={{ color: "red" }}>{totalDue} ກິບ</strong>
                </Typography>

                {isPaid && (
                  <>
                    <Typography>
                      <CheckCircleIcon
                        fontSize="small"
                        sx={{ color: "green", mr: 1 }}
                      />
                      ຈ່າຍແລ້ວ:{" "}
                      <strong style={{ color: "green" }}>
                        {totalPaid} ກິບ
                      </strong>
                    </Typography>
                  </>
                )}
                <Typography>
                  <WarningIcon
                    fontSize="small"
                    sx={{
                      color: remainingBalance > 0 ? "orange" : "green",
                      mr: 1,
                    }}
                  />
                  ຄ້າງຈ່າຍ:{" "}
                  <strong
                    style={{ color: remainingBalance > 0 ? "orange" : "green" }}
                  >
                    {remainingBalance} ກິບ
                  </strong>
                </Typography>
              </Grid>
              {isPaid && (
                <>
                  <Grid item xs={6}>
                    {student?.paymentMethod && (
                      <Typography>
                        <PaymentsIcon
                          fontSize="small"
                          sx={{ color: "blue", mr: 1 }}
                        />
                        ຮູບແບບການຊຳລະ:{" "}
                        <strong>
                          {student.paymentMethod === "CASH"
                            ? "ຈ່າຍສົດ"
                            : "ຈ່າຍໂອນs"}
                        </strong>
                      </Typography>
                    )}
                    {student?.transactionId && (
                      <Typography>
                        <CreditCardIcon
                          fontSize="small"
                          sx={{ color: "purple", mr: 1 }}
                        />
                        ເລກທຸລະກຳ:{" "}
                        <strong style={{ color: "purple" }}>
                          {student.transactionId}
                        </strong>
                      </Typography>
                    )}
                    <Typography>
                      <AccountCircleIcon
                        fontSize="small"
                        sx={{ color: "gray", mr: 1 }}
                      />
                      ຜູ້ດຳເນີນການຈ່າຍ:{" "}
                      <strong>
                        {student.paymentMethod === "CASH"
                          ? `${student?.createdBy?.firstname} ${student?.createdBy?.lastname}`
                          : "ນັກສຶກສາ"}
                      </strong>
                    </Typography>
                    {student?.cancelReason && (
                      <Typography>
                        <CancelIcon
                          fontSize="small"
                          sx={{ color: "gray", mr: 1 }}
                        />
                        ເຫດຜົນການຍົກເລີກ:{" "}
                        <strong>{student.cancelReason}</strong>
                      </Typography>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Box>

          {/* Payment List */}
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ bgcolor: "#f5f5f5" }}>
                  <TableCell>ລາຍການ</TableCell>
                  <TableCell align="right">ຈຳນວນເງີນທີ່ຕ້ອງຈ່າຍ</TableCell>
                  <TableCell align="right">ຈ່າຍແລ້ວ</TableCell>
                  <TableCell align="right">ສະຖານະ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {student?.priceList?.map((price, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{price?.entrancePriceList?.title}</TableCell>
                    <TableCell align="right">
                      {price?.entrancePriceList?.amount}
                    </TableCell>
                    <TableCell align="right">
                      {isPaid ? price.amount : "0"}
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          fontWeight: "bold",
                          gap: 0.5,
                          color:
                            price.status === "PAID"
                              ? "green"
                              : price.status === "CANCELLED"
                              ? "gray"
                              : price.status === "PENDING"
                              ? "orange"
                              : "red",
                        }}
                      >
                        {price.status === "PAID" && (
                          <>
                            <CheckCircleIcon fontSize="small" /> ຈ່າຍແລ້ວ
                          </>
                        )}
                        {price.status === "CANCELLED" && (
                          <>
                            <CancelIcon fontSize="small" /> ຍົກເລີກ
                          </>
                        )}
                        {price.status === "PENDING" && (
                          <>
                            <PendingActionsIcon fontSize="small" /> ລໍຖ້າຊຳລະ
                          </>
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {isPaid && (
            <Box sx={{ textAlign: "right", mt: 3 }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => setShowCancelConfirmModal(true)}
                startIcon={<CancelIcon />}
              >
                ຍົກເລີກການຈ່າຍເງິນ
              </Button>
            </Box>
          )}

          {isUnPaid && (
            <Box sx={{ textAlign: "right", mt: 3 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleShowDialog}
                startIcon={<PaymentsIcon />}
              >
                ດຳເນີນການຈ່າຍເງິນ
              </Button>
            </Box>
          )}
        </Box>
      </>
    </Modal>
  );
};

export default PaymentDetailsModal;

import React, { useEffect, useState } from "react";
import { fetchGet, fetchPostV2 } from "../../../common/fetchAPI";
import { Breadcrumb } from "react-bootstrap";
import { Tab, Tabs } from "@mui/material";
import { toast } from "react-toastify";
import { USER_KEY } from "../../../consts";

const AssessmentYearSetting = () => {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const role = userObject?.data?.role;
  const [currentYear, setCurrentYear] = useState("");
  const [accessmentYear, setAccessmentYear] = useState("");
  const [accessmentYearList, setAccessmentYearList] = useState([]);
  const [value, setValue] = useState(role === "ADMIN" ? "ENTRANCE" : "COURSE");

  useEffect(() => {
    let year = [];
    const NOW = new Date().getFullYear() + 6;
    // const checkMonth = new Date().getMonth() + 1;
    // if (checkMonth >= 10) {
    //     for (var i = NOW; i >= 2014; i--) {
    //         year.push(i + " - " + (parseInt(i) + 1));
    //     }
    // } else {
    for (var i = NOW; i >= 2014; i--) {
      year.push(i + " - " + (parseInt(i) + 1));
    }
    // }
    setAccessmentYearList(year);
  }, []);

  useEffect(() => {
    fetchCurrentYear();
  }, [value]);

  const fetchCurrentYear = async () => {
    try {
      const response = await fetchGet("/accessmentyear");

      if (!response?.length) {
        toast.error("ຂໍອະໄພ ລະບົບມີບັນຫາ!", {
          autoClose: 3000,
        });
        return;
      }
      const currentYear = response.find((year) => year?.type === value) || response[0];
      setCurrentYear(currentYear?.accessmentYear);
    } catch (err) {
      toast.error("ຂໍອະໄພ ລະບົບມີບັນຫາ!", {
        autoClose: 3000,
      });
    }
  };

  const AddAcessmentYear = async (data) => {
    try {
      const dataBody = { ...data, type: value }
      const response = await fetchPostV2("/accessmentyear", dataBody);
      if (response?._id) {
        toast.success("ປ່ຽນສົກສຳເລັດ", {
          autoClose: 3000,
        })
        fetchCurrentYear();
      } else {
        toast.error("ທ່ານບໍ່ມີສິດໃນການປ່ຽນສົກ!", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("ລະບົບມີບັນຫາ ບໍ່ສາມາດປ່ຽນສົກ!", {
        autoClose: 3000,
      });
    }
  };

  const handleSelectChange = (event) => {
    setAccessmentYear(event.target.value);
  };

  const submit = () => {
    if (accessmentYear) AddAcessmentYear({ accessmentYear });
    else toast.warn("ກະລຸນາເລືອກສົກກ່ອນ!", {
      autoClose: 3000,
    });;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item active>ຈັດການການເປີດສົກ</Breadcrumb.Item>
      </Breadcrumb>
      <Tabs
        value={value}
        onChange={handleChange}
        orientation="horizontal"
        sx={{ marginLeft: 1 }}
      >
        {role === "ADMIN" &&
          <Tab
            value="ENTRANCE"
            label="ເປີດສົກສຳລັບ Entrance"
            id="tab-0"
            sx={{
              backgroundColor: value === "ENTRANCE" ? "white" : "inherit",
              borderRadius: "5px 5px 0px 0px",
            }}
          />
        }

        {role !== "ADMIN" && <Tab
          value="COURSE"
          label="ເປີດສົກສຳລັບ ຫຼັກສູດ"
          id="tab-1"
          sx={{
            backgroundColor: value === "COURSE" ? "white" : "inherit",
            borderRadius: "5px 5px 0px 0px",
          }}
        />
        }
        {role !== "ADMIN" &&
          <Tab
            value="STUDENT"
            label="ເປີດສົກສຳລັບ ນັກສຶກສາ"
            id="tab-2"
            sx={{
              backgroundColor: value === "STUDENT" ? "white" : "inherit",
              borderRadius: "5px 5px 0px 0px",
            }}
          />
        }
      </Tabs>

      <div class="vh-100 bg-white text-center p-5">
        <div className="row">
          <h2>ສົກປັດຈຸບັນ {currentYear}</h2>
        </div>
        <div className="row w-100">
          <div className="col-4"></div>
          <div className="col-4">
            <select
              class="form-select form-select-md mb-3 custom-select text-center"
              aria-label=".form-select-lg example"
              value={accessmentYear}
              onChange={handleSelectChange}
            >
              <option value="">ເລືອກສົກຮຽນ</option>
              {accessmentYearList.map((item) => (
                <option className="text-center" key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="col-4"></div>
        </div>

        <div className="me-4 pe-3">
          <button onClick={submit} className="newbt-sm shadow">
            ຢືນຢັນ
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssessmentYearSetting;

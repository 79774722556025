export const generateAcademicYears = () => {
  const years = [];
  const currentYear = new Date().getFullYear() + 1;
  const currentMonth = new Date().getMonth() + 1;

  for (let i = currentYear; i >= currentYear - 20; i--) {
    if (currentMonth >= 6) {
      years.push(`${i} - ${i + 1}`);
    } else {
      years.push(`${i - 1} - ${i}`);
    }
  }

  return years;
};

export const generateAcademicYearsFromNextToFive = (startYear) => {
  const years = [];
  for (let i = startYear; i < startYear + 5; i++) {
    years.push(`${i} - ${i + 1}`);
  }

  return years;
};
import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { Breadcrumb } from "react-bootstrap";
import StudentCurrentList from "./StudentCurrentList";
import StudentHistoryList from "./StudentHistoryList";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}{" "}
      {/* Adjusted padding to 0 */}
    </div>
  );
};

const NewStudentList = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item active>ຂໍ້ມູນນັກສຶກສາ</Breadcrumb.Item>
      </Breadcrumb>
      <Tabs
        value={value}
        onChange={handleChange}
        orientation="horizontal"
        sx={{ marginLeft: 1 }}
      >
        <Tab
          label="ຂໍ້ມູນນັກສຶກສາປັດຈຸບັນ"
          id="tab-0"
          sx={{
            backgroundColor: value === 0 ? "white" : "inherit",
            borderRadius: "5px 5px 0px 0px",
          }}
        />
        <Tab
          label="ປະຫວັດຂໍ້ມູນນັກສຶກສາ"
          id="tab-1"
          sx={{
            backgroundColor: value === 1 ? "white" : "inherit",
            borderRadius: "5px 5px 0px 0px",
          }}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <StudentCurrentList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <StudentHistoryList />
      </TabPanel>
    </div>
  );
};

export default NewStudentList;

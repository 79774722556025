import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  Box,
  Stack,
  Chip,
  Card,
  CardContent,
  Alert,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { fetchGet } from "../../common/fetchAPI";

const SelectStudentGroupDialog = ({
  open,
  onClose,
  onSubmit,
  formRoom,
  formRoomValidate,
  handleInputChange,
  isLoading,
  assessmentYear,
}) => {
  const [checkList, setCheckList] = useState(null);
  const [loadingCheckList, setLoadingCheckList] = useState(false);

  useEffect(() => {
    const fetchCheckList = async () => {
      if (formRoom?.group) {
        setLoadingCheckList(true);
        const response = await fetchGet(
          `entrance/room/check?assessmentYear=${assessmentYear}&group=${formRoom.group}`
        );
        setCheckList(response);
        setLoadingCheckList(false);
      } else {
        setCheckList(null);
      }
    };
    fetchCheckList();
  }, [open, formRoom?.group]);

  const renderStatus = (isPass) =>
    isPass ? (
      <Chip
        icon={<CheckCircleIcon />}
        label="ຜ່ານ"
        color="success"
        size="small"
      />
    ) : (
      <Chip icon={<CancelIcon />} label="ບໍ່ຜ່ານ" color="error" size="small" />
    );

  const isInvalid =
    checkList &&
    (!checkList.startDateNoExpiredYet.isCan ||
      !checkList.studentInvalid.isCan ||
      !checkList.classInvalid.isCan);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "600px",
          padding: 3,
          maxWidth: "90%",
          borderRadius: 3,
        },
      }}
    >
      <DialogTitle>ເລືອກນັກສຶກສາຕາມຫມວດສາຍຮຽນ</DialogTitle>

      <DialogContent dividers>
        <FormControl size="small" fullWidth>
          <InputLabel>ຫມວດສາຍຮຽນ</InputLabel>
          <Select
            label="ຫມວດສາຍຮຽນ"
            name="group"
            value={formRoom.group}
            onChange={handleInputChange}
            error={!!formRoomValidate?.group}
          >
            <MenuItem value={"A"}>A</MenuItem>
            <MenuItem value={"B"}>B</MenuItem>
            <MenuItem value={"C"}>C</MenuItem>
          </Select>
        </FormControl>

        {formRoom?.group && (
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              ຜົນການກວດສອບ
            </Typography>

            {loadingCheckList ? (
              <Box display="flex" justifyContent="center" py={4}>
                <CircularProgress />
              </Box>
            ) : checkList ? (
              <Stack spacing={2}>
                {/* Start Date Check */}
                <Card variant="outlined">
                  <CardContent>
                    <Box display="flex" justifyContent="space-between">
                      <Typography fontWeight="bold">
                        📅 ກວດວັນທີເຂົ້າຟອມ
                      </Typography>
                      {renderStatus(checkList.startDateNoExpiredYet.isCan)}
                    </Box>
                    <Typography variant="body2" color="text.secondary" mt={1}>
                      ວັນເລີ່ມ:{" "}
                      {new Date(
                        checkList.startDateNoExpiredYet.startDate
                      ).toLocaleDateString()}{" "}
                      | ວັນສິ້ນສຸດ:{" "}
                      {new Date(
                        checkList.startDateNoExpiredYet.endDate
                      ).toLocaleDateString()}
                    </Typography>
                    {!checkList.startDateNoExpiredYet.isCan && (
                      <Alert severity="warning" sx={{ mt: 2 }}>
                        ທ່ານບໍ່ສາມາດຈັດນັກສຶກສາໃສ່ຫ້ອງເສັງໄດ້ເພາະຍັງບໍ່ທັນຮອດມື້ປີດຮັບສະຫມັກເສັງເທື່ອ
                      </Alert>
                    )}
                  </CardContent>
                </Card>

                {/* Student Invalid Check */}
                <Card variant="outlined">
                  <CardContent>
                    <Box display="flex" justifyContent="space-between">
                      <Typography fontWeight="bold">
                        👥 ກວດສະຖານະນັກສຶກສາ
                      </Typography>
                      {renderStatus(checkList.studentInvalid.isCan)}
                    </Box>
                    <Typography variant="body2" color="text.secondary" mt={1}>
                      ຈຳນວນນັກສຶກສາທີຕ້ອງການປ່ຽນສາຍ:{" "}
                      {checkList.studentInvalid.count} ຄົນ
                    </Typography>
                    {!checkList.studentInvalid.isCan && (
                      <Alert severity="warning" sx={{ mt: 2 }}>
                        ທ່ານບໍ່ສາມາດຈັດນັກສຶກສາໃສ່ຫ້ອງເສັງເພາະຍັງມີນັກສຶກສາທີລໍຖ້າການປ່ຽນສາຍຮຽນຢູ່
                      </Alert>
                    )}
                  </CardContent>
                </Card>

                {/* Class Capacity Check */}
                <Card variant="outlined">
                  <CardContent>
                    <Box display="flex" justifyContent="space-between">
                      <Typography fontWeight="bold">
                        🏠 ກວດຄວາມຈຸຂອງຫ້ອງ
                      </Typography>
                      {renderStatus(checkList.classInvalid.isCan)}
                    </Box>
                    <Typography variant="body2" color="text.secondary" mt={1}>
                      ຈັດ: {checkList.classInvalid.studentsCount} ຄົນ | ຄວາມຈຸ:{" "}
                      {checkList.classInvalid.roomsContainsCount} ຄົນ
                    </Typography>
                    {checkList.classInvalid.needMore > 0 && (
                      <Alert severity="warning" sx={{ mt: 2 }}>
                        ຈຳນວນນັກສຶກສາເກີນ {checkList.classInvalid.needMore} ຄົນ.
                        ກະລຸນາແບ່ງກຸ່ມ ຫຼື ສ້າງຫ້ອງເພີ່ມເພື່ອຮອງຮັບ.
                      </Alert>
                    )}
                  </CardContent>
                </Card>
              </Stack>
            ) : null}
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button disabled={isLoading} onClick={onClose}>
          ຍົກເລີກ
        </Button>
        <Button
          onClick={onSubmit}
          variant="contained"
          disabled={isLoading || isInvalid}
          sx={{
            position: "relative",
            width: "120px", // optional to keep consistent width
            height: "36px",
          }}
        >
          {isLoading ? (
            <CircularProgress
              size={24}
              sx={{
                color: "#FFFFFF", // ✅ Explicit white color
              }}
            />
          ) : (
            "ບັນທຶກ"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectStudentGroupDialog;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchGet } from "../../../common/fetchAPI";
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from "@mui/material";

const STATUS_CHIPS = [
  { label: "ນັກສຶກສາສະຫມັກເສັງ", value: "PENDING" },
  { label: "ນັກສຶກສາຖືກຢືນຢັນ", value: "APPROVED" },
];

const TYPE_CHIPS = [
  { label: "ສາຍຫລັກ", value: "1" },
  { label: "ສາຍສຳຮອງ1", value: "2" },
  { label: "ສາຍສຳຮອງ2", value: "3" },
];

const StudentReportTable = () => {
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState("PENDING");
  const [type, setType] = useState("1");

  useEffect(() => {
    const fetchData = async () => {
      // For refreshes, we don't clear the previous data immediately.
      setLoading(true);

      const response = await fetchGet(
        `entrance/report/major/${accessmentRedux}/${type}?status=${status}`
      );
      if (response) setReport(response);
      else setError("ບໍ່ມິຂໍ້ມຸນ");
      setLoading(false);
    };
    fetchData();
  }, [accessmentRedux, status, type]);

  // If there is an error or no data on initial load, show a full spinner or error.
  if (!report && loading)
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box sx={{ p: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );

  return (
    <Box sx={{ p: 4, position: "relative" }}>
      <Typography variant="h6" gutterBottom>
        ລາຍງານນັກສຶກສາ
      </Typography>
      <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
        {STATUS_CHIPS.map((chip) => (
          <Chip
            key={chip.value}
            label={chip.label}
            color={status === chip.value ? "primary" : "default"}
            onClick={() => setStatus(chip.value)}
          />
        ))}
      </Box>

      <Box sx={{ display: "flex", gap: 1, mb: 3 }}>
        {TYPE_CHIPS.map((chip) => (
          <Chip
            key={chip.value}
            label={chip.label}
            color={type === chip.value ? "secondary" : "default"}
            onClick={() => setType(chip.value)}
          />
        ))}
      </Box>

      {/* Overlay spinner during refresh */}
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <TableContainer component={Paper} sx={{ opacity: loading ? 0.5 : 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "white",
                }}
              >
                ຄະນະ
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "white",
                }}
              >
                ພາກ
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "white",
                }}
              >
                ສາຂາ
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "white",
                }}
                align="right"
              >
                ຈຳນວນຕາມສາຂາ
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "white",
                }}
                align="right"
              >
                ຈຳນວນຕາມພາກ
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "white",
                }}
                align="right"
              >
                ຈຳນວນຕາມຄະນະ
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {report?.faculties?.map((faculty, fIdx) =>
              faculty.departments.map((dept, dIdx) =>
                dept.majors.map((major, mIdx) => (
                  <TableRow key={`${fIdx}-${dIdx}-${mIdx}`}>
                    {dIdx === 0 && mIdx === 0 && (
                      <TableCell
                        rowSpan={faculty.departments.reduce(
                          (sum, dep) => sum + dep.majors.length,
                          0
                        )}
                      >
                        {faculty.faculty.name}
                      </TableCell>
                    )}
                    {mIdx === 0 && (
                      <TableCell rowSpan={dept.majors.length}>
                        {dept.department.name}
                      </TableCell>
                    )}
                    <TableCell>{major.major.name}</TableCell>
                    <TableCell align="center">{major.count}</TableCell>
                    {mIdx === 0 && (
                      <TableCell align="center" rowSpan={dept.majors.length}>
                        {dept.count}
                      </TableCell>
                    )}
                    {dIdx === 0 && mIdx === 0 && (
                      <TableCell
                        align="center"
                        rowSpan={faculty.departments.reduce(
                          (sum, dep) => sum + dep.majors.length,
                          0
                        )}
                      >
                        {faculty.count}
                      </TableCell>
                    )}
                  </TableRow>
                ))
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default StudentReportTable;

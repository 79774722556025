import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_KEY, NEW_API_URL } from "../../../common/contrant";
import { useSelector } from "react-redux";
import { hasPermission } from "../../../helpers/CheckPermission";
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Grid,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
} from "../../../common/fetchAPI";
import AlertMessage from "../../../common/AlertMessage";
import ConfirmModal from "../../../common/ConfirmDialog";
import { set } from "lodash";
import { CustomContainer } from "../../../common";

const RegisterPrice = () => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const url = NEW_API_URL + "pricecategory";

  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "",
  });

  const [titleLa, setTitleLa] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [price, setPrice] = useState("");
  const [credit, setCredit] = useState(false);
  const [priceList, setPricelist] = useState([]);
  const [faculty, setFaculty] = useState(null);
  const [selectID, setSelectID] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [type, setType] = useState("INTERNATIONAL");
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus({ ...actionStatus, open: false });
      }, 3000);
    }
  }, [actionStatus]);

  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (userData) {
      setFaculty(userData?.data?.faculty?._id);
    }
  }, []);

  const fetchPriceList = async () => {
    try {
      setPricelist([]);
      const facId = userData?.data?.faculty?._id || "";
      const response = await fetchGet(
        "pricecategory/" + facId + "/" + accessmentRedux + "/" + type
      );
      setPricelist(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPriceList();
  }, [faculty, accessmentRedux, type]);

  const [validateData, setValidateData] = useState({
    titleLa: "",
    titleEn: "",
    price: "",
  });

  const isDataValid = () => {
    let _validate = {
      titleLa: "",
      titleEn: "",
      price: "",
    };

    if (titleLa === "" || titleLa === null) {
      _validate = {
        ..._validate,
        titleLa: "ກະລຸນາປ້ອນຫົວຂໍ້ພາສາລາວ",
      };
    } else if (titleEn === "" || titleEn === null) {
      _validate = {
        ..._validate,
        titleEn: "ກະລຸນາປ້ອນຫົວຂໍ້ພາສາອັງກິດ",
      };
    } else if (price === "" || price === null) {
      _validate = {
        ..._validate,
        price: "ກະລຸນາປ້ອນລາຄາ",
      };
    }

    if (
      _validate.titleLa == "" &&
      _validate.titleEn == "" &&
      _validate.price == ""
    ) {
      setValidateData(_validate);
      return true;
    }
    setValidateData(_validate);
    return false;
  };

  const handleSubmit = async () => {
    if (isDataValid()) {
      const newData = {
        type: type,
        title_la: titleLa,
        title_en: titleEn,
        price: price,
        isCredit: credit,
        facultyId: faculty,
        assementYear: accessmentRedux,
      };
      const response = await (selectID
        ? fetchPut(`${url}/${selectID}`, newData)
        : fetchPost(url, newData));

      if (response) {
        setActionStatus({
          open: true,
          message: selectID ? "ແກ້ໄຂສຳເລັດ" : "ເພີ່ມສຳເລັດ",
          type: "success",
        });
        fetchPriceList();
        setShowSuccess(true);
        setTitleLa("");
        setTitleEn("");
        setPrice("");
        setCredit(false);
        setSelectID("");
        fetchPriceList();
      } else {
        setActionStatus({
          open: true,
          message: "ລະບົບມີບັນຫາ",
          type: "error",
        });
      }
    }
  };

  const handleClearData = () => {
    setTitleLa("");
    setTitleEn("");
    setPrice("");
    setCredit(false);
    setSelectID("");
  };

  const deletePriceItem = async () => {
    const response = await fetchDelete("pricecategory/" + selectID);
    if (response) {
      setActionStatus({
        open: true,
        message: "ລົບສຳເລັດ",
        type: "success",
      });
      fetchPriceList();
    } else {
      setActionStatus({
        open: true,
        message: "ມິບັນຫາໃນການລົບ",
        type: "error",
      });
    }
    setShowDelete(false);
  };

  return (
    <CustomContainer style={{marginTop: 1}}>
      <AlertMessage
        handleClose={() => {
          setActionStatus({
            ...actionStatus,
            open: false,
          });
        }}
        type={actionStatus.type}
        open={actionStatus.open}
        message={actionStatus.message}
      />
      <Grid container spacing={2}>
        {/* Form Section */}
        <Grid item xs={4}>
          {hasPermission("PAYMENT_LIST", "create") && (
            <Grid container spacing={2}>
              <Typography
                variant="h5"
                align="center"
                sx={{ lineHeight: 1.2, mb: 2 }}
              >
                ລາຍການຄ່າລົງທະບຽນ
              </Typography>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">ປະເພດ</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="ປະເພດ"
                    onChange={handleTypeChange}
                  >
                    <MenuItem value={"INTERNATIONAL"}>
                      ນັກສຶກສາຕ່າງປະເທດ
                    </MenuItem>
                    <MenuItem value={"DOMESTIC"}>ນັກສຶກສາາໃນປະເທດ</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  error={validateData.titleLa ? true : false}
                  helperText={validateData.titleLa}
                  fullWidth
                  label="ຫົວຂໍ້ (ພາສາລາວ)"
                  value={titleLa}
                  onChange={(e) => setTitleLa(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={validateData.titleEn ? true : false}
                  helperText={validateData.titleEn}
                  fullWidth
                  label="ຫົວຂໍ້ (ພາສາອັງກິດ)"
                  value={titleEn}
                  onChange={(e) => setTitleEn(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={validateData.price ? true : false}
                  helperText={validateData.price}
                  fullWidth
                  label="ລາຄາ"
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={credit}
                      onChange={(e) => setCredit(e.target.checked)}
                    />
                  }
                  label="ເປັນຫນ່ວຍກິດ"
                />
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ display: "flex", gap: "20px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {selectID ? "ບັນທືກ" : "ເພີ່ມ"}
                  </Button>

                  <Button
                    size="large"
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={handleClearData}
                  >
                    ຍົກເລີກ
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>

        {/* Table Section */}
        <Grid item xs={7}>
          <TableContainer
            sx={{
              marginTop: "43px",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: "#e9ecef",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    ລຳດັບ
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#e9ecef",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    ຫົວຂໍ້ (LA)
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#e9ecef",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    ຫົວຂໍ້ (EN)
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#e9ecef",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    ລາຄາ
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#e9ecef",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    ປະເພດ
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#e9ecef",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    ຈັດການ
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {priceList.length === 0 && (
                  <TableRow>
                    <TableCell rowSpan={10} colSpan={7} align="center">
                      ບໍ່ມີຂໍ້ມູນ
                    </TableCell>
                  </TableRow>
                )}
                {priceList.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {item.title_la}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {item.title_en}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {item.price}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {item.isCredit ? "ຫນ່ວຍກິດ" : "ອຶ່ນໆ"}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {item.type === "DOMESTIC"
                        ? "ນັກສຶກສາພາຍໃນ"
                        : "ນັກສຶກສາຕ່າງປະເທດ"}
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap", // Prevents text wrapping
                        minWidth: "120px", // Ensures enough space for buttons
                        textAlign: "center", // Centers buttons horizontally
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: 1,
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setSelectID(item._id);
                            setCredit(item.isCredit);
                            setTitleLa(item.title_la);
                            setTitleEn(item.title_en);
                            setPrice(item.price);
                          }}
                        >
                          <Edit color="primary" fontSize="medium" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setShowDelete(true);
                            setSelectID(item._id);
                          }}
                        >
                          <Delete color="error" fontSize="medium" />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <ConfirmModal
        open={showDelete}
        onCancel={() => setShowDelete(false)}
        onSubmit={deletePriceItem}
        title="ລົບຂໍ້ມຸນລາຍການຈ່າຍຄ່າລົງທະບຽນ"
        message="ທ່ານຕ້ອງການລົບແທ້ບໍ່?"
      />
    </CustomContainer>
  );
};

export default RegisterPrice;

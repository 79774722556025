import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const ClickOutside = ({ onClickOutside, children, ...props }) => {
  const containerRef = useRef(null);

  const handleClickOutside = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      onClickOutside(e);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    document.addEventListener("touchend", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("touchend", handleClickOutside, true);
    };
  }, []);

  return (
    <div ref={containerRef} {...props}>
      {children}
    </div>
  );
};

ClickOutside.propTypes = {
  onClickOutside: PropTypes.func.isRequired,
};

export default ClickOutside;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  Modal,
  Spinner,
  InputGroup,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import PreregistrationUpdateConfirm from "./PreregistrationUpdateConfirm";
import { LAO_ADDRESS } from "../../consts/Address";
import useReactRouter from "use-react-router";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { formatDateYYMMDD, valiDate } from "../../common/super";
import { CustomButton } from "../../common";
import { Formik } from "formik";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { FACULTIES, DEPARTMENTS, TRIBES, MAJORS } from "./apollo/query";
import { PRE_SIGNED_URL } from "./apollo/mutation";
import "./utils/index.css";
import { COUNTRIES_LIST } from "../../consts/Countries";
import getStudentNumber from "../../helpers/getStudentNumber";
import { fetchGet } from "../../common/fetchAPI";
import { onConvertEducationLevel, getDegreeAsText } from "../../helpers/user";

export default function Registration_Student() {
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const { history, location } = useReactRouter();
  const [selectFacultyIndex, setSelectFacultyIndex] = useState(-1);
  const [departments, setDepartments] = useState();
  const [dataMajors, setDataMajors] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const dataStudent = location.state;
  const [dataTribe, setDataTribe] = useState([]);
  const fetchTribe = async () => {
    const tribes = await fetchGet("tribe");
    setDataTribe(tribes);
  };

  useEffect(() => {
    fetchTribe();
  }, []);

  const [tribeName, setTribeName] = useState();
  const [scholarship, setScholarship] = useState();
  const [preStudentNumber, setPreStudentNumber] = useState("");
  const [facultyId, setFacultyId] = useState();
  const [studentType, setStudentType] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [studentCode, setStudentCode] = useState("");

  // ====== State Address ======
  const [selectProvinceIndex, setSelectProvinceIndex] = useState();
  const [selectDistrict, setSelectDistrict] = useState();
  // ======  State BirthDistrict ======
  const [selectBirthProvinceIndex, setSelectBirthProvinceIndex] = useState();

  const [selectParentProvinceIndex, setSelectParentProvinceIndex] = useState();
  const [selectEmergencyProvinceIndex, setSelectEmergencyProvinceIndex] =
    useState();

  // ===== State StudentType =====
  const [graduatedYear, setGraduatedYear] = useState([]);

  // state Model
  const [showAddConfirmModal, setShowAddConfirmModal] = useState(false);
  const [formParam, setFormParam] = useState({});
  const _handleShowAddConfirmModalClose = () => setShowAddConfirmModal(false);
  const _handleShowAddConfirmModalShow = () => setShowAddConfirmModal(true);

  /* ------- State Image ------*/
  const [fileUpload, setFileUpload] = useState(null);
  const [click, setClick] = useState("");
  const [idCardOrFamilyBook, setIdCardOrFamilyBook] = useState(null);
  const [idCardOrFamilyBookUrl, setIdCardOrFamilyBookUrl] = useState(null);
  const [certificate, setCertificate] = useState(null);
  // const [certificateUrl, setCertificateUrl] = useState(null)
  // const [scorecard, setScorecard] = useState(null)
  // const [scorecardUrl, setScorecardUrl] = useState(null)
  const [testCard, setTestCard] = useState(null);
  // const [testCardUrl, setTestCardUrl] = useState(null)
  const [stayTogether, setStayTogether] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [approveNote, setApproveNote] = useState(false);

  const currentYear = new Date().getFullYear();

  /* -------Apollo--------- */
  const { data: dataFaculties } = useQuery(FACULTIES, {
    variables: {
      where: {
        assessmentYear: accessmentRedux,
        isDeleted: false,
      },
    },
  });
  const [loadDeparments, { data: dataDepartments }] = useLazyQuery(DEPARTMENTS);
  const [loadMajors, { data: majorData }] = useLazyQuery(MAJORS);

  /*  ----------  image ---------*/
  const [loadPreSign, { data: dataPreSign }] = useLazyQuery(PRE_SIGNED_URL);

  // const { data: dataTribe } = useQuery(TRIBES);

  // select faculty
  const _selectFaculty = (e) => {
    setDepartments([]);
    const facultyIndex = _.findIndex(dataFaculties?.faculties, {
      id: e.target.value,
    });
    if (e.target.value) {
      loadDeparments({
        variables: {
          where: {
            faculty: e.target.value,
            assessmentYear: accessmentRedux,
            isDeleted: false,
          },
        },
      });
    }
    // setDepartments(dataFaculties?.faculties[facultyIndex].departments)
    setSelectFacultyIndex(facultyIndex);
  };
  const _selectDepartment = (e) => {
    if (e.target.value) {
      loadMajors({
        variables: {
          where: {
            department: e.target.value,
            assessmentYear: accessmentRedux,
            isDeleted: false,
          },
        },
      });
    }
  };

  useEffect(() => {
    const facultyIndexDB = _.findIndex(dataFaculties?.faculties, {
      id: dataStudent?.faculty?._id,
    });
    loadDeparments({
      variables: {
        where: {
          faculty: dataStudent?.faculty?._id,
          assessmentYear: accessmentRedux,
          isDeleted: false,
        },
      },
    });
    loadMajors({
      variables: {
        where: {
          department: dataStudent?.department?._id,
          assessmentYear: accessmentRedux,
          isDeleted: false,
        },
      },
    });
    // setDepartments(dataFaculties?.faculties[facultyIndexDB].departments)
    setSelectFacultyIndex(facultyIndexDB);
    if (dataStudent) {
      setStayTogether(dataStudent?.stayTogether);
      setIsChecked(dataStudent?.bornAbroad === "" ? false : true);
      setFacultyId(dataStudent?.faculty?._id);
      setStudentType(dataStudent?.studentType);
      const textEducationLevel = getDegreeAsText(dataStudent?.educationLevel);
      setEducationLevel(textEducationLevel);
      setTribeName(dataStudent?.tribe?.name);
      const studentCode = dataStudent?.studentNumber;
      if (
        dataStudent?.studentType === "IN_PLAN" ||
        dataStudent?.studentType === "OUT_PLAN"
      ) {
        const firstFourDigits = studentCode.substring(4);
        setStudentCode(firstFourDigits);
      } else {
        const firstFourDigits = studentCode.substring(5);
        setStudentCode(firstFourDigits);
      }
    }
  }, [dataFaculties, dataStudent]);

  useEffect(() => {
    setPreStudentNumber(
      getStudentNumber({
        faculty: facultyId,
        degree: educationLevel,
        studentType: studentType,
      })
    );
  }, [studentType, facultyId, educationLevel]);

  useEffect(() => {
    if (dataDepartments) {
      setDepartments(dataDepartments?.departments);
    }
  }, [dataDepartments]);

  useEffect(() => {
    if (majorData) {
      setDataMajors(majorData?.majors?.data);
    }
  }, [majorData]);

  const _renderDepartmentName = (id) => {
    let department = _.find(dataDepartments?.departments, function (o) {
      return o.id === id;
    });
    return department?.name;
  };
  const _renderMajorName = (id) => {
    let major = _.find(dataMajors, function (o) {
      return o.id === id;
    });
    return major?.name;
  };

  //  ======= Address ======
  const _selectProvince = (e) => {
    setSelectDistrict("");
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value,
    });
    setSelectProvinceIndex(_provinceIndex);
  };
  useEffect(() => {
    const _provinceIndexDB = _.findIndex(LAO_ADDRESS, {
      province_name: dataStudent?.address?.province,
    });
    setSelectProvinceIndex(_provinceIndexDB);
  }, [dataStudent]);

  const _selectDistrict = (e) => {
    const _districts = LAO_ADDRESS[selectProvinceIndex].district_list.filter(
      (_district) => _district.district === e.target.value
    );
    const _villages = _districts[0]?.village;
    setSelectDistrict(e.target.value);
  };

  // ======= BirthAddress ========
  const _selectBirthProvince = (e) => {
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value,
    });
    setSelectBirthProvinceIndex(_provinceIndex);
  };

  const _selectParentDistrict = (e) => {};

  const _selectParentProvince = (e) => {
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value,
    });
    setSelectParentProvinceIndex(_provinceIndex);
  };

  const _selectEmergencyProvince = (e) => {
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value,
    });
    setSelectEmergencyProvinceIndex(_provinceIndex);
  };

  useEffect(() => {
    const _provinceIndexDB = _.findIndex(LAO_ADDRESS, {
      province_name: dataStudent?.parentAddress?.province,
    });
    setSelectParentProvinceIndex(_provinceIndexDB);

    const _parentProvince = _.findIndex(LAO_ADDRESS, {
      province_name: dataStudent?.birthAddress?.province,
    });
    setSelectBirthProvinceIndex(_parentProvince);

    const _emergencyProvince = _.findIndex(LAO_ADDRESS, {
      province_name: dataStudent?.parentAddress?.province,
    });
    setSelectEmergencyProvinceIndex(_emergencyProvince);
  }, [dataStudent]);

  const _selectBirthDistrict = (e) => {};

  const _selectGraduateFromProvince = (e) => {};

  useEffect(() => {
    let year = [];
    const NOW = new Date().getFullYear();
    for (var i = NOW - 1; i >= 2014; i--) {
      year.push(i + " - " + (parseInt(i) + 1));
    }
    setGraduatedYear(year);
  }, []);

  const _selectTribe = (tribe) => {
    const tribeIndex = _.findIndex(dataTribe?.tribes, {
      id: tribe.target.value,
    });
    setTribeName(dataTribe?.tribes[tribeIndex]?.trib_name);
  };

  // ============ ເລື່ອກຮູບພາບ ===========

  useEffect(() => {
    if (dataPreSign?.preregistrationPreSignedUrl) {
      fileGetResign(dataPreSign?.preregistrationPreSignedUrl);
    } else {
      setIsLoading(false);
    }
  }, [dataPreSign]);

  const fileGetResign = async (data) => {
    try {
      if (click === "1") {
        setIdCardOrFamilyBookUrl(data?.url.split("?")[0]);
      }
      // if (click === '2') {
      // setScorecardUrl(data?.url.split('?')[0])
      // }
      // if (click === '3') {
      // setCertificateUrl(data?.url.split('?')[0])
      // }
      // if (click == '4') {
      // setTestCardUrl(data?.url.split('?')[0])
      // }

      await axios({
        method: "put",
        url: data.url,
        data: fileUpload,
        headers: {
          "Content-Type": " file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (idCardOrFamilyBook === null && idCardOrFamilyBookUrl === null) {
      setIdCardOrFamilyBook(dataStudent?.idCardOrFamilyBookUrl?.url);
      setIdCardOrFamilyBookUrl(dataStudent?.idCardOrFamilyBookUrl?.url);
    }
    setCertificate(dataStudent?.certificateUrl?.url);
    // setCertificateUrl(dataStudent?.certificateUrl?.url)
    // setScorecard(dataStudent?.scorecardUrl?.url)
    // setScorecardUrl(dataStudent?.scorecardUrl?.url)

    setTestCard(dataStudent?.testCardUrl?.url);
    // setTestCardUrl(dataStudent?.testCardUrl?.url)
  }, [dataStudent]);

  // ເລືອກໄຟລຈາກ ຄອມ
  const _handleChangeFile = async (acceptedFiles) => {
    try {
      setIsLoading(true);
      // ໄປຂໍ url ຈາກ s3
      let data =
        uuidv4() + "." + acceptedFiles?.target?.files[0].type.split("/")[1];
      if (click === "1") {
        setIdCardOrFamilyBook(data);
      }
      // if (click == '2') {
      // setScorecard(data)
      // }
      if (click === "3") {
        setCertificate(data);
      }
      if (click === "4") {
        setTestCard(data);
      }
      setFileUpload(acceptedFiles?.target?.files[0]);
      loadPreSign({
        variables: {
          name: data,
        },
      });
    } catch (error) {
      console.log("error: ", error);
      setIsLoading(false);
    }
  };

  const selectScholarship = (e) => {
    setScholarship(e.target.value);
  };
  const _handleClickConfirm = (dataStudent) => {
    setFormParam(dataStudent);
  };
  const _clickStayTogether = (e) => {
    // setStayTogether(true);
    setStayTogether(!stayTogether);
  };
  return (
    <div>
      <Col sm={12} style={{ paddingBottom: 20, paddingTop: 20 }}>
        <a
          href="#"
          onClick={() => {
            history.push(`students-prere`);
            window.location.reload();
          }}
        >
          ລົງທະບຽນນັກສຶກສາ
        </a>
        /{" "}
        <a>
          {dataStudent && dataStudent ? dataStudent?.firstNameL : "-"}{" "}
          {dataStudent?.lastNameL}
        </a>{" "}
        / ແກ້​ໄຂ​ຂໍ້​ມູນຟອມ​ລົງ​ທະ​ບຽນ
      </Col>
      <div className="container">
        <div className="col-sm-12 card" style={{ color: "#707070" }}>
          {/* {studentData && */}
          {
            <Formik
              enableReinitialize
              initialValues={{
                examCode: dataStudent?.examCode ?? "",
                id: dataStudent?.id ?? "",
                studentNumber: studentCode ?? "",
                faculty: dataStudent?.faculty?._id ?? "",
                department: dataStudent?.department?._id ?? "",
                major: dataStudent?.major?._id ?? "",
                code: dataStudent?.code,
                studentInfo: dataStudent?.studentInfo ?? "",
                educationLevel: dataStudent?.educationLevel ?? "",
                firstNameL: dataStudent?.firstNameL ?? "",
                lastNameL: dataStudent?.lastNameL ?? "",
                firstNameE: dataStudent?.firstNameE ?? "",
                lastNameE: dataStudent?.lastNameE ?? "",
                day: dataStudent?.birthday
                  ? dataStudent.birthday.split("-")[2].substring(0, 2)
                  : "",
                month: dataStudent?.birthday
                  ? dataStudent.birthday.split("-")[1]
                  : "",
                year: dataStudent?.birthday
                  ? dataStudent.birthday.split("-")[0]
                  : "",
                gender: dataStudent?.gender ?? "MALE",
                maritualStatus: dataStudent?.maritualStatus ?? "SINGLE",
                nationality: dataStudent?.nationality ?? "",
                tribe: dataStudent?.tribe?._id ?? "",
                religion: dataStudent?.religion ?? "",
                phoneHome: dataStudent?.phoneHome ?? "",
                phoneMobile: dataStudent?.phoneMobile ?? "",
                email: dataStudent?.email ?? "",
                province: dataStudent?.address?.province ?? "",
                district: dataStudent?.address?.district ?? "",
                village: dataStudent?.address?.village ?? "",
                bornAbroad: dataStudent?.bornAbroad ?? "",
                birthCountry: dataStudent?.birthAddress.country ?? "",
                birthProvince: dataStudent?.birthAddress?.province ?? "",
                birthDistrict: dataStudent?.birthAddress?.district ?? "",
                birthVillage: dataStudent?.birthAddress?.village ?? "",
                parentProvince: dataStudent?.parentAddress?.province ?? "",
                parentDistrict: dataStudent?.parentAddress?.district ?? "",
                parentVillage: dataStudent?.parentAddress?.village ?? "",
                scholarship: dataStudent?.scholarship ?? "",
                otherScholarship: dataStudent?.otherScholarship ?? "",
                graduateFromProvince: dataStudent?.graduateFromProvince ?? "",
                graduateFromSchool: dataStudent?.graduateFromSchool ?? "",
                semesterYear: dataStudent?.semesterYear ?? "",
                outPlanGraduateFromProvince:
                  dataStudent?.graduateFromProvince ?? "",
                outPlanGraduateFromSchool:
                  dataStudent?.graduateFromSchool ?? "",
                outPlanSemesterYear: dataStudent?.semesterYear ?? "",
                graduatedMajor: dataStudent?.graduatedMajor ?? "",
                contactName: dataStudent?.contactName ?? "",
                emergencyPhone: dataStudent?.emergencyPhone ?? "",
                stayTogether: dataStudent?.stayTogether ?? false,
                emergencyProvince:
                  dataStudent?.emergencyAddress?.province ?? "",
                emergencyDistrict:
                  dataStudent?.emergencyAddress?.district ?? "",
                emergencyVillage: dataStudent?.emergencyAddress?.village ?? "",
                fatherNameAndSurname: dataStudent?.fatherNameAndSurname ?? "",
                motherNameAndSurname: dataStudent?.motherNameAndSurname ?? "",
                parentTelephone: dataStudent?.parentTelephone ?? "",
                transferLetterNo: dataStudent?.transferLetterNo ?? "",
                scholarshipAgreementNo:
                  dataStudent?.scholarshipAgreementNo ?? "",
                dateTransferLetterNo:
                  formatDateYYMMDD(dataStudent?.dateTransferLetterNo) ?? "",
                dateScholarshipAgreementNo:
                  formatDateYYMMDD(dataStudent?.dateScholarshipAgreementNo) ??
                  "",
                dormitory: dataStudent?.dormitory ?? "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.faculty) {
                  errors.faculty = "ກະລຸນາເລືອກຄະນະ";
                }
                if (!values.department) {
                  errors.department = "ກະລຸນາເລືອກພາກວິຊາ";
                }
                if (!values.major) {
                  errors.major = "ກະລຸນາເລືອກສາຂາວິຊາ";
                }
                if (!values.educationLevel) {
                  errors.educationLevel = "ກະລຸນາເລືອກລະດັບສຶກສາ";
                }
                if (!values.studentInfo) {
                  errors.studentInfo = "ກະລຸນາເລືອກປະເພດນັກສຶກສາ";
                }
                if (!values.day) {
                  errors.day = "ກະລຸນາເລືອກວັນ";
                }
                if (!values.month) {
                  errors.month = "ກະລຸນາເລືອກເດີືອນ";
                }
                if (!values.year) {
                  errors.year = "ກະລຸນາເລືອກປີ";
                }
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                _handleShowAddConfirmModalShow();
                // if (selectProvince) {
                let address = {
                  province: values.province,
                  district: values.district,
                  village: values.village,
                };

                if (values.firstNameE == "" && values.lastNameE == "") {
                  delete values.firstNameE;
                  delete values.lastNameE;
                }
                values = {
                  ...values,
                  address: address,
                };
                delete values.village;
                delete values.district;
                // }
                // if (selectBirthProvince) {
                let birthAddress = {
                  province: values.birthProvince,
                  district: values.birthDistrict,
                  village: values.birthVillage,
                };

                let parentAddress = {
                  province: values.parentProvince,
                  district: values.parentDistrict,
                  village: values.parentVillage,
                };
                values = {
                  ...values,
                  parentAddress: parentAddress,
                };

                if (isChecked === true) {
                  birthAddress = {
                    province: "",
                    district: "",
                    village: "",
                  };
                }
                if (isChecked === false) {
                  values = { ...values, bornAbroad: "" };
                  birthAddress = {
                    province: values.birthProvince,
                    district: values.birthDistrict,
                    village: values.birthVillage,
                  };
                } else {
                  values = { ...values, bornAbroad: "true" };
                  birthAddress = {
                    country: values.birthCountry,
                    province: values.birthProvince,
                    district: values.birthDistrict,
                    village: values.birthVillage,
                  };
                }
                values = {
                  ...values,
                  birthAddress: birthAddress,
                };
                // }
                if (values.educationLevel === "BACHELOR_CONTINUING") {
                  values = { ...values };
                } else {
                  delete values.graduatedMajor;
                }

                let emergencyAddress = {};
                if (values.stayTogether === false) {
                  emergencyAddress = {
                    province: values.emergencyProvince,
                    district: values.emergencyDistrict,
                    village: values.emergencyVillage,
                  };
                  values = {
                    ...values,
                    emergencyAddress: emergencyAddress,
                  };
                }
                delete values.emergencyVillage;
                delete values.emergencyDistrict;
                delete values.emergencyProvince;

                const birthday = `${values.year}-${values.month}-${values.day}`;

                if (approveNote === true) {
                  values = { ...values, approveNote: false };
                }
                let data = {
                  ...values,
                  birthday,
                  faculty: dataFaculties?.faculties[selectFacultyIndex]?.id,
                  tribeName: tribeName,
                  department: values.department,
                  major: values.major,
                  facultyname:
                    dataFaculties?.faculties[selectFacultyIndex]?.name,
                  departmentname: _renderDepartmentName(values.department),
                  majorname: _renderMajorName(values.major),
                  tribe: values.tribe,
                  idCardOrFamilyBookUrl: { url: idCardOrFamilyBook },
                  certificateUrl: { url: certificate },
                  testCardUrl: { url: testCard },
                  // scorecardUrl: { url: scorecard },
                  studentType: dataStudent?.studentType,
                  preStudentNumber: preStudentNumber,
                };
                delete data.birthCountry;
                delete data.birthProvince;
                delete data.birthDistrict;
                delete data.birthVillage;
                delete data.district;
                delete data.village;
                delete data.parentDistrict;
                delete data.parentProvince;
                delete data.parentVillage;
                delete data.outPlanGraduateFromProvince;
                delete data.outPlanGraduateFromSchool;
                delete data.outPlanSemesterYear;
                await setFormParam({
                  ...data,
                  idCardOrFamilyBook,
                  certificate,
                  testCard,
                });
              }}
            >
              {({ values, errors, handleChange, handleSubmit }) => (
                <div
                  className="card-body"
                  style={{ marginLeft: 25, marginRight: 20 }}
                >
                  {dataStudent?.approveNote === true ? (
                    <div
                      style={{
                        border: "1px solid red",
                        borderRadius: 5,
                        padding: 5,
                        marginTop: 15,
                        marginBottom: 20,
                      }}
                    >
                      <p style={{ color: "red", fontSize: 20 }}>
                        ກະລຸນາລົບໝາຍເຫດອອກຖ້າແກ້ໄຂ້ຂໍ້ມູນຖືກຕ້ອງແລ້ວ
                      </p>
                      <p style={{ color: "red" }}>
                        {dataStudent?.approveNoteDetails?.incorrectBirthday ??
                          ""}
                      </p>
                      <p style={{ color: "red" }}>
                        {dataStudent?.approveNoteDetails?.incorrectName ?? ""}
                      </p>
                      <p style={{ color: "red" }}>
                        {dataStudent?.approveNoteDetails?.incorrectFile ?? ""}
                      </p>
                      <p style={{ color: "red" }}>
                        {dataStudent?.approveNoteDetails?.remark ?? ""}
                      </p>
                      <input
                        type="checkbox"
                        checked={approveNote}
                        onChange={(e) => setApproveNote(e.target.checked)}
                      />
                      <label className="pl-2">ລົບໝາຍເຫດອອກ</label>
                    </div>
                  ) : null}

                  <div
                    className="col-sm-12 textfont-size16 font-color"
                    style={{
                      backgroundColor: "#D8D8D8",
                      padding: 10,
                      borderRadius: 5,
                      marginBottom: 4,
                      color: "#707070",
                    }}
                  >
                    ຂໍ້​ມູນ​ນັກ​ສຶກ​ສາ
                  </div>
                  <div className="text-muted mgn">
                    ບ່ອນ​ເຄື່ອງ​ໝາຍ​ທີ່​ມີ{" "}
                    <span className="text-danger">*</span> ແມ່ນ​ຕ້ອງ​​ມີ​ຂໍ້​ມູນ
                  </div>
                  <Row>
                    <Col sm="12">
                      <Row sm="10">
                        <Col sm="4">
                          <Form.Label fontSize="14" className="font-color">
                            ຄະ​ນ​ະ <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="faculty"
                            isInvalid={errors.faculty}
                            value={values.faculty}
                            onChange={(e) => {
                              handleChange(e);
                              _selectFaculty(e);
                              setFacultyId(e.target.value);
                              values.department = "";
                              values.major = "";
                            }}
                          >
                            <option disabled={false} value="">
                              ເລືອກ
                            </option>
                            {dataFaculties?.faculties?.map((item, index) => {
                              return (
                                <option key={"faculty" + index} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                        <Col sm="4">
                          <Form.Label fontSize="14" className="font-color">
                            ພາກວິຊາ
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="department"
                            isInvalid={errors.department}
                            value={values.department}
                            onChange={(e) => {
                              handleChange(e);
                              _selectDepartment(e);
                              values.major = "";
                            }}
                            // value={departments}
                          >
                            <option value={false}>ທັງໝົດ</option>
                            {departments &&
                              departments?.map((item, index) => {
                                return (
                                  <option
                                    key={"department" + index}
                                    value={item.id}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Form.Control>
                        </Col>
                        <Col sm="4">
                          <Form.Label fontSize="14" className="font-color">
                            ສາ​ຂາ​ວິ​ຊາ
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="major"
                            isInvalid={errors.major}
                            value={values.major}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          >
                            <option value={false}>ທັງໝົດ</option>
                            {dataMajors &&
                              dataMajors?.map((item, index) => {
                                return (
                                  <option key={"major" + index} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Form.Control>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: 10 }}>
                        <Col sm="4">
                          <Form.Label
                            fontSize="14"
                            className="font-color"
                            name=""
                          >
                            ຂໍ້​ມູນ​ນັກ​ສຶກ​ສາ{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="studentInfo"
                            value={values.studentInfo}
                            isInvalid={errors.studentInfo}
                            onChange={handleChange}
                          >
                            <option value="">ເລືອກ</option>
                            <option value="EMPLOYEE">
                              ເປັນພະນັກງານມາຮຽນຕໍ່
                            </option>
                            <option value="ORDINARY_STUDENT">
                              ນັກຮຽນຈົບ ມໍ ປາຍມາຮຽນຕໍ່
                            </option>
                          </Form.Control>
                        </Col>
                        <Col sm="4">
                          <Form.Label fontSize="14" className="font-color">
                            ລະ​ດັບ <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={"BACHELOR_DEGREE"}
                            name="educationLevel"
                            isInvalid={errors.educationLevel}
                            onChange={(e) => {
                              handleChange(e);
                              setEducationLevel(e.target.value);
                            }}
                          >
                            <option value="">ເລືອກ</option>
                            <option value="DEPLOMA">ອະນຸປະລິນຍາ</option>
                            <option value="BACHELOR_DEGREE">ປະລິນຍາຕີ</option>
                            <option value="BACHELOR_CONTINUING">
                              ຕໍ່ເນື່ອງປະລິນຍາຕີ
                            </option>
                          </Form.Control>
                        </Col>
                        <Col sm="4">
                          <Form.Control
                            type="hidden"
                            placeholder="ກະ​ລຸ​ນາ​ປ້ອນ​ລະ​ຫັດ​ສອບເສັງ"
                            name="examCode"
                            value={values.examCode}
                            onChange={handleChange}
                            isInvalid={errors.examCode}
                          />
                          <Form.Label>
                            ລະຫັດລົງທະບຽນເຂົ້າຮຽນ {valiDate()}
                          </Form.Label>
                          <InputGroup>
                            {preStudentNumber && (
                              <InputGroup.Text
                                style={{ height: "40px", lineHeight: "40px" }}
                              >
                                {preStudentNumber}
                              </InputGroup.Text>
                            )}
                            <Form.Control
                              type="text"
                              className="form-control"
                              name="studentNumber"
                              value={values.studentNumber}
                              isInvalid={!!errors.studentNumber}
                              placeholder="ກະລຸນາປ້ອນລະຫັດລົງທະບຽນເຂົ້າຮຽນ"
                              style={{ height: "40px" }} // Ensuring height is the same
                              onChange={handleChange}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Col
                    className="col-sm-12 titlebt"
                    style={{
                      backgroundColor: "#DEDEDEDE",
                      padding: 10,
                      borderRadius: 5,
                      marginTop: 20,
                    }}
                  >
                    ຂໍ້​ມູນ​ນັກ​ສຶກ​ສາ
                  </Col>
                  <Row style={{ marginTop: 22 }}>
                    <Col sm="6">
                      <Form.Label fontSize="14" className="font-color">
                        ຊື່ <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={values.firstNameL}
                        placeholder="ກະ​ລຸ​ນາ ປ້ອນ​ຊື່​ແທ້​ຂອງ​ທ່ານ"
                        name="firstNameL"
                        onChange={handleChange}
                        isInvalid={errors.firstNameL}
                        maxLength="20"
                      />
                    </Col>
                    <Col sm="6">
                      <Form.Label fontSize="14" className="font-color">
                        ນາມສະກຸນ <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={values.lastNameL}
                        placeholder="ກະ​ລຸ​ນາ ປ້ອນນາມ​ສະ​ກຸນ​ຂອງ​ທ່ານ"
                        name="lastNameL"
                        onChange={handleChange}
                        isInvalid={errors.lastNameL}
                        maxLength="20"
                      />
                    </Col>
                    <Col sm="6" style={{ marginTop: 20 }}>
                      <Form.Label fontSize="14" className="font-color">
                        ຊື່ (ພາສາອັງກິດ) <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={values.firstNameE}
                        placeholder="ກະ​ລຸ​ນາ ປ້ອນຊື່​ແທ້​ເປັນ​ພາ​ສາ​ອັງ​ກິດ"
                        name="firstNameE"
                        onChange={handleChange}
                        isInvalid={errors.firstNameE}
                        maxLength="20"
                      />
                    </Col>
                    <Col sm="6" style={{ marginTop: 20 }}>
                      <Form.Label fontSize="14" className="font-color">
                        ນາມສະກຸນ (ພາສາອັງກິດ){" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={values.lastNameE}
                        placeholder="ກະ​ລຸ​ນາ ປ້ອນນາມ​ສະ​ກຸນ​ຂອງ​ທ່ານ"
                        name="lastNameE"
                        onChange={handleChange}
                        isInvalid={errors.lastNameE}
                        maxLength="20"
                      />
                    </Col>
                    <Row style={{ marginTop: 20 }}>
                      <Form.Label>ວັນເດືອນປີເກີດ {valiDate()}</Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          as="select"
                          onChange={handleChange}
                          name="day"
                          value={values.day}
                          isInvalid={!!errors.day}
                        >
                          <option value="">ວັນ</option>
                          {Array.from({ length: 31 }, (_, i) => {
                            const value = String(i + 1).padStart(2, "0");
                            return (
                              <option key={value} value={value}>
                                {value}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                      <Col sm={4}>
                        <Form.Control
                          as="select"
                          onChange={handleChange}
                          name="month"
                          value={values.month}
                          isInvalid={!!errors.month}
                        >
                          <option value="">ເດືອນ</option>
                          {Array.from({ length: 12 }, (_, i) => {
                            const value = String(i + 1).padStart(2, "0");
                            return (
                              <option key={value} value={value}>
                                {value}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                      <Col sm={4}>
                        <Form.Control
                          as="select"
                          onChange={handleChange}
                          name="year"
                          value={values.year}
                          isInvalid={!!errors.year}
                        >
                          <option value="">ປີ</option>
                          {Array.from({ length: 100 }, (_, i) => (
                            <option
                              key={currentYear - i}
                              value={currentYear - i}
                            >
                              {currentYear - i}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Row>
                  </Row>
                  <Row style={{ marginTop: 20 }}>
                    <Col sm={2} style={{ marginLeft: 13, marginRight: -76 }}>
                      <label className="font-color">
                        ເລືອກເພດ <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col sm={2}>
                      <Form.Group>
                        <Form.Check
                          inline
                          label="ຊາຍ"
                          type="radio"
                          value="MALE"
                          id="gender-radio"
                          name="gender"
                          onChange={handleChange}
                          checked={values.gender === "MALE" ? true : false}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group>
                        <Form.Check
                          inline
                          label="ຍິງ"
                          type="radio"
                          value="FEMALE"
                          id="gender-radio"
                          name="gender"
                          onChange={handleChange}
                          checked={values.gender === "FEMALE" ? true : false}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group>
                        <Form.Check
                          inline
                          label="ພຣະ"
                          type="radio"
                          value="MONK"
                          id="gender-radio"
                          name="gender"
                          onChange={handleChange}
                          checked={values.gender === "MONK" ? true : false}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group>
                        <Form.Check
                          inline
                          label="ສໍາມະເນນ"
                          type="radio"
                          value="NOVICE"
                          id="gender-radio"
                          name="gender"
                          onChange={handleChange}
                          checked={values.gender === "NOVICE" ? true : false}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 20 }}>
                    <Col sm={2} style={{ marginLeft: 13, marginRight: -76 }}>
                      <label className="font-color">
                        ສະຖານະ <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col sm={2}>
                      <Form.Group>
                        <Form.Check
                          inline
                          label="ໂສດ"
                          type="radio"
                          value="SINGLE"
                          id="maritualStatus-radio"
                          name="maritualStatus"
                          onChange={handleChange}
                          checked={
                            values.maritualStatus === "SINGLE" ? true : false
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group>
                        <Form.Check
                          inline
                          label="ແຕ່ງງານແລ້ວ"
                          type="radio"
                          id="maritualStatus-radio"
                          value="MARRIAGE"
                          name="maritualStatus"
                          onChange={handleChange}
                          checked={
                            values.maritualStatus === "MARRIAGE" ? true : false
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 20 }}>
                    <Col sm={2} style={{ marginLeft: 13, marginRight: -76 }}>
                      <label className="font-color">
                        ຫໍ​ພັກ​ນັກ​ສຶກ​ສາ <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col sm={2}>
                      <Form.Group>
                        <Form.Check
                          inline
                          label="ຂໍເຂົ້ານອ​ນ​ຫໍ​ພັກ"
                          type="radio"
                          value="YES"
                          id="dormitory-radio"
                          name="dormitory"
                          onChange={handleChange}
                          checked={values.dormitory === "YES" ? true : false}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group>
                        <Form.Check
                          inline
                          label="ບໍ່ຂໍເຂົ້ານອ​ນ​ຫໍ​ພັກ"
                          type="radio"
                          id="dormitory-radio"
                          value="NO"
                          name="dormitory"
                          onChange={handleChange}
                          checked={values.dormitory === "NO" ? true : false}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: 22 }}>
                    <Col sm="4">
                      <Form.Label fontSize="14" className="font-color">
                        ສັນຊາດ <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={values.nationality}
                        name="nationality"
                        onChange={handleChange}
                        isInvalid={errors.nationality}
                      >
                        <option value="">ເລືອກ</option>
                        <option value="ລາວ">ລາວ</option>
                        <option value="ກຳປູເຈຍ">ກຳປູເຈຍ</option>
                        <option value="ໄທ">ໄທ</option>
                        <option value="ຈີນ">ຈີນ</option>
                        <option value="ຍີ່ປຸ່ນ">ຍີ່ປຸ່ນ</option>
                        <option value="ເກົາຫຼີ">ເກົາຫຼີ</option>
                        <option value="ຫວຽດ">ຫວຽດ</option>
                      </Form.Control>
                    </Col>
                    <Col sm="4">
                      <Form.Label fontSize="14" className="font-color">
                        ຊົນເຜົ່າ <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={values.tribe}
                        name="tribe"
                        onChange={(tribe) => {
                          console.log("value => ", values);
                          console.log("tribe => ", tribe);
                          handleChange(tribe);
                          // set  tribe name by find by  select tribe and find where in datatribes
                          let _tribe = dataTribe.find(
                            (item) => item._id === tribe.target.value
                          );
                          setTribeName(_tribe?.name);

                          // _selectTribe(tribe);
                        }}
                        isInvalid={errors.tribe}
                      >
                        <option value="">ເລືອກ</option>
                        {dataTribe &&
                          dataTribe?.map((item, index) => {
                            return (
                              <option value={item._id}>{item?.name}</option>
                            );
                          })}
                      </Form.Control>
                    </Col>
                    <Col sm="4">
                      <Form.Label fontSize="14" className="font-color">
                        ສາສະໜາ <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={values.religion}
                        name="religion"
                        onChange={handleChange}
                        isInvalid={errors.religion}
                      >
                        <option value="">ເລືອກ</option>
                        <option value="ພຸດ">​ສາ​ສ​ະ​ໜາ​ພຸດ</option>
                        <option value="ຜີ">ສາ​ສະ​ໜາຜີ</option>
                        <option value="ຄຣິສະ​ຕຽນ">ສາ​ສະ​ໜາຄຣິສະ​ຕຽນ</option>
                        <option value="ອິດສະລາມ">ສາ​ສະ​ໜາອິດສະລາມ</option>
                      </Form.Control>
                    </Col>
                    <Col sm="4" style={{ marginTop: 20 }}>
                      <Form.Label fontSize="14" className="font-color">
                        ເບີໂທເຮືອນ
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={values.phoneHome}
                        placeholder="ກະລຸນາປ້ອນເບີໂທເຮືອນ"
                        name="phoneHome"
                        isInvalid={errors.phoneHome}
                        onChange={handleChange}
                        maxLength="11"
                      />
                    </Col>
                    <Col sm="4" style={{ marginTop: 20 }}>
                      <Form.Label fontSize="14" className="font-color">
                        ເບີໂທລະສັບມືຖື <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={values.phoneMobile}
                        placeholder="ກະລຸນາປ້ອນເບີໂທລະສັບມືຖື"
                        name="phoneMobile"
                        isInvalid={errors.phoneMobile}
                        onChange={handleChange}
                        maxLength="11"
                      />
                    </Col>
                    <Col sm="4" style={{ marginTop: 20 }}>
                      <Form.Label fontSize="14" className="font-color">
                        ອີເມວ
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={values.email}
                        placeholder="ກະລຸນາປ້ອນອີເມວ"
                        name="email"
                        isInvalid={errors.email}
                        onChange={handleChange}
                        maxLength="30"
                      />
                    </Col>
                  </Row>
                  <Col
                    className="col-sm-12 titlebt"
                    style={{
                      backgroundColor: "#D8D8D8",
                      padding: 10,
                      borderRadius: 5,
                      marginTop: 30,
                      fontSize: 16,
                    }}
                  >
                    ທີ່ຢູ່ປະຈຸບັນ
                  </Col>
                  <Row style={{ marginTop: 12 }}>
                    <Col sm="4">
                      <Form.Label fontSize="14" className="font-color">
                        ແຂວງ <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="province"
                        onChange={(e) => {
                          handleChange(e);
                          _selectProvince(e);
                        }}
                        value={values.province}
                        isInvalid={errors.province}
                        id="disabledSelect"
                      >
                        <option value="">---ເລືອກແຂວງ---</option>
                        {LAO_ADDRESS.map((province, index) => (
                          <option
                            key={"province" + index}
                            value={province?.province_name}
                          >
                            {province?.province_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col sm="4">
                      <Form.Label fontSize="14" className="font-color">
                        ເມືອງ <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        // value={selectDistrict}
                        isInvalid={errors.district}
                        name="district"
                        value={values.district}
                        onChange={(e) => {
                          _selectDistrict(e);
                          handleChange(e);
                        }}
                      >
                        <option value="">---ເລືອກເມືອງ---</option>
                        {selectProvinceIndex > -1 &&
                          LAO_ADDRESS[selectProvinceIndex].district_list.map(
                            (district, index) => (
                              <option key={"district" + index}>
                                {district.district}
                              </option>
                            )
                          )}
                      </Form.Control>
                    </Col>
                    <Col sm="4">
                      <Form.Label fontSize="14" className="font-color">
                        ບ້ານ <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="village"
                        isInvalid={errors.village}
                        placeholder="ກາລຸນາປ້ອນບ້ານ"
                        onChange={handleChange}
                        value={values.village}
                        maxLength="20"
                      />
                    </Col>
                  </Row>
                  <Col
                    className="col-sm-12 titlebt"
                    style={{
                      backgroundColor: "#D8D8D8",
                      padding: 10,
                      borderRadius: 5,
                      marginTop: 30,
                      fontSize: 16,
                    }}
                  >
                    ສະຖານທີເກີດ
                  </Col>
                  <Row style={{ marginTop: 12 }}>
                    <Col sm="12">
                      <Form.Check
                        type="checkbox"
                        label="ນັກສຶກສາເກີດໃນຕ່າງປະເທດ"
                        inline
                        value={isChecked}
                        checked={isChecked}
                        onClick={() => setIsChecked(!isChecked)}
                      />
                    </Col>
                  </Row>
                  {isChecked ? (
                    <Row>
                      <Col sm={3}>
                        <div className="form-group">
                          <label>ປະເທດ {valiDate()}</label>
                          <select
                            className="form-control"
                            name="birthCountry"
                            value={values.birthCountry}
                            onChange={handleChange}
                          >
                            <option value="">---ເລືອກປະເທດ---</option>
                            {COUNTRIES_LIST.map((countries, index) => (
                              <option
                                key={"countries" + index}
                                value={countries?.en_short_name}
                              >
                                {countries?.en_short_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-group">
                          <label>ແຂວງ {valiDate()}</label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນແຂວງ"
                            name="birthProvince"
                            value={values.birthProvince}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-group">
                          <label>ເມືອງ {valiDate()}</label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນເມືອງ"
                            name="birthDistrict"
                            value={values.birthDistrict}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-group">
                          <label>ບ້ານ {valiDate()}</label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນບ້ານ"
                            name="birthVillage"
                            value={values.birthVillage}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col sm="4">
                        <Form.Label fontSize="14" className="font-color">
                          ແຂວງ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="birthProvince"
                          onChange={(e) => {
                            _selectBirthProvince(e);
                            handleChange(e);
                          }}
                          isInvalid={errors.birthProvince}
                          value={values.birthProvince}
                        >
                          <option value="">---ເລືອກແຂວງ---</option>
                          {LAO_ADDRESS.map((province, index) => (
                            <option
                              key={"province" + index}
                              value={province?.province_name}
                            >
                              {province?.province_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col sm="4">
                        <Form.Label fontSize="14" className="font-color">
                          ເມືອງ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={values.birthDistrict}
                          name="birthDistrict"
                          isInvalid={errors.birthDistrict}
                          onChange={(e) => {
                            _selectBirthDistrict(e);
                            handleChange(e);
                          }}
                        >
                          <option value="">---ເລືອກເມືອງ---</option>
                          {selectBirthProvinceIndex > -1 &&
                            LAO_ADDRESS[
                              selectBirthProvinceIndex
                            ].district_list.map((district, index) => (
                              <option
                                key={"birth-district" + index}
                                value={district.district}
                              >
                                {district.district}
                              </option>
                            ))}
                        </Form.Control>
                      </Col>
                      <Col sm="4">
                        <Form.Label fontSize="14" className="font-color">
                          ບ້ານ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="birthVillage"
                          value={values.birthVillage}
                          isInvalid={errors.birthVillage}
                          placeholder="ກາລຸນາປ້ອນບ້ານ"
                          onChange={handleChange}
                          maxLength="20"
                        />
                      </Col>
                    </Row>
                  )}
                  {dataStudent?.studentType !== "IN_PLAN" ? (
                    <div>
                      <Col
                        className="col-sm-12 titlebt"
                        style={{
                          backgroundColor: "#D8D8D8",
                          padding: 10,
                          borderRadius: 5,
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        ສໍາຫຼັບນັກສຶກສາລະບົບຈ່າຍຄ່າຮຽນ
                      </Col>
                      <Row style={{ marginTop: 12 }}>
                        <Col sm="4">
                          <Form.Label fontSize="14" className="font-color">
                            ຈົບຈາກແຂວງ <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="graduateFromProvince"
                            isInvalid={errors.graduateFromProvince}
                            onChange={(e) => {
                              // _selectGraduateFromProvince(e)
                              handleChange(e);
                            }}
                            value={values.graduateFromProvince}
                          >
                            <option value="">---ເລືອກເມືອງ---</option>
                            {LAO_ADDRESS.map((province, index) => (
                              <option
                                key={"province" + index}
                                value={province?.province_name}
                              >
                                {province?.province_name}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col sm="4">
                          <Form.Label fontSize="14" className="font-color">
                            ຈົບຈາກໂຮງຮຽນ <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ກະລຸນາປ້ອນ"
                            value={values.graduateFromSchool}
                            name="graduateFromSchool"
                            isInvalid={errors.graduateFromSchool}
                            onChange={handleChange}
                            maxLength="40"
                          />
                        </Col>
                        <Col sm="4">
                          <Form.Label fontSize="14" className="font-color">
                            ສົກສຶກສາ <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="semesterYear"
                            isInvalid={errors.semesterYear}
                            onChange={handleChange}
                            value={values.semesterYear}
                          >
                            <option>ເລືອກສົກສຶກສາ</option>
                            {graduatedYear.map((x, index) => {
                              return (
                                <option value={x} key={index}>
                                  {x}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div>
                      <Col
                        className="col-sm-12 titlebt"
                        style={{
                          backgroundColor: "#D8D8D8",
                          padding: 10,
                          borderRadius: 5,
                          marginTop: 20,
                        }}
                      >
                        ສ​ຳຫຼັບນັກ​ສຶກ​ສາ/ພະ​ນັກ​ງານ ທຶນ​ແບ່ງ​ປັນ
                      </Col>
                      <Row style={{ marginTop: 22 }}>
                        <Col sm="12" style={{ marginTop: 20 }}>
                          <Form.Control
                            as="select"
                            id="disabledSelect"
                            name="scholarship"
                            value={values.scholarship}
                            isInvalid={errors.scholarship}
                            onChange={(e) => {
                              selectScholarship(e);
                              handleChange(e);
                            }}
                          >
                            <option value="">ເລືອກ</option>
                            <option value="ANTIONAL_STUDENT_SCHOLARSHIP">
                              ນັກ​ຮຽນ​ເກັ່ງ​ລະ​ດັບ​ຊາດ
                            </option>
                            <option value="NUOL_SCHOLARSHIP">
                              ນັກ​ຮຽນ​ເສັງ​ໄດ້​ທຶນ ມ​ຊ
                            </option>
                            <option value="RED_DIPLOMA_SCHOLARSHIP">
                              ນັກ​ຮຽນ​ເກັ່ງ​ປ​ະ​ກາ​ສະ​ນິ​ຍະ​ບັດ​ແດງ
                            </option>
                            <option value="NO_ONE_STUDENT_OF_PROVINCIAL">
                              ນັກ​ຮຽນ​ເກັ່ງ​ເສັງ​ໄດ້​ທີ່ 1 ຂອງ​ແຂວງ
                            </option>
                            <option value="ETHNIC_STUDENT_OF_PROVINCIAL">
                              ນັກ​ຮຽນ​ຊົນ​ເຜົ່າ​ຂອ​ງ​ແຂວງ
                            </option>
                            <option value="EXCHANGE_STUDENT_SCHOLARSHIP">
                              ທຶນ​ແບ່ງ​ນັກ​ຮຽນ​ສາ​ມັນ
                            </option>
                            <option value="OFFICIAL_STAFF_SCHOLARSHIP">
                              ພະ​ນັກ​ງານ
                            </option>
                            <option value="OTHER_SHOLARSHIP">
                              ທຶນ​ແບ່ງ​ປັນ​ອື່ນໆ
                            </option>
                          </Form.Control>
                        </Col>
                        <Col sm="4" style={{ marginTop: 20 }}>
                          <Form.Label fontSize="14" className="font-color">
                            ຈົບ​ຈາກແຂວງ <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="graduateFromProvince"
                            value={values.graduateFromProvince}
                            isInvalid={errors.graduateFromProvince}
                            onChange={(e) => {
                              _selectGraduateFromProvince(e);
                              handleChange(e);
                            }}
                          >
                            <option value="">---ເລືອກເມືອງ---</option>
                            {LAO_ADDRESS.map((province, index) => (
                              <option
                                key={"province" + index}
                                value={province.province_name}
                              >
                                {province?.province_name}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col sm="4" style={{ marginTop: 20 }}>
                          <Form.Label fontSize="14" className="font-color">
                            ຈົບ​ຈາກ​ໂຮງ​ຮຽນ{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ກະລຸນາປ້ອນ"
                            value={values.graduateFromSchool}
                            name="graduateFromSchool"
                            isInvalid={errors.graduateFromSchool}
                            onChange={handleChange}
                            maxLength="40"
                          />
                        </Col>
                        <Col sm="4" style={{ marginTop: 20 }}>
                          <Form.Label fontSize="14" className="font-color">
                            ສົກ​ສຶກ​ສາ <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="semesterYear"
                            isInvalid={errors.semesterYear}
                            onChange={handleChange}
                            value={values.semesterYear}
                          >
                            <option value="">ເລືອກສົກສຶກສາ</option>
                            {graduatedYear.map((x, index) => {
                              return (
                                <option value={x} key={index}>
                                  {x}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                        <Col sm="6" style={{ marginTop: 20 }}>
                          <Form.Label fontSize="14" className="font-color">
                            ເລກທີໃບນຳສົ່ງ
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="transferLetterNo"
                            placeholder="ກະ​ລຸ​ນາ​ປ້ອນເລກທີໃບນຳສົ່ງ"
                            isInvalid={errors.transferLetterNo}
                            onChange={handleChange}
                            value={values.transferLetterNo}
                            maxLength="20"
                          />
                        </Col>
                        <Col sm="6" style={{ marginTop: 20 }}>
                          <Form.Label fontSize="14" className="font-color">
                            ລົງ​ວັນ​ທີ່
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="dateTransferLetterNo"
                            isInvalid={errors.dateTransferLetterNo}
                            onChange={handleChange}
                            value={values.dateTransferLetterNo}
                          />
                        </Col>
                        <Col sm="6" style={{ marginTop: 20 }}>
                          <Form.Label fontSize="14" className="font-color">
                            ເລກທີຂໍ້ຕົກລົງເສັງ​ໄດ້​ທຶນ ມ​ຊ
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="scholarshipAgreementNo"
                            placeholder="ກະລຸນາປ້ອນເລກທີຂໍ້ຕົກລົງເສັງ​ໄດ້​ທຶນ ມ​ຊ"
                            isInvalid={errors.scholarshipAgreementNo}
                            onChange={handleChange}
                            value={values.scholarshipAgreementNo}
                            maxLength="20"
                          />
                        </Col>
                        <Col sm="6" style={{ marginTop: 20 }}>
                          <Form.Label fontSize="14" className="font-color">
                            ລົງ​ວັນ​ທີ່
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="dateScholarshipAgreementNo"
                            isInvalid={errors.dateScholarshipAgreementNo}
                            onChange={handleChange}
                            value={values.dateScholarshipAgreementNo}
                          />
                        </Col>
                        <Col sm="12" style={{ marginTop: 20 }}>
                          <Form.Label fontSize="14" className="font-color">
                            ທຶນ​ແບ່ງ​ປັນອື່ນໆ{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ກະ​ລຸ​ນາ​ປ້ອນທຶນ​ແບ່ງ​ປັນ​ອື່​ນໆ"
                            name="otherScholarship"
                            isInvalid={errors.otherScholarship}
                            onChange={handleChange}
                            value={values.otherScholarship}
                            maxLength="50"
                            disabled={
                              scholarship === ""
                                ? true
                                : scholarship === "OTHER_SHOLARSHIP"
                                ? false
                                : true
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                  {values.educationLevel === "BACHELOR_CONTINUING" ? (
                    <Row>
                      <Col sm="12">
                        <Form.Label fontSize="14" className="font-color">
                          ຈົບສາຂາວິຊາ <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="ກະ​ລຸ​ນາ​ປ້ອນຊື່ ສາຂາວິຊາຈົບ"
                          name="graduatedMajor"
                          isInvalid={errors.graduatedMajor}
                          onChange={handleChange}
                          value={values.graduatedMajor}
                          maxLength="40"
                        />
                      </Col>
                    </Row>
                  ) : null}

                  <Col
                    className="col-sm-12 titlebt"
                    style={{
                      backgroundColor: "#D8D8D8",
                      padding: 10,
                      borderRadius: 5,
                      marginTop: 30,
                      fontSize: 16,
                    }}
                  >
                    ຂໍ້​ມູນ​ຜູ້​ປົກ​ຄອງ
                  </Col>
                  <Row>
                    <Col sm="4">
                      <Form.Label fontSize="14" className="font-color">
                        ຊື່ ແລະ ນາມສະກຸນພໍ່{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="ກະ​ລຸ​ນາ​ປ້ອນຊື່ ແລະ ນາມສະກຸນພໍ່"
                        name="fatherNameAndSurname"
                        isInvalid={errors.fatherNameAndSurname}
                        onChange={handleChange}
                        value={values.fatherNameAndSurname}
                        maxLength="40"
                      />
                    </Col>
                    <Col sm="4">
                      <Form.Label fontSize="14" className="font-color">
                        ຊື່ ແລະ ນາມສະກຸນແມ່{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="ກະ​ລຸ​ນາ​ປ້ອນຊື່ ແລະ ນາມສະກຸນແມ່"
                        name="motherNameAndSurname"
                        isInvalid={errors.motherNameAndSurname}
                        onChange={handleChange}
                        value={values.motherNameAndSurname}
                        maxLength="40"
                      />
                    </Col>
                    <Col sm="4">
                      <Form.Label fontSize="14" className="font-color">
                        ເບີໂທລະສັບພໍ່/ແມ່ <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="ກະ​ລຸ​ນາ​ປ້ອນເບີໂທລະສັບພໍ່/ແມ່"
                        name="parentTelephone"
                        isInvalid={errors.parentTelephone}
                        onChange={handleChange}
                        value={values.parentTelephone}
                        maxLength="11"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 12 }}>
                    <Col sm="4">
                      <Form.Label fontSize="14" className="font-color">
                        ແຂວງ <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="parentProvince"
                        onChange={(e) => {
                          _selectParentProvince(e);
                          handleChange(e);
                        }}
                        isInvalid={errors.parentProvince}
                        value={values.parentProvince}
                      >
                        <option value="">---ເລືອກແຂວງ---</option>
                        {LAO_ADDRESS.map((province, index) => (
                          <option
                            key={"province" + index}
                            value={province?.province_name}
                          >
                            {province?.province_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col sm="4">
                      <Form.Label fontSize="14" className="font-color">
                        ເມືອງ <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={values.parentDistrict}
                        name="parentDistrict"
                        isInvalid={errors.parentDistrict}
                        onChange={(e) => {
                          _selectParentDistrict(e);
                          handleChange(e);
                        }}
                      >
                        <option value="">---ເລືອກເມືອງ---</option>
                        {selectParentProvinceIndex > -1 &&
                          LAO_ADDRESS[
                            selectParentProvinceIndex
                          ].district_list.map((district, index) => (
                            <option
                              key={"parent-district" + index}
                              value={district.district}
                            >
                              {district.district}
                            </option>
                          ))}
                      </Form.Control>
                    </Col>
                    <Col sm="4">
                      <Form.Label fontSize="14" className="font-color">
                        ບ້ານ <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="parentVillage"
                        value={values.parentVillage}
                        isInvalid={errors.parentVillage}
                        placeholder="ກາລຸນາປ້ອນບ້ານ"
                        onChange={handleChange}
                        maxLength="20"
                      />
                    </Col>
                  </Row>

                  <Col
                    className="col-sm-12 titlebt"
                    style={{
                      backgroundColor: "#D8D8D8",
                      padding: 10,
                      borderRadius: 5,
                      marginTop: 30,
                      fontSize: 16,
                    }}
                  >
                    ກໍລະນີສຸກເສີນຕິດຕໍ່
                  </Col>
                  <Row style={{ marginTop: 22 }}>
                    <Col sm="6">
                      <Form.Label fontSize="14" className="font-color">
                        ພົວ​ພັນ​ທ່ານ <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={values.contactName}
                        name="contactName"
                        isInvalid={errors.contactName}
                        placeholder="ກະ​ລຸ​ນາ ປ້ອນ​ຊື່ ແລະ ນາມ​ສະ​ກຸນ"
                        onChange={handleChange}
                        maxLength="40"
                      />
                    </Col>
                    <Col sm="6">
                      <Form.Label fontSize="14" className="font-color">
                        ເບີ​ໂທ​ຕິດ​ຕໍ່ <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={values.emergencyPhone}
                        name="emergencyPhone"
                        isInvalid={errors.emergencyPhone}
                        placeholder="​ກະ​ລຸ​ນາ ປ້ອນ​ເບີ​ຕິດ​ຕໍ່"
                        onChange={handleChange}
                        maxLength="11"
                      />
                    </Col>
                    <Form.Group
                      controlId="formBasicCheckbox"
                      style={{ marginTop: 25 }}
                    >
                      <Form.Check
                        type="checkbox"
                        label="ຢູ່ຮ່ວມກັນ"
                        name="stayTogether"
                        value={values.stayTogether}
                        checked={values.stayTogether === true ? true : false}
                        onClick={() => _clickStayTogether()}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    {stayTogether === false ? (
                      <>
                        <Form.Label fontSize="14" className="text-bold">
                          <span className="text-danger">*</span> ໝາຍເຫດ
                          ຫາກບໍ່ໄດ້ຢູ່ຮ່ວມກັນ ໃຫ້ລະບຸດ້ານລຸ່ມ
                        </Form.Label>
                        <Col sm="4">
                          <Form.Label fontSize="14" className="font-color">
                            ແຂວງ <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="emergencyProvice"
                            onChange={(e) => {
                              _selectEmergencyProvince(e);
                              handleChange(e);
                            }}
                            isInvalid={errors.emergencyProvince}
                            value={values.emergencyProvince}
                          >
                            <option value="">---ເລືອກແຂວງ---</option>
                            {LAO_ADDRESS.map((province, index) => (
                              <option
                                key={"province" + index}
                                value={province?.province_name}
                              >
                                {province?.province_name}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col sm="4">
                          <Form.Label fontSize="14" className="font-color">
                            ເມືອງ <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={values.emergencyDistrict}
                            name="emergencyDistrict"
                            isInvalid={errors.emergencyDistrict}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          >
                            <option value="">---ເລືອກເມືອງ---</option>
                            {selectEmergencyProvinceIndex > -1 &&
                              LAO_ADDRESS[
                                selectEmergencyProvinceIndex
                              ].district_list.map((district, index) => (
                                <option
                                  key={"birth-district" + index}
                                  value={district.district}
                                >
                                  {district.district}
                                </option>
                              ))}
                          </Form.Control>
                        </Col>
                        <Col sm="4">
                          <Form.Label fontSize="14" className="font-color">
                            ບ້ານ <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="emergencyVillage"
                            value={values.emergencyVillage}
                            isInvalid={errors.emergencyVillage}
                            placeholder="Please input village"
                            onChange={handleChange}
                            maxLength="50"
                          />
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </Row>

                  <Col
                    className="col-sm-12 titlebt"
                    style={{
                      backgroundColor: "#D8D8D8",
                      padding: 10,
                      borderRadius: 5,
                      marginTop: 30,
                      fontSize: 16,
                    }}
                  >
                    ອັບໂຫຼດເອກະສານຄັດຕິດ
                  </Col>

                  <Row
                    style={{
                      marginBottom: 90,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {[
                      {
                        label: "ອັບໂຫຼດໄຟລຮູບ ບັດປະຈໍາຕົວ ຫຼື ສໍາມະໂນຄົວ",
                        id: "file-upload1",
                        state: idCardOrFamilyBook,
                        setState: setIdCardOrFamilyBook,
                      },
                      {
                        label: "ອັບໂຫຼດໄຟລຮູບ ໃບປະກາດ",
                        id: "file-upload2",
                        state: certificate,
                        setState: setCertificate,
                      },
                      {
                        label: "ອັບໂຫຼດໄຟລູບ ໃບເຂົ້າຫ້ອງເສັງ",
                        id: "file-upload3",
                        state: testCard,
                        setState: setTestCard,
                      },
                    ].map((item, index) => (
                      <Col sm="4" key={index} style={{ marginTop: 30 }}>
                        <h6
                          style={{
                            fontWeight: "bold",
                            marginBottom: 10,
                            color: "#333",
                            textAlign: "center",
                          }}
                        >
                          {item.label}
                        </h6>
                        <div
                          style={{
                            border: "2px dashed #ccc",
                            borderRadius: "12px",
                            padding: "20px",
                            textAlign: "center",
                            cursor: "pointer",
                            backgroundColor: "#fafafa",
                            transition: "all 0.3s",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                            maxWidth: "320px",
                            margin: "auto",
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.borderColor = "#057CAE")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.borderColor = "#ccc")
                          }
                        >
                          <input
                            type="file"
                            id={item.id}
                            accept="image/*, application/pdf"
                            onChange={_handleChangeFile}
                            hidden
                          />
                          <label
                            htmlFor={item.id}
                            style={{ cursor: "pointer", display: "block" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              {click === String(index + 1) && isLoading ? (
                                <Spinner
                                  animation="border"
                                  style={{ color: "#057CAE" }}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faUpload}
                                  size="2x"
                                  style={{
                                    color: "#057CAE",
                                    marginBottom: "10px",
                                  }}
                                />
                              )}
                              <span style={{ fontSize: "14px", color: "#666" }}>
                                ເລືອກໄຟລ
                              </span>
                            </div>
                          </label>
                          {item.state && !isLoading && (
                            <aside
                              style={{ marginTop: "15px", textAlign: "center" }}
                            >
                              <h6
                                style={{ color: "#057CAE", fontWeight: "bold" }}
                              >
                                ໄຟລທີ່ຈະອັບໂຫລດ:
                              </h6>
                              <div>{item.state}</div>
                              <Button
                                onClick={() => item.setState(null)}
                                variant="danger"
                                style={{
                                  marginTop: "10px",
                                  borderRadius: "8px",
                                  padding: "6px 12px",
                                }}
                              >
                                ລົບໄຟລ
                              </Button>
                            </aside>
                          )}
                        </div>
                      </Col>
                    ))}
                  </Row>

                  <div
                    className="d-flex justify-content-center fixed-bottom"
                    style={{
                      marginTop: 50,
                      backgroundColor: "#fff",
                      padding: 10,
                    }}
                  >
                    <Col className="d-flex justify-content-center">
                      <div style={{ marginRight: 70 }}>
                        <CustomButton
                          type="submit"
                          title="ຍົກເລີກ"
                          onClick={() => {
                            history.goBack();
                          }}
                        />
                      </div>
                      <CustomButton
                        type="submit"
                        confirm
                        title="ຢືນຢັນ"
                        disabled={isLoading}
                        onClick={() => {
                          handleSubmit();
                        }}
                      />
                    </Col>
                  </div>
                </div>
              )}
            </Formik>
          }
        </div>
        <Modal
          //   show={show}
          //   onHide={() => setShow(false)}
          centered
        >
          <Modal.Body>
            <Col
              className="text-center"
              style={{ paddingTop: 40, paddingBottom: 50 }}
            >
              <div
                className="textfont-size72 font-color"
                style={{ color: "#00A1DE" }}
              >
                ສໍາເລັດ
              </div>
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="9x"
                style={{ color: "#00A1DE" }}
              />
              <div
                className="textfont-size19 font-color"
                style={{ marginTop: 40 }}
              >
                ປັນທືກເປັນທີ່ຮຽບຮ້ອຍ
              </div>
            </Col>
          </Modal.Body>
        </Modal>
        <PreregistrationUpdateConfirm
          showAddConfirmModal={showAddConfirmModal}
          data={formParam}
          _handleShowAddConfirmModalClose={_handleShowAddConfirmModalClose}
        />
      </div>
    </div>
  );
}

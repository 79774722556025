import React, { useState, useEffect } from 'react'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import useReactRouter from 'use-react-router'
import { Modal, Form } from 'react-bootstrap'
import Loading from '../../../common/Loading'
import { Formik } from 'formik'
import EditeTribes from './EditeTribes'
import { CustomContainer, TableHeader, CustomButton } from '../../../common'
import { valiDate, alertSuccess } from '../../../common/super'
import {
  TRIBE_MASTER,
  DELETE_TRIBEMASTER,
  CREATE_TRIBEMASTER,
} from '../../../apollo/tribeMaster/index'
import { hasPermission } from '../../../helpers/CheckPermission'
//
function SettingsTribes() {
  const [showSearchView, setShowSearchView] = useState(false)
  const [newData, setnewData] = useState()
  const _handleSearchViewClose = () => setShowSearchView(false)
  const _handleSearchViewShow = e => {
    setnewData(e)
    setShowSearchView(true)
  }
  const { history } = useReactRouter()
  const [dataUser, setDataUser] = useState(null)
  const [callDataDelete, setCallDataDelete] = useState()
  const [showConfirm, setShowConfirm] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const handleAdd = () => setShowConfirm(true)
  const handleClose = () => setShowConfirm(false)
  const _handleCloseDelete = () => setShowDelete(false)

  const [deleteTribeMaster] = useMutation(DELETE_TRIBEMASTER)

  const _Delete = async i => {
    setShowDelete(true)
    setCallDataDelete(i)
  }
  const _comfrimeDelete = async callDataDelete => {
    await deleteTribeMaster({ variables: { where: { id: callDataDelete.id } } })
    history.push('/setting-master-data/tribe/limit/50/skip/1')
    alertSuccess('ການລຶບຂໍ້ມູນຊົນເຜົ່າສຳເລັດ')
  }
  // queryDepartment
  const [
    loadTribeMaster,
    {
      loading: tribeMasterLoading,
      data: tribeMasterData
    }
  ] = useLazyQuery(TRIBE_MASTER)

  const [createTribeMaster] = useMutation(CREATE_TRIBEMASTER)

  // create
  const _saveTribes = data => {
    createTribeMaster({
      variables: {
        data: {
          name: data.name,
          note: data.note
        }
      }
    })
      .then(async () => {
        setShowConfirm(false)
        alertSuccess('ການບັນທຶກຂໍ້ມູນຊົນເຜົ່າສຳເລັດ')
      })
      .catch(err => { })
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      setDataUser(user.data)
    } else {
      history.push(`/`)
    }
  }, [history])

  useEffect(() => {
    loadTribeMaster()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUser])

  return (
    <div>
      <CustomContainer>
        <div className='row' style={{ marginTop: 0, marginBottom: 0 }}>
          <div className='col-md-10'>
            <strong>ລາຍການຊົນເຜົ່າ</strong>
          </div>
          <div className='col-md-2'>
            <button
              className='btn btn-info right'
              onClick={handleAdd}
              hidden={!hasPermission("FIXED_DATA", "create")}
            >
              <i className='fa fa-plus'></i> ເພີ່ມ
            </button>
          </div>
          {tribeMasterLoading && <Loading />}
          <table className='table-hover' borderColor='#fff' width='100%'>
            <thead>
              <TableHeader>
                <th style={{ width: 100 }}>ລຳດັບ</th>
                <th>ຊື່ຊົນເຜົ່າ</th>
                <th>ໝາຍເຫດ</th>
                {hasPermission("FIXED_DATA", "edit") || hasPermission("FIXED_DATA", "delete") ?
                  <th style={{ width: 200 }}>ຈັດການ</th>
                  : null
                }
              </TableHeader>
            </thead>
            <tbody>
              {tribeMasterData?.tribeMasters?.data?.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    border: '2px solid #ffffff',
                    textAlign: 'center',
                    background: '#f0f0f0'
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td className='left'>{item.note}</td>
                  {hasPermission("FIXED_DATA", "edit") || hasPermission("FIXED_DATA", "delete") ?
                    <td>
                      <button
                        className='btn btn-control'
                        onClick={e => _handleSearchViewShow(item)}
                        hidden={!hasPermission("FIXED_DATA", "edit")}
                      // data-toggle="modal"
                      // data-target="#editData"
                      >
                        <i className='fa fa-pen' style={{ color: '#28a745' }}></i>
                      </button>
                      &nbsp;&nbsp;
                      <button
                        className='btn btn-control'
                        data-toggle='modal'
                        data-target='#deleteTribe'
                        onClick={() => _Delete(item)}
                        hidden={!hasPermission("FIXED_DATA", "delete")}
                      >
                        <i
                          className='fa fa-trash'
                          style={{ color: '#B80000' }}
                        ></i>
                      </button>
                    </td>
                    : null
                  }
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CustomContainer>
      {/* add Data  */}
      <Formik
        initialValues={{
          tribeName: '',
          note: ''
        }}
        validate={(values) => {
          const errors = {}
          if (!values.tribeName) {
            errors.tribeName = 'Required'
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          let data = {
            name: values.tribeName,
            note: values.note
          }
          _saveTribes(data)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
          /* and other goodies */
        }) => (
          <Modal
            animation={false}
            size='lg'
            show={showConfirm}
            style={{ padding: 0 }}
            onHide={handleClose}
          >
            <Modal.Header style={{ padding: 10, border: 0 }}>
              <Modal.Title
                style={{
                  textAlign: 'center',
                  width: '100%',
                  padding: 0,
                  marginTop: 20,
                  fontWeight: 'bolder'
                }}
              >
                <strong>ເພີ່ມຂໍ້ມູນຊົນເຜົ່າ</strong>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0 100px 20px 100px',
                justifyContent: 'center'
              }}
            >
              <Form>
                <Form.Label>
                  ຊື່ຊົນເຜົ່າ {valiDate()}
                </Form.Label>
                <Form.Control
                  type="text"
                  name='tribeName'
                  onChange={handleChange}
                  value={values.tribeName}
                  placeholder='ກະລຸນາປ້ອນຊື່ຊົນເຜົ່າ'
                  isInvalid={!!errors.tribeName}
                />
                <Form.Label>ໝາຍເຫດ</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  onChange={handleChange}
                  name='note'
                  value={values.note}
                  placeholder='ກະລຸນາປ້ອນໝາຍເຫດ(ຖ້າມີ)'
                />
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <CustomButton title='ຍົກເລີກ' onClick={handleClose} />
              <CustomButton onClick={handleSubmit} confirm title='ບັນທຶກ' />
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
      {/* // delete Module */}
      <Modal
        animation={false}
        size='md'
        show={showDelete}
        style={{ padding: 0 }}
        onHide={_handleCloseDelete}
      >
        <Modal.Header style={{ padding: 10, border: 0 }}>
          <Modal.Title
            style={{
              textAlign: 'center',
              width: '100%',
              padding: 0,
              marginTop: 20,
              fontWeight: 'bolder'
            }}
          >
            <strong>ລືບຂໍ້ມູນຊົນເຜົ່າ</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 100px 20px 100px',
            justifyContent: 'center'
          }}
        >
          <span>
            ທ່ານຕ້ອງການລຶບຊົນເຜົ່າ {callDataDelete?.name} ແທ້ ຫຼື ບໍ ?
          </span>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            style={{
              background: '#fff',
              color: '#c7c7c7',
              border: '1px solid #c7c7c7'
            }}
            title='ຍົກເລີກ'
            onClick={_handleCloseDelete}
          />
          <CustomButton
            style={{ background: '#c7c7c7', color: '#fff', border: 'none' }}
            onClick={() => _comfrimeDelete(callDataDelete)}
            confirm
            title='ຢືນຢັນການລົບ'
          />
        </Modal.Footer>
      </Modal>

      <EditeTribes
        showSearchView={showSearchView}
        _handleSearchViewClose={_handleSearchViewClose}
        let
        allData={newData}
      />
    </div>
  )
}
export default SettingsTribes

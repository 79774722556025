import React, { useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import EntranceStudentList from "./EntranceStudentList";
import EntranceSetting from "./EntranceSetting";
import { hasPermission } from "../../helpers/CheckPermission";
import EntranceStudentClassRoom from "./EntranceStudentAssign";
import StudentEntranceReport from "./report";
import EntranceStudentPaymentList from "./EntranceStudentPaymentList";

const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

const EntrancePage = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ m: 0, width: "100%", typography: "body1" }}>
      <Box
        sx={{
          borderBottom: 5,
          borderColor: "divider",
          backgroundColor: "#fff",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Entrance Page Tabs"
          variant="scrollable" // Use scrollable variant for left alignment
          textColor="primary"
          indicatorColor="primary"
          sx={{
            justifyContent: "flex-start", // Align tabs to the left
          }}
        >
          {[
            "ນັກສຶກສາສົ່ງຟອມສະຫມັກເສັງ",
            "ນັກສຶກສາທີ່ຖືກຢືນຢັນແລ້ວ",
            "ນັກສຶກສາທີ່ຖືກປະຕິເສດ",
            "ນັກສຶກສາຈ່າຍຄ່າຮຽນ",
            "ນັກສຶກສາທີ່ຂໍປ່ຽນສາຍຮຽນ",
            "ນັກສຶກສາເສັງຜ່ານ",
            "ຈັດນັກສຶກສາໃສ່ຫ້ອງເສັງ",
            "ລາຍງານ",
            "ຕັ້ງຄ່າ",
          ].map((label, index) => (
            <Tab
              key={index}
              label={label}
              {...a11yProps(index)}
              sx={{
                width: "200px", // Fixed width for each tab
                textAlign: "center", // Center the text within the tab
              }}
            />
          ))}
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <EntranceStudentList status={"PENDING"} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <EntranceStudentList status={"APPROVED"} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <EntranceStudentList status={"REJECTED"} />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <EntranceStudentPaymentList />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <EntranceStudentList status={"REQUEST_UPDATE"} />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <EntranceStudentList status={"PASSED"} />
      </TabPanel>
      <TabPanel value={tabValue} index={6}>
        <EntranceStudentClassRoom status={"PASSED"} />
      </TabPanel>
      <TabPanel value={tabValue} index={7}>
        <StudentEntranceReport />
      </TabPanel>
      {hasPermission("ENTRANCE_STUDENT", "setting") ? (
        <TabPanel value={tabValue} index={8}>
          <EntranceSetting />
        </TabPanel>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh" // Adjust height as needed
        >
          <Typography textAlign="center">ທ່ານບໍ່ມີສິດໃນການຕັ້ງຄ່າ</Typography>
        </Box>
      )}
    </Box>
  );
};

export default EntrancePage;

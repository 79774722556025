import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  Alert,
  Container,
  Paper,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { fetchGet } from "../../common/fetchAPI";

const StudentAttendanceDetail = ({ studentId }) => {
  const [studentAttendance, setStudentAttendance] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null); // No default filter

  const fetchStudentAttendance = async () => {
    setLoading(true);
    try {
      const response = await fetchGet(
        `attendance/student?studentId=${studentId}`
      );
      if (response?.success) {
        setStudentAttendance(response.data);
      } else {
        setError("Failed to fetch attendance data.");
      }
    } catch (err) {
      setError("An error occurred while fetching data.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchStudentAttendance();
  }, [studentId]);

  // Group attendance records by date
  const attendanceByDate = useMemo(() => {
    return studentAttendance.reduce((acc, record) => {
      const date = new Date(record.date).toISOString().split("T")[0]; // YYYY-MM-DD
      if (!acc[date]) acc[date] = [];
      acc[date].push(record);
      return acc;
    }, {});
  }, [studentAttendance]);

  // Filtered attendance based on selected date (if selected)
  const filteredAttendance = selectedDate
    ? attendanceByDate[selectedDate.toISOString().split("T")[0]] || []
    : studentAttendance; // Default: Show all

  return (
    <Box sx={{ py: 3 }}>
      {/* Loading State */}
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <CircularProgress />
        </Box>
      )}

      {/* Error Handling */}
      {error && (
        <Alert severity="error" sx={{ my: 2 }}>
          {error}
        </Alert>
      )}

      {/* Attendance Table */}
      <Paper elevation={0} sx={{ p: 4, borderRadius: 2 }}>
        <Typography variant="h5" gutterBottom color="primary" align="center">
          ລາຍງານການມາຮຽນຂອງນັກສຶກສາ
        </Typography>

        {/* Attendance Records Table */}
        {filteredAttendance.length ? (
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                  <TableCell>
                    <b>ວັນທີ</b>
                  </TableCell>
                  <TableCell>
                    <b>ວິຊາ</b>
                  </TableCell>
                  <TableCell>
                    <b>ຊົ່ວໂມງຮຽນ</b>
                  </TableCell>
                  <TableCell>
                    <b>ອາຈານສອນ</b>
                  </TableCell>
                  <TableCell>
                    <b>ສະຖານະ</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAttendance.map((record) => (
                  <TableRow key={record._id}>
                    <TableCell>
                      {new Date(record.date).toISOString().split("T")[0]}
                    </TableCell>
                    <TableCell>
                      {record.course.course.subject.titleEn} (
                      {record.course.course.subject.code})
                    </TableCell>
                    <TableCell>{record.time}</TableCell>
                    <TableCell>
                      {record.teacher.firstname} {record.teacher.lastname}
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color:
                            record.studentRecord.status === "present"
                              ? "green"
                              : "red",
                        }}
                      >
                        {record.studentRecord.status.toUpperCase() === "PRESENT"
                          ? "ມາຮຽນ"
                          : "ຂາດຮຽນ"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography
            variant="body1"
            align="center"
            sx={{ mt: 2, color: "gray" }}
          >
            ຍັງບໍ່ມີຂໍ້ມຸນ
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default StudentAttendanceDetail;

import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, Menu, MenuItem, Select, TextField, Typography } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import { fetchGetV2, fetchPostV2 } from '../../../common/fetchAPI';
import { toast } from 'react-toastify';
import { convertGenderNam, convertGenderNamEN } from '../../../helpers/user';

function RegisterUpgradeDialog({ open, handleClose, fetchData }) {

    const [anchorEl, setAnchorEl] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [matchedData, setMatchedData] = useState();
    const [loading, setLoading] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [isSumitted, setIsSumitted] = useState(false);
    const [courseList, setCourseList] = useState([]);
    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [type, setType] = useState("");

    // Validation errors
    const [errors, setErrors] = useState({
        student: "",
        course: "",
        type: "",
    });

    useEffect(() => {
        if (selectedStudent) {
            fetchCourse();
        }
    }, [selectedStudent]);

    const fetchCourse = async () => {
        // Make API call to fetch course details based on selectedStudent
        const data = await fetchGetV2(
            `/course/student/${selectedStudent?._id}`
        );
        if (data) {
            setCourseList(data || []);
        }
    };

    const handleClickSearch = async (event) => {
        setAnchorEl(event.currentTarget);
        setLoading(true);
        try {
            const getStudent = await fetchGetV2(`student/studentnumber?studentNumber=${searchTerm}`)
            setMatchedData(getStudent);

        } catch (error) {
            setMatchedData();
            setSelectedStudent(null)
            console.log("error", error)
        } finally {
            setLoading(false);
        }
    };

    const handleSelectStudent = (student) => {
        setSelectedStudent(student);
        setAnchorEl(null);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCloseDialog = () => {
        handleClose();
        setAnchorEl(null);
        setSearchTerm("");
        setMatchedData();
        setSelectedStudent(null);
        setErrors({});
        setIsSumitted(false);
        setCourseList([]);
        setSelectedCourseId(null);
    };

    const handleSubmit = async () => {
        let newErrors = {};

        if (!selectedStudent) {
            newErrors.student = "ບໍ່ມີຂໍ້ມູນນັກສຶກສາ";
        }
        if (!selectedCourseId) {
            newErrors.course = "ກະລຸນາເລືອກວິຊາ";
        }

        if (!type) {
            newErrors.type = "ກະລຸນາເລືອກປະເພດການແກ້ເກຣດ";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setIsSumitted(true);

        const postData = {
            student: selectedStudent?._id,
            course: selectedCourseId,
            assessmentYear: selectedStudent?._assessmentYear,
            year: selectedStudent?.yearLevel,
            type: type
        }
        try {
            await fetchPostV2("studentupgrade", postData)
            toast.success("ລົງທະບຽນແກ້ເກຣດສຳເລັດ", {
                autoClose: 3000, // Customize hide time to 5 seconds (5000ms)
            });
            setAnchorEl(null)
            setSearchTerm("")
            setMatchedData([])
            setSelectedStudent(null);
            handleClose();
            fetchData();
        } catch (error) {

            if (error.response.data.message === "Student upgrade for this course already exists") {
                toast.error("ຄຳເຕືອນ ນັກສຶກສານີ້ໄດ້ລົງທະບຽນແກ້ເກຣດແລ້ວ", {
                    autoClose: 3000, // Customize hide time to 5 seconds (5000ms)
                });
            } else {
                toast.error("ລະບົບມີບັນຫາ ລົງທະບຽນແກ້ເກຣດບໍ່ສຳເລັດ", {
                    autoClose: 3000, // Customize hide time to 5 seconds (5000ms)
                });
            }
        } finally {
            setIsSumitted(false);
        }
    };

    return (
        <Dialog open={open} onClose={() => handleCloseDialog()} fullWidth maxWidth="sm">
            <DialogTitle>
                <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "#1976d2" }}
                >
                    ລົງທະບຽນແກ້ເກຣດ
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Box className="d-flex gap-2" sx={{ alignItems: "center" }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="title"
                        label="ຄົ້ນຫາດ້ວຍລະຫັດນັກສຶກສາ"
                        fullWidth
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setErrors((prev) => ({ ...prev, searchTerm: "" })); // Clear error when typing
                        }}
                    />

                    <Button
                        variant='outlined'
                        color='primary'
                        sx={{ padding: 2 }}
                        startIcon={<SearchIcon />}
                        onClick={handleClickSearch}
                    >
                        ຄົ້ນຫາ
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                        PaperProps={{ style: { maxHeight: 200 } }} // Limit dropdown height
                    >
                        {loading ? (
                            <MenuItem disabled>ກຳລັງໂຫຼດ...</MenuItem>
                        ) : matchedData !== undefined ? (
                            <MenuItem onClick={() => handleSelectStudent(matchedData)}>
                                {matchedData?.studentNumber + " "}
                                {!matchedData?.firstNameL || matchedData?.firstNameL === ""
                                    ? convertGenderNamEN(matchedData?.gender) + " " + matchedData?.firstNameE + " " + matchedData?.lastNameE
                                    : convertGenderNam(matchedData?.gender) + " " + matchedData?.firstNameL + " " + matchedData?.lastNameL}
                            </MenuItem>
                        ) : (
                            <MenuItem disabled>ບໍ່ພົບຂໍ້ມູນນັກສຶກສາ</MenuItem>
                        )}
                    </Menu>
                </Box>
                {selectedStudent &&
                    <Box
                        className="d-flex gap-2 py-2"
                        sx={{
                            borderBottom: "2px solid #f1f1f1",
                            cursor: "pointer",
                            background: "#f2f2f2",
                        }}
                    >
                        <Typography variant="body2">
                            {selectedStudent?.studentNumber + " "}
                            {!selectedStudent?.firstNameL || selectedStudent?.firstNameL === ""
                                ? convertGenderNamEN(selectedStudent?.gender) +
                                " " +
                                selectedStudent?.firstNameE + " " + selectedStudent?.lastNameE
                                : convertGenderNam(selectedStudent?.gender) +
                                " " +
                                selectedStudent?.firstNameL + " " + selectedStudent?.lastNameL}
                        </Typography>

                    </Box>
                }
                <Typography variant='caption' color="error">
                    {errors.student}
                </Typography>
                <FormControl fullWidth margin="dense" error={!!errors.course}>
                    <InputLabel>ເລືອກວິຊາ</InputLabel>
                    <Select
                        label="ເລືອກວິຊາ"
                        name="course"
                        value={selectedCourseId}
                        onChange={(e) => {
                            setSelectedCourseId(e.target.value);
                            setErrors((prev) => ({ ...prev, course: "" })); // Clear error when selecting
                        }}
                    >
                        {courseList.map((course) => {
                            return (
                                <MenuItem value={course?._id}>{course?.course?.subject?.title}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                {errors.course && (
                    <Typography variant="caption" color="error">
                        {errors.course}
                    </Typography>
                )}
                <FormControl fullWidth margin="dense" error={!!errors.course}>
                    <InputLabel>ເລືອກປະເພດການແກ້ເກຣດ</InputLabel>
                    <Select
                        label="ເລືອກປະເພດການແກ້ເກຣດ"
                        name="type"
                        value={type}
                        onChange={(e) => {
                            setType(e.target.value);
                            setErrors((prev) => ({ ...prev, type: "" })); // Clear error when selecting
                        }}
                    >
                        <MenuItem key={""} value={""}>
                            ທັງຫມົດ
                        </MenuItem>
                        <MenuItem value="UPGRADE">
                            ແກ້ເກຣດ
                        </MenuItem>
                        <MenuItem value="RETAKE">
                            ຮຽນຄືນ
                        </MenuItem>
                        <MenuItem value="SUMMER">
                            ຮຽນຊ່ວງພັກແລ້ງ
                        </MenuItem>
                        <MenuItem value="PAYMENT">
                            ເໝົາຈ່າຍ
                        </MenuItem>
                    </Select>
                </FormControl>
                {errors.type && (
                    <Typography variant="caption" color="error">
                        {errors.type}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button
                    sx={{ borderRadius: 3 }}
                    onClick={() => handleCloseDialog()}
                    variant="outlined"
                    color="secondary"
                >
                    ຍົກເລີກ
                </Button>
                <Button
                    sx={{ borderRadius: 3 }}
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={isSumitted}
                >
                    ເພີ່ມ
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RegisterUpgradeDialog
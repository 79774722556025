import React, { useState, useEffect } from "react";
import { Navbar, NavDropdown, Nav, Form } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { setAccessmentYear } from "../redux/accessmentSlice";

import Consts, { VERSION } from "../consts";
import { useLazyQuery } from "@apollo/react-hooks";
import { USER } from "../apollo/user";
import { USER_KEY } from "../consts";

function CustomNavbar() {
  const dispatch = useDispatch();
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const { history } = useReactRouter();
  const [dataUser, setDataUser] = useState(null);
  const [callStdId, setCallStdId] = useState();
  useEffect(() => {
    let year = [];
    const NOW = new Date().getFullYear() + 6;
    const checkMonth = new Date().getMonth() + 1;
    if (checkMonth >= 6) {
      for (var i = NOW; i >= 2014; i--) {
        year.push(i + " - " + (parseInt(i) + 1));
      }
    } else {
      for (var i = NOW; i >= 2014; i--) {
        year.push(i - 1 + " - " + parseInt(i));
      }
    }
    setCallStdId(year);

    const accessmentYear = localStorage.getItem("assessmentYear");
    if (accessmentYear) {
      // setDefaultAssessmentYear(accessmentYear);
      dispatch(setAccessmentYear(accessmentYear));
    } else {
      dispatch(setAccessmentYear(year[1]));
    }
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      //  loadUser({variables: { where: { id: user?.data?._id } }})
      setDataUser(user);
    } else {
      history.push(`/`);
    }
  }, [history]);

  const _onLogout = () => {
    history.push(`/`);
    localStorage.clear();
    localStorage.removeItem(USER_KEY);
  };
  const _onOpenProfile = () => {
    history.push(`/profile-detail`, dataUser);
  };

  const onSelectAccessmentYear = (e) => {
    dispatch(setAccessmentYear(e.target.value));
    localStorage.setItem("assessmentYear", e.target.value);
  };

  return (
    <Navbar
      fixed="top"
      bg="white"
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: 50,
        justifyContent: "center",
        alignItems: "flex-end",
      }}
    >
      {dataUser && (
        <div className="d-flex align-items-center">
          <Form.Control
            as="select"
            style={{
              marginTop: "10px",
              width: "200px",
              border: "none",
              backgroundColor: "#f1f1f1f1",
              cursor: "pointer",
              marginRight: "10px", // Adjust margin as needed
            }}
            defaultValue={accessmentRedux}
            onChange={(e) => onSelectAccessmentYear(e)}
          >
            {callStdId.map((year, index) => (
              <option
                key={index}
                selected={year == accessmentRedux ? true : false}
                value={year}
              >
                {year}
              </option>
            ))}
          </Form.Control>
          <NavDropdown
            style={{
              marginTop: "10px",
            }}
            alignRight
            title={
              dataUser?.data?.firstname + " " + (dataUser?.data?.lastname || "")
            }
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item onClick={() => _onOpenProfile()}>
              <FontAwesomeIcon
                icon={faUser}
                style={{ color: Consts.PRIMARY_COLOR }}
              />
              {"\t"}ຂໍ້ມູນຜູ້ໃຊ້
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={() => _onLogout()}>
              <FontAwesomeIcon
                icon={faSignOutAlt}
                style={{ color: Consts.PRIMARY_COLOR }}
              />
              {"\t"}ອອກຈາກລະບົບ
            </NavDropdown.Item>
          </NavDropdown>
        </div>
      )}
    </Navbar>
  );
}
export default CustomNavbar;

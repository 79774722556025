import React, { useCallback, useEffect, useState } from "react";
import { IconButton, Typography, Checkbox, Button, Box, Modal, TableFooter, TablePagination } from "@mui/material";
import { useSelector } from "react-redux";
import { USER_KEY } from "../../consts";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import { fetchPost, fetchPut, fetchPutV2 } from "../../common/fetchAPI";
import AlertMessage from "../../common/AlertMessage";
// import { hasPermission } from "../../../helpers/CheckPermission";
import { messageWarning } from "../../common/super";
import AddClassFilter from "./filter/AddClassFilter";
import { Breadcrumb } from "react-bootstrap";
import { CustomContainer, Title } from "../../common";

const modalStyle = (theme) => ({
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    border: `1px solid ${theme.palette.divider}`,
});

const buttonContainerStyle = {
    marginTop: 3,
    display: "flex",
    justifyContent: "center",
    gap: 2,
};

const AddStudentToClassList = () => {
    const user = localStorage.getItem(USER_KEY);
    const userObject = JSON.parse(user);
    const [facId, setFacId] = useState(userObject.data.faculty.id);
    const [depId, setDepId] = useState("");
    const [disableDep, setDisableDep] = useState(false);
    const [disableFact, setDisableFact] = useState(false);

    const [filter, setFilter] = useState({});
    const [studentList, setStudentList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedStudentIds, setSelectedStudentIds] = useState([]);

    const accessmentRedux = useSelector((state) => state.accessment.value);
    const [showConfirm, setShowConfirm] = useState(false);
    const [classroomList, setClassroomList] = useState([]);
    const [selectedClassroom, setSelectedClassroom] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [studentCount, setStudentCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [actionStatus, setActionStatus] = useState({
        open: false,
        message: "",
        type: "error",
    });

    useEffect(() => {
        if (actionStatus.open) {
            setTimeout(() => {
                setActionStatus({ ...actionStatus, open: false });
            }, 3000);
        }
    }, [actionStatus]);

    useEffect(() => {
        setSelectedStudentIds([]);
    }, [filter]);

    useEffect(() => {
        const user = localStorage.getItem(USER_KEY);
        const userObject = JSON.parse(user);

        if (userObject) {
            if (userObject.data.role === "DEP_ADMIN") {
                setDisableDep(true);
                setDepId(userObject.data.department.id);
                setFacId(userObject.data.faculty.id);
                setDisableFact(true);
            }
            if (userObject.data.role === "FAC_ADMIN") {
                setDisableDep(false);
                setDepId("");
                setFacId(userObject.data.faculty.id);
                setDisableFact(true);
            }
            if (userObject.data.role === "ADMIN") {
                setDisableDep(false);
                setDisableFact(false);
                setDepId("");
                setFacId("");
            }
        }
    }, [user]);

    useEffect(() => {
        if (showConfirm === true) {
            fetchClassRoom();
        }
    }, [showConfirm]);

    const fetchClassRoom = async () => {
        const postData = {
            major: filter?.major,
            year: 1,
        };
        const fetchGetClass = await fetchPost("classroom", postData)
        if (fetchGetClass) {
            setClassroomList(fetchGetClass?.data);
        } else {
            setActionStatus({
                open: true,
                message: "ບໍ່ມີຂໍ້ມູນຫ້ອງຮຽນ",
                type: "error",
            });
            setClassroomList([]);
        }

    }

    const fetchStudent = async () => {
        const body = {
            assessmentYear: accessmentRedux,
            faculty: filter?.faculty || facId,
            department: filter?.department,
            major: filter?.major,
            limit: rowsPerPage,
            page: page + 1,
        };

        try {
            const response = await fetchPutV2("/student/current", body);
            if (response.success) {
                setStudentList(response.data);
                setStudentCount(response.count);
            } else {
                setStudentList([]);
            }
        } catch (err) {
            setStudentList([]);
        }
    };

    useEffect(() => {
        fetchStudent();
    }, [filter, accessmentRedux, facId, page, rowsPerPage]);

    useEffect(() => {
        if (!isSubmitting) return; // Prevents unnecessary effects when not submitting

        let tempErrors = { ...errors };

        if (filter.faculty) delete tempErrors.faculty;
        else tempErrors.faculty = "ກະລຸນາເລືອກຄະນະ";

        if (filter.department) delete tempErrors.department;
        else tempErrors.department = "ກະລຸນາເລືອກພາກ";

        if (filter.major) delete tempErrors.major;
        else tempErrors.major = "ກະລຸນາເລືອກສາຂາ";

        setErrors(tempErrors);
        // setIsSubmitting(false); // Reset flag after effect runs
    }, [filter, isSubmitting]); // Watch both filter and isSubmitting

    const validateForm = useCallback(() => {
        let newErrors = {};

        if (!filter.faculty) newErrors.faculty = "ກະລຸນາເລືອກຄະນະວິຊາ";
        if (!filter.department) newErrors.department = "ກະລຸນາເລືອກພາກວິຊາ";
        if (!filter.major) newErrors.major = "ກະລຸນາເລືອກສາຂາວິຊາ";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Returns true if no errors
    }, [filter]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRowSelection = (studentId) => {
        setSelectedStudentIds((prevSelectedIds) =>
            prevSelectedIds.includes(studentId)
                ? prevSelectedIds.filter((id) => id !== studentId)
                : [...prevSelectedIds, studentId]
        );
    };

    const handleSelectAll = () => {
        const allIds = studentList
            .filter((student) => student.graduatedStatus?.status === "STUDYING") // Only include students with "STUDYING" status
            .map((student) => student._id); // Map to their IDs
        setSelectedStudentIds(allIds);
    };

    const handleClearAll = () => {
        setSelectedStudentIds([]);
    };

    const _registration = async () => {

        setIsLoading(true);

        if (selectedStudentIds.length === 0) {
            messageWarning("ຍັງບໍ່ໄດ້ເລືອກນັກຮຽນ!");
            setIsLoading(false);
            return; // Exit early if no students are selected
        }

        if (selectedClassroom === "") {
            messageWarning("ຍັງບໍ່ໄດ້ເລືອກຫ້ອງ");
            setIsLoading(false);
            return; // Exit early if no classroom is selected
        }

        try {
            const requestBody = {
                studentIds: selectedStudentIds,
                classRoom: selectedClassroom,
            };
            const response = await fetchPut("student/class", requestBody);
            if (response) {
                setActionStatus({
                    open: true,
                    message: "ເພິ່ມຫ້ອງສຳເລັດ",
                    type: "success",
                });
                console.log("sussss")
                setSelectedClassroom("");
                setIsSubmitting(false);
                setShowConfirm(false);
                // setIsLoading(false);
                await fetchStudent();
            }

        } catch (error) {
            if (error?.response?.data === "Please provide a valid list of studentIds and a new classRoom") {
                setActionStatus({
                    open: true,
                    message: "ຕ້ອງມີຂໍ້ມູນຫ້ອງ ແລະ ຂໍ້ມູນນັກສຶກສາ",
                    type: "error",
                });
            } else {
                setActionStatus({
                    open: true,
                    message: "ລະບົບມີບັນຫາ",
                    type: "error",
                });
            }
            console.log("Registration failed:", error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    const onShowComfirm = () => {
        setIsSubmitting(true); // Set the flag before validating
        if (!validateForm()) {
            return
        } else {
            setShowConfirm(true);
        }
    };

    const RowData = ({
        id,
        queue,
        code,
        name,
        lastname,
        faculty,
        department,
        major,
        classroom,
        year,
        gender,
        status,
    }) => {
        return (
            <TableRow>
                <TableCell align="center">
                    <Checkbox
                        disabled={status?.status === "GRADUATED"}
                        checked={selectedStudentIds.includes(id)}
                        onChange={() => handleRowSelection(id)}
                    />
                </TableCell>
                <TableCell align="center">{queue + 20 * (page + 1 - 1)}</TableCell>
                <TableCell align="center">{code}</TableCell>
                <TableCell align="center">
                    {gender === "FEMALE" ? "ຍິງ" : "ຊາຍ"}
                </TableCell>
                <TableCell>
                    {(gender === "MALE" ? "ທ້າວ " : "ນາງ ") + name + " " + lastname}
                </TableCell>
                <TableCell align="center">{faculty}</TableCell>
                <TableCell align="center">{department}</TableCell>
                <TableCell align="center">{major}</TableCell>
                <TableCell align="center">{classroom}</TableCell>
                <TableCell align="center">{year}</TableCell>
                <TableCell align="center">
                    {status?.status === "STUDYING" ? (
                        <Typography
                            sx={{
                                color: "green",
                                fontWeight: "bold",
                            }}
                        >
                            ກຳລັງສຶກສາ
                        </Typography>
                    ) : (
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                color: "orange",
                            }}
                        >
                            ສຳເລັດການສຶກສາແລ້ວ
                        </Typography>
                    )}
                </TableCell>
            </TableRow>
        );
    };

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item active>ຈັດຫ້ອງໃຫ້ນັກສຶກສາໃໝ່</Breadcrumb.Item>
            </Breadcrumb>
            <CustomContainer>
                <Title text={"ລາຍການນັກສຶກສາປີ 1"} />
                <div className="col-12">
                    <AlertMessage
                        message={actionStatus.message}
                        open={actionStatus.open}
                        type={actionStatus.type}
                    />
                    <AddClassFilter
                        depId={depId}
                        disableDep={disableDep}
                        factId={facId}
                        disableFact={disableFact}
                        onSelected={setFilter}
                        errors={errors}
                    />
                </div>
            </CustomContainer>
            <CustomContainer>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between", // Separate items to the left and right
                        alignItems: "center",
                        marginBottom: "20px",
                    }}
                >
                    {/* Buttons on the left */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px", // Adds spacing between the buttons
                        }}
                    >
                        <Button
                            onClick={handleSelectAll}
                            startIcon={<DoneOutlineIcon />}
                            variant="contained"
                            color="primary"
                        >
                            ເລືອກທັງຫມົດ
                        </Button>
                        <Button
                            startIcon={<ClearIcon />}
                            onClick={handleClearAll}
                            variant="contained"
                            color="error"
                        >
                            ຍົກເລີກ
                        </Button>
                        <Typography mt={1}>
                            ຈຳນວນນັກສຶກສາທີເລືອກແລ້ວ{" "}
                            <Typography
                                component="span"
                                sx={{
                                    color: "#1976d2",
                                    fontWeight: "bold",
                                    fontSize: 20,
                                }}
                            >
                                {selectedStudentIds.length}
                            </Typography>
                        </Typography>
                    </Box>

                    {/* Save button on the right */}
                    {/* {hasPermission('GRADUATED', 'approve') && */}
                    <Button
                        onClick={onShowComfirm}
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        disabled={selectedStudentIds.length === 0 || Object.keys(errors).length > 0}
                    >
                        ເພີ່ມຫ້ອງໃຫ້ນັກສຶກສາ
                    </Button>
                    {/* } */}
                </Box>

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    align="center"
                                    sx={{
                                        color: "white",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Select
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        color: "white",
                                        fontWeight: "bold",
                                    }}
                                >
                                    ລຳດັບ
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        color: "white",
                                        fontWeight: "bold",
                                    }}
                                >
                                    ລະຫັດ
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        color: "white",
                                        fontWeight: "bold",
                                    }}
                                >
                                    ເພດ
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        color: "white",
                                        fontWeight: "bold",
                                    }}
                                >
                                    ຊື່ ແລະ ນາມສະກຸນ
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        color: "white",
                                        fontWeight: "bold",
                                    }}
                                >
                                    ຄະນະ
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        color: "white",
                                        fontWeight: "bold",
                                    }}
                                >
                                    ພາກວິຊາ
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        color: "white",
                                        fontWeight: "bold",
                                    }}
                                >
                                    ສາຂາ
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        color: "white",
                                        fontWeight: "bold",
                                    }}
                                >
                                    ຫ້ອງຮຽນ
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        color: "white",
                                        fontWeight: "bold",
                                    }}
                                >
                                    ປີຮຽນ
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        color: "white",
                                        fontWeight: "bold",
                                    }}
                                >
                                    ສະຖານະ
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {studentList.map((student, index) => (
                                <RowData
                                    key={student._id}
                                    id={student._id}
                                    queue={index + 1}
                                    code={student?.userId}
                                    name={student?.firstNameL || student?.firstNameE}
                                    lastname={student?.lastNameL || student?.lastNameE}
                                    faculty={student?.faculty?.name}
                                    department={student?.department?.name}
                                    major={student?.major?.name}
                                    classroom={student?.classRoom?.name}
                                    year={student.yearLevel}
                                    gender={student?.gender}
                                    status={student?.graduatedStatus}
                                />
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={11} align="right" style={{ width: "100%" }}>
                                    <Box display="flex" justifyContent="flex-end" width="100%">
                                        <TablePagination
                                            rowsPerPageOptions={[10, 20, 50, 100, 500, 1000, 5000]}
                                            count={studentCount}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            SelectProps={{
                                                inputProps: { "aria-label": "rows per page" },
                                                native: true,
                                            }}
                                        />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </CustomContainer>

            <Modal
                open={showConfirm}
                onClose={() => {
                    setShowConfirm(false);
                }}
            >
                <Box sx={modalStyle}>
                    <Typography variant="h6" id="modal-title" gutterBottom>
                        {"ເພີ່ມຫ້ອງໃຫ້ນັກສຶກສາ"}
                    </Typography>
                    <div className="row w-100">
                        <div className="col-12">
                            <select
                                className="form-select"
                                onChange={(e) => {
                                    setSelectedClassroom(e.target.value);
                                }}
                            >
                                <option value="">ກະລຸນາເລືອກຫ້ອງຮຽນ</option>
                                {classroomList.map((item) => (
                                    <option key={item._id} value={item._id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <Box sx={buttonContainerStyle}>
                        <Button
                            sx={{ px: 4, py: 1.5 }}
                            onClick={() => _registration()}
                            variant="contained"
                            color="error"
                            disabled={isLoading}
                        >
                            ຢືນຢັນ
                        </Button>
                        <Button
                            sx={{ px: 4, py: 1.5 }}
                            onClick={() => setShowConfirm(false)}
                            variant="outlined"
                            color="primary"
                        >
                            ຍົກເລີກ
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* {showConfirm && (
                <div className="my-modal">
                    <div className="modal-content w-10">
                        <h3>ເພີ່ມຫ້ອງໃຫ້ນັກສຶກສາ</h3>
                        <div className="row w-100">
                            <div className="col-12 text-center">
                                <div className="mb-2">
                                    <select
                                        className="p-1 px-3"
                                        value={selectedClassroom}
                                        onChange={handleClassChange}
                                    >
                                        <option value={""}>ກະລຸນາເລືອກຫ້ອງຮຽນ</option>
                                        {classroomList &&
                                            classroomList.map((classroom, index) => (
                                                <option
                                                    key={classroom?._id}
                                                    value={classroom?._id}
                                                    selected={index === 0}
                                                >
                                                    {classroom?.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <button
                                    onClick={() => closeConfirm()}
                                    type="button"
                                    className="btn btn-danger rounded py-2 px-5"
                                >
                                    ຍົກເລີກ
                                </button>
                            </div>
                            <div className="col-6 w-50">
                                <button
                                    disabled={isLoading}
                                    onClick={() => _registration()}
                                    type="button"
                                    className="btn btn-primary rounded py-2 px-5"
                                >
                                    ຢືນຢັນ
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )} */}
        </div>
    );
};

export default AddStudentToClassList;
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchGet } from "../../../common/fetchAPI";
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  CircularProgress,
  Alert,
  Chip,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";

const COLORS = [
  "#6366f1",
  "#d946ef",
  "#0ea5e9",
  "#10b981",
  "#f59e0b",
  "#ef4444",
  "#94a3b8",
];

const STATUS_CHIPS = [
  { label: "ນັກສຶກສາສະມັກເສັງ", value: "PENDING" },
  { label: "ນັກສຶກສາຖືກຢືນຢັນ", value: "APPROVED" },
  { label: "ນັກສຶກສາເສັງຜ່ານ", value: "PASSED" },
];

const StudentEntranceReportByProvince = () => {
  const accessmentRedux = useSelector((state) => state.accessment.value);

  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState("PENDING");

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetchGet(
          `entrance/report/province/${accessmentRedux}?status=${status}`
        );
        if (response) {
          setReport(response);
        } else {
          setError("ລະບົບມີບັນຫາໃນການສະເເດງ");
        }
      } catch (err) {
        setError(err.message || "Failed to fetch data");
      }
      setLoading(false);
    };

    getData();
  }, [accessmentRedux, status]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "60vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ my: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Box sx={{ width: "100%", minHeight: "100vh", bgcolor: "#f5f5f5", p: 4 }}>
      <Box sx={{ display: "flex", gap: 1, mb: 3 }}>
        {STATUS_CHIPS.map((chip) => (
          <Chip
            key={chip.value}
            label={chip.label}
            color={status === chip.value ? "primary" : "default"}
            onClick={() => setStatus(chip.value)}
          />
        ))}
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              ລາຍງານຕາມແຂວງ
            </Typography>
            <Box
              sx={{
                width: "100%",
                height: 400,
                overflowX: "auto",
                overflowY: "hidden",
              }}
            >
              <ResponsiveContainer
                width={Math.max(report.provinces.length * 100, 500)}
                height={350}
              >
                <BarChart
                  data={report.provinces}
                  margin={{ top: 20, right: 30, left: 20, bottom: 80 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="province"
                    angle={report.provinces.length > 5 ? -45 : 0}
                    textAnchor="end"
                    height={60}
                  />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="count">
                    {report.provinces.map((_, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StudentEntranceReportByProvince;

import React, { useState, useEffect, useRef } from "react";
import useReactRouter from "use-react-router";
import { useLazyQuery } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CircularProgress from "@mui/material/CircularProgress";

import Alert from "@mui/material/Alert";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import Snackbar from "@mui/material/Snackbar";

/**
 *
 * @Library
 *
 */
import * as _ from "lodash";
import queryString from "query-string";

/**
 *
 * @Component
 *
 */
import {
  CustomContainer,
  Title,
  CustomButton,
  TableHeader,
  TableCell,
} from "../../common";
import {
  Breadcrumb,
  OverlayTrigger,
  Tooltip,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import Loading from "../../common/Loading";
/**
 *
 * @Constant
 *
 */
import Consts, { USER_KEY } from "../../consts";
import Routers from "../../consts/router";

import { FACULTIES } from "../../apollo/faculty";
import { DEPARTMENTS } from "../../apollo/deparment";

import {
  currency,
  formatDateYYMMDD,
  onConvertStudentType,
} from "../../common/super";
import { convertGenderNam, convertGenderNamEN } from "../../helpers/user";
import PaginationHelper from "../../helpers/PaginationHelper";
import { MAJORS } from "../../apollo/major";
import * as XLSX from "xlsx";
import axios from "axios";
import { API_KEY, NEW_API_URL } from "../../common/contrant";
import StudentTable from "./ImportList";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AddIcon from "@mui/icons-material/Add";
import getStudentNumber from "../../helpers/getStudentNumber";
import { fetchGet, fetchGetV2 } from "../../common/fetchAPI";
import BlockComponent from "../../common/BlockComponent";
import { hasPermission } from "../../helpers/CheckPermission";

function CodePreregistrationLIst() {
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const { history, location } = useReactRouter();
  const parsed = queryString?.parse(location?.state);
  /**
   *
   * @State
   *
   */
  const [dataFaculties, setDataFaculties] = useState([]);
  const [facultyId, setFacultyId] = useState(
    !parsed?.faculty ? "" : parsed?.faculty
  );
  const [dataDepartments, setDataDepartments] = useState([]);
  const [departmentId, setDepartmentId] = useState(
    !parsed?.department ? "" : parsed?.department
  );
  const [dataMajors, setDataMajors] = useState([]);
  const [majorId, setMajorId] = useState(!parsed?.major ? "" : parsed?.major);

  const [educationLevel, setEducationLevel] = useState(
    !parsed?.educationLevel ? "" : parsed?.educationLevel
  );

  const [studentType, setStudentType] = useState(
    !parsed?.studentType ? "" : parsed?.studentType
  );
  const [code, setCode] = useState("");
  const [dataCodeTotal, setDataCodeTotal] = useState([]);
  /**
   *
   * @Apollo
   *
   */
  const { _limit, _skip, Pagination_helper } = PaginationHelper();
  // const [loadCodes, { loading: codeLoading, data: codeData }] = useLazyQuery(
  //   CODES,
  //   { fetchPolicy: "cache-and-network" }
  // );

  const [loadFaculties, { data: facultyData }] = useLazyQuery(FACULTIES);
  const [loadDepartments, { data: departmentData }] = useLazyQuery(DEPARTMENTS);
  const [loadMajors, { data: majorData }] = useLazyQuery(MAJORS);
  /**
   *
   * @UseEffect
   *
   */

  const [excelData, setExcelData] = useState([]);
  const [errorCode, setErrorCode] = useState();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [importLoading, setImportLoading] = useState(false);
  const [importError, setImportError] = useState(false);
  const [importSuccess, setImportSuccess] = useState(false);
  const [formatDateNotMatch, setFormatDateNotMatch] = useState(false);
  const [studentNumberNoMatch, setStudentNumberNoMatch] = useState(false);
  const [duplicateCode, setDuplicateCode] = useState(false);
  const [preStudentNumber, setPreStudentNumber] = useState("");

  useEffect(() => {
    loadFaculties({
      variables: {
        where: { isDeleted: false, assessmentYear: accessmentRedux },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessmentRedux]);

  useEffect(() => {
    if (!facultyData) return;
    setDataFaculties(facultyData?.faculties);
  }, [facultyData]);

  useEffect(() => {
    if (!departmentData) return;
    setDataDepartments(departmentData?.departments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentData]);

  useEffect(() => {
    if (!majorData) return;
    setDataMajors(majorData?.majors?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [majorData]);

  useEffect(() => {
    if (facultyId !== "") {
      loadDepartments({
        variables: {
          where: {
            isDeleted: false,
            faculty: facultyId,
            assessmentYear: accessmentRedux,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facultyId]);

  useEffect(() => {
    if (departmentId !== "") {
      loadMajors({
        variables: {
          where: {
            isDeleted: false,
            department: departmentId,
            assessmentYear: accessmentRedux,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  const getWhereClause = () => {
    let _where = { assessmentYear: accessmentRedux, isDeleted: false };
    if (facultyId !== "") _where = { ..._where, faculty: facultyId };
    if (departmentId !== "") _where = { ..._where, department: departmentId };
    if (majorId !== "") _where = { ..._where, major: majorId };
    if (educationLevel !== "")
      _where = { ..._where, educationLevel: educationLevel };
    if (studentType !== "") _where = { ..._where, studentType: studentType };
    if (code) _where = { ..._where, code: code };
    return _where;
  };

  useEffect(() => {
    setPreStudentNumber(
      getStudentNumber({
        faculty: facultyId,
        degree: educationLevel,
        studentType: studentType,
      })
    );
  }, [
    facultyId,
    departmentId,
    majorId,
    educationLevel,
    studentType,
    code,
    accessmentRedux,
  ]);

  const [codeData, setCodedata] = useState([]);
  const [permissiondenied, setPermissiondenied] = useState(false);

  const fetchCodes = async () => {
    let _where = getWhereClause();
    // Convert _where object to query string
    const queryString = new URLSearchParams(_where).toString();
    // Append query string to the API URL
    const response = await fetchGetV2(`studentcode?${queryString}`);
    if (response.status === "error") {
      setPermissiondenied(true);
      return;
    }
    setCodedata(response?.data);
    setDataCodeTotal(response?.total);
  };

  useEffect(() => {
    fetchCodes();
  }, [
    facultyId,
    departmentId,
    majorId,
    educationLevel,
    studentType,
    code,
    accessmentRedux,
  ]);

  useEffect(() => {
    if (importSuccess) {
      const timer = setTimeout(() => {
        setImportSuccess(false);
      }, 4000);

      return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
    }

    if (importError) {
      setTimeout(() => {
        setImportError(false);
      }, 15000);
    }

    if (formatDateNotMatch) {
      setTimeout(() => {
        setExcelData([]);
        setFormatDateNotMatch(false);
      }, 10000);
    }

    if (duplicateCode) {
      setTimeout(() => {
        setDuplicateCode(false);
      }, 10000);
    }

    if (studentNumberNoMatch) {
      setTimeout(() => {
        setStudentNumberNoMatch(false);
      }, 10000);
    }
  }, [
    importError,
    importSuccess,
    formatDateNotMatch,
    duplicateCode,
    studentNumberNoMatch,
  ]);

  useEffect(() => {
    if (formatDateNotMatch === false && excelData?.length > 0) {
      setOpen(true);
    }
  }, [excelData, formatDateNotMatch]);

  /**
   *
   * @Function
   *
   */

  const _codeDetail = (event) => {
    history.push(Routers.CODE_PRERE_DETAIL + "/id/" + event?._id, event);
  };

  const _codeEdit = (event) => {
    history.push(Routers.CODE_PRERE_EDIT + "/id/" + event?._id, event);
  };

  const _codeAdd = () => {
    history.push(Routers.CODE_PRERE_ADD);
  };

  const _selectFaculty = (e) => {
    setFacultyId(e.target.value);
    setDepartmentId("");
    setDataDepartments([]);
    setMajorId("");
    setDataMajors([]);
  };
  const _onSelectedDepartment = (e) => {
    setDepartmentId(e.target.value);
    setMajorId("");
    setDataMajors([]);
  };
  const _onSelectedMajor = (e) => {
    setMajorId(e.target.value);
  };

  const _onSelectedEducationLevel = (e) => {
    setEducationLevel(e.target.value);
  };

  const _onSelectedStudentType = (e) => {
    setStudentType(e.target.value);
  };
  const _keyCode = (e) => {
    if (e.keyCode == 32) e.preventDefault();
    if (e.key === "Enter") setCode(e.target.value);
  };

  function stringToDate(dateString) {
    // Split the string by the "." delimiter
    const parts = dateString.split(".");

    // Assuming the format is day.month.year
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Subtract 1 because months are 0-indexed
    const year = parseInt(parts[2], 10);

    // Create a new Date object
    return new Date(year, month, day);
  }

  const handleFileUpload = (e) => {
    const fileInput = e.target;
    const file = fileInput.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Check if the file format is as expected
      if (
        !workbook ||
        !workbook.SheetNames ||
        workbook.SheetNames.length === 0
      ) {
        // Handle incorrect format here, maybe show an error message
        console.log("Invalid file format. Please upload a valid Excel file.");
        return;
      }

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      let count = 0;
      const students = parsedData.map((item) => {
        if (count !== 0) {
          let gender = "";
          if (item[1] && item[1].trim) {
            if (item[1].trim() === "ຍິງ") {
              gender = "FEMALE";
            } else if (item[1].trim() === "ພຣະ") {
              gender = "MONK";
            } else if (
              item[1].trim() === "ສນ" ||
              item[1].trim() === "ສຳມະເນນ" ||
              item[1].trim() === "ສ.ນ"
            ) {
              gender = "NOVICE";
            } else if (item[1].trim() === "ຊາຍ") {
              gender = "MALE";
            }
          }
          let birthday = item[6];
          if (birthday !== undefined) {
            const dateRegex =
              /^(0?[1-9]|1[0-9]|2[0-9]|3[01])\.(0?[1-9]|1[0-2])\.\d{4}$/;
            if (typeof birthday === "string" && dateRegex.test(birthday)) {
              birthday = stringToDate(birthday);
            } else {
              setFormatDateNotMatch(true);
              setErrorCode(`${item[0]}`);
            }
          }
          const student = {
            code: item[0],
            firstNameL: item[2],
            firstNameE: item[3],
            lastNameL: item[4],
            lastNameE: item[5],
            assessmentYear: accessmentRedux,
            birthday: formatDateYYMMDD(birthday),
            gender: gender,
            faculty: facultyId,
            department: departmentId,
            major: majorId,
            educationLevel: educationLevel,
            studentType: studentType,
          };
          return student;
        }
        count++;
        return;
      });
      // Filter out null and code-less students
      const validStudents = students.filter(
        (student) =>
          student &&
          student.code !== null &&
          student.code !== undefined &&
          student.gender !== "" &&
          student.gender !== undefined
      );
      const allCodes = validStudents.map((student) => student.code);
      // check codes that do not start with the pre-student number
      const studentNumberNoMatchs = allCodes.filter(
        (c) => !c.startsWith(preStudentNumber)
      );

      if (studentNumberNoMatchs.length > 0) {
        setErrorCode(studentNumberNoMatchs);
        setStudentNumberNoMatch(true);
      } else {
        // Identify codes that appear more than once
        const duplicateCodes = allCodes.filter(
          (code, index, self) => self.indexOf(code) !== index
        );

        if (duplicateCodes.length > 0) {
          setErrorCode(duplicateCodes);
          setDuplicateCode(true);
        } else {
          setExcelData(validStudents);
        }
      }

      fileInput.value = "";
    };

    reader.readAsArrayBuffer(file);
  };

  // if (codeLoading) return <Loading />;
  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item
          href=""
          onClick={() =>
            history.push(Routers.CODE_PRERE_LIST + Routers.PAGE_GINATION)
          }
        >
          ລະຫັດລົງທະບຽນເຂົ້າຮຽນ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ລະຫັດລົງທະບຽນເຂົ້າຮຽນທັງໝົດ</Breadcrumb.Item>
      </Breadcrumb>

      {permissiondenied ? (
        <BlockComponent />
      ) : (
        <CustomContainer>
          <Title text={"ລະຫັດລົງທະບຽນເຂົ້າຮຽນ"} />
          <Row>
            <Col sm={3}>
              <p>ຄະນະ</p>
              <Form.Control
                as="select"
                style={{
                  border: "none",
                  backgroundColor: "#f1f1f1f1",
                  cursor: "pointer",
                }}
                value={facultyId}
                onChange={(e) => {
                  _selectFaculty(e);
                }}
              >
                <option value="">ຄະນະທັງໝົດ</option>
                {dataFaculties?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {" "}
                      {item.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
            <Col sm={3}>
              <p>ພາກວິຊາ</p>
              <Form.Control
                as="select"
                style={{
                  border: "none",
                  backgroundColor: "#f1f1f1f1",
                  cursor: "pointer",
                }}
                value={departmentId}
                onChange={(e) => _onSelectedDepartment(e)}
              >
                <option value="">ທັງໝົດ</option>
                {dataDepartments?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
            <Col sm={3}>
              <p>ສາຂາວິຊາ</p>
              <Form.Control
                as="select"
                style={{
                  border: "none",
                  backgroundColor: "#f1f1f1f1",
                  cursor: "pointer",
                }}
                value={majorId}
                onChange={(e) => _onSelectedMajor(e)}
              >
                <option value="">ທັງໝົດ</option>
                {dataMajors?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
            <Col sm={3}>
              <p>ລະດັບສຶກສາ</p>
              <Form.Control
                as="select"
                style={{
                  border: "none",
                  backgroundColor: "#f1f1f1f1",
                  cursor: "pointer",
                }}
                onChange={(e) => _onSelectedEducationLevel(e)}
                value={educationLevel}
              >
                <option value="">ທັງຫມົດ</option>
                <option value="DEPLOMA">ອະນຸປະລິນຍາ</option>
                <option value="BACHELOR_DEGREE">ປະລິນຍາຕີ</option>
                <option value="BACHELOR_CONTINUING">ຕໍ່ເນື່ອງປະລິນຍາຕີ</option>
              </Form.Control>
            </Col>
            <Col sm={3}>
              <p>ປະເພດນັກສຶກສາ</p>
              <Form.Control
                as="select"
                style={{
                  border: "none",
                  backgroundColor: "#f1f1f1f1",
                  cursor: "pointer",
                }}
                value={studentType}
                onChange={(e) => _onSelectedStudentType(e)}
              >
                <option value="">ທັງໝົດ</option>
                <option value="IN_PLAN">ນັກສຶກສາລາວ (ໃນແຜນ)</option>
                <option value="OUT_PLAN">ນັກສຶກສາລາວ (ນອກແຜນ)</option>
                <option value="IN_PLAN_EN">ນັກສຶກສາຕ່າງປະເທດ (ໃນແຜນ)</option>
                <option value="OUT_PLAN_EN">ນັກສຶກສາຕ່າງປະເທດ (ນອກແຜນ)</option>
              </Form.Control>
            </Col>
            <Col sm={3}>
              <p>
                ລະຫັດລົງທະບຽນເຂົ້າຮຽນ{" "}
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      ປ້ອນລະຫັດລົງທະບຽນເຂົ້າຮຽນ Enter ເພື່ອຄົ້ນຫາ
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color={Consts.PRIMARY_COLOR}
                  />
                </OverlayTrigger>
              </p>
              <Form.Control
                type="text"
                placeholder="ປ້ອນລະຫັດລົງທະບຽນເຂົ້າຮຽນ"
                style={{
                  border: "none",
                  backgroundColor: "#f1f1f1f1",
                  cursor: "pointer",
                }}
                name="code"
                defaultValue={code}
                onKeyDown={(e) => _keyCode(e)}
              />
            </Col>
          </Row>

          <div className="row">
            <div className="col-12 bg-da d-flex w-100 justify-content-start">
              <Button
                sx={{
                  width: "200px",
                  marginRight: "20px",
                }}
                color="primary"
                onClick={() => {
                  const link = document.createElement("a");
                  link.href =
                    "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/File+Import+%E0%BA%82%E0%BB%8D%E0%BB%89%E0%BA%A1%E0%BA%B8%E0%BA%99%E0%BA%99%E0%BA%B1%E0%BA%81%E0%BA%AA%E0%BA%B6%E0%BA%81%E0%BA%AA%E0%BA%B2%E0%BA%AA%E0%BA%B0%E0%BA%AB%E0%BA%A1%E0%BA%B1%E0%BA%81%E0%BB%80%E0%BA%82%E0%BA%BB%E0%BB%89%E0%BA%B2%E0%BA%AE%E0%BA%BD%E0%BA%99.xlsx";
                  link.download = "filename.xlsx"; // Specify filename here
                  link.click();
                }}
                variant="contained"
                startIcon={<DownloadIcon />}
              >
                ໂຫລດ Format
              </Button>
              {hasPermission("CODE_STUDENT", "create") && (
                <Button
                  disabled={
                    !facultyId ||
                    !departmentId ||
                    !majorId ||
                    studentType === "" ||
                    educationLevel === ""
                  }
                  sx={{
                    width: "200px",
                  }}
                  color="success"
                  variant="contained"
                  component="label"
                  startIcon={<AttachFileIcon />}
                >
                  ເລືອກໄຟຣ
                  <input
                    hidden
                    accept=".xlsx, .xls" // Specify the allowed file types
                    multiple
                    onChange={handleFileUpload}
                    type="file"
                  />
                </Button>
              )}
              {hasPermission("CODE_STUDENT", "create") && (
                <div className="col-2 d-flex justify-content-start">
                  <Button
                    sx={{
                      width: "200px",
                    }}
                    startIcon={<AddIcon />}
                    onClick={() => _codeAdd()}
                    variant="contained"
                  >
                    ເພິ່ມເປັນບຸກຄົນ
                  </Button>
                </div>
              )}
            </div>

            <div
              style={{
                marginTop: 10,
                marginBottom: 8,
                fontSize: 16,
                color: Consts.FONT_COLOR_SECONDARY,
              }}
            >
              ທັງໝົດ {currency(dataCodeTotal)} ຄົນ
            </div>

            {/* Table list */}
            <div>
              <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                <thead>
                  <TableHeader>
                    <th style={{ width: 70 }}>ລຳດັບ</th>
                    <th>ລະຫັດລົງທະບຽນເຂົ້າຮຽນ</th>
                    <th>ຊື່ ແລະ ນາມສະກຸນ</th>
                    <th>ຄະນະ</th>
                    <th>ພາກວິຊາ</th>
                    <th>ສາຂາວິຊາ</th>
                    <th>ປະເພດນັກຮຽນ</th>
                    {hasPermission("CODE_STUDENT", "edit") ||
                    hasPermission("CODE_STUDENT", "read") ? (
                      <th style={{ width: 150 }}>ຈັດການ</th>
                    ) : null}
                  </TableHeader>
                </thead>
                <tbody>
                  {codeData?.map((x, index) => {
                    return (
                      <tr
                        style={{
                          borderBottom: "2px solid #ffff",
                          textAlign: "center",
                        }}
                        key={index}
                      >
                        <TableCell>
                          {index + 1 + _limit * (_skip - 1)}
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          {x.code}
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          {!x?.firstNameL || x?.firstNameL === ""
                            ? convertGenderNamEN(x?.gender) +
                              " " +
                              x?.firstNameE +
                              " " +
                              x?.lastNameE
                            : convertGenderNam(x?.gender) +
                              " " +
                              x?.firstNameL +
                              " " +
                              x?.lastNameL}
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          {x.faculty && x.faculty.name}
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          {x.department && x.department.name}
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          {x.major && x.major.name}
                        </TableCell>
                        <TableCell>
                          {onConvertStudentType(x.studentType)}
                        </TableCell>
                        {hasPermission("CODE_STUDENT", "edit") ||
                        hasPermission("CODE_STUDENT", "read") ? (
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              {hasPermission("CODE_STUDENT", "edit") && (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      ແກ້ໄຂຂໍ້ມູນ
                                    </Tooltip>
                                  }
                                >
                                  <div
                                    onClick={() => _codeEdit(x)}
                                    style={{
                                      cursor: "pointer",
                                      backgroundColor: "#FFFFFF",
                                      padding: 3,
                                      width: 64,
                                      borderRadius: 4,
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={["fas", "edit"]}
                                      color={Consts.BORDER_COLOR}
                                    />{" "}
                                  </div>
                                </OverlayTrigger>
                              )}
                              {hasPermission("CODE_STUDENT", "read") && (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      ເບິ່ງລາຍລະອຽດ
                                    </Tooltip>
                                  }
                                >
                                  <div
                                    onClick={() => _codeDetail(x)}
                                    style={{
                                      cursor: "pointer",
                                      backgroundColor: "#FFFFFF",
                                      padding: 3,
                                      width: 64,
                                      borderRadius: 4,
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={["fas", "eye"]}
                                      color={Consts.BORDER_COLOR}
                                    />{" "}
                                  </div>
                                </OverlayTrigger>
                              )}
                            </div>
                          </TableCell>
                        ) : null}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Dialog open={open} maxWidth="lg">
                <DialogTitle>Import ຂໍ້ມູນນັກສຶກສາ</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <StudentTable data={excelData} />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    className="bg-primary text-white"
                    onClick={async () => {
                      setImportLoading(true);
                      setOpen(false);
                      const user = localStorage.getItem(USER_KEY);
                      const userObject = JSON.parse(user);
                      const token = userObject?.accessToken;
                      await axios
                        .post(NEW_API_URL + "/importprere", excelData, {
                          headers: {
                            api_key: API_KEY,
                            Authorization: `Bearer ${token}`,
                          },
                        })
                        .then((res) => {
                          setExcelData([]);
                          setImportLoading(false);
                          setImportSuccess(true);
                        })
                        .catch((error) => {
                          // set already exist code
                          if (error.response && error.response.status === 400) {
                            setErrorCode(error.response.data.error);
                          } else {
                            console.log("Error:", error.message);
                          }
                          setExcelData([]);
                          setImportLoading(false);
                          setImportError(true);
                        });
                    }}
                  >
                    ຢືນຢັນ
                  </Button>
                  <Button onClick={handleClose}>ຍົກເລີກ</Button>
                </DialogActions>
              </Dialog>

              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                open={importError}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert severity="error" sx={{ width: "100%" }}>
                  ລະຫັດນັກສຶກສາ {errorCode} ມີຢູ່ໃນລະບົບແລ້ວ !
                </Alert>
              </Snackbar>

              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                open={formatDateNotMatch}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert severity="error" sx={{ width: "100%" }}>
                  ທ່ານປ້ອນວັນເດືອນປີເກີດບໍ່ຖືກຕ້ອງຢູ່ລະຫັດທີ່ {errorCode} <br />{" "}
                  ຮູບແບບທີ່ຖືກຕ້ອງແມ່ນ ວັນ.ເດືອນ.ປີ
                </Alert>
              </Snackbar>
              {formatDateNotMatch === false ? (
                <>
                  <Snackbar
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    open={studentNumberNoMatch}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert severity="error" sx={{ width: "100%" }}>
                      ລະຫັດນັກສຶກສາ {errorCode ? errorCode + ", " : ""}{" "}
                      ບໍ່ຕົງກັບລະຫັດຄະນະທີ່ເລືອກ
                    </Alert>
                  </Snackbar>

                  <Snackbar
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    open={duplicateCode}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert severity="error" sx={{ width: "100%" }}>
                      ໄຟຣນີ້ມີລະຫັດນັກສຶກສາ {errorCode} ຊ້ຳກັນ
                    </Alert>
                  </Snackbar>
                </>
              ) : null}

              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                open={importSuccess}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert severity="success" sx={{ width: "100%" }}>
                  ການ Import ສຳເລັດ!
                </Alert>
              </Snackbar>

              <Dialog
                open={importLoading}
                aria-labelledby="progress-dialog-title"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "16px",
                  }}
                >
                  <CircularProgress color="primary" />
                  <h2 id="progress-dialog-title" style={{ marginTop: "16px" }}>
                    Loading...
                  </h2>
                </div>
              </Dialog>
            </div>

            {Pagination_helper(
              dataCodeTotal,
              Routers.CODE_PRERE_LIST,
              `faculty=${facultyId}&&department=${departmentId}&&major=${majorId}&&studentType=${studentType}`
            )}
          </div>
        </CustomContainer>
      )}
    </div>
  );
}

export default CodePreregistrationLIst;

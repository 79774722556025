import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPostV2,
  fetchPut,
  fetchPutV2,
} from "../../common/fetchAPI";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "../../common/ConfirmModal";
import { toast } from "react-toastify";
import AssignEvaluateFormToTeacher from "./addEvaluationToTeacherModal";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignDateToEvaluateForm from "./assignDateToForm";
import { USER_KEY } from "../../consts";
import { useSelector } from "react-redux";
import { hasPermission } from "../../helpers/CheckPermission";

const EvaluationTeacherlist = () => {
  const [evaluationList, setEvaluationList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showAssignDate, setShowAssignDate] = useState(false);
  const accessmentRedux = useSelector((state) => state.accessment.value);

  const [year, setYear] = useState("ທັງໝົດ");
  const [semester, setSemester] = useState("ທັງໝົດ");

  const getEvaluationTeacherList = async () => {
    const user = localStorage.getItem(USER_KEY);
    const userObject = user ? JSON.parse(user) : null;

    let _semester = semester;
    let _year = year;

    if (semester === "ທັງໝົດ") {
      _semester = "";
    }
    if (year === "ທັງໝົດ") {
      _year = "";
    }

    if (userObject?.data?.faculty?._id) {
      const response = await fetchGet(
        "/evaluation/teacher?faculty=" +
        userObject.data.faculty._id +
        `&semester=${_semester}&year=${_year}&assessmentYear=${accessmentRedux}`
      );
      setEvaluationList(response || []);
    }
  };

  useEffect(() => {
    getEvaluationTeacherList();
  }, [year, semester]);

  const deleteTemplate = async () => {
    if (selectedId) {
      const _delete = await fetchDelete("/evaluation/teacher/" + selectedId);
      if (_delete) {
        toast.info("ລົບສຳເລັດ", { autoClose: 1500 });
        setShowDeleteModal(false);
        await getEvaluationTeacherList();
      } else {
        setShowDeleteModal(false);
        toast.error("ລະບົບມີບັນຫາໃນການລົບ", { autoClose: 1500 });
      }
    }
  };

  const assignFormToTeacher = async (data) => {
    const assign = await fetchPostV2("/evaluation/assign", data);
    if (assign.response) {
      toast.error(assign?.response?.data?.message || "ລະບົບມີບັນຫາ", {
        autoClose: 1500,
      });
    } else {
      toast.info("ສຳເລັດ", { autoClose: 1500 });
      setShowAssignModal(false);
      getEvaluationTeacherList();
    }

    // if (assign) {
    //   toast.info("ສຳເລັດ", { autoClose: 1500 });
    //   setShowAssignModal(false);
    //   getEvaluationTeacherList();
    // } else {
    //   toast.error("ລະບົບມີບັນຫາ", { autoClose: 1500 });
    // }
  };

  return (
    <Grid sx={{ backgroundColor: "white" }} container>
      <AssignDateToEvaluateForm
        open={showAssignDate}
        onClose={() => setShowAssignDate(false)}
        onSubmit={async (data) => {
          const assignDate = await fetchPutV2(
            "/evaluation/date/" + selectedId,
            data
          );
          if (assignDate?.response) {
            console.log("assignDate => ", assignDate.response);
            toast.error(assignDate?.response?.data?.message || "ລະບົບມີບັນຫາ", {
              autoClose: 5000,
            });
          } else {
            setShowAssignDate(false);
            getEvaluationTeacherList();
            toast.info("ສຳເລັດ", { autoClose: 5000 });
          }
        }}
      />
      <AssignEvaluateFormToTeacher
        onCancel={() => setShowAssignModal(false)}
        onSubmit={({
          selectedTeacher,
          selectedCourse,
          selectedForm,
          semester,
          year,
          assessmentYear,
        }) => {
          const user = localStorage.getItem(USER_KEY);
          const userObject = user ? JSON.parse(user) : null;
          assignFormToTeacher({
            teacher: selectedTeacher,
            course: selectedCourse,
            sections: selectedForm?.sections || [],
            semester,
            year,
            assessmentYear,
            description: selectedForm?.description || "",
            faculty: userObject.data.faculty._id,
          });
        }}
        open={showAssignModal}
      />
      <ConfirmModal
        title={"ທ່ານຕ້ອງການລົບແທ້ບໍ່?"}
        message={"ຖ້າທ່ານກົດຢືນຢັນທຸກຂໍ້ມຸນທີ່ຢູ່ໃນຟອມນີ້ຈະຖືລົບອອກຫມົດ?"}
        onCancel={() => setShowDeleteModal(false)}
        onSubmit={deleteTemplate}
        open={showDeleteModal}
      />
      <Box
        sx={{
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography variant="h5">ລາຍການເມີນອາຈານ</Typography>
        <Box>
          {hasPermission('EVALUATION', 'create') &&
            <Button
              onClick={() => setShowAssignModal(true)}
              startIcon={<AssignmentIcon sx={{ color: "white" }} />}
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                paddingTop: 1,
                marginRight: 1,
                paddingBottom: 1,
                backgroundColor: "#04658f",
                color: "white",
                "&:hover": { backgroundColor: "#03506b" },
              }}
            >
              ສ້າງເເບບຟອມໃຫ້ອາຈານ
            </Button>
          }
          <IconButton color="primary" onClick={getEvaluationTeacherList}>
            <ReplayIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          p: 2,
          width: "100%",
          gap: 3,
        }}
      >
        <FormControl sx={{ marginTop: "20px" }} fullWidth>
          <InputLabel size="small" id="department-select-label">
            ເລືອກປີຮຽນ
          </InputLabel>
          <Select
            label="ເລືອກປີຮຽນ"
            size="small"
            value={year}
            onChange={(e) => {
              setYear(e.target.value);
            }}
          >
            {[
              { label: "ທັງໝົດ", value: "ທັງໝົດ" },
              { label: "1", value: 1 },
              { label: "2", value: 2 },
              { label: "3", value: 3 },
              { label: "4", value: 4 },
              { label: "5", value: 5 },
              { label: "6", value: 6 },
            ].map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ marginTop: "20px" }} fullWidth>
          <InputLabel size="small">ເລືອກພາກຮຽນ</InputLabel>
          <Select
            label="ເລືອກພາກຮຽນ"
            size="small"
            value={semester}
            onChange={(e) => {
              setSemester(e.target.value);
            }}
          >
            {[
              { label: "ທັງໝົດ", value: "ທັງໝົດ" },
              { label: "1", value: 1 },
              { label: "2", value: 2 },
            ].map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  width: "100px",
                  backgroundColor: "#04658f",
                  color: "white",
                  textAlign: "center",
                }}
              >
                ລຳດັບ
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#04658f",
                  color: "white",
                  textAlign: "center",
                }}
              >
                ຫົວຂໍ້
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#04658f",
                  color: "white",
                  textAlign: "center",
                }}
              >
                ອາຈານ
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#04658f",
                  color: "white",
                  textAlign: "center",
                }}
              >
                ວິຊາ
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#04658f",
                  color: "white",
                  textAlign: "center",
                }}
              >
                ຈຳນວນທີ່ປະເມີນແລ້ວ
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#04658f",
                  color: "white",
                  textAlign: "center",
                }}
              >
                ຊ່ວງເວລາປະເມີນ
              </TableCell>
              {hasPermission('EVALUATION', 'create') || hasPermission('EVALUATION', 'create') ?
                <TableCell
                  sx={{
                    width: "200px",
                    backgroundColor: "#04658f",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  ຈັດການ
                </TableCell>
                : null
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {evaluationList?.length > 0 ? (
              evaluationList.map((row, index) => (
                <TableRow key={row?._id || index}>
                  <TableCell
                    sx={{
                      width: "100px",
                      textAlign: "center",
                      color: "black",
                      backgroundColor: "white",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "start",
                      color: "black",
                      backgroundColor: "white",
                    }}
                  >
                    {row?.description || "N/A"}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "start",
                      color: "black",
                      backgroundColor: "white",
                    }}
                  >
                    {row?.teacher?.firstname && row?.teacher?.lastname
                      ? `${row.teacher.firstname} ${row.teacher.lastname}`
                      : "N/A"}
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "start",
                      color: "black",
                      backgroundColor: "white",
                    }}
                  >
                    {row?.course?.course?.subject?.title || ""}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      color: "#00509d",
                      backgroundColor: "white",
                    }}
                  >
                    {row?.studentCount || "0"}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      color: "black",
                      backgroundColor: "white",
                    }}
                  >
                    {row?.startDate && row?.endDate
                      ? `${new Date(
                        row.startDate
                      ).toLocaleDateString()} - ${new Date(
                        row.endDate
                      ).toLocaleDateString()}`
                      : "ຍັງບໍ່ທັນກຳນົດມື້ປະເມີນ"}
                  </TableCell>
                  {hasPermission('EVALUATION', 'create') || hasPermission('EVALUATION', 'create') ?
                    <TableCell
                      sx={{
                        width: "200px",
                        textAlign: "center",
                        color: "black",
                        backgroundColor: "white",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          setShowAssignDate(true);
                          setSelectedId(row?._id);
                        }}
                        color="warning"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setSelectedId(row?._id);
                          setShowDeleteModal(true);
                        }}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    : null
                  }
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                  ບໍ່ພົບຂໍ້ມູນ
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default EvaluationTeacherlist;

import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  List,
  ListItem,
  Button,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  Collapse,
  DialogContent,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CommentIcon from "@mui/icons-material/Comment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box } from "@mui/system";
import AddChapterModal from "./AddChapterModal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemIcon from "@mui/material/ListItemIcon";
import { fetchDelete } from "../../../common/fetchAPI";
import CommentModal from "./CommentModal";
import YouTube from "react-youtube";
import ArticleIcon from "@mui/icons-material/Article";
import { hasPermission } from "../../../helpers/CheckPermission";

const ChapterList = ({ chapters, isLoading, lesson, onRefresh }) => {
  const [showAddChapter, setShowAddChapter] = useState(false);
  const [expandedChapter, setExpandedChapter] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);

  const [showCommentModal, setShowCommentModal] = useState(false);

  const handleExpandClick = (chapterId) => {
    setExpandedChapter(expandedChapter === chapterId ? null : chapterId);
  };

  const handleMenuClick = (event, chapter) => {
    setAnchorEl(event.currentTarget);
    setSelectedChapter(chapter);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedChapter(null);
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [chapterId, setChapterId] = useState();

  const confirmDelete = async () => {
    setLoading(true);
    setError("");
    try {
      await fetchDelete("tchapter/" + chapterId);
      onRefresh();
      setOpenDeleteDialog(false);
    } catch (err) {
      setError("ມີບາງຢ່າງຜິດພາດ ການລົບບໍ່ສຳເລັດ.");
    } finally {
      setLoading(false);
    }
  };

  const extractYouTubeID = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <AddChapterModal
        onClose={() => {
          setShowUpdateDialog(false);
          setSelectedChapter("");
        }}
        chapterData={selectedChapter}
        onRefresh={() => {
          setShowUpdateDialog(false);
          onRefresh();
          setSelectedChapter("");
        }}
        open={showUpdateDialog}
      />
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>ທ່ານຕ້ອງການລົບແທ້ບໍ່​?</DialogTitle>
        <DialogContent>
          {error && (
            <DialogContentText color="error">{error}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} disabled={loading}>
            ຍົກເລີກ
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={confirmDelete}
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : "ລົບ"}
          </Button>
        </DialogActions>
      </Dialog>

      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Typography variant="h5">
          {"ບົດທີ " + (lesson?.lesson || "") + "   " + (lesson?.title || "")}
        </Typography>
      </Box>

      <Box
        sx={{
          padding: 2,
          marginTop: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}
      >
        <Typography>{lesson?.description}</Typography>
      </Box>
      {hasPermission('E_LEARNING', 'create') &&
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between", // Distribute space between the left and right groups
            alignItems: "center", // Ensure all buttons are vertically aligned
            borderBottom: "1px solid #e0e0e0",
            paddingBottom: 2,
            marginTop: 2,
            paddingRight: 2,
          }}
        >

          <Box
            sx={{
              marginLeft: 2,
            }}
          >
            <Button
              target="_blank"
              sx={{
                borderRadius: 3,
              }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setShowAddChapter(true);
              }}
            >
              ເພິ້ມຫົວຂໍ້ໃຫມ່
            </Button>
          </Box>

          {/* The two buttons aligned to the right */}
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              startIcon={<ArticleIcon />}
              href={lesson?.file}
              target="_blank"
              variant="outlined"
            >
              ໂຫລດເອກະສານ
            </Button>
            {lesson?.fileLink && (
              <Button
                startIcon={<FilePresentIcon />}
                href={lesson?.file}
                target="_blank"
                variant="outlined"
              >
                ລີງເອກະສານ
              </Button>
            )}
          </Box>
        </Box>
      }
      <CommentModal
        chapterId={chapterId}
        onClose={() => setShowCommentModal(false)}
        open={showCommentModal}
      />

      <AddChapterModal
        onRefresh={() => {
          setShowAddChapter(false);
          onRefresh();
          setSelectedChapter("");
        }}
        onClose={() => {
          setShowAddChapter(false);
          setSelectedChapter("");
        }}
        open={showAddChapter}
      />
      <List sx={{ padding: 0 }}>
        {chapters.map((chapter) => (
          <ListItem key={chapter._id} sx={{ padding: 0 }}>
            <Card
              sx={{
                width: "100%",
                marginBottom: 0, // Remove space between items
                boxShadow: "none", // Disable shadow
                border: "1px solid #e0e0e0", // Add border
                borderRadius: 0, // Remove border-radius for full width
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">
                    {"ຂໍ້ທີ " + chapter.chapter}: {chapter.title}
                  </Typography>
                  {hasPermission('E_LEARNING', 'edit') || hasPermission('E_LEARNING', 'delete') ?
                    <IconButton
                      onClick={(event) => handleMenuClick(event, chapter)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    : null
                  }
                  <Menu
                    anchorEl={anchorEl}
                    open={
                      Boolean(anchorEl) && selectedChapter?._id === chapter._id
                    }
                    onClose={handleMenuClose}
                  >
                    {hasPermission('E_LEARNING', 'edit') &&
                      <MenuItem
                        onClick={() => {
                          setSelectedChapter(chapter);
                          setShowUpdateDialog(true);
                        }}
                      >
                        <ListItemIcon>
                          <EditIcon fontSize="small" />
                        </ListItemIcon>
                        ແກ້ໄຂ
                      </MenuItem>
                    }
                    {hasPermission('E_LEARNING', 'delete') &&
                      <MenuItem
                        onClick={() => {
                          setOpenDeleteDialog(true);
                          setChapterId(chapter._id);
                        }}
                      >
                        <ListItemIcon>
                          <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        ລົບ
                      </MenuItem>
                    }

                  </Menu>
                </Box>

                {chapter.description && (
                  <Typography variant="body2" color="textSecondary" paragraph>
                    {chapter.description}
                  </Typography>
                )}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: 2,
                  }}
                >
                  <Button
                    startIcon={<PlayArrowIcon />}
                    onClick={() => handleExpandClick(chapter._id)}
                    variant="outlined"
                  >
                    {expandedChapter === chapter._id
                      ? "ຂະຫຍາຍຫນ້ວຍລົງ"
                      : "ເບີ່ງວິດີໂອ"}
                  </Button>
                  <Box sx={{ gap: 1, display: "flex", marginBottom: 1 }}>
                    {chapter.file && (
                      hasPermission('E_LEARNING', 'create') &&
                      <Button
                        startIcon={<FilePresentIcon />}
                        href={chapter.file}
                        target="_blank"
                        variant="outlined"
                      >
                        ໂຫລດເອກະສານ
                      </Button>

                    )}
                    <Button
                      onClick={() => {
                        setShowCommentModal(true);
                        setChapterId(chapter._id);
                      }}
                      startIcon={<CommentIcon />}
                      variant="outlined"
                    >
                      {chapter?.commentCount || "0"}
                    </Button>
                    <Button startIcon={<VisibilityIcon />} variant="outlined">
                      {chapter?.viewCount || "0"}
                    </Button>
                  </Box>
                </Box>

                <Collapse
                  in={expandedChapter === chapter._id}
                  timeout="auto"
                  unmountOnExit
                >
                  <CardMedia>
                    <YouTube
                      videoId={extractYouTubeID(chapter.video)}
                      opts={{ width: "100%" }}
                    />
                  </CardMedia>
                </Collapse>
              </CardContent>
            </Card>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ChapterList;

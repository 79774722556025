import React, { useState } from "react";
import { Chip, Paper, Box } from "@mui/material";
import LoginLogsList from "./LoginLogsList";
import ResetPasswordLogsList from "./ResetPasswordLogsList";
import MamageUserLogList from "./ManageUserLogsList";
import MamageStudentLogList from "./ManageStudentLogsList";
import ApprovePrereStudentLogList from "./ApprovePrereStudentLogList";
import SendingScoreLogList from "./SendingScoreLogList";
import ManageTimeLogsList from "./ManageTimeLogsList";
import ManageCourseLogList from "./ManageCourseLogslist";
import PromoteStudentLogList from "./PromoteStudentLogs";
import RegisterStudentLogsList from "./RegisterStudentLogsList";
import ElearningTeacher from "./ElearningTeacher";
import ElearningStudent from "./ElearningStudent";
import ManageEntrancePriceLogsList from "./ManageEntrancePriceLogsList";
import ManagePlaceStudyLogsList from "./ManagePlaceStudyList";

export default function LogList() {
  const [selectedLog, setSelectedLog] = useState(0);

  // Define log categories
  const logCategories = [
    { label: "ການເຂົ້າລະບົບ", component: <LoginLogsList /> },
    { label: "ປ່ຽນລະຫັດຜ່ານ", component: <ResetPasswordLogsList /> },
    { label: "ຈັດການຜູ້ໃຊ້", component: <MamageUserLogList /> },
    { label: "ຈັດການນັກສຶກສາ", component: <MamageStudentLogList /> },
    { label: "ຢືນຢັນນັກສຶກສາ", component: <ApprovePrereStudentLogList /> },
    { label: "ສົ່ງຄະເເນນ", component: <SendingScoreLogList /> },
    { label: "ເວລາຮຽນ", component: <ManageTimeLogsList /> },
    { label: "ວິຊາຮຽນ", component: <ManageCourseLogList /> },
    { label: "ເລື່ອນຊັ້ນ", component: <PromoteStudentLogList /> },
    { label: "ລົງທະບຽນຮຽນ", component: <RegisterStudentLogsList /> },
    { label: "E-Learning ອາຈານ", component: <ElearningTeacher /> },
    { label: "E-learning ນັກສຶກສາ", component: <ElearningStudent /> },
    {
      label: "ຈັດການຄ່າຮຽນ Entrance",
      component: <ManageEntrancePriceLogsList />,
    },
    {
      label: "ຈັດການສະຖານທີຮຽນ",
      component: <ManagePlaceStudyLogsList />,
    },
  ];

  return (
    <div
      style={{ backgroundColor: "white", padding: "16px", borderRadius: "8px" }}
    >
      {/* Scrollable Chip Container */}
      <Paper
        elevation={0}
        sx={{ overflowX: "auto", whiteSpace: "nowrap", padding: "8px" }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            overflowX: "auto",
            scrollbarWidth: "thin",
          }}
        >
          {logCategories.map((log, index) => (
            <Chip
              key={index}
              label={log.label}
              onClick={() => setSelectedLog(index)}
              color={selectedLog === index ? "primary" : "default"}
              sx={{
                paddingBottom: 2,
                paddingTop: 2,
                paddingLeft: 1,
                paddingRight: 1,
                mb: 2,
                cursor: "pointer",
                fontWeight: selectedLog === index ? "bold" : "normal",
              }}
            />
          ))}
        </Box>
      </Paper>

      {/* Display Selected Log Content */}
      <Box sx={{ marginTop: 3 }}>{logCategories[selectedLog].component}</Box>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Container,
  Typography,
  Box,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { API_KEY, NEW_API_URL } from "../../../common/contrant";
import { USER_KEY } from "../../../consts";

const ScholarshipSetting = () => {
  const user = JSON.parse(localStorage.getItem(USER_KEY));
  const token = user?.accessToken;
  const accessmentRedux = useSelector((state) => state.accessment.value);

  const url = NEW_API_URL + "scholarshipquota";

  const [scholarshipList, setScholarshipList] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedID, setSelectedID] = useState("");
  const [formOpen, setFormOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetchData();
  }, [accessmentRedux]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}/${accessmentRedux}`, {
        headers: { api_key: API_KEY, Authorization: `Bearer ${token}` },
      });
      setTotal(response.data?.total);
      setScholarshipList(response.data?.quotas || []);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    if (!title.trim()) return alert("ກະລຸນາປ້ອນຫົວຂໍ້");
    const data = { title, description, accessmentYear: accessmentRedux };
    try {
      if (editData) {
        await axios.put(`${url}/${editData._id}`, data, {
          headers: { api_key: API_KEY, Authorization: `Bearer ${token}` },
        });
      } else {
        await axios.post(url, data, {
          headers: { api_key: API_KEY, Authorization: `Bearer ${token}` },
        });
      }
      setShowSuccess(true);
      closeForm();
      fetchData();
    } catch (error) {
      alert("ເກີດບັນຫາ");
    }
  };

  const deleteRow = async (id) => {
    try {
      await axios.delete(`${url}/${id}`, {
        headers: { api_key: API_KEY, Authorization: `Bearer ${token}` },
      });
      setShowSuccess(true);
      fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  const openEditForm = (item) => {
    setEditData(item);
    setTitle(item.title);
    setDescription(item.description);
    setFormOpen(true);
  };

  const openCreateForm = () => {
    setEditData(null);
    setTitle("");
    setDescription("");
    setFormOpen(true);
  };

  const closeForm = () => {
    setFormOpen(false);
    setEditData(null);
    setTitle("");
    setDescription("");
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f8f9fa",
        height: "90vh",
      }}
      padding={5}
    >
      <Typography variant="h5">ຈັດການທຶນການສຶກສາ</Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          mt: 3
        }}
      >
        <Typography variant="h6">{"ຈຳນວນທັງຫມົດ " + total} </Typography>
        <Button
          sx={{
            paddingRight: 4,
            paddingLeft: 4,
          }}
          size="large"
          variant="contained"
          onClick={openCreateForm}
        >
          ເພີ່ມໃໝ່
        </Button>
      </Box>

      <TableContainer sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: "white",
                  textAlign: "center",
                }}
              >
                ຫົວຂໍ້
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  textAlign: "center",
                }}
              >
                ລາຍລະອຽດ
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  textAlign: "center",
                }}
              >
                ປີປະເມີນ
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  textAlign: "center",
                }}
              >
                ຈັດການ
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scholarshipList.map((row) => (
              <TableRow key={row._id}>
                <TableCell
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {row.title}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {row.description}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {row.accessmentYear}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <IconButton onClick={() => openEditForm(row)}>
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setSelectedID(row._id);
                      setShowDelete(true);
                    }}
                    color="error"
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={formOpen} onClose={closeForm}>
        <DialogTitle>{editData ? "ແກ້ໄຂຂໍ້ມູນ" : "ເພີ່ມໃໝ່"}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="ຫົວຂໍ້"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            fullWidth
            margin="dense"
            label="ລາຍລະອຽດ"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeForm}>ຍົກເລີກ</Button>
          <Button onClick={handleSubmit} variant="contained">
            {editData ? "ບັນທຶກ" : "ເພີ່ມ"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showDelete} onClose={() => setShowDelete(false)}>
        <DialogTitle>ຕ້ອງການລົບແທ້ບໍ່?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowDelete(false)}>ຍົກເລີກ</Button>
          <Button
            onClick={() => {
              deleteRow(selectedID);
              setShowDelete(false);
            }}
            color="error"
          >
            ລົບ
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showSuccess}
        autoHideDuration={4000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert severity="success">ສຳເລັດ!</Alert>
      </Snackbar>
    </Box>
  );
};

export default ScholarshipSetting;

import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetStatistichData } from "../Api";
import Loading from "../../../common/Loading";
import * as XLSX from "xlsx";
import { hasPermission } from "../../../helpers/CheckPermission";

const TeacherBySpecialSubject = ({ faculty, department }) => {
  const [data, setData] = useState(null);

  const cellStyle = {
    width: "400px",
    height: "40px",
    textAlign: "center",
  };
  const getData = async () => {
    setData(null);
    try {
      const result = await fetStatistichData(
        `degreeteacher?faculty=${faculty}&department=${department}`
      );
      setData(result);
    } catch (error) {
      setData(null);
    }
  };
  const generateMerges = () => {
    return [
      { s: { r: 0, c: 0 }, e: { r: 3, c: 0 } },
      { s: { r: 0, c: 1 }, e: { r: 3, c: 1 } },
      { s: { r: 0, c: 2 }, e: { r: 0, c: 5 } },
      { s: { r: 0, c: 6 }, e: { r: 0, c: 9 } },
      { s: { r: 0, c: 10 }, e: { r: 0, c: 13 } },
      { s: { r: 0, c: 14 }, e: { r: 0, c: 17 } },
      { s: { r: 0, c: 18 }, e: { r: 0, c: 21 } },
      // merge second rows
      { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
      { s: { r: 1, c: 4 }, e: { r: 1, c: 5 } },
      { s: { r: 1, c: 6 }, e: { r: 1, c: 7 } },
      { s: { r: 1, c: 8 }, e: { r: 1, c: 9 } },
      { s: { r: 1, c: 10 }, e: { r: 1, c: 11 } },
      { s: { r: 1, c: 12 }, e: { r: 1, c: 13 } },
      { s: { r: 1, c: 14 }, e: { r: 1, c: 15 } },
      { s: { r: 1, c: 16 }, e: { r: 1, c: 17 } },
      { s: { r: 1, c: 18 }, e: { r: 1, c: 19 } },
      { s: { r: 1, c: 20 }, e: { r: 1, c: 21 } },
      // merge third rows
      { s: { r: 2, c: 2 }, e: { r: 2, c: 3 } },
      { s: { r: 2, c: 4 }, e: { r: 2, c: 5 } },
      { s: { r: 2, c: 6 }, e: { r: 2, c: 7 } },
      { s: { r: 2, c: 8 }, e: { r: 2, c: 9 } },
      { s: { r: 2, c: 10 }, e: { r: 2, c: 11 } },
      { s: { r: 2, c: 12 }, e: { r: 2, c: 13 } },
      { s: { r: 2, c: 14 }, e: { r: 2, c: 15 } },
      { s: { r: 2, c: 16 }, e: { r: 2, c: 17 } },
      { s: { r: 2, c: 18 }, e: { r: 2, c: 19 } },
      { s: { r: 2, c: 20 }, e: { r: 2, c: 21 } },
    ];
  };

  const staffTypes = Array.from({ length: 8 }, (_, i) => (i + 1).toString());

  const formatDataForExcel = () => {
    const headers = [
      [
        "ລຳດັບ",
        "ລະດັບຊັ້ນວິຊາສະເພາະ",
        "ຈຳນວນຄູສອນ",
        "ຈຳນວນຄູສອນ",
        "ຈຳນວນຄູສອນ",
        "ຈຳນວນຄູສອນ",
        "ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານທີ່ໄດ້ສອນ",
        "ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານທີ່ໄດ້ສອນ",
        "ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານທີ່ໄດ້ສອນ",
        "ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານທີ່ໄດ້ສອນ",
        "ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານບໍ່ໄດ້ສອນ",
        "ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານບໍ່ໄດ້ສອນ",
        "ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານບໍ່ໄດ້ສອນ",
        "ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານບໍ່ໄດ້ສອນ",
        "ຄູສອນ ແລະ ພະນັກງານບໍລິຫານລາພັກຍາວນານ (ສະເພາະປະຈຳການ)",
        "ຄູສອນ ແລະ ພະນັກງານບໍລິຫານລາພັກຍາວນານ (ສະເພາະປະຈຳການ)",
        "ຄູສອນ ແລະ ພະນັກງານບໍລິຫານລາພັກຍາວນານ (ສະເພາະປະຈຳການ)",
        "ຄູສອນ ແລະ ພະນັກງານບໍລິຫານລາພັກຍາວນານ (ສະເພາະປະຈຳການ)",
        "ຈຳນວນຄູສອນ, ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານທັງຫມົດ",
        "ຈຳນວນຄູສອນ, ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານທັງຫມົດ",
        "ຈຳນວນຄູສອນ, ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານທັງຫມົດ",
        "ຈຳນວນຄູສອນ, ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານທັງຫມົດ",
      ],
      [
        "ລຳດັບ",
        "ລະດັບຊັ້ນວິຊາສະເພາະ",
        "1",
        "1",
        "2",
        "2",
        "3",
        "3",
        "4",
        "4",
        "5",
        "5",
        "6",
        "6",
        "7",
        "7",
        "8",
        "8",
        "1+3+5+7+8",
        "1+3+5+7+8",
        "2+4+6",
        "2+4+6",
      ],
      [
        "ຄູສອນປະຈຳ",
        "ຄູສອນປະຈຳ",
        "ຄູສອນປະຈຳ",
        "ຄູສອນປະຈຳ",
        "ຄູຮັບເຊີນ",
        "ຄູຮັບເຊີນ",
        "ພ/ງ ປະຈຳການ",
        "ພ/ງ ປະຈຳການ",
        "ພ/ງ ຮັບເຊີນ",
        "ພ/ງ ຮັບເຊີນ",
        "ພ/ງ ປະຈຳການ",
        "ພ/ງ ປະຈຳການ",
        "ພ/ງ ຮັບເຊີນ",
        "ພ/ງ ຮັບເຊີນ",
        "ໄປຮຽນຕໍ່",
        "ໄປຮຽນຕໍ່",
        "ພັກປິ່ນປົວ",
        "ພັກປິ່ນປົວ",
        "ປະຈຳການ",
        "ປະຈຳການ",
        "ຮັບເຊີນ",
        "ຮັບເຊີນ",
      ],
      [
        "ລວມ",
        "ຍິງ",
        "ລວມ",
        "ຍິງ",
        "ລວມ",
        "ຍິງ",
        "ລວມ",
        "ຍິງ",
        "ລວມ",
        "ຍິງ",
        "ລວມ",
        "ຍິງ",
        "ລວມ",
        "ຍິງ",
        "ລວມ",
        "ຍິງ",
        "ລວມ",
        "ຍິງ",
        "ລວມ",
        "ຍິງ",
        "ລວມ",
        "ຍິງ",
      ],
    ];

    let rows = [["I", "ສຳລັບຜູ້ມີວິຊາສະເພາະ"]];
    let index = 1; // Define index outside of the map function

    degreeLevelList.map((degree) => {
      Object.entries(data.degree).map(([key, value]) => {
        if (key === degree) {
          rows.push([
            index,
            degree,
            ...staffTypes.flatMap((type) => [
              value[type].total ?? 0,
              value[type].female ?? 0,
            ]),
            (value["1"].total ?? 0) +
            (value["3"].total ?? 0) +
            (value["5"].total ?? 0) +
            (value["7"].total ?? 0) +
            (value["8"].total ?? 0),
            (value["1"].female ?? 0) +
            (value["3"].female ?? 0) +
            (value["5"].female ?? 0) +
            (value["7"].female ?? 0) +
            (value["8"].female ?? 0),
            (value["2"].total ?? 0) +
            (value["4"].total ?? 0) +
            (value["6"].total ?? 0),
            (value["2"].female ?? 0) +
            (value["4"].female ?? 0) +
            (value["6"].female ?? 0),
          ]);
          index++;
        }
      });
    });

    rows.push(["II", "ສຳລັບຜູ້ບໍ່ມີວິຊາສະເພາະ"]);

    index = 1;
    secondaryList.map((degree) => {
      Object.entries(data.no_degree).map(([key, value]) => {
        if (key === degree) {
          rows.push([
            index,
            degree,
            ...staffTypes.flatMap((type) => [
              value[type].total ?? 0,
              value[type].female ?? 0,
            ]),
            (value["1"].total ?? 0) +
            (value["3"].total ?? 0) +
            (value["5"].total ?? 0) +
            (value["7"].total ?? 0) +
            (value["8"].total ?? 0),
            (value["1"].female ?? 0) +
            (value["3"].female ?? 0) +
            (value["5"].female ?? 0) +
            (value["7"].female ?? 0) +
            (value["8"].female ?? 0),
            (value["2"].total ?? 0) +
            (value["4"].total ?? 0) +
            (value["6"].total ?? 0),
            (value["2"].female ?? 0) +
            (value["4"].female ?? 0) +
            (value["6"].female ?? 0),
          ]);
          index++;
        }
      });
    });

    return [...headers, ...rows];
  };

  // export to excel
  const onExport = () => {
    const ws = XLSX.utils.aoa_to_sheet(formatDataForExcel());
    const merges = generateMerges();
    ws["!merges"] = merges;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "TeacherList");
    XLSX.writeFile(wb, "TeacherList.xlsx");
  };

  const degreeLevelList = [
    "ປະລິນຍາເອກ",
    "ປະລິນຍາໂທ",
    "ເໜືອປະລິນຍາຕີ",
    "ປະລິນຍາຕີ",
    "ອະນຸປະລິນຍາ (ຊັ້ນສູງ)",
    "ຊັ້ນກາງ",
    "ຊັ້ນຕົ້ນ",
    "ຊັ້ນຕົ້ນ",
  ];

  const secondaryList = [
    "ຈົບຊັ້ນມັດທະຍົມຕອນປາຍ",
    "ຈົບຊັ້ນມັດທະຍົມຕອນຕົ້ນ",
    "ຈົບຊັນປະຖົມ",
    "ບໍ່ຈົບຊັ້ນປະຖົມ",
    "ບໍ່ມີການສຶກສາ",
  ];

  useEffect(() => {
    getData();
  }, [faculty, department]);

  let queue = 0;
  let queue1 = 0;

  return (
    <div>
      <Grid container display={"flex"} justifyContent={"space-between"}>
        <Grid item lg={10}>
          <h5>ຈຳນວນຄູ-ອາຈານສອນ ແລະ ພະນັກງານບໍລິຫານ ຕາມຊັ້ນວິຊາສະເພາະ</h5>
        </Grid>
        <Grid item lg={1}>
          {hasPermission('STATISTIC', 'export') &&
            <Button
              variant="contained"
              color="success"
              startIcon={<i className="fa fa-file-excel"></i>}
              sx={{ textTransform: "none" }}
              onClick={onExport}
            >
              Export
            </Button>
          }
        </Grid>
        <div
          style={{
            maxHeight: "80vh",
            overflowY: "auto",
            paddingBottom: "100px",
            marginTop: "20px",
          }}
        >
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
            }}
          >
            <thead
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "#fff",
                zIndex: 1,
              }}
            >
              <tr>
                <th
                  rowSpan={4}
                  style={{
                    width: "100px",
                  }}
                >
                  ລຳດັບ
                </th>
                <th
                  rowSpan={4}
                  style={{
                    width: "400px",
                  }}
                >
                  ລະດັບຊັ້ນວິຊາສະເພາະ
                </th>
                <th colSpan={4} style={cellStyle}>
                  ຈຳນວນຄູສອນ
                </th>
                <th colSpan={4} style={cellStyle}>
                  ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານທີ່ໄດ້ສອນ
                </th>
                <th colSpan={4} style={cellStyle}>
                  ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານບໍ່ໄດ້ສອນ
                </th>
                <th colSpan={4} style={cellStyle}>
                  ຄູສອນ ແລະ ພະນັກງານບໍລິຫານລາພັກຍາວນານ (ສະເພາະປະຈຳການ)
                </th>
                <th colSpan={4} style={cellStyle}>
                  ຈຳນວນຄູສອນ, ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານທັງຫມົດ
                </th>
              </tr>
              <tr>
                <th colSpan={2} style={cellStyle}>
                  1
                </th>
                <th colSpan={2} style={cellStyle}>
                  2
                </th>
                <th colSpan={2} style={cellStyle}>
                  3
                </th>
                <th colSpan={2} style={cellStyle}>
                  4
                </th>
                <th colSpan={2} style={cellStyle}>
                  5
                </th>
                <th colSpan={2} style={cellStyle}>
                  6
                </th>
                <th colSpan={2} style={cellStyle}>
                  7
                </th>
                <th colSpan={2} style={cellStyle}>
                  8
                </th>
                <th colSpan={2} style={cellStyle}>
                  1 + 3 + 5 + 7 + 8
                </th>
                <th colSpan={2} style={cellStyle}>
                  2 + 4 + 6
                </th>
              </tr>
              <tr>
                <th colSpan={2} style={cellStyle}>
                  ຄູສອນປະຈຳ
                </th>
                <th colSpan={2} style={cellStyle}>
                  ຄູຮັບເຊີນ
                </th>
                <th colSpan={2} style={cellStyle}>
                  ພ/ງ ປະຈຳການ
                </th>
                <th colSpan={2} style={cellStyle}>
                  ພ/ງ ຮັບເຊີນ
                </th>
                <th colSpan={2} style={cellStyle}>
                  ພ/ງ ປະຈຳການ
                </th>
                <th colSpan={2} style={cellStyle}>
                  ພ/ງ ຮັບເຊີນ
                </th>
                <th colSpan={2} style={cellStyle}>
                  ໄປຮຽນຕໍ່
                </th>
                <th colSpan={2} style={cellStyle}>
                  ພັກປິ່ນປົວ
                </th>
                <th colSpan={2} style={cellStyle}>
                  ປະຈຳການ
                </th>
                <th colSpan={2} style={cellStyle}>
                  ຮັບເຊີນ
                </th>
              </tr>
              <tr>
                <th colSpan={1} style={cellStyle}>
                  ລວມ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ຍິງ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ລວມ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ຍິງ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ລວມ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ຍິງ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ລວມ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ຍິງ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ລວມ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ຍິງ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ລວມ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ຍິງ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ລວມ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ຍິງ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ລວມ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ຍິງ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ລວມ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ຍິງ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ລວມ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ຍິງ
                </th>
              </tr>
            </thead>
            {data ? (
              <tbody>
                <tr>
                  <td style={cellStyle}> I </td>
                  <td colSpan={20} style={{ textAlign: "start" }}>
                    ສຳລັບຜູ້ມີວິຊາສະເພາະ
                  </td>
                </tr>
                {degreeLevelList.map((degree) => {
                  return Object.entries(data.degree).map(
                    ([key, value], index) => {
                      if (key == degree) {
                        queue++;
                        return (
                          <tr>
                            <td style={cellStyle}>{queue}</td>
                            <td>{degree}</td>
                            {staffTypes.map((type) => (
                              <React.Fragment key={type}>
                                <td style={cellStyle}>
                                  {value[type]?.total || 0}
                                </td>
                                <td style={cellStyle}>
                                  {value[type]?.female || 0}
                                </td>
                              </React.Fragment>
                            ))}
                            <td style={cellStyle}>
                              {value["1"].total +
                                value["3"].total +
                                value["5"].total +
                                value["7"].total +
                                value["8"].total}
                            </td>
                            <td style={cellStyle}>
                              {value["1"].female +
                                value["3"].female +
                                value["5"].female +
                                value["7"].female +
                                value["8"].female}
                            </td>
                            <td style={cellStyle}>
                              {value["2"].total +
                                value["4"].total +
                                value["6"].total}
                            </td>
                            <td style={cellStyle}>
                              {value["2"].female +
                                value["4"].female +
                                value["6"].female}
                            </td>
                          </tr>
                        );
                      } else return <tr></tr>;
                    }
                  );
                })}
                <td style={cellStyle}> II </td>
                <td colSpan={20} style={{ textAlign: "start" }}>
                  ສຳລັບຜູ້ບໍ່ມີວິຊາສະເພາະ
                </td>
                {secondaryList.map((degree) => {
                  return Object.entries(data.no_degree).map(
                    ([key, value], index) => {
                      if (key == degree) {
                        queue1++;
                        return (
                          <tr>
                            <td style={cellStyle}>{queue1}</td>
                            <td>{degree}</td>
                            {staffTypes.map((type) => (
                              <React.Fragment key={type}>
                                <td style={cellStyle}>
                                  {value[type]?.total || 0}
                                </td>
                                <td style={cellStyle}>
                                  {value[type]?.female || 0}
                                </td>
                              </React.Fragment>
                            ))}
                            <td style={cellStyle}>
                              {value["1"].total +
                                value["3"].total +
                                value["5"].total +
                                value["7"].total +
                                value["8"].total}
                            </td>
                            <td style={cellStyle}>
                              {value["1"].female +
                                value["3"].female +
                                value["5"].female +
                                value["7"].female +
                                value["8"].female}
                            </td>
                            <td style={cellStyle}>
                              {value["2"].total +
                                value["4"].total +
                                value["6"].total}
                            </td>
                            <td style={cellStyle}>
                              {value["2"].female +
                                value["4"].female +
                                value["6"].female}
                            </td>
                          </tr>
                        );
                      } else return <tr></tr>;
                    }
                  );
                })}
              </tbody>
            ) : (
              <div className="bg-white">
                <Loading />
              </div>
            )}
          </table>
        </div>
      </Grid>
    </div>
  );
};

export default TeacherBySpecialSubject;

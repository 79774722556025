import React, { useEffect, useState } from "react";
import DataFilter from "../../common/FilterTest";
import { IconButton, Typography, Checkbox, Button, Box } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIos";
import { useSelector } from "react-redux";
import { USER_KEY } from "../../consts";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import { fetchGet, fetchPutV2 } from "../../common/fetchAPI";
import AlertMessage from "../../common/AlertMessage";
import { Modal } from "@mui/material";

const modalStyle = (theme) => ({
  position: "absolute",
  width: 400,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  outline: "none",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  border: `1px solid ${theme.palette.divider}`,
});

const buttonContainerStyle = {
  marginTop: 3,
  display: "flex",
  justifyContent: "center",
  gap: 2,
};

// this file does noot sue in real user  it just create for dev only
const StudentListClass = () => {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const [facId, setFacId] = useState(userObject.data.faculty.id);
  const [depId, setDepId] = useState("");
  const [disableDep, setDisableDep] = useState(false);
  const [disableFact, setDisableFact] = useState(false);

  const [filter, setFilter] = useState({});
  const [studentList, setStudentList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);

  const accessmentRedux = useSelector((state) => state.accessment.value);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [classlist, setClassList] = useState([]);

  const fetchClasslist = async () => {
    const response = await fetchGet("/class/" + filter?.major + "/1");
    if (response) {
      setClassList(response?.data || []);
    }
  };

  useEffect(() => {
    fetchClasslist();
  }, [filter, showConfirm]);

  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "error",
  });

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus({ ...actionStatus, open: false });
      }, 3000);
    }
  }, [actionStatus]);

  useEffect(() => {
    setSelectedStudentIds([]);
  }, [filter]);

  useEffect(() => {
    const user = localStorage.getItem(USER_KEY);
    const userObject = JSON.parse(user);

    if (userObject) {
      if (userObject.data.role === "DEP_ADMIN") {
        setDisableDep(true);
        setDepId(userObject.data.department.id);
        setFacId(userObject.data.faculty.id);
        setDisableFact(true);
      }
      if (userObject.data.role === "FAC_ADMIN") {
        setDisableDep(false);
        setDepId("");
        setFacId(userObject.data.faculty.id);
        setDisableFact(true);
      }
      if (userObject.data.role === "ADMIN") {
        setDisableDep(false);
        setDisableFact(false);
        setDepId("");
        setFacId("");
      }
    }
  }, [user]);

  const fetchStudent = async (limit) => {
    const body = {
      history: true,
      assessmentYear: accessmentRedux,
      faculty: filter?.faculty || facId,
      department: filter?.department,
      major: filter?.major,
      classroom: filter?.class,
      limit: limit,
      page: currentPage,
      keyword: keyword,
    };

    try {
      const response = await fetchPutV2("/student/current", body);
      if (response.success) {
        setStudentList(response.data);
        setTotalPage(response.totalPageCount);
      } else {
        setStudentList([]);
      }
    } catch (err) {
      setStudentList([]);
    }
  };

  useEffect(() => {
    if (filter.class) {
      fetchStudent(300);
    } else {
      // just give  example to user know that  there is a student in the list
      fetchStudent(20);
    }
  }, [filter, accessmentRedux, facId, currentPage, keyword]);

  const handleRowSelection = (studentId) => {
    setSelectedStudentIds((prevSelectedIds) =>
      prevSelectedIds.includes(studentId)
        ? prevSelectedIds.filter((id) => id !== studentId)
        : [...prevSelectedIds, studentId]
    );
  };

  const handleSelectAll = () => {
    const allIds = studentList
      .filter((student) => student.graduatedStatus?.status === "STUDYING") // Only include students with "STUDYING" status
      .map((student) => student._id); // Map to their IDs
    setSelectedStudentIds(allIds);
  };

  const handleClearAll = () => {
    setSelectedStudentIds([]);
  };

  const [selectedClass, setSelectedClass] = useState("");
  const onConfirm = async () => {
    setIsLoading(true);
    const body = {
      studentIds: selectedStudentIds,
      classRoom: selectedClass,
    };

    const response = await fetchPutV2("/student/class", body);
    setIsLoading(false);
    setSelectedStudentIds([]);
    setShowConfirm(false);
    if (response?.success) {
      setActionStatus({
        open: true,
        message: "ສຳເລັດ",
        type: "success",
      });
      await fetchStudent();
    } else {
      setActionStatus({
        open: true,
        message: "ລະບົບມີບັນຫາ",
        type: "error",
      });
    }
  };

  const onShowComfirm = () => {
    setShowConfirm(true);
  };

  const RowData = ({
    id,
    queue,
    code,
    name,
    lastname,
    faculty,
    department,
    major,
    classroom,
    year,
    gender,
    status,
  }) => {
    return (
      <TableRow>
        <TableCell align="center">
          <Checkbox
            disabled={status?.status === "GRADUATED"}
            checked={selectedStudentIds.includes(id)}
            onChange={() => handleRowSelection(id)}
          />
        </TableCell>
        <TableCell align="center">{queue + 20 * (currentPage - 1)}</TableCell>
        <TableCell align="center">{code}</TableCell>
        <TableCell align="center">
          {gender === "FEMALE" ? "ຍິງ" : "ຊາຍ"}
        </TableCell>
        <TableCell>
          {(gender === "MALE" ? "ທ້າວ " : "ນາງ ") + name + " " + lastname}
        </TableCell>
        <TableCell align="center">{faculty}</TableCell>
        <TableCell align="center">{department}</TableCell>
        <TableCell align="center">{major}</TableCell>
        <TableCell align="center">{classroom}</TableCell>
        <TableCell align="center">{year}</TableCell>
        <TableCell align="center">
          {status?.status === "STUDYING" ? (
            <Typography
              sx={{
                color: "green",
                fontWeight: "bold",
              }}
            >
              ກຳລັງສຶກສາ
            </Typography>
          ) : (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "orange",
              }}
            >
              ສຳເລັດການສຶກສາແລ້ວ
            </Typography>
          )}
        </TableCell>
      </TableRow>
    );
  };

  useEffect(() => {
    console.log("showConfirm => ", showConfirm);
  }, [showConfirm]);

  return (
    <div className="px-3 pt-3">
      <div className="col-12">
        <Modal
          open={showConfirm}
          onClose={() => {
            setShowConfirm(false);
          }}
        >
          <Box sx={modalStyle}>
            <Typography variant="h6" id="modal-title" gutterBottom>
              {"ເລືອກຫ້ອງຮຽນ"}
            </Typography>
            <div className="row w-100">
              <div className="col-12">
                <select
                  className="form-select"
                  onChange={(e) => {
                    setSelectedClass(e.target.value);
                  }}
                >
                  <option value="">ເລືອກຫ້ອງຮຽນ</option>
                  {classlist.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <Box sx={buttonContainerStyle}>
              <Button
                sx={{ px: 4, py: 1.5 }}
                onClick={() => {
                  onConfirm();
                }}
                variant="contained"
                color="error"
                disabled={!selectedClass}
              >
                ຢືນຢັນ
              </Button>
              <Button
                sx={{ px: 4, py: 1.5 }}
                onClick={() => setShowConfirm(false)}
                variant="outlined"
                color="primary"
              >
                ຍົກເລີກ
              </Button>
            </Box>
          </Box>
        </Modal>

        <AlertMessage
          message={actionStatus.message}
          open={actionStatus.open}
          type={actionStatus.type}
        />
        <DataFilter
          depId={depId}
          disableDep={disableDep}
          factId={facId}
          disableFact={disableFact}
          onSelected={setFilter}
        />
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between", // Separate items to the left and right
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        {/* Buttons on the left */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px", // Adds spacing between the buttons
          }}
        >
          <Button
            onClick={handleSelectAll}
            startIcon={<DoneOutlineIcon />}
            variant="contained"
            color="primary"
          >
            ເລືອກທັງຫມົດ
          </Button>
          <Button
            startIcon={<ClearIcon />}
            onClick={handleClearAll}
            variant="contained"
            color="error"
          >
            ຍົກເລີກ
          </Button>
          <Typography mt={1}>
            ຈຳນວນນັກສຶກສາທີເລືອກແລ້ວ{" "}
            <Typography
              component="span"
              sx={{
                color: "#1976d2",
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              {selectedStudentIds.length}
            </Typography>
          </Typography>
        </Box>
        <Button
          onClick={onShowComfirm}
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          disabled={!selectedStudentIds.length > 0 || !filter.class}
        >
          {isLoading ? <CircularProgress /> : "ບັນທຶກ"}
        </Button>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Select
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                ລຳດັບ
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                ລະຫັດ
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                ເພດ
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                ຊື່ ແລະ ນາມສະກຸນ
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                ຄະນະ
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                ພາກວິຊາ
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                ສາຂາ
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                ຫ້ອງຮຽນ
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                ປີຮຽນ
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                ສະຖານະ
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {studentList.map((student, index) => (
              <RowData
                key={student._id}
                id={student._id}
                queue={index + 1}
                code={student?.userId}
                name={student?.firstNameL || student?.firstNameE}
                lastname={student?.lastNameL || student?.lastNameE}
                faculty={student?.faculty?.name}
                department={student?.department?.name}
                major={student?.major?.name}
                classroom={student?.classRoom?.name}
                year={student.yearLevel}
                gender={student?.gender}
                status={student?.graduatedStatus}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="row w-100">
        <div className="col-5 text-end">
          <IconButton
            onClick={() => {
              if (currentPage > 1) setCurrentPage((page) => page - 1);
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
        </div>
        <div className="col-2 text-center pt-2">
          <Typography>{currentPage + " ໃນ " + totalPage}</Typography>
        </div>
        <div className="col-5">
          <IconButton
            onClick={() => {
              if (currentPage < totalPage) setCurrentPage((page) => page + 1);
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default StudentListClass;

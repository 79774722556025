import { NEW_API_URL, API_KEY } from "../../../common/contrant";
import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CustomMessage from "../../../common/CustomStatusMessage";
import { useSelector, useDispatch } from "react-redux";
import { setFilter } from "../../../redux/filtrSlice";
import { useHistory } from "react-router-dom";
import { USER_KEY } from "../../../consts";
import {
  SCORE_STATUS,
  SCORE_STATUS_LIST,
  getScoreStatusColor,
} from "./ScoreStatus";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Autocomplete from "@mui/material/Autocomplete";
import { GRADE_SENDING_COURSE_GRADE } from "../../../consts/router";
import { fetchGet } from "../../../common/fetchAPI";
import { hasPermission } from "../../../helpers/CheckPermission";

const CourseList = () => {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);

  const filterData = useSelector((state) => state.filter);
  const accessmentRedux = useSelector((state) => state.accessment.value);

  const dispatch = useDispatch();
  const history = useHistory();

  const [searchValue, setSearchValue] = useState("");
  const [teacherList, setTeacherList] = useState([]);

  const [subjects, setSubjects] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedItem, setSelectedItem] = useState("");
  const [showComnfirm, setShowConfirm] = useState(false);
  const [majors, setMajors] = useState([]);
  const [classRooms, setClassRoom] = useState([]);

  const [faculties, setFaculties] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [count, setCount] = useState(0);

  const [formData, setFormData] = useState({
    faculty: filterData.faculty || "",
    department: filterData.department || "",
    major: filterData.major || "",
    title: "",
    year: "",
    assementYear: accessmentRedux,
    status: "",
    classRoom: "",
    semester: "",
    teacher: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleEdit = (subject) => {
    history.push("/course-add", subject);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleDelete = (id) => {
    setSelectedItem({
      id: id,
    });
    setShowConfirm(true);
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  // Fetch data from the API
  const fetchData = async () => {
    try {
      // Create a new URLSearchParams object and append parameters
      const apiUrl = `${NEW_API_URL}score/status?pageSize=${rowsPerPage}&faculty=${formData.faculty}&department=${formData.department}&courseTitle=${formData.title}&status=${formData.status}&page=${page}&keyword=${formData.title}&major=${formData?.major}&year=${formData.year}&classRoom=${formData.classRoom}&semester=${formData.semester}&teacher=${formData.teacher}&assessmentYear=${accessmentRedux}`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      setCount(data?.count || 0);
      setSubjects(data?.data || []);
    } catch (error) {
      setSubjects([]);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [formData, rowsPerPage, page, accessmentRedux]); // Empty dependency array means this effect runs once on component mount

  const fetchDepartment = async () => {
    if (formData?.faculty) {
      const _department = await fetchGet(
        `/department?faculty=${formData?.faculty}&assessmentYear=${accessmentRedux}`
      );
      setDepartments(_department);
    }
  };
  useEffect(() => {
    let _filterData = {};
    if (formData.faculty !== "") {
      _filterData.faculty = formData.faculty;
    }

    // Check and set department if not null
    if (formData.department !== "") {
      _filterData.department = formData.department;
    }

    // Check and set major if not null
    if (formData.major !== "") {
      _filterData.major = formData.major;
    }
    // Fetch faculties, departments, and education levels from your backend API
    // Update the state variables (faculties, departments, educationLevels) accordingly
    // For simplicity, I'm using placeholder data here

    // Dispatch the setFilter action with the filterData payload
    dispatch(setFilter(_filterData));
    // Fetch faculties
    axios
      .get(NEW_API_URL + "faculty", {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setFaculties(response.data?.data || []))
      .catch((error) => console.error(error));

    fetchDepartment();
    // Fetch departments
    // axios
    //   .get(NEW_API_URL + "department/" + formData.faculty, {
    //     headers: {
    //       api_key: API_KEY,
    //     },
    //   })
    //   .then((response) => setDepartments(response.data?.data || []))
    //   .catch((error) => console.error(error));

    // Fetch majors
    if (formData.department) {
      axios
        .get(NEW_API_URL + "getmajo/" + formData.department, {
          headers: {
            api_key: API_KEY,
          },
        })
        .then((response) => setMajors(response.data?.data || []))
        .catch((error) => console.error(error));
    }
    // Fetch majors
    if (formData.major && formData.year) {
      axios
        .get(NEW_API_URL + "class/" + formData.major + "/" + formData.year, {
          headers: {
            api_key: API_KEY,
          },
        })
        .then((response) => setClassRoom(response.data?.data || []))
        .catch((error) => console.error(error));
    }
  }, [formData]);

  // on seaarch teacher
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${NEW_API_URL}search/teacher/${searchValue}`
        );
        setTeacherList(response.data); // Assuming the API returns an array of options
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (searchValue !== "") {
      fetchData();
    } else {
      setTeacherList([]); // Clear options if searchValue is empty
    }
  }, [searchValue]);

  useEffect(() => {
    if (snackbarOpen) {
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 5000);
    }
  }, [snackbarOpen]);

  const handleSelect = (event, newValue) => {
    setSelectedItem(newValue);
    if (newValue) {
      setFormData((preData) => ({
        ...preData,
        teacher: newValue?._id,
      }));
    }
  };
  return (
    <div className="row">
      <CustomMessage
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <Paper elevation={0} className="bg-white mt-1 pb-2">
        <div className="px-4 text-start w-100 mt-4">
          <h3>ລາຍການການສົ່ງຄະເເນນ</h3>
        </div>
        <Grid
          container
          className="d-flex justify-content-center px-4"
          spacing={2}
        >
          <Grid className="text-start" item xs={12}>
            <FormControl fullWidth size="small" margin="normal">
              <TextField
                name="title"
                onChange={handleChange}
                type="text"
                className="mt-2"
                variant="standard"
                placeholder="ຄົ້ນຫາຊື່ວິຊາ"
                InputProps={{
                  style: {
                    height: "33px",
                  },
                  startAdornment: <SearchIcon />,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="faculty">ຄະນະ</InputLabel>
              <Select
                disabled={
                  userObject?.data?.role === "FAC_ADMIN" ||
                  userObject?.data?.role === "DEP_ADMIN"
                }
                label="ຄະນະ"
                name="faculty"
                value={formData.faculty || ""}
                onChange={handleChange}
              >
                <MenuItem key={""} value={""}>
                  ທັງຫມົດ
                </MenuItem>
                {faculties.map((faculty) => (
                  <MenuItem key={faculty._id} value={faculty._id}>
                    {faculty.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="department">ພາກ</InputLabel>
              <Select
                disabled={userObject?.data?.role === "DEP_ADMIN"}
                label="ພາກ"
                name="department"
                value={formData.department || ""}
                onChange={handleChange}
              >
                <MenuItem key={""} value={""}>
                  ທັງຫມົດ
                </MenuItem>
                {departments.map((department) => (
                  <MenuItem key={department?._id} value={department?._id}>
                    {department?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="department">ສາຂາ</InputLabel>
              <Select
                label="ສາຂາ"
                name="major"
                value={formData.major || ""}
                onChange={handleChange}
              >
                <MenuItem key={""} value={""}>
                  ທັງຫມົດ
                </MenuItem>
                {majors.map((major) => (
                  <MenuItem key={major._id} value={major._id}>
                    {major.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="faculty">ປີຮຽນ</InputLabel>
              <Select
                size="small"
                label="ປີຮຽນ"
                name="year"
                value={formData.year}
                onChange={handleChange}
              >
                <MenuItem key={""} value={""}>
                  {"ທັງຫມົດ"}
                </MenuItem>
                {[1, 2, 3, 4, 5, 6].map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="faculty">ພາກຮຽນ</InputLabel>
              <Select
                size="small"
                label="ພາກຮຽນ"
                name="semester"
                value={formData.semester}
                onChange={handleChange}
              >
                <MenuItem key={""} value={""}>
                  {"ທັງຫມົດ"}
                </MenuItem>
                {[1, 2].map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="classRoom">ຫ້ອງຮຽນ</InputLabel>
              <Select
                label="ຫ້ອງຮຽນ"
                name="classRoom"
                value={formData.classRoom}
                onChange={handleChange}
              >
                <MenuItem key={""} value={""}>
                  ທັງຫມົດ
                </MenuItem>
                {classRooms.map((classRoom) => (
                  <MenuItem key={classRoom._id} value={classRoom._id}>
                    {classRoom.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth size="small" margin="normal">
              <Autocomplete
                size="small"
                options={teacherList}
                getOptionLabel={(option) =>
                  option.firstname + "  " + option.lastname
                }
                fullWidth
                name="teacher"
                onChange={handleSelect}
                onInputChange={(event, newValue) => setSearchValue(newValue)}
                renderInput={(params) => (
                  <TextField {...params} label="ຄົ້ນຫາອາຈານ" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="educationLevel">ສະຖານະຄະເເນນ</InputLabel>
              <Select
                label="ລະດັບການສຶກສາ"
                name="status"
                value={formData.educationLevel}
                onChange={handleChange}
              >
                <MenuItem key={""} value={""}>
                  ທັງຫມົດ
                </MenuItem>
                {SCORE_STATUS_LIST.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={0} className="bg-white px-4 pb-2">
        <Typography variant="h6">ຈຳນວນທັງຫມົດ {count}</Typography>
      </Paper>
      <Paper elevation={0} className="bg-white pb-3">
        <TableContainer>
          <Table>
            <TableHead style={{ backgroundColor: "#e9ecef" }}>
              <TableRow style={{ backgroundColor: "#e9ecef" }}>
                <TableCell align="center">ລຳດັບ</TableCell>
                <TableCell colSpan={2} align="center">
                  ສາຂາ
                </TableCell>
                <TableCell align="center">ລະຫັດວິຊາ</TableCell>
                <TableCell colSpan={2} align="center">
                  ຊື່ວິຊາ
                </TableCell>
                <TableCell align="center">ປີຮຽນ</TableCell>
                <TableCell align="center">ພາກຮຽນ</TableCell>
                <TableCell align="center">ຫ້ອງຮຽນ</TableCell>
                <TableCell colSpan={2} align="center">
                  ອາຈານສອນ
                </TableCell>
                <TableCell colSpan={2} align="center">
                  ສະຖານະ
                </TableCell>
                {hasPermission('GRADE', 'read') &&
                  <TableCell align="center">ຈັດການ</TableCell>
                }
              </TableRow>
            </TableHead>

            <TableBody>
              {subjects.length > 0 &&
                subjects.map((subject, index) => (
                  <TableRow key={subject._id}>
                    <TableCell align="center">
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell
                      className="text-start"
                      colSpan={2}
                      align="center"
                    >
                      {subject.major}
                    </TableCell>
                    <TableCell align="center">{subject?.courseCode}</TableCell>
                    <TableCell
                      className="text-start"
                      colSpan={2}
                      align="center"
                    >
                      {subject?.title}
                    </TableCell>
                    <TableCell align="center">{subject.year}</TableCell>
                    <TableCell align="center">{subject.semester}</TableCell>
                    <TableCell align="center">{subject.classRoom}</TableCell>
                    <TableCell
                      className="text-start"
                      colSpan={2}
                      align="center"
                    >
                      {subject?.teachers.map(
                        (teacher) =>
                          "ທ່ານ " +
                          (teacher?.gender === "FEMALE" ? "ນາງ​  " : "") +
                          teacher +
                          " , "
                      ) || ""}
                    </TableCell>
                    <TableCell colSpan={2} align="center">
                      <Typography
                        style={{
                          fontWeight: "bold",
                          opacity: 1,
                          color: getScoreStatusColor(subject.status || ""),
                          padding: "10px",
                        }}
                      >
                        {SCORE_STATUS[subject.status || ""]}
                      </Typography>
                    </TableCell>
                    {hasPermission('GRADE', 'read') &&
                      <TableCell align="center">
                        <IconButton
                          color="info"
                          size="small"
                          aria-label="Edit"
                          onClick={() => {
                            history.push({
                              pathname: GRADE_SENDING_COURSE_GRADE,
                              state: {
                                data: {
                                  classRoom: subject?.classRoomId,
                                  accessmentYear: accessmentRedux,
                                  courseId: subject?.courseId,
                                },
                              },
                            });
                          }}
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>

            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              colSpan={13} // Adjusted colSpan to include the Actions column
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              elevation={0}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default CourseList;

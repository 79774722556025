import React from "react";
import { Box, Typography } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";

const BlockComponent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f8f9fa",
        textAlign: "center",
        padding: 2,
      }}
    >
      <BlockIcon sx={{ fontSize: 80, color: "#d32f2f", marginBottom: 2 }} />
      <Typography variant="h5" fontWeight="bold" color="textPrimary">
        ບໍ່ອະນຸຍາດໃຫ້ເຂົ້າເບິ່ງ
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ marginTop: 1 }}>
        ທ່ານບໍ່ມີສິດໃນການເຂົ້າເບິ່ງເນື້ອຫານີ້.
      </Typography>
    </Box>
  );
};

export default BlockComponent;

module.exports = {
  PAGE_GINATION: "/limit/40/skip/1",
  // Teacher
  TEACHER_LIST: "/teacher-list",
  TEACHER_ADD: "/teacher-add",
  TEACHER_FORM_STATISTIC: "/teacher-form-statistic",
  TEACHER_FORM_PLACE_OF_BIRTH: "/teacher-form-place-of-birth",
  TEACHER_EDIT: "/teacher-edit",
  TEACHER_DETAIL: "/teacher-detail",
  TEACHER_FORM_STATISTIC: "/teacher-form-statistic",
  TEACHER_FORM_PLACE_OF_BIRTH: "/teacher-form-place-of-birth",
  TIME_STUDY: "/timestudy",
  // Student
  STUDENT_LIST: "/student-list",
  STUDENT_HISTORY_LIST: "/student-history-list",
  STUDENT_ADD: "/student-add",
  STUDENT_DETAIL: "/student-detail",
  STUDENT_EDIT: "/student-edit",
  STUDENT_YEAR_ONE: "/student-year-one",
  STUDENT_YEAR_TWO: "/student-year-two",
  STUDENT_YEAR_THREE: "/student-year-three",
  STUDENT_YEAR_FOUR: "/student-year-four",
  // Pregistration
  PRERE_STUDENT: "/students-prere",
  PRERE_STUDENT_LIST: "/students-prere",
  PRERE_STUDENT_LIST_V2: "/students-prere",

  PRERE_STUDENT_LIST_ALL: "/students-prere",

  PRERE_STUDENT_REJECTED: "/students-prere",
  PRERE_STUDENT_FAC_LIST: "/students-prere",
  PRERE_STUDENT_STATISTISTIC: "/students-prere",
  PRERE_STUDENT_STATISTISTIC_TRIBE: "/students-prere",

  // Code Preregistration
  CODE_PRERE_LIST: "/code-preregistration-list",
  CODE_PRERE_ADD: "/code-preregistration-add",
  CODE_PRERE_EDIT: "/code-preregistration-edit",
  CODE_PRERE_DETAIL: "/code-preregistration-detail",

  // Course
  SUBJECT_LIST: "/subject-list",
  COURSE_LIST: "/course-list",
  COURSE_ADD: "/course-add",
  COURSE_EDIT: "/course-edit",
  COURSE_DETAIL: "/course-detail",
  COURSE_DETAIL_DOC_LIST: "/course-detail-doc-list",

  // assign student to class
  ASSIGN_STUDENT_LIST: "/assign-student-list",
  ASSIGN_STUDENT_ADD: "/assign-student-add",
  ASSIGN_STUDENT_EDIT: "/assign-edit",
  ASSIGN_STUDENT_DETAIL: "/assign-detail",

  //settings
  PROMOTE_TO_NEXT_GRADE: "/promote-to-next-grade",

  // Grades
  GRADE_TAPS: "/grades-tap",
  GRADE_INDEX: "/grades-index",
  GRADE_COURSE_LIST: "/grades-course-list",
  GRADE_SENT_LIST: "/grades-sent-detail",
  GRADE_COURSE_DETAIL: "/grades-course-detail/",

  // Registers
  REGISTRATIONS_ADD: "/registrations-add",
  REGISTRATIONS_LIST: "/registration-list",

  // History Login
  LOGIN_HISTORY: "/logs",
  ADMIN_HISTORY: "/admin-history",
  FACULTY_HISTORY: "/faculty-history",
  DEPARTMENT_HISTORY: "/department-history",
  TEACHER_HISTORY: "/teacher-history",
  STUDENT_HISTORY: "/student-history",

  // Document
  DOCUMENT_LIST: "/document-list",
  DOCUMENT_DETAIL: "/document-detail",

  //  Notice
  Notice_List: "/notice-list",
  LOGIN_MANAGEMENT: "/login-management",

  PRICE_MANAGEMENT: "/pricessetting",
  ASSEMENT_YEAR_SETTING: "/assessmentyearsetting",
  PERMISSION: "/setting-usergroup",
  PLACE_STUDY: "/place-study",
  PLACE_STUDY_DETAIL: "/place-study-detail",
  SCHOLARSHIP: "/scholarship-setting",
  
  // Sending Grade And Apply,Reject
  GRADE_SENDING_COURSE_LIST: "/grade-sending-courses-list",
  GRADE_SENDING_COURSE_DETAIL: "/grade-sending-detail",
  GRADE_UPGRADE_LIST: "/grade-upgrade-list",
  UPGRADE_HISTORY: "/upgrade-history",

  // grade class
  GRADE_CLASS_LIST: "/grades-class-list",
  GRADE_CLASS_DETAIL: "/grade-class-detail",
  GRADE_SENDING_COURSE_GRADE: "/grade-sending-status",

  // payment
  PAYMENT_LIST: "/payment-list",

  // drop uot 
  DROP_OUT_LIST: "/drop-out-list",
  DROP_OUT_ADD: "/drop-out-add",
  DROP_OUT_EDIT: "/drop-out-edit",
  DROP_OUT_DETAIL: "/drop-out-detail",
};

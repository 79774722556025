import * as _ from "lodash";
import { jwtDecode } from "jwt-decode";

// custom import
import { USER_KEY } from "../consts";

// get user data from localStorage
export const getUserDataFromLCStorage = () => {
  const user = JSON.parse(localStorage.getItem(USER_KEY));
  return user;
};

export const getUserRole = () => {
  const user = JSON.parse(localStorage.getItem(USER_KEY));
  return user?.data?.role;
};

export const notUserRoleAdminAndDean = () => {
  const user = JSON.parse(localStorage.getItem(USER_KEY));
  const role = user?.data?.role !== "ADMIN" && user?.data?.role !== "DEAN";
  return role;
};
export const getUserFacultyId = () => {
  const user = JSON.parse(localStorage.getItem(USER_KEY));
  return user?.data?.faculty?._id;
};
export const getUserDepartmentId = () => {
  const user = JSON.parse(localStorage.getItem(USER_KEY));
  return user?.data?.department?._id;
};

// store user data to localStorage
export const storeUserDataToLCStorage = (data) => {
  localStorage.setItem(USER_KEY, JSON.stringify(data));
};

export const useAuth = () => {
  const userData = getUserDataFromLCStorage();

  // Check if user data exists
  if (_.isEmpty(userData)) {
    return false;
  }

  const { accessToken } = userData;

  // Check if access token exists
  if (!accessToken) {
    return false;
  }

  // Decode the JWT token
  const decodedToken = jwtDecode(accessToken);

  // Check if the token has an expiration time
  if (!decodedToken || !decodedToken.exp) {
    return false;
  }

  // Check if the token has expired
  const isTokenExpired = decodedToken.exp * 1000 < Date.now();

  return !isTokenExpired;
};

export const reConvertGender = (gender) => {
  let result = "";
  switch (gender) {
    case "ຊາຍ":
      result = "MALE";
      break;
    case "ຍິງ":
      result = "FEMALE";
      break;
    default: // Do nothing
  }
  return result;
};

export const convertGender = (gender) => {
  let result = "";
  switch (gender) {
    case "MALE":
      result = "ຊາຍ";
      break;
    case "FEMALE":
      result = "ຍິງ";
      break;
    case "MONK":
      result = "ພຣະ";
      break;
    case "NOVICE":
      result = "ສຳມະເນນ";
      break;
    case "OTHER":
      result = "ອື່ນໆ";
      break;
    default: // Do nothing
  }
  return result;
};

export const convertGenderNam = (gender) => {
  let result = "";
  switch (gender) {
    case "MALE":
      result = "ທ້າວ";
      break;
    case "FEMALE":
      result = "ນາງ";
      break;
    case "MONK":
      result = "ພຣະ";
      break;
    case "OTHER":
      result = "ສຳມະເນນ";
      break;
    case "NOVICE":
      result = "ສຳມະເນນ";
      break;
    default: // Do nothing
  }
  return result;
};
export const convertNamNar = (gender) => {
  let result = "";
  switch (gender) {
    case "MALE":
      result = "ທ່ານ";
      break;
    case "FEMALE":
      result = "ທ່ານ ນາງ";
      break;
    case "MONK":
      result = "ພຣະ";
      break;
    case "OTHER":
      result = "ສຳມະເນນ";
      break;
    case "NOVICE":
      result = "ສຳມະເນນ";
      break;
    default: // Do nothing
  }
  return result;
};

export const convertGenderNamEN = (gender) => {
  let result = "";
  switch (gender) {
    case "MALE":
      result = "Mr";
      break;
    case "FEMALE":
      result = "Ms";
      break;
    case "MONK":
      result = "Monk";
      break;
    case "OTHER":
      result = "Novice";
      break;
    case "NOVICE":
      result = "Novice";
      break;
    default: // Do nothing
  }
  return result;
};

export const reConvertStatus = (status) => {
  let result = "";
  switch (status) {
    case "ແຕ່ງງານແລ້ວ":
      result = "MARRIAGE";
      break;
    case "ໂສດ":
      result = "SINGLE";
      break;
    default: // Do nothing
  }
  return result;
};

export const convertStatus = (status) => {
  let result = "";
  switch (status) {
    case "MARRIAGE":
      result = "ແຕ່ງງານແລ້ວ";
      break;
    case "SINGLE":
      result = "ໂສດ";
      break;
    default: // Do nothing
  }
  return result;
};

export const getDegreeAsText = (id) => {
  const idToTitleMap = {
    "5f9a74dc24aa9a0007a3c5b0": "BACHELOR_DEGREE",
    "6142f209427134962ba93c58": "BACHELOR_CONTINUING",
    "5f9a74c024aa9a0007a3c5ae": "DEPLOMA",
  };

  return idToTitleMap[id.toString()] || "BACHELOR_DEGREE"; // Default case
};

export const onConvertEducationLevel = (educationLevel) => {
  let result;
  switch (educationLevel) {
    case "DEPLOMA":
      result = "ອະນຸປະລິນຍາ";
      break;
    case "BACHELOR_DEGREE":
      result = "ປະລິນຍາຕີ";
      break;
    case "BACHELOR_CONTINUING":
      result = "ຕໍ່ເນື່ອງປະລິນຍາຕີ";
      break;
    default: // Do nothing
  }
  return result;
};

export const onConvertStudentInfo = (studentInfo) => {
  let result;
  switch (studentInfo) {
    case "EMPLOYEE":
      result = "ເປັນພະນັກງານມາຮຽນຕໍ່";
      break;
    case "ORDINARY_STUDENT":
      result = "ນັກຮຽນຈົບ ມໍ ປາຍມາຮຽນຕໍ່";
      break;
    default: // Do nothing
  }
  return result;
};

export const convertRole = (role) => {
  let result;
  switch (role) {
    case "TEACHER":
      return "ອາຈານສອນ";
    case "DEP_ADMIN":
      return "ວິຊາການພາກ";
    case "FAC_ADMIN":
      return "ວິຊາການຄະນະ";
    case "ADMIN":
      return "ວິຊາການ ມຊ";
    case "DEP_ADMIN_TEACHER":
      return "ວິຊາການພາກ ແລະ ອາຈານ";
    case "FAC_ADMIN_TEACHER":
      return "ວິຊາການຄະນະ ແລະ ອາຈານ";
    case "ADMIN_TEACHER":
      return "ວິຊາການ ມຊ ແລະ ອາຈານ";
    case "DEAN":
      return "ອະທິການບໍດີ";
    default: // Do nothing
  }
  return result;
};

export const onStudentType = (role) => {
  let result;
  switch (role) {
    case "OUT_PLAN":
      result = "ນອກແຜນ";
      break;
    case "IN_PLAN":
      result = "ໃນແຜນ";
      break;
    default: // Do nothing
  }
  return result;
};

export const convertScholarship = (role) => {
  let result;
  switch (role) {
    case "ANTIONAL_STUDENT_SCHOLARSHIP":
      result = "ນັກຮຽນເກັ່ງລະດັບຊາດ";
      break;
    case "NUOL_SCHOLARSHIP":
      result = "ນັກຮຽນເສັງໄດ້ທຶນ ມຊ";
      break;
    case "RED_DIPLOMA_SCHOLARSHIP":
      result = "ນັກຮຽນເກັ່ງປະກາສະນິຍະບັດແດງ";
      break;
    case "NO_ONE_STUDENT_OF_PROVINCIAL":
      result = "ນັກຮຽນເກັ່ງເສັງໄດ້ທີ່ 1 ຂອງແຂວງ";
      break;
    case "ETHNIC_STUDENT_OF_PROVINCIAL":
      result = "ນັກຮຽນຊົນເຜົ່າຂອງແຂວງ";
      break;
    case "EXCHANGE_STUDENT_SCHOLARSHIP":
      result = "ທຶນແບ່ງນັກຮຽນສາມັນ";
      break;
    case "OFFICIAL_STAFF_SCHOLARSHIP":
      result = "ພະນັກງານ";
      break;
    case "OTHER_SHOLARSHIP":
      result = "ທຶນແບ່ງປັນອື່ນໆ";
      break;
    default: // Do nothing
  }
  return result;
};
export const convertScholarshipEn = (role) => {
  let result;
  switch (role) {
    case "ORGANIZATION_IN_LAOPDR_MINISTRIES":
      result = "The organization in the Lao PDR(Ministries)";
      break;
    case "ORGANIZATION_IN_LAOPDR_PROVINCIAL_EDUCATION_DIVISION":
      result = "The organization in the Lao PDR(Provincial Education Division)";
      break;
    case "HIGHER_EDUCATION_INSTITUTION_FORM_ABROAD":
      result = "Higher Education institution form abroad";
      break;
    case "PERSONAL_FINANCIAL":
      result = "Personal financial";
      break;
    case "ANTIONAL_STUDENT_SCHOLARSHIP":
      result = "Outstanding student in general subject/talent student";
      break;
    case "NUOL_SCHOLARSHIP":
      result = "NUOL's talent student";
      break;
    case "RED_DIPLOMA_SCHOLARSHIP":
      result = "First-class honours";
      break;
    case "ETHNIC_STUDENT_OF_PROVINCIAL":
      result = "Provincial ethnic student";
      break;
    case "MINISTRY_OF_EDUCATION_AND_SPORTS":
      result = "Ministry of Education and Sports";
      break;
    case "OTHER_SHOLARSHIP":
      result = "Other scholarship";
      break;
    default: // Do nothing
  }
  return result;
};

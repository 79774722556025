import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { fetchPost, fetchPostV2 } from '../../common/fetchAPI';
import { generateAcademicYearsFromNextToFive } from '../../helpers/GenerateAcadimicYears';
import { toast } from 'react-toastify';

const generateYears = (start) => {
    return Array.from({ length: 7 - start }, (_, i) => start + i);
};
const DropBackModal = ({ open, onClose, onRefresh, data }) => {
    const startYear = parseInt(data?.assessmentYear.split(" - ")[1], 10);// split last year in string
    const AssesmentYears = generateAcademicYearsFromNextToFive(startYear);
    const [classRoomList, setClassRoomList] = useState([]);
    const [assessmentYear, setAssessmentYear] = useState("");
    const [year, setYear] = useState(data?.yearLevel);
    const [classRoom, setClassRoom] = useState("");
    const [isSumitted, setIsSumitted] = useState(false);
    const [errors, setErrors] = useState({
        classRoom: "",
        // year: "",
        assementYear: ""
    })

    useEffect(() => {
        // if (year !== "") {
        fetchClassroom();
        // } else {
        //     setClassRoomList([]);
        // }
    }, [data]);

    const fetchClassroom = async () => {
        const postData = {
            major: data?.major?._id,
            year: data?.yearLevel,
        };
        const fetchGetClass = await fetchPost("classroom", postData)
        if (fetchGetClass) {
            setClassRoomList(fetchGetClass?.data);
            // setSelectedClassroom(fetchGetClass?.data[0]);
        } else {

            setClassRoomList([]);
            // setSelectedClassroom();
        }
    };

    const handleCloseDialog = () => {
        onClose();
        setErrors({});
        setAssessmentYear("");
        setClassRoom("");
        onRefresh();
    }

    const handleSubmit = async () => {

        let newErrors = {};

        if (!assessmentYear) {
            newErrors.assementYear = "ກະລຸນາເລືອກສົກທີ່ຈະກັບມາຮຽນ";
        }
        if (!classRoom) {
            newErrors.classRoom = "ກະລຸນາເລືອກຫ້ອງຮຽນ";
        }
        // if (!year) {
        //     newErrors.year = "ກະລຸນາເລືອກປີ";
        // }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        try {
            setIsSumitted(true);
            const body = {
                assessmentYear: assessmentYear,
                classRoom: classRoom,
                yearLevel: data?.yearLevel
            }
            await fetchPostV2("studentdrop/reassign/" + data?._id, body);
            handleCloseDialog()
            toast.success("ສຳເລັດ ການສະເໜີຕົວຮຽນຄືນ", {
                autoClose: 5000, // Customize hide time to 5 seconds (5000ms)
            });
        } catch (error) {
            toast.error("ລະບົບມີບັນຫາ ບັນທືກໄດ້", {
                autoClose: 5000, // Customize hide time to 5 seconds (5000ms)
            });

        } finally {
            setIsSumitted(false);
        }

    }

    return (
        <Dialog open={open} onClose={() => handleCloseDialog()} fullWidth maxWidth="sm">
            <DialogTitle>
                <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "#1976d2" }}
                >
                    ເພີ່ມນັກສຶກສາທີ່ຕ້ອງການໂຈະ
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <FormControl fullWidth error={!!errors.assementYear}>
                    <InputLabel id="faculty-select-label">ເລືອກສົກທີ່ຈະກັບມາຮຽນ</InputLabel>
                    <Select
                        label="ເລືອກສົກທີ່ຕ້ອງການສຳເນົາໄປ"
                        onChange={(e) => {
                            setAssessmentYear(e.target.value);
                            setErrors((prev) => ({ ...prev, assessmentYear: "" })); // Clear error when selecting
                        }}
                        fullWidth
                    >
                        {AssesmentYears.map((year, i) => (
                            <MenuItem key={i} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.assementYear && (
                        <Typography variant="caption" color="error">
                            {errors.assementYear}
                        </Typography>
                    )}
                </FormControl>
                <FormControl fullWidth margin="dense" >
                    <InputLabel>ປີ</InputLabel>
                    <Select
                        label="ປີ"
                        value={data?.yearLevel}
                        // onChange={(e) => {
                        //     setYear(e.target.value);
                        //     // setErrors((prev) => ({ ...prev, year: "" })); // Clear error when selecting
                        // }}
                        disabled={true}
                    >
                        <MenuItem key={""} value={""}>
                            ເລືອກປີ
                        </MenuItem>
                        {generateYears(data?.yearLevel).map((item) => (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {/* {errors.year && (
                    <Typography variant="caption" color="error">
                        {errors.year}
                    </Typography>
                )} */}
                <FormControl fullWidth margin="dense" error={!!errors.classRoom}>
                    <InputLabel>ເລືອກຫ້ອງ</InputLabel>
                    <Select
                        label="ເລືອກຫ້ອງ"
                        value={classRoom}
                        onChange={(e) => {
                            setClassRoom(e.target.value);
                            setErrors((prev) => ({ ...prev, classRoom: "" })); // Clear error when selecting
                        }}
                    >
                        <MenuItem key={""} value={""}>
                            ເລືອກຫ້ອງ
                        </MenuItem>
                        {classRoomList.map((item, index) => (
                            <MenuItem key={index} value={item?._id}>
                                {item?.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {errors.classRoom && (
                    <Typography variant="caption" color="error">
                        {errors.classRoom}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button
                    sx={{ borderRadius: 3 }}
                    onClick={() => handleCloseDialog()}
                    variant="outlined"
                    color="secondary"
                >
                    ຍົກເລີກ
                </Button>
                <Button
                    sx={{ borderRadius: 3 }}
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={isSumitted}
                >
                    ເພີ່ມ
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DropBackModal
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useEffect, useState } from 'react'
import useReactRouter from "use-react-router";
import { formatDateDashDMY } from '../../common/super';
import { convertGenderNam, convertGenderNamEN } from '../../helpers/user';
import { Breadcrumb } from 'react-bootstrap';
import { CustomContainer } from '../../common';
import { fetchGet, fetchPutV2 } from '../../common/fetchAPI';
import { useSelector } from 'react-redux';
import AssignOneDialog from './AssignOneDialog';
import { toast } from 'react-toastify';

function EntranceStudentAssignOne() {
    const accessmentRedux = useSelector((state) => state.accessment.value);
    const { history, location } = useReactRouter();
    const { classRoom } = location?.state
    const [studentList, setStudentList] = useState([]);
    const [studentData, setStudentData] = useState();
    const [count, setCount] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        fetchStudentByRoom();
    }, []);

    const fetchStudentByRoom = async () => {
        const response = await fetchGet(
            `entrance/student?room=${classRoom?._id}&assessmentYear=${accessmentRedux}`
        );
        if (response) {
            setStudentList(response?.students)
            setCount(response?.total)
        };
    };

    const handleClose = () => setDialogOpen(false);
    const handleSelect = async (data) => {
        let bodyData = { ...data, studentId: studentData?._id }
        setIsSubmitted(true);
        try {
            const fetchAssign = await fetchPutV2("entrance/student/room/assign", bodyData);
            if (fetchAssign?.success) {
                toast.success("ຍ້າຍຫ້ອງສຳເລັດ", {
                    autoClose: 3000, // Customize hide time to 5 seconds (5000ms)
                });
                fetchStudentByRoom();
                handleClose();
            } else {
                throw new Error();
            }
        } catch (error) {
            console.log("error:", error?.response)
            toast.error("ລະບົບມີບັນຫາ ກະລຸນາລອງໃຫມ່", {
                autoClose: 3000, // Customize hide time to 5 seconds (5000ms)
            });
        } finally {
            setIsSubmitted(false);
        }
    };


    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item
                    href=""
                    onClick={() =>
                        history.push("/entrance")
                    }
                >
                    Entrance
                </Breadcrumb.Item>
                <Breadcrumb.Item active>ຈຳນວນນັກສຶກສາຂອງຫ້ອງ {classRoom?.name} </Breadcrumb.Item>
            </Breadcrumb>
            <CustomContainer>
                <Typography>ຈຳນວນນັກສຶກສາທັງໝົດ {count} ຄົນ</Typography>
                <TableContainer>
                    <Table sx={{ minWidth: 1200 }} size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow className="thead-primary">
                                <TableCell className="tablecell-head" width={70}>
                                    ລຳດັບ
                                </TableCell>
                                <TableCell className="tablecell-head" width={200}>
                                    ຊື່ ແລະ ນາມສະກຸນ
                                </TableCell>
                                <TableCell className="tablecell-head" width={150}>
                                    ລະຫັດຟອມນັກສຶກສາ
                                </TableCell>
                                <TableCell className="tablecell-head">
                                    ລະຫັດເຂົ້າຫ້ອງເສັງ
                                </TableCell>
                                <TableCell className="tablecell-head">ເບິໂທລະສັບ</TableCell>
                                <TableCell className="tablecell-head">ໝວດສາຍຮຽນ</TableCell>
                                <TableCell className="tablecell-head" width={150}>ສາຍຫລັກ</TableCell>
                                <TableCell className="tablecell-head" width={150}>ສາຍສຳຮອງ</TableCell>
                                <TableCell className="tablecell-head">ວັນທີ່ລົງທະບຽນ</TableCell>
                                <TableCell className="tablecell-head" width={70}>ຈັດການ</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {studentList.length > 0 &&
                                studentList.map((student, index) => (
                                    <TableRow key={student?._id}>
                                        <TableCell align="center">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell align="start">
                                            {
                                                !student?.firstNameL || student?.firstNameL === ""
                                                    ? convertGenderNamEN(student?.gender) +
                                                    " " +
                                                    student?.firstNameE +
                                                    " " +
                                                    student?.lastNameE
                                                    : convertGenderNam(student?.gender) +
                                                    " " +
                                                    student?.firstNameL +
                                                    " " +
                                                    student?.lastNameL
                                            }
                                        </TableCell>
                                        <TableCell align="center">{student?.code}</TableCell>
                                        <TableCell align="center">{student?.examCode}</TableCell>
                                        <TableCell align="center">
                                            {student?.phoneMobile}
                                        </TableCell>
                                        <TableCell align="center">
                                            {student?.group}
                                        </TableCell>
                                        <TableCell align="start">
                                            {student?.major?.name || ""}
                                        </TableCell>
                                        <TableCell align="start">
                                            {student?.major2?.name || ""}
                                        </TableCell>
                                        <TableCell align="center">
                                            {formatDateDashDMY(student?.createdAt)}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button
                                                color="primary"
                                                size="small"
                                                aria-label="Delete"
                                                variant='contained'
                                                onClick={() => {
                                                    setDialogOpen(true)
                                                    setStudentData(student)
                                                }}
                                            >
                                                ຍ້າຍຫ້ອງ
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CustomContainer>
            <AssignOneDialog
                open={dialogOpen}
                onClose={handleClose}
                onSelect={handleSelect}
                assessmentYear={accessmentRedux}
                student={studentData}
                isSubmitted={isSubmitted}
            />


        </div>
    )
}

export default EntranceStudentAssignOne
import React, { useState, useEffect } from "react"
import { Breadcrumb } from "react-bootstrap";
import useReactRouter from "use-react-router"
import Nav from "react-bootstrap/Nav"
import { NAV } from "../../../consts/index"
import { GRADE_UPGRADE_LIST, UPGRADE_HISTORY } from "../../../consts/router";


export default function UpgradeNav() {
  const { history, match } = useReactRouter()
  const url = match.url
  const defaultURL = url.split('/')[1]
  const [titleItem, setTitleItem] = useState("ລາຍການແກ້ເກຣດ")
  useEffect(() => {
    if (defaultURL === "grades-list/limit/20/skip/1") {
      setTitleItem("ຄະແນນທັງໝົດ");
    } else {
      setTitleItem("ລາຍການແກ້ເກຣດ")
    }
  }, [defaultURL])

  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push("/grades-index/1")}>
          <strong>ຄະແນນ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push("/grades-index/1")}>
          <strong>ເລືອກໝວດໝູ່</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <strong>ຄະແນນເທີມ3</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{titleItem}</Breadcrumb.Item>
      </Breadcrumb>
      <Nav variant="tabs" style={{paddingLeft: 10}}>
        <Nav.Item>
          <Nav.Link
            active={defaultURL === "grade-upgrade-list" ? true : false}
            //    href={`/grades-course-list/${pageNambers}`}
            onClick={() => { history.push(GRADE_UPGRADE_LIST); }}
          >
            ລາຍການລົງທະບຽນແກ້ເກຣດ
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
        </Nav.Item>
        <Nav.Link
          onClick={() => history.push(UPGRADE_HISTORY)}
          active={defaultURL === "upgrade-history" ? true : false}
        >
          ປະຫວັດການແກ້ເກຣດ
        </Nav.Link>
        {/* <Nav.Item>
          <Nav.Link
            onClick={() => history.push(`/upgrade-student-history/${pageNambers}`)}
            active={defaultURL == "upgrade-student-history" ? true : false}
          >
            ປະຫວັດການສົ່ງ
          </Nav.Link>
        </Nav.Item> */}
      </Nav>
    </div>
  );
}
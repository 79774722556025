export const getPermissions = () => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      console.log("No user found in localStorage.");
      return [];
    }
    // const parsedUser = JSON.parse(user);
    const userGroup = user?.data?.userGroup;
    if (!userGroup) {
      console.log("No userGroup found in user data.");
      return [];
    }

    return userGroup?.permissionList || [];
  } catch (error) {
    console.error("Error parsing user data: ", error);
    return [];
  }
};

export const hasPermission = (permission, action) => {
  const permissions = getPermissions();
  // console.log("Available permissions:", permissions);

  // Check if permissions is an object and if permission exists
  if (permissions && typeof permissions === 'object' && permissions[permission]) {
    const actionPermissions = permissions[permission];

    // Check if the action exists and if its value is true
    if (actionPermissions[action] && actionPermissions[action].value) {
      // console.log("true", actionPermissions[action].value);
      return true;
    }
  }

  // Log and return false if permission or action is not found or value is false
  console.warn(`Permission "${permission}" or action "${action}" is not granted.`);
  return false;
};
import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_KEY, NEW_API_URL } from "../../../common/contrant";

const AddClassFilter = ({
  onSelected,
  disableFact,
  factId,
  disableDep,
  depId,
  errors
}) => {
  const [facultyList, setFacultyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const [selectedFaculty, setSelectedFaculty] = useState(factId);
  const [selectedMajor, setSelectedMajor] = useState("");
  const [majorList, setMajorList] = useState([]);

  useEffect(() => {
    setSelectedDepartment(depId);
    setSelectedFaculty(factId);
  }, [factId, depId]);

  const handleFacultyChange = (event) => {
    setSelectedFaculty(event.target.value);
  };
  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };
  
  const handleMajorChange = (event) => {
    const selectedId = event.target.value;
    setSelectedMajor(selectedId); // Assuming you have state for selectedMajor
  };

  const fetchFaculty = async () => {
    try {
      const response = await axios.get(NEW_API_URL + "/faculty", {
        headers: {
          api_key: API_KEY,
        },
      });
      if (response.data.success) {
        setFacultyList(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching faculty:", error);
    }
  };

  const fetchDepartment = async () => {
    if (selectedFaculty) {
      try {
        const response = await axios.get(
          NEW_API_URL + "/department/" + selectedFaculty,
          {
            headers: {
              api_key: API_KEY,
            },
          }
        );
        if (response.data.success) {
          setDepartmentList(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching department:", error);
      }
    }
  };

  const fetchMajor = async () => {
    if (selectedDepartment) {
      try {
        const response = await axios.get(
          NEW_API_URL + "/getmajo/" + selectedDepartment,
          {
            headers: {
              api_key: API_KEY,
            },
          }
        );
        if (response.data.success) {
          setMajorList(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching major:", error);
      }
    }
  };

  useEffect(() => {
    // if department is exist in user role then we
    // can not select faculty or department
    if (!disableDep) setSelectedDepartment("");
    setSelectedMajor("");
    fetchDepartment();
    fetchMajor();
  }, [selectedFaculty]);

  useEffect(() => {
    setSelectedMajor("");
    fetchMajor();
  }, [selectedDepartment]);

  useEffect(() => {
    fetchFaculty();
  }, []);

  useEffect(() => {
    onSelected({
      faculty: selectedFaculty,
      department: selectedDepartment,
      major: selectedMajor,
    });
  }, [
    selectedFaculty,
    selectedDepartment,
    selectedMajor,
  ]);

  return (
    <div className="">
      <div className="row">
        <div className="col-lg-3 col-md-4 col-sm-6">
          <div className="form-group">
            <label>ຄະນະວິຊາ</label>
            <select
              disabled={disableFact}
              className="form-select form-select-md mb-3 "
              aria-label=".form-select-lg example"
              value={selectedFaculty}
              onChange={handleFacultyChange}
            >
              <option value="">ເລືອກຄະນະວິຊາ</option>
              {facultyList.map((faculty) => (
                <option key={faculty._id} value={faculty._id}>
                  {faculty.name}
                </option>
              ))}
            </select>
            {errors.faculty && <div className="text-danger">{errors.faculty}</div>}
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6">
          <div className="form-group">
            <label>ພາກວິຊາ</label>
            <select
              disabled={disableDep}
              className="form-select form-select-md mb-3 "
              aria-label=".form-select-lg example"
              value={selectedDepartment}
              onChange={handleDepartmentChange}
            >
              <option value="">ເລືອກພາກວິຊາ</option>
              {departmentList.map((department) => (
                <option
                  key={department._id}
                  value={department._id}
                  disabled={department._id === selectedDepartment}
                >
                  {department.name}
                </option>
              ))}
            </select>
            {errors.department && <div className="text-danger">{errors.department}</div>}
          </div>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-6">
          <div className="form-group">
            <label>ສາຂາວິຊາ</label>
            <select
              className="form-select form-select-md mb-3 "
              aria-label=".form-select-lg example"
              value={selectedMajor}
              onChange={handleMajorChange}
            >
              <option value="">ເລືອກສາຂາວິຊາ</option>
              {majorList.map((major) => (
                <option key={major._id} value={major._id}>
                  {major.name}
                </option>
              ))}
            </select>
            {errors.major && <div className="text-danger">{errors.major}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClassFilter;

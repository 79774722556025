import { NEW_API_URL, API_KEY } from "../../common/contrant";
import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Button,
  Typography,
  IconButton,
  Box,
  FormLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { useSelector } from "react-redux";
import { USER_KEY } from "../../consts";
import Routers from "../../consts/router";
import Consts from "../../consts";
import { ROLE_LIST } from "../../common/GetRoleDescription";
import getRoleDescription from "../../common/GetRoleDescription";
import CustomMessage from "../../common/CustomStatusMessage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { CustomContainer } from "../../common";
import useReactRouter from "use-react-router";
import { convertGender, convertRole } from "../../helpers/user";
import { formatDateDashDMY } from "../../common/super";
import { hasPermission } from "../../helpers/CheckPermission";

const TeacherDetail = () => {
  const filterData = useSelector((state) => state.filter);
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const { history, location } = useReactRouter();
  const token = userObject?.accessToken;
  const [previewImageURL, setPreviewImageURL] = useState("");
  const inputImage = useRef("inputImage");
  const userId = location?.state;
  const [userData, setUserData] = useState(null);
  // const addImage = () => {
  //   inputImage.current.click();
  // };
  useEffect(() => {
    axios
      .get(`${NEW_API_URL}users/${userId}`, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => setUserData(response?.data || []))
      .catch((error) => console.error(error));
  }, [userId]);

  const handleChangeImage = (event) => {
    if (event.target.files[0]) {
      setPreviewImageURL(URL.createObjectURL(event.target.files[0]));
      // setCoverImage(event.target.files[0]);
    }
  };

  const handleEdit = () => {
    localStorage.removeItem("generalInfo");
    localStorage.removeItem("placeOfBirthInfo");
    localStorage.removeItem("statisticInfo");
    history.push(Routers.TEACHER_ADD, userData);
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item
          href=""
          onClick={() =>
            history.push(Routers.TEACHER_LIST + Routers.PAGE_GINATION)
          }
        >
          ຈັດການອາຈານ
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href=""
          onClick={() =>
            history.push(Routers.TEACHER_LIST + Routers.PAGE_GINATION)
          }
        >
          ອາຈານທັງໝົດ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ລາຍລະອຽດຂໍ້ມູນອາຈານ</Breadcrumb.Item>
      </Breadcrumb>

      <CustomContainer>
        <div>
          <div className="d-flex justify-content-between">
            <div>
              <h4 style={{ fontWeight: "bold" }}>
                ລາຍລະອຽດຂໍ້ມູນ ພ/ງ ຄູ-ອາຈານ
              </h4>
            </div>
            <div>
              {hasPermission("USER", "edit") && (
                <Button variant="contained" color="info" onClick={handleEdit}>
                  <EditIcon /> ແກ້ໄຂ
                </Button>
              )}
            </div>
          </div>
          <Row className="d-flex" sm={12}>
            <Col sm={3}>
              <Box
                width={170}
                height={170}
                sx={{
                  position: "relative",
                  backgroundColor: "lightgray",
                  border: "1px dashed grey",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={previewImageURL ? previewImageURL : "/empty-image64.png"}
                  alt="teacher image"
                />
              </Box>
              <input
                type="file"
                id="inputImage"
                multiple
                name="image"
                ref={inputImage}
                style={{ display: "none" }}
                onChange={handleChangeImage}
              />
              <div
                style={{
                  width: 170,
                  marginTop: "2px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></div>
            </Col>
            <Col sm={5}>
              <div style={{ backgroundColor: "#fff", marginTop: 20 }}>
                {/* Form container */}
                <div>
                  <h4 style={{ fontWeight: "bold" }}>ຂໍ້ມູນທົ່ວໄປ</h4>
                  <p>
                    ຊື່*:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.firstname}
                    </span>
                  </p>
                  <p>
                    ນາມສະກຸນ*:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.lastname}
                    </span>
                  </p>
                  <p>
                    ເພດ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {convertGender(userData?.gender)}
                    </span>
                  </p>
                  <p>
                    ວັນ ເດືອນ ປີ ເກີດ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {formatDateDashDMY(userData?.dateOfBirth)}
                    </span>
                  </p>
                  <p>
                    ສັນຊາດ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.nationality}
                    </span>
                  </p>
                  <p>
                    ຊົນເຜົ່າ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.tribe?.name}
                    </span>
                  </p>
                  <p>
                    ສາສະໜາ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.religion?.name}
                    </span>
                  </p>
                  <p>
                    ເບີໂທ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.phone}
                    </span>
                  </p>
                  <p>
                    email:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.email}
                    </span>
                  </p>
                  <p>
                    ອື່ນໆ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.emailOther}
                    </span>
                  </p>
                  <p>
                    ຄະນະ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.faculty?.name}
                    </span>
                  </p>
                  <p>
                    ສັງກັດຢູ່ພາກ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.department?.name}
                    </span>
                  </p>
                  <p>
                    ສິດການນໍາໃຊ້ລະບົບ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {convertRole(userData?.role)}
                    </span>
                  </p>
                  <p>
                    ກຸ່ມຜູ້ໃຊ້ລະບົບ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.userGroup?.title}
                    </span>
                  </p>
                  <p>
                    ເລກບັນຊີຜູ້ໃຊ້ :
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.userId}
                    </span>
                  </p>
                  <p>
                    ລະຫັດຜ່ານ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.password}
                    </span>
                  </p>
                  <h4 style={{ fontWeight: "bold" }}>ຂໍ້ມູນສະຖິຕິ</h4>
                  <p>
                    ເລືອກປະເພດຕາມຊັ້ນ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.name}
                    </span>
                  </p>
                  <p>
                    ສະຖານະ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.positionStatus}
                    </span>
                  </p>
                  <p>
                    ສັງກັດລັດ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {formatDateDashDMY(userData?.dateStartWork)}
                    </span>
                  </p>
                  <p>
                    ສຶກສາສາມັນ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.secondaryYear}
                    </span>
                  </p>
                  <p>
                    ວຸດທິການສຶກສາ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.educationDegree}
                    </span>
                  </p>
                  <p>
                    ສາຂາວິຊາຈົບ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.graduatedMajor}
                    </span>
                  </p>
                  <p>
                    ວັນທິີເດືອນປີ ຍົກລະດັບການສຶກສາ:
                    <span
                      style={{
                        color: Consts.PRIMARY_COLOR,
                        marginLeft: 10,
                        marginLeft: 10,
                      }}
                    >
                      {formatDateDashDMY(userData?.startDateEnchance)}
                    </span>
                  </p>
                  <p>
                    ວັນທີເດືອນປີ ສຳເລັດການສຶກສາ:
                    <span
                      style={{
                        color: Consts.PRIMARY_COLOR,
                        marginLeft: 10,
                        marginLeft: 10,
                      }}
                    >
                      {formatDateDashDMY(userData?.endDateEnchance)}
                    </span>
                  </p>
                  <p>
                    ຈົບຈາກປະເທດ:
                    <span
                      style={{
                        color: Consts.PRIMARY_COLOR,
                        marginLeft: 10,
                        marginLeft: 10,
                      }}
                    >
                      {userData?.graudatedCountry}
                    </span>
                  </p>
                  <p>
                    ຮຽນຕໍ່ລະດັບ ປ... ສາຂາວິຊາ:
                    <span
                      style={{
                        color: Consts.PRIMARY_COLOR,
                        marginLeft: 10,
                        marginLeft: 10,
                      }}
                    >
                      {userData?.enchanceLevel}
                    </span>
                  </p>
                  <p>
                    ທິດສະດີການເມືອງ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.civilLecture}
                    </span>
                  </p>
                  <p>
                    ວັນເດືອນປີແຕ່ງຕັ້ງວິຊາການ:
                    <span
                      style={{
                        color: Consts.PRIMARY_COLOR,
                        marginLeft: 10,
                        marginLeft: 10,
                      }}
                    >
                      {formatDateDashDMY(userData?.positionAdministerNameDate)}
                    </span>
                  </p>
                  <p>
                    ຕໍາແໜ່ງວິຊາການ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.degree}
                    </span>
                  </p>
                  <p>
                    ຕຳແໜ່ງບໍລິຫານ:
                    <span
                      style={{
                        color: Consts.PRIMARY_COLOR,
                        marginLeft: 10,
                        marginLeft: 10,
                      }}
                    >
                      {userData?.positionAdminister}
                    </span>
                  </p>
                  <p>
                    ຊື່ ຕຳແໜ່ງບໍລິຫານ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.positionAdministerName}
                    </span>
                  </p>
                  <p>
                    ວັນເດືອນປີແຕ່ງຕັ້ງຕໍາແໜ່ງບໍລິຫານ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {formatDateDashDMY(userData?.positionAdministerNameDate)}
                    </span>
                  </p>
                  <p>
                    ພາກສ່ວນຫ້ອງການສຳນັການ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.positionOffice}
                    </span>
                  </p>
                  <p>
                    ປະເທດຈົບ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {userData?.followCountry}
                    </span>
                  </p>
                  <p>
                    ວັນເດືອນປີ ຕິດຕາມຄອບຄົວ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {formatDateDashDMY(userData?.dateFamilyFollow)}
                    </span>
                  </p>
                  <p>
                    ວັນເດືອນປີກັບມາຈາກການຕິດຕາມຄ/ຄ:
                    <span
                      style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}
                    >
                      {formatDateDashDMY(userData?.dateFamilyFollowBack)}
                    </span>
                  </p>
                  <div >
                    <label>
                      <input type="checkbox" checked={!!userData?.womenAssociation} readOnly className="mr-2" />
                      ເປັນສະຫະພັນແມ່ຍິງ
                    </label>
                    <br />
                    <label>
                      <input type="checkbox" checked={!!userData?.partyAssociation} readOnly className="mr-2" />
                      ເປັນສະມາຊິກພັກ
                    </label>
                    <br />
                    <label>
                      <input type="checkbox" checked={!!userData?.commiteeAssociation} readOnly className="mr-2" />
                      ເປັນກຳມະບານ
                    </label>
                    <br />
                    <label>
                      <input type="checkbox" checked={!!userData?.youthAssociation} readOnly className="mr-2" />
                      ເປັນຊາວໜຸ່ມ
                    </label>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <h4 style={{ fontWeight: "bold" }}>ສະຖານທີ່ເກີດ</h4>
              <p>
                ແຂວງເກີດ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {userData?.provinceBirth}
                </span>
              </p>
              <p>
                ເມືອງເກີດ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {userData?.districtBirth}
                </span>
              </p>
              <p>
                ບ້ານເກີດ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {userData?.villageBirth}
                </span>
              </p>
              <p>
                ແຂວງປັດຈຸບັນ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {userData?.provinceNow}
                </span>
              </p>
              <p>
                ເມືອງປັດຈຸບັນ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {userData?.districtNow}
                </span>
              </p>
              <p>
                ບ້ານປັດຈຸບັນ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {userData?.villageNow}
                </span>
              </p>
            </Col>
          </Row>
        </div>
      </CustomContainer>
    </div>
  );
};

export default TeacherDetail;

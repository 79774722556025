import React, { useEffect, useState } from "react";
import PrereStudentFilter from "../../common/PrereStudentFilter";
import { getAllStudentRegister } from "./fetchData";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import GetAppIcon from "@mui/icons-material/GetApp";
import EditIcon from "@mui/icons-material/Edit";
import useReactRouter from "use-react-router";
import { onExport } from "./exportStudents";
import { convertGenderNam, convertGenderNamEN } from "../../helpers/user";
import { formatDateDashDMY } from "../../common/super";
import { useSelector } from "react-redux";
import BlockComponent from "../../common/BlockComponent";
import { hasPermission } from "../../helpers/CheckPermission";

export default function PrereStudentList({ status }) {
  const [filter, setFilter] = useState();
  const [students, setStudents] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { history } = useReactRouter();
  const accessmentRedux = useSelector((state) => state.accessment.value);

  useEffect(() => {
    setFilter((preData) => ({ ...preData, assessmentYear: accessmentRedux }));
  }, [accessmentRedux]);

  const [permissionDenied, setPermissionDenied] = useState(false);

  const fetchData = async () => {
    const data = await getAllStudentRegister(
      filter,
      page + 1,
      rowsPerPage,
      status
    );

    if (
      data?.status === "error" &&
      data?.message ===
        "Permission denied. You are not authorized to access this resource."
    ) {
      setPermissionDenied(true);
      return; // Stop further execution if permission is denied
    }

    setPermissionDenied(false); // Reset in case it was previously set to true
    setStudents(data?.data || []);
    setCount(data?.count || 0);
  };

  useEffect(() => {
    fetchData();
  }, [filter, rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const highlightText = (text, filter) => {
    if (!filter?.title) return text;

    const regex = new RegExp(`(${filter?.title})`, "gi");
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span
          key={index}
          style={{ backgroundColor: "#4895ef", color: "white" }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <div>
      {permissionDenied ? (
        <BlockComponent />
      ) : (
        <>
          <PrereStudentFilter
            onSelect={(filterData) => {
              setFilter(filterData);
            }}
          />
          <div className="d-flex justify-content-end m-0" alignItems="flex-end">
            <Typography
              sx={{
                marginRight: "20px",
                paddingTop: "6px",
              }}
            >
              {"ຈຳນວນທັງຫມົດ " + count}
            </Typography>
            <Button
              onClick={() => {
                onExport(students);
              }}
              variant="contained"
              color="success"
              startIcon={<GetAppIcon />}
            >
              Export
            </Button>
          </div>
          <Paper elevation={0} className="pb-3 pt-3">
            <TableContainer>
              <Table
                sx={{ minWidth: 1800 }}
                size="small"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow className="thead-primary">
                    <TableCell className="tablecell-head" width={70}>
                      ລຳດັບ
                    </TableCell>
                    <TableCell className="tablecell-head">ໄອດີ</TableCell>
                    <TableCell className="tablecell-head" width={200}>
                      ຊື່ ແລະ ນາມສະກຸນ
                    </TableCell>
                    <TableCell className="tablecell-head">ເບິໂທລະສັບ</TableCell>
                    <TableCell className="tablecell-head">ສາຂາ</TableCell>
                    <TableCell className="tablecell-head">
                      ຈົບຈາກໂຮງຮຽນ
                    </TableCell>
                    <TableCell className="tablecell-head">
                      ວັນທີ່ລົງທະບຽນ
                    </TableCell>
                    <TableCell className="tablecell-head">
                      ລົງທະບຽນຈາກ ມຊ
                    </TableCell>
                    <TableCell className="tablecell-head">
                      ລົງທະບຽນຈາກຄະນະ
                    </TableCell>
                    <TableCell className="tablecell-head">ໝາຍເຫດ</TableCell>
                    <TableCell className="tablecell-head">ຈັດການ</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students.length > 0 &&
                    students.map((student, index) => (
                      <TableRow key={student._id}>
                        <TableCell align="center">
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell align="start">
                          {highlightText(student?.studentNumber, filter)}
                        </TableCell>
                        <TableCell align="start">
                          {highlightText(
                            !student?.firstNameL || student?.firstNameL === ""
                              ? convertGenderNamEN(student?.gender) +
                                  " " +
                                  student?.firstNameE +
                                  " " +
                                  student?.lastNameE
                              : convertGenderNam(student?.gender) +
                                  " " +
                                  student?.firstNameL +
                                  " " +
                                  student?.lastNameL,
                            filter
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {highlightText(student?.phoneMobile + "", filter)}
                        </TableCell>
                        <TableCell align="start">
                          {student?.major?.name || ""}
                        </TableCell>
                        <TableCell align="start">
                          {student.graduateFromSchool || ""}
                        </TableCell>
                        <TableCell align="center">
                          {formatDateDashDMY(student?.updatedAt)}
                        </TableCell>
                        <TableCell align="center">
                          {student?.statusStep === "REQUEST" ? (
                            <span style={{ color: "#057CAE" }}>ຢືນຢັນແລ້ວ</span>
                          ) : student?.statusStep === "SEND_FORM" ? (
                            <span style={{ color: "#FF8800" }}>
                              ລໍຖ້າການຢືນຢັນ
                            </span>
                          ) : student?.statusStep === "APPROVED" ||
                            student?.statusStep === "ADMIN_APPROVED" ? (
                            <span style={{ color: "#1B8900" }}>
                              ລົງທະບຽນແລ້ວ
                            </span>
                          ) : student?.statusStep === "REJECTED" ? (
                            <span style={{ color: "#FF0000" }}>ຖືກປະຕິເສດ</span>
                          ) : null}
                        </TableCell>
                        <TableCell align="center">
                          {student?.statusStep === "REQUEST" ? (
                            <span style={{ color: "#057CAE" }}>ຢືນຢັນແລ້ວ</span>
                          ) : student?.statusStep === "SEND_FORM" ? (
                            <span style={{ color: "#FF8800" }}>
                              ລໍຖ້າການຢືນຢັນ
                            </span>
                          ) : student?.statusStep === "ADMIN_APPROVED" ? (
                            <span style={{ color: "#FF8800" }}>
                              ລໍຖ້າການຢືນຢັນ
                            </span>
                          ) : student?.statusStep === "APPROVED" ? (
                            <span style={{ color: "#1B8900" }}>
                              ລົງທະບຽນແລ້ວ
                            </span>
                          ) : student?.statusStep === "REJECTED" ? (
                            <span style={{ color: "#FF0000" }}>ຖືກປະຕິເສດ</span>
                          ) : null}
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            title={
                              student?.approveNoteDetails?.incorrectBirthday +
                              (student?.approveNoteDetails?.incorrectName
                                ? ", " +
                                  student?.approveNoteDetails?.incorrectName
                                : "") +
                              (student?.approveNoteDetails?.incorrectFile
                                ? ", " +
                                  student?.approveNoteDetails?.incorrectFile
                                : "") +
                              (student?.approveNoteDetails?.remark
                                ? ", " + student?.approveNoteDetails?.remark
                                : "")
                            }
                          >
                            {student?.approveNote === true ? (
                              <Button color="error">
                                {student?.approveNoteDetails?.incorrectBirthday}
                                {student?.approveNoteDetails?.incorrectName
                                  ? ", " +
                                    student?.approveNoteDetails?.incorrectName
                                  : ""}
                                {student?.approveNoteDetails?.incorrectFile
                                  ? ", " +
                                    student?.approveNoteDetails?.incorrectFile
                                  : ""}
                                {student?.approveNoteDetails?.remark
                                  ? ", " + student?.approveNoteDetails?.remark
                                  : ""}
                              </Button>
                            ) : (
                              <Button color="error">{student?.comment}</Button>
                            )}
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            color="primary"
                            size="small"
                            aria-label="Delete"
                            onClick={() => {
                              history.push(
                                "/preregistration_student-detail",
                                student
                              );
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TablePagination
                  rowsPerPageOptions={[
                    10,
                    20,
                    50,
                    { label: "ທັງຫມົດ", value: count },
                  ]}
                  colSpan={10} // Adjusted colSpan to include the Actions column
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  elevation={0}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
    </div>
  );
}

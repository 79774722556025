import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Tooltip,
    Paper,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import useReactRouter from "use-react-router";
import { Edit, Delete, RemoveRedEye } from "@mui/icons-material";
import ConfirmModal from "../../../common/ConfirmDialog";
import { fetchGet, fetchDelete } from "../../../common/fetchAPI";
import { USER_KEY } from "../../../consts";
import AddIcon from "@mui/icons-material/Add";
import { hasPermission } from "../../../helpers/CheckPermission";
import AddModal from "./addModal";
import { toast } from "react-toastify";
import Routers from "../../../consts/router";
import { Breadcrumb } from "react-bootstrap";
import { CustomContainer } from "../../../common";

const PlaceStudyList = () => {
    const { history } = useReactRouter();
    const user = localStorage.getItem(USER_KEY);
    const userObject = JSON.parse(user);
    const role = userObject?.data?.role;
    const [pleceStudy, setPlaceStudy] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [editData, setEditData] = useState();
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [facultyList, setFacultyList] = useState([]);
    const [selectedFaculty, setSelectedFaculty] = useState(role === "ADMIN" ? "" : userObject?.data?.faculty?._id);

    const fetchFaculty = async () => {
        try {
            const response = await fetchGet("/faculty");
            if (response.success) {
                setFacultyList(response?.data);
            }
        } catch (error) {
            console.error("Error fetching faculty:", error);
        }
    };

    const fetchPlaceStudy = async () => {
        const response = await fetchGet("placestudy?faculty=" + selectedFaculty);
        if (response?.data) {
            setPlaceStudy(response?.data);
        }
    };

    useEffect(() => {
        fetchFaculty();
        fetchPlaceStudy();
    }, []);

    useEffect(() => {
        fetchPlaceStudy();
    }, [selectedFaculty]);

    const handleOnCancel = () => {
        setShowAddModal(false);
        setEditData();
    };

    const handleEdit = (data) => {
        setShowAddModal(true);
        setEditData(data);
    };

    const handleDelete = async () => {
        if (selectedGroupId) {
            const response = await fetchDelete(`/placestudy/${selectedGroupId}`);
            if (response) {
                toast.success("ລົບສະຖານທີ່ຮຽນສຳເລັດ", {
                    autoClose: 3000, // Customize hide time to 5 seconds (5000ms)
                });
                fetchPlaceStudy();
                // Refresh the user group list after deletion
            } else {
                toast.error("ລະບົບມີບັນຫາ ລົບສະຖານທີ່ຮຽນບໍ່ສຳເລັດ", {
                    autoClose: 3000, // Customize hide time to 5 seconds (5000ms)
                });
            }
            setConfirmDelete(false);
        }
    };

    const handleFacultyChange = (event) => {
        setSelectedFaculty(event.target.value);
    };

    const handleDeleteConfirmation = (id) => {
        setSelectedGroupId(id);
        setConfirmDelete(true);
    };

    return (
        <Box>
            <Breadcrumb>
                <Breadcrumb.Item href="" onClick={() => history.push("/setting-list")}>
                    ຕັ້ງຄ່າ
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    ຈັດການສະຖານທີ່ຮຽນ
                </Breadcrumb.Item>
            </Breadcrumb>
            <CustomContainer
                style={{
                    height: "100vh",
                }}
            >
                <AddModal
                    open={showAddModal}
                    onCancel={handleOnCancel}
                    setEditData={setEditData}
                    editData={editData}
                    fetchPlaceStudy={fetchPlaceStudy}
                />

                {/* Confirmation Modal */}
                <ConfirmModal
                    title={"ຢືນຢັນການລົບສະຖານທີ່ຮຽນ?"}
                    open={confirmDelete}
                    onSubmit={handleDelete}
                    onCancel={() => setConfirmDelete(false)}
                />

                {/* User Group Table */}

                <Typography variant="h5" sx={{ p: 2 }}>
                    ລາຍການສະຖານທີ່ຮຽນ
                </Typography>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: 2,
                        mb: 3,
                    }}
                >
                    <FormControl sx={{ minWidth: 300 }} size="small">
                        <InputLabel id="faculty-select-label">ຄະນະທັງຫມົດ</InputLabel>
                        <Select
                            disabled={role !== "ADMIN"}
                            label="ຄະນະທັງຫມົດ"
                            value={selectedFaculty}
                            onChange={handleFacultyChange}
                        >
                            <MenuItem value="">
                                <span>ຄະນະທັງຫມົດ</span>
                            </MenuItem>
                            {facultyList.map((faculty) => (
                                <MenuItem key={faculty._id} value={faculty._id}>
                                    {faculty.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* {hasPermission('SETTING_PERMISSION', 'create') && ( */}
                    {role !== "ADMIN" &&
                        <Button
                            onClick={() => setShowAddModal(true)}
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                        >
                            ເພີ່ມສະຖານທີ່ຮຽນ
                        </Button>
                    }
                    {/* )} */}
                </Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{ color: "white", textAlign: "center", fontWeight: "bold" }}
                            >
                                ຊື່ສະຖານທີ່ຮຽນ
                            </TableCell>
                            <TableCell
                                sx={{ color: "white", textAlign: "center", fontWeight: "bold" }}
                            >
                                ຄະນະ
                            </TableCell>
                            <TableCell
                                sx={{ color: "white", textAlign: "center", fontWeight: "bold" }}
                            >
                                ຈຳນວນນັກສຶກສາທີ່ຮອງຮັບ
                            </TableCell>
                            <TableCell
                                sx={{ color: "white", textAlign: "center", fontWeight: "bold" }}
                            >
                                ລີ້ງແຜ່ນທີ່
                            </TableCell>
                            <TableCell
                                sx={{ color: "white", textAlign: "center", fontWeight: "bold" }}
                            >
                                ວັນທີສ້າງ
                            </TableCell>
                            {/* {hasPermission('SETTING_PERMISSION', 'edit') || hasPermission('SETTING_PERMISSION', 'delete') ? */}
                            {role !== "ADMIN" &&
                                <TableCell
                                    sx={{
                                        color: "white",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    }}
                                >
                                    ຈັດການ
                                </TableCell>
                            }
                            {/*  : null
                         } */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pleceStudy.length > 0 &&
                            pleceStudy.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        sx={{
                                            textAlign: "center",
                                        }}
                                    >
                                        {row?.title}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            textAlign: "center",
                                        }}
                                    >
                                        {row?.faculty?.name}
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            textAlign: "center",
                                        }}
                                    >
                                        {row?.amount === 0 ? null : row?.amount}
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            textAlign: "center",
                                        }}
                                    >
                                        {row?.url}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            textAlign: "center",
                                        }}
                                    >
                                        {new Date(row?.createdAt).toLocaleDateString()}
                                    </TableCell>
                                    {/* {hasPermission('SETTING_PERMISSION', 'edit') || hasPermission('SETTING_PERMISSION', 'delete') ? */}
                                    {role !== "ADMIN" &&
                                        <TableCell sx={{ textAlign: "center" }}>
                                            {/* {hasPermission('SETTING_PERMISSION', 'edit') ? */}
                                            <Tooltip title="View">
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => history.push(Routers.PLACE_STUDY_DETAIL, row)}
                                                >
                                                    <RemoveRedEye />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Edit">
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => handleEdit(row)}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                            {/* : null
                                        }
                                        {hasPermission('SETTING_PERMISSION', 'delete') ? */}
                                            <Tooltip title="Delete">
                                                <IconButton
                                                    color="error"
                                                    onClick={() => handleDeleteConfirmation(row._id)}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                            {/* : null
                                        } */}
                                        </TableCell>
                                    }
                                    {/* : null
                                } */}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </CustomContainer>
        </Box>
    );
};

export default PlaceStudyList;

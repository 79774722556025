import React, { useEffect, useState } from 'react'
import { CustomContainer } from '../../../common'
import UpgradeNav from '../common/UpgradeNav'
import { USER_KEY } from '../../../consts';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchDelete, fetchGet } from '../../../common/fetchAPI';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { setFilter } from '../../../redux/filtrSlice';
import { convertGenderNam, convertGenderNamEN } from '../../../helpers/user';
import { upGradeTypeL } from '../../../common/super';
import RegisterUpgradeDialog from './RegisterUpgradeDialog';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../common/ConfirmDialog';

const GradeUpgradeList = () => {
    const user = localStorage.getItem(USER_KEY);
    const userObject = JSON.parse(user);

    const filterData = useSelector((state) => state.filter);
    const accessmentRedux = useSelector((state) => state.accessment.value);

    const dispatch = useDispatch();
    const [students, setStudents] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [majors, setMajors] = useState([]);
    const [classRooms, setClassRoom] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [count, setCount] = useState(0);
    const [formData, setFormData] = useState({
        faculty: filterData.faculty || "",
        department: filterData.department || "",
        major: filterData.major || "",
        year: "",
        assementYear: accessmentRedux,
        classRoom: "",
        type: "",
    });
    const [openDialog, setOpenDialog] = useState(false);
    const [upgradeId, setUpgradeId] = useState(null);
    const [showCancelConfirm, setShowCancelConfirm] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    // Fetch data from the API
    const fetchData = async () => {
        try {
            // Create a new URLSearchParams object and append parameters
            const apiUrl = `studentupgrade?limit=${rowsPerPage}&page=${page + 1}&faculty=${formData.faculty}&department=${formData.department}&major=${formData?.major}&year=${formData.year}&classroom=${formData.classRoom}&assessmentYear=${accessmentRedux}&type=${formData.type}`;
            const response = await fetchGet(apiUrl);
            setCount(response?.totalRecords || 0);
            setStudents(response?.data || []);
        } catch (error) {
            setStudents([]);
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [formData, rowsPerPage, page, accessmentRedux]); // Empty dependency array means this effect runs once on component mount

    const fetchFaculty = async () => {
        const _faculties = await fetchGet(`/faculty`);
        setFaculties(_faculties?.data || []);
    };
    const fetchDepartment = async () => {
        if (formData?.faculty) {
            const _department = await fetchGet(
                `/department?faculty=${formData?.faculty}&assessmentYear=${accessmentRedux}`
            );
            setDepartments(_department || []);
        }
    };
    const fetchMajor = async () => {
        const _major = await fetchGet("getmajo/" + formData.department)
        setMajors(_major?.data || []);
    };
    const fetchClass = async () => {
        const _classroom = await fetchGet("class/" + formData.major + "/" + formData.year)
        setClassRoom(_classroom?.data || []);
    };
    useEffect(() => {
        let _filterData = {};
        if (formData.faculty !== "") {
            _filterData.faculty = formData.faculty;
        }
        // Check and set department if not null
        if (formData.department !== "") {
            _filterData.department = formData.department;
        }

        // Check and set major if not null
        if (formData.major !== "") {
            _filterData.major = formData.major;
        }
        // Dispatch the setFilter action with the filterData payload
        dispatch(setFilter(_filterData));
        // Fetch faculties
        fetchFaculty()

        fetchDepartment();
        if (formData.department) {
            fetchMajor();
        }
        // Fetch majors
        if (formData.major && formData.year) {
            fetchClass();
        }
    }, [formData]);

    const getTeacherNames = (teachers) => {
        if (!teachers || teachers.length === 0) return "ບໍ່ມີອາຈານສອນ";
        return teachers.map(teacher =>
            `ທ່ານ ${teacher?.gender === "FEMALE" ? "ນາງ​ " : ""}${teacher?.firstname || ''}`
        ).join(", ");
    };

    const handleClose = () => {
        setOpenDialog(false);
    }

    const handleCancel = async (id) => {
        try {
            await fetchDelete(`studentupgrade/${id}`);
            toast.success("ຍົກເລີກນັກສຶກສາລົງທະບຽນແກ້ເກຣດສຳເລັດ");
            fetchData();
        } catch (error) {
            toast.error("ລະບົບມີບັນຫາ ຍົກເລີກບໍ່ສຳເລັດ");
        }
    };

    return (
        <div>
            <UpgradeNav />
            <CustomContainer style={{ marginTop: -8 }}>
                <Paper elevation={0} className="bg-white mt-1 pb-2">
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xs={3}>
                            <FormControl fullWidth size="small" margin="normal">
                                <InputLabel htmlFor="faculty">ຄະນະ</InputLabel>
                                <Select
                                    disabled={
                                        userObject?.data?.role === "FAC_ADMIN" ||
                                        userObject?.data?.role === "DEP_ADMIN"
                                    }
                                    label="ຄະນະ"
                                    name="faculty"
                                    value={formData.faculty || ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem key={""} value={""}>
                                        ທັງຫມົດ
                                    </MenuItem>
                                    {faculties.map((faculty) => (
                                        <MenuItem key={faculty._id} value={faculty._id}>
                                            {faculty.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth size="small" margin="normal">
                                <InputLabel htmlFor="department">ພາກ</InputLabel>
                                <Select
                                    disabled={userObject?.data?.role === "DEP_ADMIN"}
                                    label="ພາກ"
                                    name="department"
                                    value={formData.department || ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem key={""} value={""}>
                                        ທັງຫມົດ
                                    </MenuItem>
                                    {departments.map((department) => (
                                        <MenuItem key={department?._id} value={department?._id}>
                                            {department?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth size="small" margin="normal">
                                <InputLabel htmlFor="department">ສາຂາ</InputLabel>
                                <Select
                                    label="ສາຂາ"
                                    name="major"
                                    value={formData.major || ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem key={""} value={""}>
                                        ທັງຫມົດ
                                    </MenuItem>
                                    {majors.map((major) => (
                                        <MenuItem key={major._id} value={major._id}>
                                            {major.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth size="small" margin="normal">
                                <InputLabel htmlFor="faculty">ປີຮຽນ</InputLabel>
                                <Select
                                    size="small"
                                    label="ປີຮຽນ"
                                    name="year"
                                    value={formData.year}
                                    onChange={handleChange}
                                >
                                    <MenuItem key={""} value={""}>
                                        {"ທັງຫມົດ"}
                                    </MenuItem>
                                    {[1, 2, 3, 4, 5, 6].map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth size="small" margin="normal">
                                <InputLabel htmlFor="classRoom">ຫ້ອງຮຽນ</InputLabel>
                                <Select
                                    label="ຫ້ອງຮຽນ"
                                    name="classRoom"
                                    value={formData.classRoom}
                                    onChange={handleChange}
                                >
                                    <MenuItem key={""} value={""}>
                                        ທັງຫມົດ
                                    </MenuItem>
                                    {classRooms.map((classRoom) => (
                                        <MenuItem key={classRoom?._id} value={classRoom?._id}>
                                            {classRoom?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth size="small" margin="normal">
                                <InputLabel htmlFor="type">ປະເພດການແກ້ເກຣດ</InputLabel>
                                <Select
                                    label="ປະເພດການແກ້ເກຣດ"
                                    name="type"
                                    value={formData.type}
                                    onChange={handleChange}
                                >
                                    <MenuItem key={""} value={""}>
                                        ທັງຫມົດ
                                    </MenuItem>
                                    <MenuItem value="UPGRADE">
                                        ແກ້ເກຣດ
                                    </MenuItem>
                                    <MenuItem value="RETAKE">
                                        ຮຽນຄືນ
                                    </MenuItem>
                                    <MenuItem value="SUMMER">
                                        ຮຽນຊ່ວງພັກແລ້ງ
                                    </MenuItem>
                                    <MenuItem value="PAYMENT">
                                        ເໝົາຈ່າຍ
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </CustomContainer>

            <CustomContainer>
                <Box className="d-flex justify-content-between align-items-center mb-3">
                    <Typography variant="h6">ຈຳນວນທັງຫມົດ {count}</Typography>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => setOpenDialog(true)}
                    >
                        ລົງທະບຽນແກ້ເກຣດ
                    </Button>
                </Box>
                <Paper elevation={0} className="bg-white pb-3">
                    <TableContainer>
                        <Table sx={{ minWidth: 2300 }} size="small" aria-label="simple table">
                            <TableHead>
                                <TableRow className="thead-primary">
                                    <TableCell align="center"
                                        width={70}
                                        className="tablecell-head"
                                    >
                                        ລຳດັບ
                                    </TableCell>
                                    <TableCell align="center"
                                        width={150}
                                        className="tablecell-head"
                                    >
                                        ລະຫັດນັກສຶກສາ
                                    </TableCell>
                                    <TableCell align="center"
                                        width={150}
                                        className="tablecell-head"
                                    >
                                        ຊື່
                                    </TableCell>
                                    <TableCell align="center"
                                        width={150}
                                        className="tablecell-head"
                                    >
                                        ນາມສະກຸນ
                                    </TableCell>
                                    <TableCell align="center"
                                        width={200}
                                        className="tablecell-head"
                                    >
                                        ຄະນະ
                                    </TableCell>
                                    <TableCell align="center"
                                        width={200}
                                        className="tablecell-head"
                                    >
                                        ພາກ
                                    </TableCell>
                                    <TableCell align="center"
                                        width={200}
                                        className="tablecell-head"
                                    >
                                        ສາຂາ
                                    </TableCell>
                                    <TableCell align="center"
                                        className="tablecell-head"
                                        width={150}
                                    >
                                        ລະຫັດວິຊາ
                                    </TableCell>
                                    <TableCell align="center"
                                        className="tablecell-head"
                                        width={200}
                                    >
                                        ຊື່ວິຊາ
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className="tablecell-head"
                                    >
                                        ປີຮຽນ
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className="tablecell-head"
                                    >
                                        ພາກຮຽນ
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className="tablecell-head"
                                    >
                                        ຫ້ອງຮຽນ
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className="tablecell-head"
                                        width={150}
                                    >
                                        ອາຈານສອນ
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className="tablecell-head"
                                        width={150}
                                    >
                                        ປະເພດການແກ້ເກຣດ
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className="tablecell-head"
                                    >
                                        ເກຣດເດີມ
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className="tablecell-head"
                                        width={130}
                                    >
                                        ຈັດການ
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {students.length > 0 &&
                                    students.map((item, index) => (
                                        <TableRow key={item?._id}>
                                            <TableCell align="center">
                                                {index + 1}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                            >
                                                {item?.student?.studentNumber}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                            >
                                                {!item?.student?.firstNameL || item?.student?.firstNameL === ""
                                                    ? convertGenderNamEN(item?.student?.gender) +
                                                    " " +
                                                    item?.student?.firstNameE
                                                    : convertGenderNam(item?.student?.gender) +
                                                    " " +
                                                    item?.student?.firstNameL
                                                }
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                            >
                                                {item?.student?.lastNameL}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                            >
                                                {item?.student?.faculty?.name}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                            >
                                                {item?.student?.department?.name}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                            >
                                                {item?.student?.major?.name}
                                            </TableCell>
                                            <TableCell
                                                align="center"

                                            >
                                                {item?.course?.course?.subject?.code}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                            >
                                                {item?.course?.course?.subject?.title}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                            >
                                                {item?.year}
                                            </TableCell>
                                            <TableCell
                                                align="center"

                                            >
                                                {item?.course?.course?.semester}
                                            </TableCell>
                                            <TableCell
                                                align="center"

                                            >
                                                {item?.student?.classRoom?.name}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                            >
                                                <Tooltip title={getTeacherNames(item?.course?.teacher)} arrow>
                                                    <div style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        cursor: 'pointer'
                                                    }}>
                                                        {getTeacherNames(item?.course?.teacher)}
                                                    </div>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell
                                                align='center'
                                            >
                                                {upGradeTypeL(item?.type)}
                                            </TableCell>
                                            <TableCell
                                                align='center'
                                            >
                                                {item?.firstGrade}
                                            </TableCell>
                                            <TableCell
                                                align='center'
                                            >
                                                <Button
                                                    variant='outlined'
                                                    color='error'
                                                    onClick={() => {
                                                        setUpgradeId(item?._id);
                                                        setShowCancelConfirm(true);
                                                    }}
                                                >
                                                    ຍົກເລີກ
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>

                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50]}
                                colSpan={16} // Adjusted colSpan to include the Actions column
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                elevation={0}
                                SelectProps={{
                                    inputProps: { "aria-label": "rows per page" },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Table>
                    </TableContainer>
                </Paper>
            </CustomContainer>
            <RegisterUpgradeDialog
                open={openDialog}
                handleClose={handleClose}
                fetchData={fetchData}
            />
            <ConfirmModal
                onSubmit={() => {
                    setShowCancelConfirm(false);
                    handleCancel(upgradeId);
                }}
                onCancel={() => setShowCancelConfirm(false)}
                message={"ເມື່ອທ່ານກົດຢືນຢັນຂໍ້ມຸນການລົງທະບຽນຈະແມ່ນຖືກລືບ"}
                open={showCancelConfirm}
                title={"ຍົກເລີກການລົງທະບຽນ"}
            />
        </div>
    )
}

export default GradeUpgradeList
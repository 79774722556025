import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { NEW_API_URL, API_KEY } from "../../../common/contrant";
import { USER_KEY } from "../../../consts";
import axios from "axios";
import debounce from "lodash.debounce";
import AlertMessage from "../../../common/AlertMessage";
import ConfirmModal from "../../../common/ConfirmDialog";
import { fetchGet, fetchPost, fetchPut } from "../../../common/fetchAPI";
import useReactRouter from "use-react-router";
import { useLocation } from "react-router-dom";

const permissionList = {
  STUDENT: {
    label: "ຈັດການຂໍ້ມູນນັກສຶກສາ",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    delete: {
      label: "ລຶບ",
      value: true,
    },
    edit: {
      label: "ແກ້ໄຂ",
      value: true,
    },
    create: {
      label: "ສ້າງ",
      value: true,
    },
    export: {
      label: "Export excel",
      value: true,
    },
  },
  PRERE_STUDENT: {
    label: "ລົງທະບຽນຮຽນ",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    edit: {
      label: "ແກ້ໄຂ",
      value: true,
    },
    approve: {
      label: "ອະນຸມັດ",
      value: true,
    },
    reject: {
      label: "ປະຕິເສດ",
      value: true,
    },
    export: {
      label: "Export excel",
      value: true,
    },
  },
  CODE_STUDENT: {
    label: "ລະຫັດລົງທະບຽນເຂົ້າຮຽນ",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    delete: {
      label: "ລຶບ",
      value: true,
    },
    edit: {
      label: "ແກ້ໄຂ",
      value: true,
    },
    create: {
      label: "ສ້າງ",
      value: true,
    },
  },

  USER: {
    label: "ຂໍ້ມູນພະນັກງານ ແລະ ອາຈານ",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    delete: {
      label: "ລຶບ",
      value: true,
    },
    edit: {
      label: "ແກ້ໄຂ",
      value: true,
    },
    create: {
      label: "ສ້າງ",
      value: true,
    },
    resetpassword: {
      label: "ປ່ຽນລະຫັດຜ່ານ",
      value: true,
    },
    export: {
      label: "Export excel",
      value: true,
    },
  },
  COURSE: {
    label: "ຂໍ້ມຸນຫລັກສູດ (ວິຊາ, ຕາຕະລາງ)",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    delete: {
      label: "ລຶບ",
      value: true,
    },
    edit: {
      label: "ແກ້ໄຂ",
      value: true,
    },
    create: {
      label: "ສ້າງ",
      value: true,
    },
    export: {
      label: "Export excel",
      value: true,
    },
  },
  REGISTER_YEAR_ONE: {
    label: "ລົງທະບຽນນັກສຶກສາປີ 1",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    delete: {
      label: "ລຶບ",
      value: true,
    },
    register: {
      label: "ລົງທະບຽນ",
      value: true,
    },
  },
  REGISTER_YEAR_TWO_UP: {
    label: "ລົງທະບຽນນັກສຶກສາປີ 2 ຂຶ້ນ",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    register: {
      label: "ລົງທະບຽນ",
      value: true,
    },
    cancel: {
      label: "ຍົກເລີກ",
      value: true,
    },
    export: {
      label: "Export Excel",
      value: true,
    },
  },
  REGISTER_PAYMENT: {
    label: "ຈັດການການຈ່າຍເງິນ",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    register: {
      label: "ຊຳລະຄ່າຮຽນ",
      value: true,
    },
    export: {
      label: "Export Excel",
      value: true,
    },
  },
  EVALUATION: {
    label: "ການປະເມີນອາຈານ",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    delete: {
      label: "ລຶບ",
      value: true,
    },
    edit: {
      label: "ແກ້ໄຂ",
      value: true,
    },
    create: {
      label: "ສ້າງ",
      value: true,
    },
  },
  GRADE: {
    label: "ຈັດການຄະເເນນ",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    approve: {
      label: "ຢືນຢັນ",
      value: true,
    },
    reject: {
      label: "ປະຕິເສດ",
      value: true,
    },
  },
  E_LEARNING: {
    label: "ຮຽນອອນໄລນ",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    delete: {
      label: "ລຶບ",
      value: true,
    },
    edit: {
      label: "ແກ້ໄຂ",
      value: true,
    },
    create: {
      label: "ສ້າງ",
      value: true,
    },
    report: {
      label: "ລາຍງານ",
      value: true,
    },
  },
  GRADUATED: {
    label: "ຮຽນຈົບຊັ້ນ",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    approve: {
      label: "ຢືນຢັນ",
      value: true,
    },
  },
  STATISTIC: {
    label: "ສະຖິຕິ",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    export: {
      label: "Export Excel",
      value: true,
    },
  },
  FIXED_DATA: {
    label: "ຂໍ້ມູນຄົງທີ",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    delete: {
      label: "ລຶບ",
      value: true,
    },
    edit: {
      label: "ແກ້ໄຂ",
      value: true,
    },
    create: {
      label: "ສ້າງ",
      value: true,
    },
  },
  LOGS: {
    label: "ຂໍ້ມູນການໃຊ້ລະບົບ",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
  },
  PROMOTE_GRADE: {
    label: "ເລື່ອນຊັ້ນນັກສຶກສາ",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
  },
  PAYMENT_LIST: {
    label: "ຈັດການການຈ່າຍຄ່າຮຽນ",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    delete: {
      label: "ລຶບ",
      value: true,
    },
    edit: {
      label: "ແກ້ໄຂ",
      value: true,
    },
    create: {
      label: "ສ້າງ",
      value: true,
    },
  },
  TIME_STUDY: {
    label: "ຈັດການຊົ່ວໂມງຮຽນ",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    delete: {
      label: "ລຶບ",
      value: true,
    },
    edit: {
      label: "ແກ້ໄຂ",
      value: true,
    },
    create: {
      label: "ສ້າງ",
      value: true,
    },
  },
  ACCESMENTYEAR: {
    label: "ຈັດການການເປີດ - ປິດສົກຮຽນ",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    delete: {
      label: "ລຶບ",
      value: true,
    },
    edit: {
      label: "ແກ້ໄຂ",
      value: true,
    },
    create: {
      label: "ສ້າງ",
      value: true,
    },
  },
  ENTRANCE_STUDENT: {
    label: "ຂໍ້ມູນນັກສຶກສາ Entrance",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    approve: {
      label: "ຢືນຢັນ",
      value: true,
    },
    reject: {
      label: "ປະຕິເສດ",
      value: true,
    },
    delete: {
      label: "ລຶບ",
      value: true,
    },
    setting: {
      label: "ຈັດການການຕັ້ງຄ່າ Entrance",
      value: true,
    },
    export: {
      label: "Export Excel",
      value: true,
    },
  },
  SETTING_PERMISSION: {
    label: "ຈັດການກຸ່ມຜູ້ໃຊ້",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    delete: {
      label: "ລຶບ",
      value: true,
    },
    edit: {
      label: "ແກ້ໄຂ",
      value: true,
    },
    create: {
      label: "ສ້າງ",
      value: true,
    },
  },
  PAYMENT_ENTRANCE_LIST: {
    label: "ຈັດການການເງິນ Entrance",
    access: {
      label: "ການເຂົ້າເຖີງ",
      value: true,
    },
    read: {
      label: "ອ່ານ",
      value: true,
    },
    delete: {
      label: "ລຶບ",
      value: true,
    },
    edit: {
      label: "ແກ້ໄຂ",
      value: true,
    },
    create: {
      label: "ສ້າງ",
      value: true,
    },
  },
};

const MemoizedPermissionList = React.memo(
  ({ permissions, handleToggle }) => {
    return (
      <Grid container spacing={1}>
        {Object.entries(permissions).map(([category, details]) => {
          // if details is access set all switches disabled
          const isAccessDisabled = details.access && details.access.value === false;
          return (
            <Grid item xs={12} md={6} key={category}>
              <Paper
                elevation={3}
                sx={{
                  p: 4,
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {details.label}
                </Typography>
                <Box sx={{ mt: 1 }}>
                  {Object.entries(details).map(([action, actionDetails]) => {
                    if (typeof actionDetails === "object" && actionDetails.label) {
                      return (
                        <FormControlLabel
                          sx={{ mr: 4 }}
                          key={action}
                          control={
                            <Switch
                              checked={actionDetails.value}
                              onChange={() => handleToggle(category, action)}
                              disabled={isAccessDisabled && action !== "access"} // when access turned off set all of accept access
                            />
                          }
                          label={actionDetails.label}
                        />
                      );
                    }
                    return null;
                  })}
                </Box>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    );
  }
);


const PermissionList = () => {
  const { history } = useReactRouter();
  const [permissions, setPermissions] = useState(permissionList);
  const [facultyList, setFacultyList] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState("");
  const [title, setTitle] = useState("");
  const [confirmAdd, setConfirmAdd] = useState(false);
  const [role, setRole] = useState();
  const [titleError, setTitleError] = useState("");
  const location = useLocation();
  const { id } = location.state || {}; // Fallback if state is undefined

  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "error",
  });

  const fetchUserGroup = async () => {
    fetchFaculty();
    if (id) {
      const response = await fetchGet("usergroup/" + id);
      if (response) {
        setPermissions(response?.userGroup?.permissionList);
        setTitle(response?.userGroup?.title);
        setSelectedFaculty(response?.userGroup?.faculty?._id);
      }
    } else {
      setPermissions(permissionList);
      const user = localStorage.getItem(USER_KEY);
      const userObject = JSON.parse(user);
      setRole(userObject?.data?.role);
      setSelectedFaculty(userObject?.data?.faculty?._id);
    }
  };

  useEffect(() => {
    fetchUserGroup();
  }, [id]);

  const addUserGroup = async () => {
    const body = {
      permissionList: permissions,
      title: title,
      faculty: selectedFaculty,
    };
    let response;

    if (id) {
      response = await fetchPut("/usergroup/" + id, body);
    } else {
      response = await fetchPost("/usergroup", body);
    }
    if (response) {
      setActionStatus({
        message: id ? "ແກ້ໄຂກຸ່ມຜູ້ໃຊ້ສຳເລັດ" : "ເພີ່ມກຸ່ມຜູ້ໃຊ້ສຳເລັດ",
        type: "success",
        open: true,
      });
    } else {
      setActionStatus({
        message: id ? "ແກ້ໄຂກຸ່ມຜູ້ໃຊ້ມີບັນຫາ" : "ເພີ່ມກຸ່ມຜູ້ໃຊ້ມີບັນຫາ",
        type: "error",
        open: true,
      });
    }
    setConfirmAdd(false);
    window.location.href = "/setting-usergroup";
  };

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus({ ...actionStatus, open: false });
      }, 3000);
    }
  }, [actionStatus]);

  const fetchFaculty = async () => {
    try {
      const response = await axios.get(NEW_API_URL + "/faculty", {
        headers: {
          api_key: API_KEY,
        },
      });
      if (response.data.success) {
        setFacultyList(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching faculty:", error);
    }
  };

  const handleFacultyChange = (event) => {
    setSelectedFaculty(event.target.value);
  };

  // Debounced Title Change Handler
  const debouncedHandleTitleChange = debounce((value) => {
    setTitle(value);
  }, 100);

  const handleTitleChange = (event) => {
    const value = event.target.value;
    // setTitle(value); // Update state immediately
    setTitleError(""); // Clear any errors
    // Debounced logic for API calls or heavy tasks
    debouncedHandleTitleChange(value);
  };

  const handleToggle = (category, action) => {
    setPermissions((prevState) => {
      const currentValue = prevState[category][action]?.value;
      if (action === "access") {
        // If "access" is being turned OFF, set all values to false
        if (currentValue) {
          return {
            ...prevState,
            [category]: Object.fromEntries(
              Object.entries(prevState[category]).map(([key, val]) => [
                key,
                typeof val === "object" && val.hasOwnProperty("value")
                  ? { ...val, value: false }
                  : val
              ])
            ),
          };
        } else {
          // If "access" is being turned ON, set all values to true
          return {
            ...prevState,
            [category]: Object.fromEntries(
              Object.entries(prevState[category]).map(([key, val]) => [
                key,
                typeof val === "object" && val.hasOwnProperty("value")
                  ? { ...val, value: true }
                  : val
              ])
            ),
          };
        }
      }

      // Otherwise, toggle the specific action
      return {
        ...prevState,
        [category]: {
          ...prevState[category],
          [action]: {
            ...prevState[category][action],
            value: !currentValue,
          },
        },
      };
    });
  };


  const handleSave = () => {
    if (!title) {
      setTitleError("ກະລຸນາປ້ອນຊື່ກຸ່ມຜູ້ໃຊ້");
      return;
    }
    setConfirmAdd(true);
  };

  const handleCancel = () => {
    setPermissions(permissionList);
    setTitle("");
    setSelectedFaculty("");
    history.push("/setting-usergroup");
  };

  return (
    <Box sx={{ p: 3 }}>
      <AlertMessage
        open={actionStatus.open}
        message={actionStatus.message}
        type={actionStatus.type}
      />
      <ConfirmModal
        title={
          id ? "ຢືນຢັນການເເກ້ໄຂກຸ່ມຜູ້ໃຊ້ໃຫມ່?" : "ຢືນຢັນການເພີ່ມກຸ່ມຜູ້ໃຊ້ໃຫມ່"
        }
        open={confirmAdd}
        onSubmit={addUserGroup}
        onCancel={() => setConfirmAdd(false)}
      />

      {/* Faculty Selection and Title */}
      <Grid
        sx={{
          py: 4,
          backgroundColor: "white",
          px: 4,
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
        }}
        container
        spacing={1}
        mb={1}
      >
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <Typography variant="h4" gutterBottom>
              ຈັດການກຸ່ມຜູ້ໃຊ້
            </Typography>
          </FormControl>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            width: "100%",
            mb: 2,
          }}
        >
          <Button variant="contained" color="primary" onClick={handleSave}>
            ບັນທຶກ
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            ຍົກເລີກ
          </Button>
        </Box>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="faculty-select-label">ຄະນະ</InputLabel>
            <Select
              disabled={role !== "ADMIN"}
              label="ຄະນະ"
              value={selectedFaculty}
              onChange={handleFacultyChange}
              fullWidth
            >
              {facultyList.map((faculty) => (
                <MenuItem key={faculty._id} value={faculty._id}>
                  {faculty.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="ຊື່ກຸ່ມຜູ່ໃຊ້"
            // defaultValue={title}
            onChange={handleTitleChange}
            fullWidth
            value={title}
            error={!!titleError}
            helperText={titleError}
          />
        </Grid>
      </Grid>

      <MemoizedPermissionList
        permissions={permissions}
        handleToggle={handleToggle}
      />
    </Box>
  );
};

export default PermissionList;

import React, { useEffect, useState } from "react";
import { Box, Modal, Typography, Button } from "@mui/material";
import EntranceStudentFilter from "../../common/EntranceFilter";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { fetchGet, fetchPut } from "../../common/fetchAPI";
import { useSelector } from "react-redux";
import { convertGenderNam, convertGenderNamEN } from "../../helpers/user";
import { formatDateDashDMY } from "../../common/super";
import useReactRouter from "use-react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GetAppIcon from "@mui/icons-material/GetApp";
import AddIcon from "@mui/icons-material/Add";
import AlertMessage from "../../common/AlertMessage";
import Handsontable from "handsontable";
import { HotTable } from "@handsontable/react";
import CloseIcon from "@mui/icons-material/Close";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SaveIcon from "@mui/icons-material/Save";
import "handsontable/dist/handsontable.full.min.css";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { hasPermission } from "../../helpers/CheckPermission";

const onExport = (studentlist) => {
  let queue = 0;

  const StudentExport = studentlist.map((studentItem) => {
    queue++;
    return {
      ລຳດັບ: queue,
      "ຊື່ ແລະ ນາມສະກຸນ":
        studentItem?.firstNameL + " " + studentItem?.lastNameL,
      ເພດ: studentItem?.gender,
      "ສາຍຫລັກ (ຄະນະ)": studentItem?.faculty.name,
      "ສາຍຫລັກ (ພາກວິຊາ)": studentItem?.department.name,
      "ສາຍຫລັກ (ສາຂາ)": studentItem?.major.name,
      "ສາຍສຳຮອງ (ຄະນະ)": studentItem?.faculty2.name,
      "ສາຍສຳຮອງ (ພາກວິຊາ)": studentItem?.department2.name,
      "ສາຍສຳຮອງ (ສາຂາ)": studentItem?.major2.name,
      ລະຫັດເຂົ້າຫ້ອງເສັງ: studentItem?.examCode,
      ສະຖານທີເສັງ: studentItem?.place?.place,
      ຕຶກ: studentItem?.building?.building,
      ຫ້ອງເສັງ: studentItem?.room?.name,
      ເບີໂທລະສັບ: studentItem?.phoneMobile,
      ຫມວດສາຍຮຽນ: studentItem?.group,
      ວັນເດືອນປີເກີດ: studentItem?.birthday,
      ແຂວງປັດຈຸບັນ: studentItem?.address?.provice,
      ເມືອງປັດຈຸບັນ: studentItem?.address?.district,
      ບ້ານປັດຈຸບັນ: studentItem?.address?.village,
      ສັນຊາດ: studentItem?.nationality,
      ຊົນເຜົ່າ: studentItem?.tribe?.name,
      ຊື່ແມ່: studentItem?.motherNameAndSurname,
      ຊື່ພໍ່: studentItem?.contactName,
      ສະຖານະສຸຂະພາບ:
        studentItem?.healthStatus === "GOOD" ? "ແຂງແຮງດີ" : "ບໍ່ແຂງແຮງດີ",
    };
  });

  const ws = XLSX.utils.json_to_sheet(StudentExport);

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Student Data");

  const xlsxBuffer = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array",
    mimeType:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const blob = new Blob([new Uint8Array(xlsxBuffer)], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  saveAs(blob, "studentExport.xlsx");
};

const EntranceStudentList = ({ status }) => {
  const [filter, setFilter] = useState({});
  const [studentList, setStudentList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { history } = useReactRouter();
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const [showAddStudentExam, setShowAddStudentExam] = useState(false);
  const [tableData, setTableData] = useState([[]]);
  const [isLoading, setIsLoading] = useState(false);
  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    setFilter((preData) => ({
      ...preData,
      assessmentYear: accessmentRedux,
    }));
  }, [accessmentRedux]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const highlightText = (text, filter) => {
    if (!filter?.title) return text;

    const regex = new RegExp(`(${filter?.title})`, "gi");
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span
          key={index}
          style={{ backgroundColor: "#4895ef", color: "white" }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const markStudentPassed = async () => {
    const body = {
      students: tableData,
      major: filter.major,
      department: filter.department,
      faculty: filter.faculty,
    };
    setIsLoading(true);
    const response = await fetchPut("entrance/student/passed/", body);
    if (response) {
      setActionStatus({
        open: true,
        message: "ເພີ່ມນັກສຶກສາເສັ່ງຜ່ານສຳເລັດ",
        type: "success",
      });
    } else {
      setActionStatus({
        open: true,
        message: "ຂໍອະໄພ ລະບົບມີບັນຫາ",
        type: "error",
      });
    }
    await fetchData();
    setIsLoading(false);
  };

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus({ ...actionStatus, open: false });
      }, 3000);
    }
  }, [actionStatus]);

  const fetchData = async () => {
    const queryParams = {
      faculty: filter?.faculty,
      department: filter?.department,
      major: filter?.major,
      status: status,
      searchQuery: filter?.title,
      assessmentYear: filter?.assessmentYear,
      date: filter?.date,
      place: filter?.place,
      building: filter?.building,
      room: filter?.room,
    };

    const queryString = new URLSearchParams(queryParams).toString();

    const response = await fetchGet(
      `/entrance/student?${queryString}&page=${page + 1}&limit=${rowsPerPage}`
    );

    if (response?.success && response?.students) {
      setCount(response?.total);
      // setPage(response?.page);
      setStudentList(response?.students);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter, rowsPerPage, page]);

  const handleAddStudentExam = () => {
    setShowAddStudentExam(true);
  };

  const handleModalClose = () => {
    setShowAddStudentExam(false);
    setTableData([[]]);
  };

  const handlePasteClick = () => {
    navigator.clipboard.readText().then((clipboardData) => {
      const rows = clipboardData
        .split("\n")
        .filter((row) => row.trim())
        .map((row) => row.split("\t"));
      setTableData(rows);
    });
  };

  return (
    <Box>
      <EntranceStudentFilter
        status={status}
        onSelect={(data) => {
          setFilter(data);
        }}
      />
      <AlertMessage
        open={actionStatus.open}
        message={actionStatus.message}
        type={actionStatus.type}
      />
      <div
        className="d-flex py-3 bg-white justify-content-end m-0"
        alignItems="flex-end"
      >
        <Typography
          sx={{
            marginRight: "20px",
            paddingTop: "6px",
          }}
        >
          {"ຈຳນວນທັງຫມົດ " + count}
        </Typography>
        {status === "PASSED" && (
          <Button
            sx={{
              mr: 3,
            }}
            disabled={!filter?.faculty || !filter?.department || !filter?.major}
            onClick={handleAddStudentExam}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            ເພີ່ມນັກສຶກສາເສັງຜ່ານ
          </Button>
        )}
        {hasPermission("ENTRANCE_STUDENT", "export") && (
          <Button
            onClick={() => {
              onExport(studentList);
            }}
            variant="contained"
            color="success"
            startIcon={<GetAppIcon />}
          >
            Export
          </Button>
        )}
      </div>

      <Paper elevation={0} className="pb-3 pt-3">
        <TableContainer>
          <Table sx={{ minWidth: 1200 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow className="thead-primary">
                <TableCell className="tablecell-head" width={70}>
                  ລຳດັບ
                </TableCell>
                {status === "PASSED" && (
                  <TableCell className="tablecell-head py-3">
                    ລະຫັດນັກສຶກສາ
                  </TableCell>
                )}
                <TableCell className="tablecell-head py-3" width={200}>
                  ຊື່ ແລະ ນາມສະກຸນ
                </TableCell>
                <TableCell className="tablecell-head" width={200}>
                  ລະຫັດຟອມນັກສຶກສາ
                </TableCell>
                {(status === "APPROVED" || status === "PASSED") && (
                  <TableCell className="tablecell-head">
                    ລະຫັດເຂົ້າຫ້ອງເສັງ
                  </TableCell>
                )}
                <TableCell className="tablecell-head">ເບິໂທລະສັບ</TableCell>
                <TableCell className="tablecell-head" width={200}>
                  ສາຍຫລັກ
                </TableCell>
                <TableCell className="tablecell-head" width={200}>
                  ສາຍສຳຮອງ
                </TableCell>
                <TableCell className="tablecell-head">ວັນທີ່ລົງທະບຽນ</TableCell>
                {status === "REJECTED" && (
                  <TableCell className="tablecell-head">ຫມາຍເຫດ</TableCell>
                )}
                <TableCell className="tablecell-head">ຈັດການ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentList.length > 0 &&
                studentList.map((student, index) => (
                  <TableRow key={student._id}>
                    <TableCell align="center">
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    {status === "PASSED" && (
                      <TableCell>{student?.studentNumber}</TableCell>
                    )}
                    <TableCell align="start">
                      {highlightText(
                        !student?.firstNameL || student?.firstNameL === ""
                          ? convertGenderNamEN(student?.gender) +
                              " " +
                              student?.firstNameE +
                              " " +
                              student?.lastNameE
                          : convertGenderNam(student?.gender) +
                              " " +
                              student?.firstNameL +
                              " " +
                              student?.lastNameL,
                        filter
                      )}
                    </TableCell>
                    <TableCell align="center">{student?.code}</TableCell>
                    {(status === "APPROVED" || status === "PASSED") && (
                      <TableCell align="center">{student?.examCode}</TableCell>
                    )}
                    <TableCell align="center">
                      {highlightText(student?.phoneMobile + "", filter)}
                    </TableCell>
                    <TableCell align="start">
                      {student?.major?.name || ""}
                    </TableCell>
                    <TableCell align="start">
                      {student?.major2?.name || ""}
                    </TableCell>
                    <TableCell align="center">
                      {formatDateDashDMY(student?.createdAt)}
                    </TableCell>
                    {status === "REJECTED" && (
                      <TableCell className="tablecell-head">
                        <Typography
                          sx={{
                            color: "red",
                          }}
                        >
                          {student?.status?.reason}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell align="center">
                      <IconButton
                        color="primary"
                        size="small"
                        aria-label="Delete"
                        onClick={() => {
                          history.push("/entrance/" + student._id);
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TablePagination
              rowsPerPageOptions={[
                10,
                20,
                50,
                { label: "ທັງຫມົດ", value: count },
              ]}
              colSpan={document.querySelectorAll(".tablecell-head").length} //auto width with cell
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              elevation={0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Table>
        </TableContainer>
      </Paper>

      <Modal
        open={showAddStudentExam}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleModalClose();
          }
        }}
        aria-labelledby="add-student-exam-modal"
        aria-describedby="add-student-exam-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", md: 600 },
            maxHeight: "80vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 3,
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Typography variant="h6" fontWeight="bold" color="primary">
              ເພີ່ມນັກສຶກສາເສັງຜ່ານ
            </Typography>
            <Button onClick={handleModalClose}>
              <CloseIcon />
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography
              sx={{
                mt: 1,
                mr: 3,
              }}
            >
              ກົດປຸ່ມນີ້ເພື່ອວາງຂໍ້ມຸນນັກສຶກສາ
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileCopyIcon />}
              sx={{ mb: 3, borderRadius: 5 }}
              onClick={handlePasteClick}
            >
              ວາງ
            </Button>
          </Box>

          <HotTable
            data={tableData}
            colHeaders={["ລະຫັດເຂົ້າຫ້ອງເສັງ", "ລະຫັດນັກສຶກສາ"]}
            rowHeaders={true}
            stretchH="all" // Ensures full width
            autoColumnSize={false} // Disables auto-sizing for columns
            width="100%"
            height="300"
            licenseKey="non-commercial-and-evaluation"
            columns={[
              { data: 0, title: "ລະຫັດເຂົ້າຫ້ອງເສັງ" },
              { data: 1, title: "ລະຫັດນັກສຶກສາ" },
            ]}
            afterChange={(changes) => {
              if (changes) {
                const updatedData = [...tableData];
                changes.forEach(([row, prop, oldValue, newValue]) => {
                  updatedData[row][prop] = newValue;
                });
                setTableData(updatedData);
              }
            }}
            style={{
              border: "1px solid #e0e0e0",
              fontFamily: "Roboto, sans-serif",
            }}
          />

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              endIcon={
                isLoading ? <CircularProgress size={20} /> : <SaveIcon />
              }
              onClick={async () => {
                await markStudentPassed();
                handleModalClose();
              }}
              sx={{ mt: 3 }}
              disabled={isLoading} // Disable the button while loading
            >
              {isLoading ? "ກຳລັງປະມວນຜົນ..." : "ບັນທຶກ"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default EntranceStudentList;

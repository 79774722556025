import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Consts from "../../consts";
import { CustomContainer, TableHeader } from "../../common";
import * as _ from "lodash";
import { useLazyQuery } from "@apollo/react-hooks";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import {
  PREREGISTRATION_DEPARTMENT,
  PREREGISTRATION_STATISTIC,
} from "./apollo/query";
import { FACULTIES } from "../../apollo/faculty/index";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Loading from "../../common/Loading";
import useReactRouter from "use-react-router";
import Routers from "../../consts/router";
import { toDayDash } from "../../common/super";
import { FACULTY } from "../../common/facultyConstant";

export default function RegistrationStudentStatistic({ stdIdFilter }) {
  const { history } = useReactRouter();
  // var stdId = stdIdFilter == "ທັງໝົດ" ? "" : stdIdFilter;
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const [isAdmin, setIsAdmin] = useState();
  const [dataUser, setDataUser] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [facSqlId, setFacSqlId] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [userName, setUserName] = useState(false);
  const [facName, setFacName] = useState(false);
  const [statisticDATA, setStatisticDATA] = useState();

  const _handleShowConfirmModalClose = () => setShowConfirmModal(false);
  const _handleShowConfirmModalShow = () => setShowConfirmModal(true);

  const [loadDepartmentData, { data: departmentData }] = useLazyQuery(
    PREREGISTRATION_DEPARTMENT
  );

  const [
    loadStatisticData,
    { data: statisticData, loading: loadingStatistic },
  ] = useLazyQuery(PREREGISTRATION_STATISTIC);

  // console.log('statisticData', statisticData)

  const [loadFaculties, { data: facultyData }] = useLazyQuery(FACULTIES);
  // console.log('facultyData:', facultyData?.faculties)

  useEffect(() => {
    loadFaculties();
    loadDepartmentData();
    loadStatisticData({
      variables: {
        where: {
          assessmentYear: accessmentRedux,
          statusStep_in: [
            "APPROVED",
            "ADMIN_APPROVED",
            "REQUEST",
            "REJECTED",
            "SEND_FORM",
          ],
        },
      },
    });
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    // console.log('user', user)
    if (user) {
      if (user.data.role == "ADMIN") {
        let facSqlIndex = _.findIndex(FACULTY, {
          id: user?.data?.faculty?._id,
        });
        setFacSqlId("ALL_FACULTY");
        setIsAdmin(true);
        setUserName(
          user.data.firstname
            ? user.data.firstname
            : " " + " " + user.data.lastname
            ? user.data.lastname
            : ""
        );
        setFacName(facultyData?.faculties[facSqlIndex]?.name);
      } else {
        let facSqlIndex = _.findIndex(facultyData?.faculties, {
          id: user?.data?.faculty?._id,
        });
        setUserName(user?.data?.firstname + " " + user?.data?.lastname);
        setFacSqlId(facultyData?.faculties[facSqlIndex]?.id);
        setFacName(facultyData?.faculties[facSqlIndex]?.name);
        setIsAdmin(false);
      }
    }

    if (user) {
      setDataUser(user.data);
    } else {
      history.push(`/`);
    }
  }, [facultyData]);

  const departmentDATA = departmentData?.preregistrationStudentDepartment;
  // const statisticDATA = statisticData?.preregistrationStudentStatistics

  useEffect(() => {
    if (facSqlId)
      if (facSqlId == "ALL_FACULTY") {
        let qlWhere = {
          assessmentYear: accessmentRedux,
          statusStep_in: [
            "APPROVED",
            "ADMIN_APPROVED",
            "REQUEST",
            "REJECTED",
            "SEND_FORM",
          ],
        };
        // if (stdId != "") qlWhere = { ...qlWhere, semesterYear: stdId };
        loadDepartmentData({
          variables: { where: qlWhere },
        });
        loadStatisticData({
          variables: { where: qlWhere },
        });
      } else {
        let qlWhere = {
          assessmentYear: accessmentRedux,
          statusStep_in: [
            "APPROVED",
            "ADMIN_APPROVED",
            "REQUEST",
            "REJECTED",
            "SEND_FORM",
          ],
        };
        if (facSqlId) qlWhere = { ...qlWhere, faculty: { id: facSqlId } };
        // if (stdId) qlWhere = { ...qlWhere, semesterYear: stdId };
        loadDepartmentData({
          variables: { where: qlWhere },
        });
        loadStatisticData({
          variables: { where: qlWhere },
        });
      }
  }, [facSqlId, accessmentRedux]);

  useEffect(() => {
    let _newData = departmentDATA
      ?.filter((xindex) => {
        if (!isAdmin) return true;
        else {
          if (xindex >= 14) return false;
          else return true;
        }
      })
      .map((item) => {
        return {
          name: item?.name,
          ລົງທະບຽນແລ້ວ: item?.approvedCount,
          ຍັງບໍ່ລົງທະບຽນ: item?.requestCount,
        };
      });
    setGraphData(_newData);
  }, [departmentDATA]);

  useEffect(() => {
    let userRole = dataUser?.role;
    // check role
    if (userRole !== "ADMIN" && userRole !== "DEAN") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [dataUser]);

  useEffect(() => {
    setStatisticDATA(statisticData?.preregistrationStudentStatistics);
    // console.log('statistic, ',statisticData?.preregistrationStudentStatistics?.data)
  }, [statisticData]);
  // get faculty id
  const _selectFaculty = (e) => {
    setFacSqlId(e.target.value);
  };

  return (
    <div>
      {loadingStatistic && <Loading />}
      <CustomContainer style={{ marginTop: 0 }}>
        <Row>
          <Col sm={2}>
            <p>ຄະນະວິຊາ</p>
          </Col>
          <Col sm={6}>
            {/* {facSqlId && ( */}
            <Form.Control
              disabled={isAdmin}
              as="select"
              style={{ border: "none", backgroundColor: "#f1f1f1f1" }}
              onChange={(e) => _selectFaculty(e)}
              value={facSqlId}
            >
              <option value="ALL_FACULTY">ຄະນະທັງໝົດ </option>
              {facultyData?.faculties?.map((item, index) => {
                return (
                  <option key={"faculty" + index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Control>
            {/* )} */}
          </Col>
          <Col sm={3}>
            <button
              className="btn btn-outline-success"
              onClick={() => _handleShowConfirmModalShow()}
            >
              <i className="fa fa-file-excel"></i> Export Excel
            </button>
          </Col>
        </Row>
        {/* {departmentDATA?.length > 0 ? ( */}
        <div>
          <div
            style={{
              marginTop: 24,
              marginBottom: 8,
              fontSize: 16,
              color: Consts.FONT_COLOR_SECONDARY,
            }}
          >
            <Row style={{ paddingLeft: "10%", paddingLeft: "10%" }}>
              <BarChart
                width={900}
                height={300}
                data={graphData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 20,
                }}
              >
                <XAxis dataKey="name" hide={true} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="ລົງທະບຽນແລ້ວ" stackId="b" fill="#47A6E5" />
                <Bar dataKey="ຍັງບໍ່ລົງທະບຽນ" stackId="b" fill="#057CAE" />
              </BarChart>
            </Row>
          </div>
          {/* Table list */}
          <div>
            <Row>
              <div className="container" style={{ textAlign: "center" }}>
                <div className="listItem" style={{ textAlign: "center" }}>
                  <b>
                    {statisticDATA && statisticDATA?.total}
                    <hr /> ສະຖິຕິທັງໝົດ
                  </b>
                </div>
                <div className="listItem" style={{ textAlign: "center" }}>
                  <b>
                    {statisticDATA && statisticDATA?.approvedCounts}
                    <hr /> ລົງທະບຽນສຳເລັດ
                  </b>
                </div>
                <div className="listItem" style={{ textAlign: "center" }}>
                  <b>
                    {statisticDATA && statisticDATA?.requestCounts}
                    <hr />
                    ລົງທະບຽນບໍ່ສຳເລັດ
                  </b>
                </div>
              </div>
            </Row>
            {/* {(loading || called) && ""} */}
            {/* <Row>
                <Col sm={3}>
                  ສະແດງ {departmentData?.statistics.length} ໃນ{" "}
                  {departmentData?.statistics.length}
                </Col>
              </Row> */}
            <table
              className="table table-hover"
              style={{ width: "100%" }}
            >
              <thead>
                <TableHeader>
                  <th
                    style={{
                      backgroundColor: Consts.PRIMARY_COLOR,
                      color: "white",
                      width: 70,
                    }}
                  >
                    ລຳດັບ
                  </th>
                  <th
                    style={{
                      backgroundColor: Consts.PRIMARY_COLOR,
                      color: "white",
                      width: 70,
                    }}
                  >
                    ສາຂາວິຊາ
                  </th>
                  <th
                    style={{
                      backgroundColor: Consts.PRIMARY_COLOR,
                      color: "white",
                      width: 70,
                    }}
                  >
                    ສະຖິຕິທັງໝົດ
                  </th>
                  <th
                    style={{
                      backgroundColor: Consts.PRIMARY_COLOR,
                      color: "white",
                      width: 70,
                    }}
                  >
                    ລົງທະບຽນສຳເລັດ
                  </th>
                </TableHeader>
              </thead>
              <tbody>
                {departmentDATA?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        borderBottom: "2px solid #ffff",
                        textAlign: "center",
                      }}
                    >
                      <td>{index + 1}</td>
                      <td style={{ textAlign: "left" }}>{item?.name}</td>
                      <td>{item?.countDepartment}</td>
                      <td>{item?.approvedCount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <Modal
          center
          show={showConfirmModal}
          onHide={_handleShowConfirmModalClose}
          size="lg"
        >
          <Modal.Header style={{ textAlign: "center", paddingTop: 20 }}>
            {"ລາຍງານຂໍ້ມູນສະຖິຕິ ສົກຮຽນ " + accessmentRedux}
            <div style={{ height: 20 }} />
            <div className="row" style={{ padding: 20 }}>
              <Button
                className="btn"
                variant="outline-dark"
                onClick={_handleShowConfirmModalClose}
              >
                ຍົກເລີກ
              </Button>
              &nbsp;&nbsp;
              <ReactHTMLTableToExcel
                style={{ backgroundColor: "none", border: "1px solid red" }}
                className="btn btn-outline-success"
                table="ExportData"
                filename={
                  "ລາຍງານຂໍ້ມູນສະຖິຕິ ສົກຮຽນ " +
                  accessmentRedux +
                  " " +
                  toDayDash()
                }
                sheet="Sheet"
                buttonText="Export excel"
                font={"NotoSansLao"}
                headerPaddingCellOptions={{ background: "#ff0000" }}
              />
            </div>
          </Modal.Header>

          <Modal.Body>
            <div>
              <table
                className="table"
                id="ExportData"
                style={{
                  fontSize: 12,
                  fontFamily: "NotoSansLao",
                }}
              >
                <thead>
                  <tr>
                    <td colSpan={4}>
                      {facName}&nbsp;ສົກຮຽນ {accessmentRedux}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: 70 }}>ລຳດັບ</th>
                    <th>ສາຂາວິຊາ</th>
                    <th>ສະຖິຕິທັງໝົດ</th>
                    <th>ລົງທະບຽນສຳເລັດ</th>
                  </tr>
                </thead>
                <tbody>
                  {departmentDATA?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td style={{ textAlign: "left" }}> {item?.name}</td>
                        <td style={{ textAlign: "right" }}>
                          {" "}
                          {item?.countDepartment}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {item?.approvedCount}
                        </td>
                      </tr>
                    );
                  })}
                  <tr style={{ width: "100%" }}>
                    <td colSpan={3}>ສະຖິຕິ ທັງໝົດ</td>
                    <td style={{ textAlign: "right" }}>
                      {statisticDATA && statisticDATA?.total}
                      ຄົນ
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>ລົງທະບຽນແລ້ວ</td>
                    <td style={{ textAlign: "right" }}>
                      {statisticDATA && statisticDATA?.approvedCounts}
                      ຄົນ
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>ຍັງບໍ່ທັນລົງທະບຽນ</td>
                    <td style={{ textAlign: "right" }}>
                      {statisticDATA && statisticDATA?.requestCounts}
                      ຄົນ
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>ວັນທີ</td>
                    <td style={{ textAlign: "right" }}>{toDayDash()}</td>
                  </tr>
                  <tr>
                    <td colSpan={3}>ໂດຍ</td>
                    <td style={{ textAlign: "right" }}>{userName}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div style={{ height: 20 }} />
            <div className="row" style={{ padding: 20 }}>
              <Button
                className="btn"
                variant="outline-dark"
                onClick={_handleShowConfirmModalClose}
              >
                ຍົກເລີກ
              </Button>
              &nbsp;&nbsp;
              <ReactHTMLTableToExcel
                style={{ backgroundColor: "none", border: "1px solid red" }}
                className="btn btn-outline-success"
                table="ExportData"
                filename={
                  "ລາຍງານຂໍ້ມູນສະຖິຕິ ສົກຮຽນ " +
                  accessmentRedux +
                  " " +
                  toDayDash()
                }
                sheet="Sheet"
                buttonText="Export excel"
                font={"NotoSansLao"}
                headerPaddingCellOptions={{ background: "#ff0000" }}
              />
            </div>
          </Modal.Footer>
        </Modal>
      </CustomContainer>
    </div>
  );
}

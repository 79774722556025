import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { API_KEY, NEW_API_URL } from "../../common/contrant";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import { FormHelperText } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function AddTeacherModal({
  open,
  onClose,
  handleSubmit,
  faculty,
  department,
}) {
  const [teacherList, setTeacherList] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);

  const [facultyList, setFacultyList] = useState([]);
  const [facId, setFaculty] = useState();

  const [departmentList, setDepartmentList] = useState([]);
  const [depId, setDepartment] = useState();
  const [error, setError] = useState(false);

  const handleChangeTeachers = (event) => {
    setSelectedTeachers(event.target.value);
    if (event.target.value.length > 0) {
      setError(false);
    }
  };

  const fetchFilterData = async () => {
    const _facultyList = await axios.get(NEW_API_URL + "faculty", {
      headers: {
        api_key: API_KEY,
      },
    });
    setFacultyList(_facultyList.data?.data || []);
    if (facId) {
      const _departmentList = await axios.get(
        NEW_API_URL + "department/" + facId,
        {
          headers: {
            api_key: API_KEY,
          },
        }
      );
      setDepartmentList(_departmentList.data?.data || []);
    }
  };

  const fetchTeacher = async () => {
    try {
      if (depId) {
        const response = await axios.get(NEW_API_URL + "userteacher/" + depId, {
          headers: {
            api_key: API_KEY,
          },
        });
        setTeacherList(response.data);
      }
    } catch (err) {
      console.error("Error fetching teachers:", err);
    }
  };

  useEffect(() => {
    setDepartment(department);
    setFaculty(faculty);
  }, [faculty, department, open]);

  useEffect(() => {
    fetchFilterData();
    fetchTeacher();
  }, [open, facId, depId]);

  const handleReset = () => {
    setSelectedTeachers([]);
    setTeacherList([]);
    setFaculty("");
    setDepartment("");
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        handleReset();
        onClose();
      }}
    >
      <Box sx={style}>
        <div className="col-12 mt-4">
          <Typography sx={{ fontWeight: "bold" }}>ເລືອກອາຈານສອນ</Typography>
          <div>
            <FormControl sx={{ marginTop: "20px" }} fullWidth>
              <InputLabel size="small" id="faculty-select-label">
                ຄະນະ
              </InputLabel>
              <Select
                size="small"
                labelId="faculty-select-label"
                value={facId}
                label={"ຄະນະ"}
                onChange={(e) => {
                  setFaculty(e.target.value);
                  setDepartment("");
                }}
              >
                {facultyList.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="col-12 mt-1">
          <div>
            <FormControl sx={{ marginTop: "20px" }} fullWidth>
              <InputLabel size="small" id="department-select-label">
                ພາກ
              </InputLabel>
              <Select
                label={"ພາກ"}
                size="small"
                labelId="department-select-label"
                value={depId}
                onChange={(e) => setDepartment(e.target.value)}
              >
                {departmentList.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="col-12 mt-1">
          <div>
            <FormControl sx={{ marginTop: "20px" }} fullWidth>
              <InputLabel size="small" id="teacher-select-label">
                ອາຈານ
              </InputLabel>
              <Select
                size="small"
                label={"ອາຈານ"}
                value={selectedTeachers}
                onChange={handleChangeTeachers}
                multiple
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    {selected.map((teacherId, index) => (
                      <Chip
                        key={index}
                        label={
                          teacherList.find(
                            (teacher) => teacher._id === teacherId
                          )?.firstname +
                          " " +
                          teacherList.find(
                            (teacher) => teacher._id === teacherId
                          )?.lastname
                        }
                        style={{ margin: 2 }}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {teacherList.map((teacher, index) => (
                  <MenuItem key={(teacher._id, index)} value={teacher._id}>
                    {teacher.firstname + " " + teacher.lastname}
                  </MenuItem>
                ))}
              </Select>
              {error && <FormHelperText sx={{ color: "red" }}>ກະລຸນາເລືອກອາຈານ</FormHelperText>}
            </FormControl>
          </div>
        </div>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
          <Button
            onClick={() => {
              if (selectedTeachers.length === 0) {
                setError(true);
                return;
              }
              handleSubmit(selectedTeachers);
            }}
            variant="contained"
            color="primary"
          >
            ບັນທຶກ
          </Button>
          <Button
            onClick={() => {
              handleReset();
              onClose();
            }}
            sx={{ ml: 2 }}
          >
            ຍົກເລີກ
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

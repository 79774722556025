import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TablePagination,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Alert } from "@mui/material";
import axios from "axios";
import { NEW_API_URL } from "../../common/contrant";
import DeleteIcon from "@mui/icons-material/Delete";
// import PasswordModal from "../../common/PasswordModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const ScholarshipAndJob = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    files: null,
    description: "",
    properties: "",
    others: "",
    type: "SCHOLARSHIP",
  });

  // const [passwordModalOpen, setPasswordModalOpen] = useState(true);
  const [passwordAttempts, setPasswordAttempts] = useState(0);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scholarships, setScholarships] = useState([]);
  const [selectedScholarshipId, setSelectedScholarshipId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteCandidate, setDeleteCandidate] = useState(null);
  const [filterType, setFilterType] = useState("SCHOLARSHIP");

  // Pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  //Inside your component
  const [isEditing, setIsEditing] = useState(false);

  //checkbox
  const [isCheckedTrue, setIsCheckedTrue] = useState(false);
  const [isCheckedFalse, setIsCheckedFalse] = useState(false);

  const handleCheckboxTrueChange = (e) => {
    setIsCheckedTrue(e.target.checked);
    if (e.target.checked) {
      setIsCheckedFalse(false);
    }
  };

  const handleCheckboxFalseChange = (e) => {
    setIsCheckedFalse(e.target.checked);
    if (e.target.checked) {
      setIsCheckedTrue(false);
    }
  };

  useEffect(() => {
    fetchScholarships();
  }, [filterType]);

  useEffect(() => {
    if (passwordAttempts === 5) {
      history.push("/");
    }
  }, [passwordAttempts]);
  const fetchScholarships = async () => {
    try {
      const response = await axios.get(
        NEW_API_URL + "scholarship?type=" + filterType
      );
      setScholarships(response.data);
    } catch (err) {
      setError("Error fetching scholarships");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const newFilesArray = Array.from(e.target.files); // Convert FileList to array
    setFormData((prevData) => ({
      ...prevData,
      files: [...(prevData.files || []), ...newFilesArray],
    }));
  };

  const removeFile = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      files: prevData.files.filter((_, i) => i !== index), // Remove file at index
    }));
  };

  const validateFields = () => {
    const { description, properties, others } = formData;
    if (!description || !properties || !formData.files) {
      setError("All fields are required!");
      return false;
    }
    if (!isCheckedTrue && !isCheckedFalse) {
      setError("ກະລຸນາເລືອກໃຫ້ແຈ້ງເຕືອນ ຫຼື ບໍ່!");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;

    const formDataToSend = new FormData();

    // Append form data fields
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "files" && value) {
        // Append multiple files
        Array.from(value).forEach((file) => {
          formDataToSend.append("files", file);
        });
      } else {
        formDataToSend.append(key, value);
      }
    });

    setLoading(true);

    try {
      if (selectedScholarshipId) {
        // Update
        await axios.put(
          NEW_API_URL + "scholarship/" + selectedScholarshipId,
          formDataToSend
        );
      } else {
        // Create
        await axios.post(NEW_API_URL + "scholarship", formDataToSend);
        if (isCheckedTrue) {
          await sendNotification();
        }
      }
      setSuccess(true);
      fetchScholarships();
      setFormData({
        files: null, // Reset files
        description: "",
        properties: "",
        others: "",
        type: "SCHOLARSHIP",
      });
      setSelectedScholarshipId(null);
      setError("");
    } catch (err) {
      setError(err.response?.data?.error || "ເກີດບັນຫາໃນການເພີ່ມ ຫລື ເເກ້ໄຂ");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setError("");
    setSuccess(false);
  };

  const handleRowSelect = (scholarship) => {
    setFormData({
      files: scholarship?.image,
      description: scholarship.description,
      properties: scholarship.properties,
      others: scholarship.others,
      type: scholarship.type,
    });
    setSelectedScholarshipId(scholarship._id);
    setIsEditing(true); // Set to true when editing
  };

  const handleDeleteClick = (scholarship) => {
    setDeleteCandidate(scholarship);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (!deleteCandidate) return;
    try {
      await axios.delete(NEW_API_URL + "scholarship/" + deleteCandidate._id);
      setSuccess(true);
      fetchScholarships();
      setDeleteCandidate(null);
      setOpenDeleteDialog(false);
    } catch (err) {
      setError(err.response?.data?.error || "ເກີດບັນຫາໃນການລົບ.");
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
    setDeleteCandidate(null);
  };

  const filteredScholarships = filterType
    ? scholarships.filter((scholarship) =>
        scholarship.type.toLowerCase().includes(filterType.toLowerCase())
      )
    : scholarships;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handlePasswordValidate = (isValid) => {
  //   if (!isValid) {
  //     setPasswordAttempts((prev) => prev + 1);
  //     setPasswordModalOpen(false);
  //   }
  // };

  const sendNotification = async () => {
    await axios.post(NEW_API_URL + "notification/send", {
      title:
        formData.type === "JOB" ? "ປະກາດຮັບສະຫມັກວຽກ" : "ທຶນການສຶກສາອອກໃຫມ່",
      body: formData?.description,
      path: formData.type === "JOB" ? "jobs" : "scholarship",
    });
  };

  return (
    <Box
      sx={{
        paddingTop: "50px",
        width: "100%",
        backgroundColor: "white",
        paddingBottom: "100px",
        marginBottom: "100px",
      }}
    >
      {/* <PasswordModal
        open={passwordModalOpen}
        onClose={() => setPasswordModalOpen(false)}
        onValidate={handlePasswordValidate}
      /> */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" component="h2" gutterBottom>
            ເພິ່ມ/ເເກ້ໄຂ ຂໍ້ມູນສະຫມັກວຽກ ແລະ ທືນການສຶກສາ
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>ປະເພດ</InputLabel>
              <Select
                size="small"
                name="type"
                label="ປະເພດ"
                value={formData.type}
                onChange={handleChange}
                required
              >
                <MenuItem value="SCHOLARSHIP"> ທືນການສຶກສາ</MenuItem>
                <MenuItem value="JOB">ສະຫມັກວຽກ</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="ຫົວຂໍ້"
              name="description"
              value={formData.description}
              onChange={handleChange}
              sx={{ mb: 2 }}
              required
              multiline
              rows={4}
              size="small"
            />
            <TextField
              fullWidth
              label="ຄຸນສົມບັດ / ເງື່ອນໄຂ"
              name="properties"
              value={formData.properties}
              onChange={handleChange}
              sx={{ mb: 2 }}
              required
              multiline
              rows={4}
              size="small"
            />
            <TextField
              fullWidth
              label="ອື່ນໆ"
              name="others"
              value={formData.others}
              onChange={handleChange}
              sx={{ mb: 2 }}
              required
              multiline
              rows={4}
              size="small"
            />
            <input
              type="file"
              name="files"
              multiple
              onChange={handleFileChange}
              style={{ marginBottom: 16 }}
            />

            {/* Preview selected images */}
            <Grid container spacing={2}>
              {formData.files &&
                Array.from(formData.files).map((file, index) => (
                  <Grid item xs={4} key={index}>
                    <Box
                      sx={{
                        position: "relative",
                        border: "1px solid #ccc",
                        padding: 1,
                        borderRadius: 1,
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={
                          file && selectedScholarshipId
                            ? file
                            : URL.createObjectURL(file)
                        }
                        alt={`Preview ${index}`}
                        style={{
                          width: "100%",
                          height: "100px",
                          objectFit: "cover",
                        }}
                      />
                      <Button
                        onClick={() => removeFile(index)}
                        sx={{
                          position: "absolute",
                          top: 4,
                          right: 4,
                          backgroundColor: "red",
                          color: "white",
                          borderRadius: "50%",
                          minWidth: 0,
                          width: "24px",
                          height: "24px",
                          fontSize: "12px",
                        }}
                      >
                        ×
                      </Button>
                    </Box>
                  </Grid>
                ))}
            </Grid>

            <Box className="p-3 border border-danger m-3">
              <Box className="py-2">ໃຫ້ແຈ້ງເຕືອນ ຫຼື ບໍ່*</Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="checkboxtrue"
                      checked={isCheckedTrue}
                      onChange={handleCheckboxTrueChange}
                    />
                  }
                  label="ແຈ້ງເຕືອນ"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="checkboxfalse"
                      checked={isCheckedFalse}
                      onChange={handleCheckboxFalseChange}
                    />
                  }
                  label="ບໍ່ແຈ້ງເຕືອນ"
                />
              </Box>
            </Box>

            <Grid
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{ paddingLeft: "50px", paddingRight: "50px" }}
                size="large"
                variant="contained"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "ບັນທຶກ"
                )}
              </Button>
            </Grid>
          </form>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h5" component="h2" gutterBottom>
              ລາຍການສະຫມັກວຽກ ແລະ ທືນການສຶກສາ
            </Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>ປະເພດ</InputLabel>
              <Select
                label={"ປະເພດ"}
                size="small"
                value={filterType}
                onChange={(e) => setFilterType(e.target.value)}
              >
                <MenuItem value="SCHOLARSHIP"> ທືນການສຶກສາ</MenuItem>
                <MenuItem value="JOB">ສະຫມັກວຽກ</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: "center", color: "white" }}>
                    ລາຍລະອຽດ
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", color: "white" }}>
                    ຄຸນສົມບັດ / ເງື່ອນໄຂ
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", color: "white" }}>
                    ປະເພດ
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", color: "white" }}>
                    ສ້າງວັນທີ່
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", color: "white" }}>
                    ຈັດການ
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredScholarships
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((scholarship) => (
                    <TableRow key={scholarship._id}>
                      <TableCell>{scholarship?.description}</TableCell>
                      <TableCell>{scholarship?.properties}</TableCell>
                      <TableCell>
                        {scholarship?.type === "SCHOLARSHIP"
                          ? "ທຶນການສຶກສາ"
                          : "ສະຫມັກວຽກ"}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {new Date(scholarship.createdAt).toLocaleString()}
                      </TableCell>
                      <TableCell
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          onClick={() => handleRowSelect(scholarship)}
                          variant="contained"
                        >
                          ເເກ້ໄຂ
                        </Button>
                        <IconButton
                          onClick={() => handleDeleteClick(scholarship)}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredScholarships.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>

      <Snackbar
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          ການດຳເນີນການສຳເລັດ!
        </Alert>
      </Snackbar>

      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">ຢືນຢັນການລຶບ</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            ທ່ານເເນ່ໃຈບໍວ່າຈະລຶບ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>ຍົກເລີກ</Button>
          <Button onClick={handleDeleteConfirm} autoFocus>
            ລຶບ
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ScholarshipAndJob;

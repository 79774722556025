import React, { useEffect, useState } from "react";
import { API_KEY, NEW_API_URL } from "./contrant";
import axios from "axios";
import { CustomContainer } from "./CustomContainer";

const DataFilter = ({
  onSelected,
  disableFact,
  factId,
  disableDep,
  depId,
  hideClassroom,
  hideMajor,
  hideYear,
  semester,
}) => {
  const [facultyList, setFacultyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const [selectedFaculty, setSelectedFaculty] = useState(factId);
  const [selectedMajor, setSelectedMajor] = useState("");
  const [majorList, setMajorList] = useState([]);

  const [selectedClass, setSelectedClass] = useState("");
  const [classList, setClassList] = useState([]);
  const [year, setYear] = useState(null);

  const [_semester, setSemester] = useState("");

  useEffect(() => {
    setSelectedDepartment(depId);
    setSelectedFaculty(factId);
  }, [factId, depId]);

  const handleFacultyChange = (event) => {
    setSelectedFaculty(event.target.value);
  };
  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };
  const handleMajorChange = (event) => {
    setSelectedMajor(event.target.value);
  };
  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
  };
  const handleYearChange = (event) => {
    setYear(event.target.value);
  };
  const fetchFaculty = async () => {
    try {
      const response = await axios.get(NEW_API_URL + "/faculty", {
        headers: {
          api_key: API_KEY,
        },
      });
      if (response.data.success) {
        setFacultyList(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching faculty:", error);
    }
  };

  // Similar changes for fetchDepartment, fetchMajor, and fetchClass functions

  // ...

  const fetchDepartment = async () => {
    if (selectedFaculty) {
      try {
        const response = await axios.get(
          NEW_API_URL + "/department/" + selectedFaculty,
          {
            headers: {
              api_key: API_KEY,
            },
          }
        );
        if (response.data.success) {
          setDepartmentList(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching department:", error);
      }
    }
  };

  // Similar changes for fetchMajor and fetchClass functions

  // ...

  const fetchMajor = async () => {
    if (selectedDepartment) {
      try {
        const response = await axios.get(
          NEW_API_URL + "/getmajo/" + selectedDepartment,
          {
            headers: {
              api_key: API_KEY,
            },
          }
        );
        if (response.data.success) {
          setMajorList(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching major:", error);
      }
    }
  };

  // ...

  const fetchClass = async () => {
    if (selectedMajor) {
      try {
        const response = await axios.get(
          NEW_API_URL + "/class/" + selectedMajor,
          {
            headers: {
              api_key: API_KEY,
            },
          }
        );
        if (response.data.success) {
          setClassList(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching class:", error);
      }
    }
  };

  useEffect(() => {
    setSelectedClass("");

    // if department is exist in user role then we
    // can not select faculty or department
    if (!disableDep) setSelectedDepartment("");
    setSelectedMajor("");
    fetchDepartment();
    fetchMajor();
    fetchClass();
  }, [selectedFaculty]);

  useEffect(() => {
    setSelectedClass("");
    setSelectedMajor("");
    fetchMajor();
    fetchClass();
  }, [selectedDepartment]);

  useEffect(() => {
    setSelectedClass("");
    fetchClass();
  }, [selectedMajor]);

  useEffect(() => {
    fetchFaculty();
  }, []);

  useEffect(() => {
    onSelected({
      faculty: selectedFaculty,
      department: selectedDepartment,
      major: selectedMajor,
      class: selectedClass,
      year: year,
      semester: _semester,
    });
  }, [
    selectedFaculty,
    selectedClass,
    selectedDepartment,
    selectedMajor,
    year,
    _semester,
  ]);

  return (
    <div>
      <div className="row">
        <div className="col-lg-3 col-md-4 col-sm-6">
          <div className="form-group">
            <label>ຄະນະວິຊາ</label>
            <select
              disabled={disableFact}
              className="form-select form-select-md mb-3 "
              aria-label=".form-select-lg example"
              value={selectedFaculty}
              onChange={handleFacultyChange}
            >
              <option value="">ເລືອກຄະນະວິຊາ</option>
              {facultyList.map((faculty) => (
                <option key={faculty._id} value={faculty._id}>
                  {faculty.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6">
          <div className="form-group">
            <label>ພາກວິຊາ</label>
            <select
              disabled={disableDep}
              className="form-select form-select-md mb-3 "
              aria-label=".form-select-lg example"
              value={selectedDepartment}
              onChange={handleDepartmentChange}
            >
              <option value="">ເລືອກພາກວິຊາ</option>
              {departmentList.map((department) => (
                <option
                  key={department._id}
                  value={department._id}
                  disabled={department._id === selectedDepartment}
                >
                  {department.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {hideMajor === undefined ? (
          <div className="col-lg-2 col-md-4 col-sm-6">
            <div className="form-group">
              <label>ສາຂາວິຊາ</label>
              <select
                className="form-select form-select-md mb-3 "
                aria-label=".form-select-lg example"
                value={selectedMajor}
                onChange={handleMajorChange}
              >
                <option value="">ເລືອກສາຂາວິຊາ</option>
                {majorList.map((major) => (
                  <option key={major._id} value={major._id}>
                    {major.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <></>
        )}
        {hideClassroom === undefined ? (
          <div className="col-lg-2 col-md-4 col-sm-6">
            <div className="form-group">
              <label>ຫ້ອງຮຽນ</label>
              <select
                className="form-select form-select-md mb-3 "
                aria-label=".form-select-lg example"
                id="class"
                value={selectedClass}
                onChange={handleClassChange}
              >
                <option value="">ເລືອກຫ້ອງຮຽນ</option>
                {classList.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.name + " ປີ " + item.year}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default DataFilter;

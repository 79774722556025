import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Avatar,
  Divider,
  Chip,
  Stack,
} from "@mui/material";
import {
  Person,
  School,
  Home,
  ContactPhone,
  Star,
  Business,
  Info,
} from "@mui/icons-material"; // Icons
import { fetchGet } from "../../common/fetchAPI";
import { useParams } from "react-router-dom";
import { getStudentTypeDisplay } from "../../common/getData";
import { convertGender } from "../../helpers/user";
import { URL_IMAGE_STUDENT } from "../../consts";
import StudentScoreDetail from "./StudentScoreDetail";
import StudentAttendanceDetail from "./StudentAttendenceDetail";

function StudentDetail() {
  const { id } = useParams();
  const [studentDetail, setStudentDetail] = useState(null);
  const [tabIndex, setTabIndex] = useState(0); // Default tab is General Info

  useEffect(() => {
    if (id) {
      fetchStudentDetail();
    }
  }, [id]);

  const fetchStudentDetail = async () => {
    const response = await fetchGet("student/" + id);
    if (response) {
      setStudentDetail(response);
    }
  };

  const handleTabChange = (index) => {
    setTabIndex(index);
  };

  if (!studentDetail) {
    return (
      <Typography variant="h6" align="center" mt={4}>
        ກຳລັງໂຫລດ
      </Typography>
    );
  }

  return (
    <Box sx={{ width: "100%", mx: "auto", mt: 4, px: 10, mb: 5 }}>
      {/* Modern Chip Navigation */}
      <Stack direction="row" spacing={2} justifyContent="center" sx={{ mb: 3 }}>
        <Chip
          label="ຂໍ້ມຸນທົ່ວໄປ"
          icon={<Person />}
          onClick={() => handleTabChange(0)}
          variant={tabIndex === 0 ? "filled" : "outlined"}
          color={tabIndex === 0 ? "primary" : "default"}
        />
        <Chip
          label="ຄະເເນນ"
          icon={<School />}
          onClick={() => handleTabChange(1)}
          variant={tabIndex === 1 ? "filled" : "outlined"}
          color={tabIndex === 1 ? "secondary" : "default"}
        />
        <Chip
          label="ການເຂົ້າຫ້ອງຮຽນ"
          icon={<Business />}
          onClick={() => handleTabChange(2)}
          variant={tabIndex === 2 ? "filled" : "outlined"}
          color={tabIndex === 2 ? "success" : "default"}
        />
      </Stack>

      {/* Tab Content */}
      {tabIndex === 0 && <GeneralInfo student={studentDetail} />}
      {tabIndex === 1 && <StudentScoreDetail studentId={id} />}
      {tabIndex === 2 && <StudentAttendanceDetail studentId={id} />}
      {tabIndex === 3 && (
        <PlaceholderTab title="Documents Data (Coming Soon)" />
      )}
    </Box>
  );
}

/* General Information - Default Tab */
function GeneralInfo({ student }) {
  return (
    <Grid
      sx={{
        width: "100%",
      }}
      container
      spacing={1}
    >
      {/* Left Side: Profile Details (Now Styled Like Other Sections) */}
      <Grid item xs={12} sm={4}>
        <Section
          title="ຂໍ້ມຸນທົ່ວໄປ"
          icon={<Person sx={{ color: "#1976D2" }} />}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} align="center">
              <Avatar
                src={URL_IMAGE_STUDENT + student.image?.url}
                sx={{ width: 120, height: 120, mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <InfoRow label="ລະຫັດນັກສຶກສາ" value={student.userId} />
              <InfoRow label="ເພດ" value={convertGender(student.gender)} />
              <InfoRow
                label="ວັນເດືອນປີເກີດ"
                value={new Date(student.birthday).toLocaleDateString()}
              />
              <InfoRow label="ສັນຊາດ" value={student?.nationality} />
              <InfoRow label="ຊົນເຜົ່າ" value={student?.tribe?.name} />
              <InfoRow label="ເບີໂທລະສັບ" value={student?.phoneMobile} />
              <InfoRow label="ອີເມວ" value={student?.email || "ບໍ່ມິຂໍ້ມຸນ"} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  {"ສະຖານະການຮຽນຈົບ: "}
                </Typography>
                <Typography
                  sx={{
                    ml: 1,
                    color:
                      student?.graduatedStatus?.status === "STUDYING"
                        ? "green"
                        : "red",
                    fontWeight: "bold",
                  }}
                >
                  {student?.graduatedStatus?.status === "STUDYING"
                    ? "ກຳລັງຮຽນ"
                    : "ຈົບແລ້ວ"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  ສະຖານະການຮຽນ
                </Typography>
                <Typography
                  sx={{
                    ml: 1,
                    color:
                      student?.studyStatus === "STUDYING"
                        ? "green"
                        : student?.studyStatus === "DROP"
                        ? "red"
                        : "orange",
                    fontWeight: "bold",
                  }}
                >
                  {student?.studyStatus === "STUDYING"
                    ? "ກຳລັງສຶກສາາ"
                    : student?.studyStatus === "DROP"
                    ? "Drop"
                    : "ໂຈະການຮຽນ"}
                </Typography>
              </Box>
               
            </Grid>
          </Grid>
        </Section>
      </Grid>

      {/* Right Side: Academic Information */}
      <Grid item xs={12} sm={4}>
        <Section
          title="ຂໍ້ມຸນການສຶກສາ"
          icon={<School sx={{ color: "#1976D2" }} />}
        >
          <InfoRow label="ພາກວິຊາ" value={student.department?.name} />
          <InfoRow label="ຄະນະ" value={student.faculty?.name} />
          <InfoRow label="ສາຂາ" value={student.major?.name} />
          <InfoRow label="ປີຮຽນ" value={student?.yearLevel} />
          <InfoRow label="ສົກຮຽນ" value={student?._assessmentYear} />
          <InfoRow label="ຫ້ອງຮຽນ" value={student?.classRoom?.name} />
          <InfoRow
            label="ທຶນທີ່ໄດ້ຮັບ"
            value={student.scholarShipQuota?.title || "ບໍ່ມີ"}
          />
          <InfoRow
            label="ປະເພດນັກສຶກສາ"
            value={getStudentTypeDisplay(student?.studentType)}
          />
          <InfoRow
            label="ລະດັບການສຶກສາ"
            value={student?.educationLevel?.name}
          />
          <InfoRow label="ຂໍ້ມຸນນັກສຶກສາ" value={student?.personalType?.name} />
          <InfoRow
            label="ຈົບຈາກແຂວງ"
            value={student?.payTuition?.graduatedProvince}
          />
          <InfoRow
            label="ສົກຮຽນທີຈົບ"
            value={student?.payTuition?.graduatedAccessMentYear}
          />
          <InfoRow
            label="ຈົບຈາກໂຮງຮຽນ"
            value={student?.payTuition?.graduatedSchool}
          />

          <InfoRow
            label="ເປັນສະຫະພັນແມ່ຍີງ"
            value={student.womenAssociation ? "ເປັນ" : "ບໍ່ເປັນ"}
          />
          <InfoRow
            label="ເປັນຊາວຫມຸມ"
            value={student.youthAssociation ? "ເປັນ" : "ບໍ່ເປັນ"}
          />
          <InfoRow
            label="ເປັນກຳມະບານ"
            value={student.commiteeAssociation ? "ເປັນ" : "ບໍ່ເປັນ"}
          />
          {/* <Box
            sx={{
              mb: "64px",
            }}
          ></Box> */}
        </Section>
      </Grid>

      {/* Address & Emergency Contact */}
      <Grid item xs={12} sm={4}>
        <Section
          title="ຂໍ້ມຸນທີ່ຢູ່ ແລະ ຕິດຕໍ່"
          icon={<Home sx={{ color: "#4CAF50" }} />}
        >
          <InfoRow label="ມາຈາກປະເທດ" value={student.address?.country} />
          <InfoRow label="ແຂວງປັດຈຸບັນ" value={student.address?.province} />
          <InfoRow label="ເມືອງງປັດຈຸບັນ" value={student.address?.district} />
          <InfoRow label="ບ້ານປັດຈຸບັນ" value={student.address?.village} />
          <InfoRow label="ແຂວງເກີດ" value={student.birthAddress?.province} />
          <InfoRow label="ເມືອງງເກີດ" value={student.birthAddress?.district} />
          <InfoRow label="ບ້ານເກີດ" value={student.birthAddress?.village} />
          <InfoRow
            label="ສຸກເສີນຕີດຕໍ່"
            value={student.emergencyContact?.relationship || "ບໍ່ມິຂໍ້ມຸນ"}
          />
          <InfoRow
            label="ແຂວງ"
            value={student.emergencyContact?.emergencyProvince || "ບໍ່ມິຂໍ້ມຸນ"}
          />
          <InfoRow
            label="ເມືອງ"
            value={student.emergencyContact?.emergencyDistrict || "ບໍ່ມິຂໍ້ມຸນ"}
          />
          <InfoRow
            label="ບ້ານ"
            value={student.emergencyContact?.emergencyVillage || "ບໍ່ມິຂໍ້ມຸນ"}
          />
          <InfoRow
            label="ອາໄສຢູ່ຮ່ວມກັນ"
            value={
              student.emergencyContact?.isLiveTogether === true
                ? "ຢູ່ຮ່ວມກັນ"
                : "ບໍ່ໄດ້ຢູ່ຮ່ວມກັນ"
            }
          />
          <InfoRow
            label="ເບີໂທຕິດຕໍ່"
            value={student?.parentPhone || "ບໍ່ມີ"}
          />
        </Section>
      </Grid>
    </Grid>
  );
}

/* Placeholder for Future Tabs */
const PlaceholderTab = ({ title }) => (
  <Card sx={fixedHeightCard}>
    <CardContent>
      <Typography variant="h6" align="center">
        {title}
      </Typography>
    </CardContent>
  </Card>
);

/* Section Component */
const Section = ({ title, icon, children }) => (
  <Card sx={fixedHeightCard}>
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        padding: "8px 16px",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
      }}
    >
      <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
        {icon} <span style={{ marginLeft: 8 }}>{title}</span>
      </Typography>
    </Box>
    <CardContent>{children}</CardContent>
  </Card>
);

/* InfoRow Component */
const InfoRow = ({ label, value }) => (
  <Typography variant="subtitle1">
    <strong>{label}:</strong> {value || "ບໍ່ມິຂໍ້ມຸນ"}
  </Typography>
);

/* Fixed Height for All Sections */
const fixedHeightCard = {
  height: "70vh",
  mb: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
};

export default StudentDetail;

import { USER_KEY } from "../consts";
import axios from "axios";
import { NEW_API_URL, API_KEY } from "./contrant";
const user = localStorage.getItem(USER_KEY);
const userObject = JSON.parse(user);
const token = userObject?.accessToken;

const axiosInstance = axios.create({
  baseURL: NEW_API_URL,
  headers: {
    api_key: API_KEY,
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle error silently
    return Promise.reject(error);
  }
);

export const fetchGet = async (url) => {
  try {
    const response = await axiosInstance.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    // Handle error silently
    return null; // or handle the error as needed
  }
};

export const fetchPostV2 = async (url, data) => {
  try {
    const response = await axiosInstance.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      return {
        status: "error",
        message:
          "Permission denied. You are not authorized to access this resource.",
      };
    }
    throw error; // Re-throw other errors
  }
};

export const fetchGetV2 = async (url) => {
  try {
    const response = await axiosInstance.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      return {
        status: "error",
        message:
          "Permission denied. You are not authorized to access this resource.",
      };
    }
    if (error.response?.status === 404) {
      return {
        code: 404,
        status: "error",
        message:
          "Permission denied. You are not authorized to access this resource.",
      };
    }

    throw error; // Re-throw other errors
  }
};

export const fetchPutV2 = async (url, data) => {
  try {
    const response = await axiosInstance.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      return {
        status: "error",
        message:
          "Permission denied. You are not authorized to access this resource.",
      };
    }
    if (error.response?.status === 400) {
      return {
        code: 400,
        status: "error",
        message:
          "Permission denied. You are not authorized to access this resource.",
      };
    }
    throw error; // Re-throw other errors
  }
};

export const fetchPost = async (url, data) => {
  try {
    const response = await axiosInstance.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    // Handle error silently
    return null; // or handle the error as needed
  }
};

export const fetchPut = async (url, data) => {
  try {
    const response = await axiosInstance.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    // Handle error silently
    throw error; // or handle the error as needed
  }
};

export const fetchDelete = async (url) => {
  try {
    const response = await axiosInstance.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    // Handle error silently
    return null; // or handle the error as needed
  }
};

export const fetchPostFormData = async (url, data) => {
  try {
    const response = await axiosInstance.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    // Handle error silently
    return null; // or handle the error as needed
  }
};

export const fetchPutFormData = async (url, data) => {
  try {
    const response = await axiosInstance.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    // Handle error silently
    return null; // or handle the error as needed
  }
};

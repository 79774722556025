import React from 'react'
import {
    Input,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Button
} from '@mui/material'
import { SUBJECT_GROUP } from '../../../consts/SubjectGroup'
import ExportCourseDetail from './ExportCourseDetail';
import { hasPermission } from '../../../helpers/CheckPermission';
const CourseDetailFilter = ({ formData, setFormData, courseData }) => {
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };
    return (
        <div>
            <Grid container justify="space-between">
                <Grid item container spacing={2} xs={10}>
                    <Grid item xs={3}>
                        <FormControl fullWidth size='small'>
                            <InputLabel htmlFor="subjectGroup">ໝວດວິຊາ</InputLabel>
                            <Select
                                // disabled={userObject?.data?.role === "DEP_ADMIN"}
                                label="ໝວດວິຊາ"
                                name="subjectGroup"
                                value={formData.subjectGroup || ""}
                                onChange={handleChange}
                            >
                                <MenuItem key={""} value={""}>
                                    ທັງຫມົດ
                                </MenuItem>
                                {SUBJECT_GROUP.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth size='small'>
                            <InputLabel htmlFor="year">ປີຮຽນ</InputLabel>
                            <Select
                                label="ປີຮຽນ"
                                name="year"
                                value={formData.year || ""}
                                onChange={handleChange}
                            >
                                <MenuItem key={""} value={""}>
                                    ທັງຫມົດ
                                </MenuItem>
                                <MenuItem value={1}>
                                    ປີ 1
                                </MenuItem>
                                <MenuItem value={2}>
                                    ປີ 2
                                </MenuItem>
                                <MenuItem value={3}>
                                    ປີ 3
                                </MenuItem>
                                <MenuItem value={4}>
                                    ປີ 4
                                </MenuItem>
                                <MenuItem value={5}>
                                    ປີ 5
                                </MenuItem>
                                <MenuItem value={6}>
                                    ປີ 6
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth size='small'>
                            <InputLabel htmlFor="semester">ພາກຮຽນ</InputLabel>
                            <Select
                                label="ພາກຮຽນ"
                                name="semester"
                                value={formData.semester || ""}
                                onChange={handleChange}
                            >
                                <MenuItem key={""} value={""}>
                                    ທັງຫມົດ
                                </MenuItem>
                                <MenuItem value={1}>
                                    ພາກຮຽນ I
                                </MenuItem>
                                <MenuItem value={2}>
                                    ພາກຮຽນ II
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {hasPermission('COURSE', 'export') &&
                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: "flex-end" }}>
                        <ExportCourseDetail data={courseData} />
                    </Grid>
                }
            </Grid>

        </div>
    )
}

export default CourseDetailFilter
import React, { useState, useEffect, useRef } from "react";
import useReactRouter from "use-react-router";
import { useLazyQuery } from "@apollo/react-hooks";
/**
 *
 * @Library
 *
 */
import * as _ from "lodash";
import { Formik } from "formik";
import axios from "axios";
/**
 *
 * @Component
 *
 */
import { Col, Row, Form, Button, Toast, Breadcrumb } from "react-bootstrap";
/**
 *
 * @Constant
 *
 */
import Consts from "../../consts";
import Routers from "../../consts/router";
import { LAO_ADDRESS } from "../../consts/Address";
import { COUNTRIES_LIST } from "../../consts/Countries";
/**
 *
 * @Apollo
 *
 */
import { FACULTIES } from "../../apollo/faculty";
import { TRIBE_MASTER } from "../../apollo/tribeMaster/index";
import { RELIGIONAL_MASTERS } from "../../apollo/religionalMasters";
import { EDUCATIONLEVEL } from "../../apollo/educationLevel";
import { PERSONALTYPE } from "../../apollo/ personalTypeMaster";
import { PRE_SIGNED_URL } from "../../apollo/student";
import { DEPARTMENTS } from "../../apollo/deparment";
import { CLASSROOM_MASTERS } from "../../apollo/classRoom";
import { MAJORS } from "../../apollo/major";
/**
 *
 * @Function
 *
 */
import { alertWarnings, valiDate } from "../../common/super";
import { CustomContainer } from "../../common";
import StudentAddConfirm from "./StudentAddConfirm";
import { Progress } from "reactstrap";
import { useSelector } from "react-redux";
import { fetchGet } from "../../common/fetchAPI";

function StudentAdd() {
  const { history } = useReactRouter();
  /**
   *
   * @State
   *
   */
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const [coverImage, setCoverImage] = useState();
  const [previewImageURL, setPreviewImageURL] = useState();
  const [showAddConfirmModal, setShowAddConfirmModal] = useState(false);
  const [selectComeFormProvince, setSelectComeFormProvince] = useState("");
  const [selectCurrentProvinceIndex, setSelectCurrentProvinceIndex] =
    useState(-1);

  const [selectCurrentProvince, setSelectCurrentProvince] = useState("");
  const [selectCurrentDistrict, setSelectCurrentDistrict] = useState("");
  const [selectBirthProvinceIndex, setSelectBirthProvinceIndex] = useState(-1);
  const [selectBirthProvince, setSelectBirthProvince] = useState("");
  const [selectBirthDistrict, setSelectBirthDistrict] = useState("");

  const [strengthPass, setStrengthPass] = useState(0);
  const [checkPasswordLength, setCheckPasswordLength] = useState("");
  const [formParam, setFormParam] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [isBornAbroad, setIsBornAbroad] = useState(false);
  const [scholarship, setScholarship] = useState();
  const [studentType, setStudentType] = useState("");
  const [clickCheckbox, setClickCheckbox] = useState(false);
  const [assessmentYear, setAssessmentYear] = useState([]);

  const [facultyId, setFacultyId] = useState("");
  const [facultyName, setFacultyName] = useState("");

  const [dataUser, setDataUser] = useState();
  const [role, setRole] = useState();

  const [departmentId, setDepartmentId] = useState("");
  const [departmentName, setDepartmentName] = useState("");

  const [majorId, setMajorId] = useState("");
  const [majorName, setMajorName] = useState("");

  const [classroomId, setClassroomId] = useState("");
  const [classroomName, setClassroomName] = useState("");

  const [year, setYear] = useState("");

  const inputImage = useRef("inputImage");
  /**
   *
   * @Apollo
   *
   */
  const [loadReligionalMaster, { data: religionalMasterData }] =
    useLazyQuery(RELIGIONAL_MASTERS);
  const [loadTribeMaster, { data: tribeMasterData }] =
    useLazyQuery(TRIBE_MASTER);
  const [loadEducationMaster, { data: EducationData }] =
    useLazyQuery(EDUCATIONLEVEL);
  const [loadPersonalTypeMaster, { data: PersonalTypeData }] =
    useLazyQuery(PERSONALTYPE);
  const [loadPresignImage, { data: presignData }] =
    useLazyQuery(PRE_SIGNED_URL);

  const [facultyList, setFacultyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [majorList, setMajorList] = useState([]);
  const [classRoomList, setClassRoomList] = useState([]);

  const fetchClassRoom = async () => {
    if (majorId === "" || year === "") return;
    const response = await fetchGet("class/" + majorId + "/" + year);
    if (response) {
      setClassRoomList(response?.data);
    }
  };

  const fetchDepartments = async () => {
    const response = await fetchGet(
      "department?assessmentYear=" + accessmentRedux + "&faculty=" + facultyId
    );
    if (response) {
      setDepartmentList(response);
    }
  };

  const fetcMajor = async () => {
    const response = await fetchGet("getmajo/" + departmentId);
    if (response) {
      setMajorList(response?.data);
    }
  };

  const fetchFaculties = async () => {
    const response = await fetchGet(
      "faculty?assessmentYear=" + accessmentRedux
    );
    if (response) {
      setFacultyList(response.data);
    }
  };

  useEffect(() => {
    fetchFaculties();
    loadPersonalTypeMaster();
    loadEducationMaster();
    loadTribeMaster();
    loadReligionalMaster();

    let year = [];
    const NOW = new Date().getFullYear() + 6;
    for (var i = NOW; i >= 2014; i--) {
      year.push(i + " - " + (parseInt(i) + 1));
    }
    setAssessmentYear(year);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setDataUser(user.data);
    } else {
      history.push(`/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessmentRedux]);

  useEffect(() => {
    if (dataUser?.role === "ADMIN" || dataUser?.role === "DEAN") {
      setFacultyId("");
      setDepartmentId("");
    } else {
      setFacultyId(dataUser?.faculty?._id);
      setFacultyName(dataUser?.faculty?.name);
      setDepartmentId(dataUser?.department?._id);
      setDepartmentName(dataUser?.department?.name);
      setMajorId(dataUser?.major?._id);
      setMajorName(dataUser?.major?.name);
    }
    setRole(dataUser?.role);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUser]);

  useEffect(() => {
    fetchDepartments();
  }, [facultyId]);

  useEffect(() => {
    fetcMajor();
  }, [departmentId]);

  useEffect(() => {
    setClassroomId("");
    fetchClassRoom();
  }, [majorId, year]);

  useEffect(() => {
    getPresignImage(coverImage);
  }, [coverImage, presignData]);

  /**
   *
   * @Function
   *
   */
  const _handleShowAddConfirmModalClose = () => setShowAddConfirmModal(false);
  const _handleShowAddConfirmModalShow = () => setShowAddConfirmModal(true);

  const _addImage = () => {
    inputImage.current.click();
  };

  const getPresignImage = async (file) => {
    await loadPresignImage({
      variables: {
        mimeType: file?.type,
        fileName: file?.name?.split(".")[0],
      },
    });
  };

  const _handleChangeImage = (event) => {
    if (event.target.files[0]) {
      setPreviewImageURL(URL.createObjectURL(event.target.files[0]));
      setCoverImage(event.target.files[0]);
    }
  };
  const _uploadFile = async () => {
    if (presignData.preSignedUrl) {
      const response = await axios({
        method: "put",
        url: presignData.preSignedUrl.url,
        data: coverImage,
        headers: {
          "Content-Type": " file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      });
      return response;
    }
  };
  const _selectPersonalType = (e) => {
    const personIndex = _.findIndex(
      PersonalTypeData?.personalTypeMasters?.data,
      { id: e.target.value }
    );
    setStudentType(
      PersonalTypeData?.personalTypeMasters?.data[personIndex]?.name
    );
  };
  const _selectFacalty = (e) => {
    setDepartmentId("");
    setDepartmentList([]);
    setMajorId("");
    setMajorList([]);
    setClassroomId("");
    setClassRoomList([]);
    setFacultyId(e.target.value);
    const facIndex = _.findIndex(facultyList, { _id: e.target.value });
    setFacultyName(facultyList[facIndex]?.name);
  };
  const _selectDepartment = (e) => {
    setMajorId("");
    setMajorList([]);
    setClassroomId("");
    setClassRoomList([]);
    setDepartmentId(e.target.value);
    const depIndex = _.findIndex(departmentList, { _id: e.target.value });
    setDepartmentName(departmentList[depIndex]?.name);
  };
  const _selectMajor = (e) => {
    setClassroomId("");
    setClassRoomList([]);
    setMajorId(e.target.value);
    const majorIndex = _.findIndex(majorList, { _id: e.target.value });
    setMajorName(majorList[majorIndex]?.name);
  };
  const _selectClassroom = (e) => {
    setClassroomId(e.target.value);
    const classIndex = _.findIndex(classRoomList, { _id: e.target.value });
    setClassroomName(classRoomList[classIndex]?.name);
  };

  function checkpassword(password) {
    var strength = 0;
    if (password.match(/[a-z]+/)) strength += 1;
    if (password.match(/[A-Z]+/)) strength += 1;
    if (password.match(/[0-9]+/)) strength += 1;
    if (password.match(/[$@#&!%_?]+/)) strength += 1;

    switch (strength) {
      case 0:
        setStrengthPass(0);
        break;

      case 1:
        setStrengthPass(25);
        break;

      case 2:
        setStrengthPass(50);
        break;

      case 3:
        setStrengthPass(75);
        break;

      case 4:
        setStrengthPass(100);
        break;
      default:
        setStrengthPass(0);
        break;
    }
  }
  const checkLength = (password) => {
    if (password && password.length < 6) {
      setCheckPasswordLength("ກະລຸນາປ້ອນໜ້ອຍສຸດຢ່າງໜ້ອຍ 6 ໂຕຂຶ້ນໄປ");
    } else {
      setCheckPasswordLength("");
    }
  };
  // ເລືອກທີຢູ່ປະຈຸບັນ
  const _selectCurrentProvince = (e) => {
    setSelectCurrentDistrict("");
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value,
    });
    setSelectCurrentProvinceIndex(_provinceIndex);
    setSelectCurrentProvince(e.target.value);
  };
  const _selectCurrentDistrict = (e) => {
    setSelectCurrentDistrict(e.target.value);
  };

  const _selectBirthProvince = (e) => {
    setSelectBirthDistrict("");
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value,
    });
    setSelectBirthProvinceIndex(_provinceIndex);
    setSelectBirthProvince(e.target.value);
  };
  const _selectComeFormProvince = (e) => {
    setSelectComeFormProvince(e.target.value);
  };
  const _selectBirthDistrict = (e) => {
    setSelectBirthDistrict(e.target.value);
  };

  const _selectScholarship = (e) => {
    setClickCheckbox(!clickCheckbox);
  };
  const selectScholarship = (e) => {
    setScholarship(e.target.value);
  };

  const [commiteeAssociation, setCommiteeAssociation] = useState(false);
  const [womenAssociation, setWomenAssociation] = useState(false);
  const [youthAssociation, setYouthAssociation] = useState(false);

  const [selectedScholarship, setSelectedScholarship] = useState("");
  const [scholarshipList, setScholarshipList] = useState([]);

  const fetchScholarShiptList = async (req, res) => {
    const response = await fetchGet("scholarshipquota/" + accessmentRedux);
    if (response) {
      setScholarshipList(response.quotas);
    }
  };

  useEffect(() => {
    fetchScholarShiptList();
  }, []);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item
          href=""
          onClick={() =>
            history.push(Routers.STUDENT_LIST + Routers.PAGE_GINATION)
          }
        >
          ຈັດການນັກສຶກສາ
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href=""
          onClick={() =>
            history.push(Routers.STUDENT_LIST + Routers.PAGE_GINATION)
          }
        >
          ລາຍຊື່ນັກສຶກສາທັງໝົດ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ເພີ່ມນັກຮຽນ</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer style={{ marginTop: 0 }}>
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
          }}
        >
          <div className="container">
            <Formik
              initialValues={{
                studyStatus: "STUDYING",
                firstNameL: "",
                lastNameL: "",
                firstNameE: "",
                lastNameE: "",
                faculty: "",
                department: "",
                major: "",
                _assessmentYear: "",
                birthday: "",
                // bornAbroad: "",
                phoneHome: "",
                phoneMobile: "",
                email: "",
                description: "",
                userId: "",
                password: "",
                confirmPassword: "",
                note: "",
                yearLevel: "",
                day: 0,
                month: 0,
                year: 0,
                gender: "",
                maritualStatus: "SINGLE",
                scholarship: "",
                scholarshipFrom: "",
                graduatedAccessMentYear: "",
                tribeMasters: "",
                classRoom: "",
              }}
              validate={(values) => {
                checkpassword(values.password);
                checkLength(values.password);
                const errors = {};
                if (!values._assessmentYear)
                  errors.assessmentYear = "ກະລຸນາເລືອກສົກຮຽນ";
                return errors;
              }}
              onSubmit={async (values) => {
                if (values.password !== values.confirmPassword) {
                  alertWarnings("ກະລຸນາຢືນຢັນລະຫັດຜ່ານກ່ອນ");
                  return;
                }
                if (!values._assessmentYear || values._assessmentYear === "")
                  values._assessmentYear = assessmentYear[0];
                if (!values.yearLevel) delete values.yearLevel;
                if (!values.email) delete values.email;
                if (!values.phone) delete values.phone;
                if (values.faculty === "") {
                  values = {
                    ...values,
                    faculty: facultyId,
                  };
                }
                if (values.classRoom && classroomId) {
                  values = {
                    ...values,
                    classRoom: classroomId,
                  };
                } else {
                  delete values.classRoom;
                }
                if (values.Education) {
                  values = {
                    ...values,
                    educationLevel: values.Education,
                  };
                  delete values.Education;
                } else {
                  delete values.Education;
                }
                if (values.religional) {
                  values = {
                    ...values,
                    religion: values.religional,
                  };
                  delete values.religional;
                } else {
                  delete values.religional;
                }
                if (values.tribeMasters) {
                  let tribe = values.tribeMasters;
                  values = {
                    ...values,
                    tribe: tribe,
                  };
                  delete values.tribeMasters;
                } else {
                  delete values.tribeMasters;
                }
                if (values.relationship || values.isLiveTogether) {
                  let emergencyContact = {
                    relationship: values.relationship,
                    isLiveTogether: String(values.isLiveTogether),
                  };
                  values = {
                    ...values,
                    emergencyContact: emergencyContact,
                  };
                  delete values.relationship;
                  delete values.isLiveTogether;
                } else {
                  delete values.relationship;
                  delete values.isLiveTogether;
                }

                if (selectCurrentProvince) {
                  let address = {
                    province: selectCurrentProvince,
                    district: selectCurrentDistrict,
                    village: values.currentVillage,
                  };
                  values = {
                    ...values,
                    address: address,
                  };
                  delete values.currentVillage;
                }

                if (selectBirthProvince) {
                  let birthAddress = {
                    province: selectBirthProvince,
                    district: selectBirthDistrict,
                    village: values.birthVillage,
                  };

                  values = {
                    ...values,
                    birthAddress: birthAddress,
                    bornAbroad: "",
                  };
                }
                if (isBornAbroad === true) {
                  let birthAddress = {
                    country: values.birthCountry,
                    province: values.birthProvince,
                    district: values.birthDistrict,
                    village: values.birthVillage,
                  };
                  values = {
                    ...values,
                    birthAddress: birthAddress,
                    bornAbroad: "true",
                  };
                }

                if (
                  studentType === "ນັກຮຽນຕ່າງປະເທດ" &&
                  values.currentCountry
                ) {
                  let address = {
                    country: values.currentCountry,
                    province: values.currentProvince,
                    district: values.currentDistrict,
                    village: values.currentVillage,
                  };
                  values = {
                    ...values,
                    address: address,
                  };
                }

                if (studentType === "ນັກຮຽນຕ່າງປະເທດ" && values.birthCountry) {
                  let birthAddress = {
                    country: values.birthCountry,
                    province: values.birthProvince,
                    district: values.birthDistrict,
                    village: values.birthVillage,
                  };
                  values = {
                    ...values,
                    birthAddress: birthAddress,
                  };
                }

                if (
                  clickCheckbox === false &&
                  studentType === "ນັກຮຽນຕ່າງປະເທດ" &&
                  values.graduatedCountry
                ) {
                  let payTuition = {
                    graduatedCountry: values.graduatedCountry,
                    graduatedProvince: values.graduatedFromProvince,
                    graduatedSchool: values.endStudy,
                    graduatedAccessMentYear: values.graduatedAccessMentYear,
                  };
                  values = {
                    ...values,
                    studentType: "OUT_PLAN_EN",
                    scholarship: values.scholarship,
                    payTuition: payTuition,
                    _assessmentYear: values._assessmentYear,
                  };
                }
                if (
                  clickCheckbox === true &&
                  studentType === "ນັກຮຽນຕ່າງປະເທດ" &&
                  values.graduatedCountry
                ) {
                  let comingFrom = {
                    graduatedCountry: values.graduatedCountry,
                    graduatedProvince: values.graduatedFromProvince,
                    graduatedSchool: values.endStudy,
                    graduatedAccessMentYear: values.graduatedAccessMentYear,
                  };
                  values = {
                    ...values,
                    studentType: "IN_PLAN_EN",
                    scholarship: values.scholarship,
                    comingFrom: comingFrom,
                    _assessmentYear: values._assessmentYear,
                  };
                }

                if (clickCheckbox === false && selectComeFormProvince) {
                  let payTuition = {
                    graduatedProvince: selectComeFormProvince,
                    graduatedSchool: values.endStudy,
                    graduatedAccessMentYear: values.graduatedAccessMentYear,
                  };
                  values = {
                    ...values,
                    studentType: "OUT_PLAN",
                    scholarship: "NO",
                    payTuition: payTuition,
                    _assessmentYear: values._assessmentYear,
                  };
                }
                if (clickCheckbox === true && selectComeFormProvince) {
                  let comingFrom = {
                    graduatedProvince: selectComeFormProvince,
                    graduatedSchool: values.endStudy,
                    graduatedAccessMentYear: values.graduatedAccessMentYear,
                  };
                  values = {
                    ...values,
                    studentType: "IN_PLAN",
                    scholarship: values.scholarship,
                    comingFrom: comingFrom,
                    _assessmentYear: values._assessmentYear,
                  };
                }

                values = {
                  ...values,
                  scholarShipQuota: selectedScholarship,
                  commiteeAssociation: commiteeAssociation,
                  womenAssociation: womenAssociation,
                  youthAssociation: youthAssociation,
                };

                let fileName = null;
                if (coverImage) {
                  const res = await _uploadFile();
                  fileName = res?.request?.responseURL
                    ?.split("?")[0]
                    .split("/")[4];
                  values = {
                    ...values,
                    image: { url: fileName },
                  };
                }

                let data = {
                  ...values,
                  scholarshipAgreementNo: values.scholarshipAgreementNo,
                  dateScholarshipAgreementNo:
                    values.dateScholarshipAgreementNo ?? null,
                  transferLetterNo: values.transferLetterNo,
                  dateTransferLetterNo: values.dateTransferLetterNo ?? null,
                  _assessmentYear: values._assessmentYear,
                };
                delete values.currentCountry;
                delete values.currentProvince;
                delete values.currentDistrict;
                delete values.currentVillage;
                delete values.birthCountry;
                delete values.birthProvince;
                delete values.birthDistrict;
                delete values.birthVillage;
                delete values.graduatedCountry;
                delete values.graduatedFromProvince;
                delete data.birthCountry;
                delete data.birthProvince;
                delete data.birthDistrict;
                delete data.birthVillage;
                delete data.graduatedAccessMentYear;
                delete data.endStudy;


                let paramQL = { data };
                setFormParam(paramQL);
                _handleShowAddConfirmModalShow();
              }}
            >
              {({ values, errors, handleChange, handleSubmit }) => (
                <form>
                  <Row>
                    <Col sm={12}>
                      <h5>
                        <strong>ເພີ່ມນັກຮຽນ</strong>
                      </h5>{" "}
                      <hr />
                    </Col>
                    <Col sm={12}>
                      <img
                        alt=""
                        src={
                          previewImageURL
                            ? previewImageURL
                            : "https://www.seekpng.com/png/detail/414-4140251_you-profile-picture-question-mark.png"
                        }
                        className="browserImage"
                      />
                      <input
                        type="file"
                        id="inputImage"
                        multiple
                        name="image"
                        ref={inputImage}
                        style={{ display: "none" }}
                        onChange={_handleChangeImage}
                      />
                      <Button
                        variant="outline-secondary"
                        onClick={_addImage}
                        style={{ marginLeft: 20, width: 140 }}
                      >
                        <i className="fa fa-download"></i> ໂຫຼດຮູບ
                      </Button>
                      <Row>
                        <Col sm={12}>
                          <div className="heading">
                            <b>ກະລຸນາປ້ອນລະຫັດນັກສຶກສາ</b>
                          </div>
                        </Col>
                      </Row>
                      <div className="form-group">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="ກະລຸນາປ້ອນລະຫັດນັກສຶກສາ"
                          name="studentNumber"
                          value={values.studentNumber}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <div className="heading">
                        <b>ຂໍ້ມູນນັກສຶກສາ</b>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>ຄະນະ {valiDate()}</label>
                      {facultyList && (
                        <Form.Control
                          as="select"
                          onChange={(e) => {
                            _selectFacalty(e);
                            handleChange(e);
                          }}
                          name="faculty"
                          disabled={
                            role !== "ADMIN" && role !== "DEAN" ? true : false
                          }
                          value={facultyId !== "" ? facultyId : values.faculty}
                        >
                          <option disabled={true} value="">
                            ---ກະລຸນາເລືອກຄະນະ---
                          </option>
                          {facultyList?.map((x, index) => (
                            <option key={index} value={x?._id}>
                              {x.name}
                            </option>
                          ))}
                        </Form.Control>
                      )}
                    </Col>
                    <Col>
                      <label>ພາກວິຊາ {valiDate()}</label>
                      <Form.Control
                        as="select"
                        name="department"
                        value={!departmentId ? departmentId : values.department}
                        onChange={(e) => {
                          handleChange(e);
                          _selectDepartment(e);
                        }}
                      >
                        <option value="">---ກະລຸນາເລືອກພາກວິຊາ---</option>
                        {departmentList?.map((x, index) => (
                          <option key={index} value={x?._id}>
                            {x.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col>
                      <label>ສາຂາວິຊາ {valiDate()}</label>
                      <Form.Control
                        as="select"
                        name="major"
                        value={!majorId ? majorId : values.major}
                        onChange={(e) => {
                          handleChange(e);
                          _selectMajor(e);
                        }}
                      >
                        <option value="">---ກະລຸນາເລືອກສາຂາວິຊາ---</option>
                        {majorList?.map((x, index) => (
                          <option key={index} value={x?._id}>
                            {x.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col sm={2}>
                      <div className="form-group">
                        <label>ສົກສຶກສາ {valiDate()}</label>
                        <Form.Control
                          as="select"
                          name="_assessmentYear"
                          value={values._assessmentYear}
                          isInvalid={!!errors.assessmentYear}
                          onChange={handleChange}
                        >
                          <option disabled={true} value="">
                            ---ກະລຸນາປ້ອນສົກຮຽນ---
                          </option>
                          {assessmentYear.map((x, assesIndex) => {
                            return (
                              <option key={assesIndex} value={x}>
                                {x}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </div>
                    </Col>
                    <Col sm={2}>
                      <div className="form-group">
                        <label>ປີຮຽນ {valiDate()}</label>
                        <Form.Control
                          as="select"
                          name="yearLevel"
                          value={values.yearLevel}
                          onChange={(e) => {
                            handleChange(e);
                            setYear(e?.target?.value);
                          }}
                        >
                          <option disabled={true} value="">
                            ---ກະລຸນາປີຮຽນ---
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                        </Form.Control>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <div className="form-group">
                        <strong>ຂໍ້ມູນນັກສຶກສາ {valiDate()}</strong>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="form-group">
                        <select
                          className="form-control"
                          onChange={(e) => {
                            handleChange(e);
                            _selectPersonalType(e);
                          }}
                          name="personalType"
                          value={values.personalType}
                        >
                          <option value="">ເລືອກຂໍ້ມູນນັກສຶກສາ</option>
                          {PersonalTypeData?.personalTypeMasters?.data?.map(
                            (personalType, index) => (
                              <option
                                key={"personalType" + index}
                                value={personalType?.id}
                              >
                                {personalType?.name}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <div className="form-group">
                        <strong>ລະດັບສຶກສາ {valiDate()}</strong>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="form-group">
                        <select
                          className="form-control"
                          onChange={handleChange}
                          name="Education"
                          value={values.Education}
                        >
                          <option value="">ເລືອກລະດັບສຶກສາ</option>
                          {EducationData?.educationLevelMasters?.data?.map(
                            (Education, index) => (
                              <option
                                key={"Education" + index}
                                value={Education?.id}
                              >
                                {Education?.name}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <div className="form-group">
                        <strong>ຫ້ອງຮຽນ {valiDate()}</strong>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="form-group">
                        <Form.Control
                          as="select"
                          name="classRoom"
                          value={!classroomId ? classroomId : values.classRoom}
                          onChange={(e) => {
                            handleChange(e);
                            _selectClassroom(e);
                          }}
                        >
                          <option value="">ເລືອກຫ້ອງຮຽນ</option>
                          {classRoomList?.map((x, index) => (
                            <option key={index} value={x?._id}>
                              {x.name}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={2}>
                      <div className="form-group">
                        <strong>ສະຖານະການຮຽນ {valiDate()}</strong>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="form-group">
                        <select
                          className="form-control"
                          onChange={handleChange}
                          name="personalType"
                          value={values.studyStatus}
                        >
                          <option value="">ເລືອກສະຖານະການຮຽນ</option>
                          <option value="STUDYING">ກຳລັງສຶກສາ</option>
                          <option value="DROP">ໂຈະການຮຽນ</option>
                          <option value="DONT_STUDY">ປະລະການຮຽນ</option>
                          <option value="GRADUATED">ຈົບການສຶກສາ</option>
                        </select>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={2}>
                      <div className="form-group">
                        <strong>ໄດ້ທຶນການສຶກສາາ</strong>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="form-group">
                        {scholarshipList.length === 0 ? (
                          <select className="form-control" disabled>
                            <option>ບໍ່ມີຂໍ້ມູນທຶນການສຶກສາ</option>
                          </select>
                        ) : (
                          <select
                            className="form-control"
                            onChange={(e) =>
                              setSelectedScholarship(e.target.value)
                            }
                            value={selectedScholarship}
                          >
                            {scholarshipList.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.title}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row className="align-items-center">
                    <Col sm={2}>
                      <div className="form-group">
                        <strong>ຂໍ້ມູນທົ່ວໄປ</strong>
                      </div>
                    </Col>

                    <Col sm="auto">
                      <Form.Check
                        inline
                        label="ເປັນແມ່ຍິງ"
                        type="checkbox"
                        id="womenAssociation-checkbox"
                        name="womenAssociation"
                        checked={womenAssociation}
                        onChange={() => setWomenAssociation(!womenAssociation)}
                      />
                    </Col>

                    <Col sm="auto">
                      <Form.Check
                        inline
                        label="ເປັນຊາວຫມຸ່ມ"
                        type="checkbox"
                        id="youthAssociation-checkbox"
                        checked={youthAssociation}
                        onChange={() => setYouthAssociation(!youthAssociation)}
                      />
                    </Col>

                    <Col sm="auto">
                      <Form.Check
                        inline
                        label="ເປັນກຳມະບານ"
                        type="checkbox"
                        id="commiteeAssociation-checkbox"
                        checked={commiteeAssociation}
                        onChange={() =>
                          setCommiteeAssociation(!commiteeAssociation)
                        }
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12}>
                      <div className="heading">
                        <b>ໄອດີແລະລະຫັດຜ່ານ</b>
                      </div>
                    </Col>
                  </Row>
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ໄອດີ
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="ກະລຸນາປ້ອນ"
                        name="userId"
                        value={values.userId}
                        onChange={handleChange}
                        isInvalid={!!errors.userId}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ລະຫັດຜ່ານ
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="ກະລຸນາປ້ອນ"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />
                      <div>
                        <Progress multi>
                          <Progress
                            bar
                            color="danger"
                            value={strengthPass >= 25 ? 25 : 0}
                          >
                            {strengthPass < 25 ? "" : "ຕ່ຳ"}
                          </Progress>
                          <Progress
                            bar
                            color="warning"
                            value={strengthPass >= 50 ? 25 : 0}
                          >
                            {strengthPass < 50 ? "" : "ກາງ"}
                          </Progress>
                          <Progress
                            bar
                            color="info"
                            value={strengthPass >= 75 ? 25 : 0}
                          >
                            {strengthPass < 75 ? "" : "ສູງ"}
                          </Progress>
                          <Progress
                            bar
                            color="success"
                            value={strengthPass >= 100 ? 25 : 0}
                          >
                            {strengthPass < 100 ? "" : "ສຸດຍອດ"}
                          </Progress>
                        </Progress>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                      {checkPasswordLength ? (
                        <p style={{ color: "red", fontSize: 12 }}>
                          {checkPasswordLength}
                        </p>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ຍືນຍັນລະຫັດຜ່ານ
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="ກະລຸນາປ້ອນ"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        isInvalid={!!errors.confirmPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmPassword}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Row>
                    <Col sm={12}>
                      <br />
                      <div className="heading">
                        <b>ຂໍ້ມູນທົ່ວໄປ</b>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <div className="form-group">
                        <label>ຊື່ {valiDate()}</label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="ກະລຸນາປ້ອນຊື່"
                          name="firstNameL"
                          value={values.firstNameL}
                          onChange={handleChange}
                          isInvalid={!!errors.firstNameL}
                        />
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="form-group">
                        <label>ນາມສະກຸນ {valiDate()}</label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="ກະລຸນາປ້ອນນາມສະກຸນ"
                          name="lastNameL"
                          value={values.lastNameL}
                          onChange={handleChange}
                          isInvalid={!!errors.lastNameL}
                        />
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="form-group">
                        <label>ຊື່(ອັງກິດ) {valiDate()}</label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="ກະລຸນາປ້ອນຊື່(ອັງກິດ)"
                          name="firstNameE"
                          value={values.firstNameE}
                          onChange={handleChange}
                          isInvalid={!!errors.firstNameE}
                        />
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="form-group">
                        <label>ນາມສະກຸນ(ອັງກິດ) {valiDate()}</label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="ກະລຸນາປ້ອນນາມສະກຸນ(ອັງກິດ)"
                          name="lastNameE"
                          value={values.lastNameE}
                          onChange={handleChange}
                          isInvalid={!!errors.lastNameE}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Label>ວັນເດືອນປີເກີດ {valiDate()}</Form.Label>
                      <div className="form-group">
                        <Form.Control
                          type="date"
                          className="form-control"
                          placeholder="ວັນທີ"
                          name="birthday"
                          value={values.birthday}
                          onChange={handleChange}
                          isInvalid={!!errors.birthday}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={1.5} style={{ marginLeft: 13 }}>
                      <label>ເລືອກເພດ {valiDate()}</label>
                    </Col>
                    <Col sm={2}>
                      <div className="form-group">
                        <Form.Check
                          inline
                          label="ຊາຍ"
                          type="radio"
                          value="MALE"
                          id="gender-radio"
                          name="gender"
                          onChange={handleChange}
                          defaultChecked={
                            values.gender === "MALE" ? true : false
                          }
                        />
                      </div>
                    </Col>
                    <Col sm={2}>
                      <div className="form-group">
                        <Form.Check
                          inline
                          label="ຍິງ"
                          type="radio"
                          value="FEMALE"
                          id="gender-radio"
                          name="gender"
                          onChange={handleChange}
                          defaultChecked={
                            values.gender === "FEMALE" ? true : false
                          }
                        />
                      </div>
                    </Col>
                    <Col sm={2}>
                      <div className="form-group">
                        <Form.Check
                          inline
                          label="ພຣະ"
                          type="radio"
                          value="MONK"
                          id="gender-radio"
                          name="gender"
                          onChange={handleChange}
                          defaultChecked={
                            values.gender === "MONK" ? true : false
                          }
                        />
                      </div>
                    </Col>
                    <Col sm={2}>
                      <div className="form-group">
                        <Form.Check
                          inline
                          label="ອື່ນໆ"
                          type="radio"
                          value="OTHER"
                          id="gender-radio"
                          name="gender"
                          onChange={handleChange}
                          defaultChecked={
                            values.gender === "OTHER" ? true : false
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={1.5} style={{ marginLeft: 13 }}>
                      <label>ສະຖານະ {valiDate()}</label>
                    </Col>
                    <Col sm={2}>
                      <div className="form-group">
                        <Form.Check
                          inline
                          label="ໂສດ"
                          type="radio"
                          value="SINGLE"
                          id="maritualStatus-radio"
                          name="maritualStatus"
                          onChange={handleChange}
                          defaultChecked={
                            values.maritualStatus === "SINGLE" ? true : false
                          }
                        />
                      </div>
                    </Col>
                    <Col sm={2}>
                      <div className="form-group">
                        <Form.Check
                          inline
                          label="ແຕ່ງງານແລ້ວ"
                          type="radio"
                          value="MARRIAGE"
                          id="maritualStatus-radio"
                          name="maritualStatus"
                          onChange={handleChange}
                          defaultChecked={
                            values.maritualStatus === "MARRIAGE" ? true : false
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  {studentType === "ນັກຮຽນຕ່າງປະເທດ" ? (
                    <Row>
                      <Col sm={6}>
                        <div className="form-group">
                          <label>ສັນຊາດ {valiDate()}</label>
                          <Form.Control
                            as="select"
                            name="nationality"
                            value={values.nationality}
                            onChange={handleChange}
                          >
                            <option value="">ເລືອກ</option>
                            {COUNTRIES_LIST.map((nationalities, index) => (
                              <option
                                key={"nationalities" + index}
                                value={nationalities?.nationality}
                              >
                                {nationalities?.nationality}
                              </option>
                            ))}
                          </Form.Control>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="form-group">
                          <label>ສາສະໜາ {valiDate()}</label>
                          <select
                            className="form-control"
                            onChange={handleChange}
                            name="religional"
                            value={values.religional}
                          >
                            <option value="">ເລືອກ</option>
                            {religionalMasterData?.religionalMasters?.data?.map(
                              (religional, index) => (
                                <option
                                  key={"religional" + index}
                                  value={religional?.id}
                                >
                                  {religional?.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col sm={4}>
                        <div className="form-group">
                          <label>ສັນຊາດ {valiDate()}</label>
                          <Form.Control
                            as="select"
                            name="nationality"
                            value={values.nationality}
                            onChange={handleChange}
                          >
                            <option value="">ເລືອກ</option>
                            <option value="ລາວ">ລາວ</option>
                            <option value="ໄທ">ໄທ</option>
                            <option value="ຈີນ">ຈີນ</option>
                            <option value="ຍີ່ປຸ່ນ">ຍີ່ປຸ່ນ</option>
                            <option value="ເກົາຫຼີ">ເກົາຫຼີ</option>
                            <option value="ຫວຽດ">ຫວຽດ</option>
                          </Form.Control>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="form-group">
                          <label>ຊົນເຜົ່າ {valiDate()}</label>
                          <select
                            className="form-control"
                            onChange={handleChange}
                            name="tribeMasters"
                            value={values.tribeMasters}
                          >
                            <option value="">ເລືອກ</option>
                            {tribeMasterData?.tribeMasters?.data?.map(
                              (tribeMasters, index) => (
                                <option
                                  key={"tribe" + index}
                                  value={tribeMasters?.id}
                                >
                                  {tribeMasters?.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="form-group">
                          <label>ສາສະໜາ {valiDate()}</label>
                          <select
                            className="form-control"
                            onChange={handleChange}
                            name="religional"
                            value={values.religional}
                          >
                            <option value="">ເລືອກ</option>
                            {religionalMasterData?.religionalMasters?.data?.map(
                              (religional, index) => (
                                <option
                                  key={"religional" + index}
                                  value={religional?.id}
                                >
                                  {religional?.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col sm={4}>
                      <div className="form-group">
                        <label>ເບີໂທເຮືອນ {valiDate()}</label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="ກະລຸນາປ້ອນເບີເຮືອນ"
                          name="phoneHome"
                          value={values.phoneHome}
                          onChange={handleChange}
                          isInvalid={!!errors.phoneHome}
                        />
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="form-group">
                        <label>ເບີໂທລະສັບມືຖື {valiDate()}</label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="ກະລຸນາປ້ອນເບີໂທລະສັບມືຖື"
                          name="phoneMobile"
                          value={values.phoneMobile}
                          onChange={handleChange}
                          isInvalid={!!errors.phoneMobile}
                        />
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="form-group">
                        <label>ອີເມວ {valiDate()}</label>
                        <Form.Control
                          type="email"
                          className="form-control"
                          placeholder="ກະລຸນາປ້ອນອີເມວ"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <div className="heading">
                        <b>ທີ່ຢູ່ປັດຈຸບັນ</b>
                      </div>
                    </Col>
                  </Row>
                  {studentType === "ນັກຮຽນຕ່າງປະເທດ" ? (
                    <Row>
                      <Col sm={3}>
                        <div className="form-group">
                          <label>ປະເທດ {valiDate()}</label>
                          <select
                            className="form-control"
                            name="currentCountry"
                            value={values.currentCountry}
                            onChange={handleChange}
                          >
                            <option value="">---ເລືອກປະເທດ---</option>
                            {COUNTRIES_LIST.map((countries, index) => (
                              <option
                                key={"countries" + index}
                                value={countries?.en_short_name}
                              >
                                {countries?.en_short_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-group">
                          <label>ແຂວງ {valiDate()}</label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນແຂວງ"
                            name="currentProvince"
                            value={values.currentProvince}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-group">
                          <Form.Label>ເມືອງ {valiDate()}</Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນເມືອງ"
                            name="currentDistrict"
                            value={values.currentDistrict}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-group">
                          <label>ບ້ານ {valiDate()}</label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນບ້ານ"
                            name="currentVillage"
                            value={values.currentVillage}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col sm={4}>
                        <div className="form-group">
                          <label>ແຂວງ {valiDate()}</label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              _selectCurrentProvince(e);
                              handleChange(e);
                            }}
                          >
                            <option value="">---ເລືອກແຂວງ---</option>
                            {LAO_ADDRESS.map((province, index) => (
                              <option key={"province" + index}>
                                {province?.province_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="form-group">
                          <Form.Label>ເມືອງ {valiDate()}</Form.Label>
                          <Form.Control
                            as="select"
                            className="form-control"
                            value={selectCurrentDistrict}
                            onChange={(e) => {
                              _selectCurrentDistrict(e);
                              handleChange(e);
                            }}
                          >
                            <option disabled={true} value="">
                              ---ເລືອກ---
                            </option>
                            {selectCurrentProvinceIndex > -1 &&
                              LAO_ADDRESS[
                                selectCurrentProvinceIndex
                              ]?.district_list?.map((district, curentIndex) => (
                                <option key={"current-district" + curentIndex}>
                                  {district.district}
                                </option>
                              ))}
                          </Form.Control>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="form-group">
                          <label>ບ້ານ {valiDate()}</label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນບ້ານ"
                            name="currentVillage"
                            value={values.currentVillage}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col sm={12}>
                      <div className="heading">
                        <b>ສະຖານທີ່ເກີດ</b>
                      </div>
                    </Col>
                  </Row>
                  {studentType === "ນັກຮຽນຕ່າງປະເທດ" ? (
                    <Row>
                      <Col sm={3}>
                        <div className="form-group">
                          <label>ປະເທດ {valiDate()}</label>
                          <select
                            className="form-control"
                            name="birthCountry"
                            value={values.birthCountry}
                            onChange={handleChange}
                          >
                            <option value="">---ເລືອກປະເທດ---</option>
                            {COUNTRIES_LIST.map((countries, index) => (
                              <option
                                key={"countries" + index}
                                value={countries?.en_short_name}
                              >
                                {countries?.en_short_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-group">
                          <label>ແຂວງ {valiDate()}</label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນແຂວງ"
                            name="birthProvince"
                            value={values.birthProvince}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-group">
                          <label>ເມືອງ {valiDate()}</label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນເມືອງ"
                            name="birthDistrict"
                            value={values.birthDistrict}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-group">
                          <label>ບ້ານ {valiDate()}</label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນບ້ານ"
                            name="birthVillage"
                            value={values.birthVillage}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <Row>
                        <Col sm="12">
                          <Form.Check
                            type="checkbox"
                            label="ນັກສຶກສາເກີດໃນຕ່າງປະເທດ"
                            inline
                            value={isBornAbroad}
                            defaultChecked={isBornAbroad}
                            onClick={() => setIsBornAbroad(!isBornAbroad)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        {isBornAbroad ? (
                          <Row>
                            <Col sm={3}>
                              <div className="form-group">
                                <label>ປະເທດ {valiDate()}</label>
                                <select
                                  className="form-control"
                                  name="birthCountry"
                                  value={values.birthCountry}
                                  onChange={handleChange}
                                >
                                  <option value="">---ເລືອກປະເທດ---</option>
                                  {COUNTRIES_LIST.map((countries, index) => (
                                    <option
                                      key={"countries" + index}
                                      value={countries?.en_short_name}
                                    >
                                      {countries?.en_short_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            <Col sm={3}>
                              <div className="form-group">
                                <label>ແຂວງ {valiDate()}</label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  placeholder="ກະລຸນາປ້ອນແຂວງ"
                                  name="birthProvince"
                                  value={values.birthProvince}
                                  onChange={handleChange}
                                />
                              </div>
                            </Col>
                            <Col sm={3}>
                              <div className="form-group">
                                <label>ເມືອງ {valiDate()}</label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  placeholder="ກະລຸນາປ້ອນເມືອງ"
                                  name="birthDistrict"
                                  value={values.birthDistrict}
                                  onChange={handleChange}
                                />
                              </div>
                            </Col>
                            <Col sm={3}>
                              <div className="form-group">
                                <label>ບ້ານ {valiDate()}</label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  placeholder="ກະລຸນາປ້ອນບ້ານ"
                                  name="birthVillage"
                                  value={values.birthVillage}
                                  onChange={handleChange}
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <>
                            <Col sm={4}>
                              <div className="form-group">
                                <label>ແຂວງ {valiDate()}</label>
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    _selectBirthProvince(e);
                                    handleChange(e);
                                  }}
                                >
                                  <option value="">---ເລືອກແຂວງ---</option>
                                  {LAO_ADDRESS.map((province, proIndex) => (
                                    <option key={"province" + proIndex}>
                                      {province?.province_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <label>ເມືອງ {valiDate()}</label>
                                <Form.Control
                                  as="select"
                                  className="form-control"
                                  name="district"
                                  value={selectBirthDistrict}
                                  onChange={(e) => {
                                    _selectBirthDistrict(e);
                                    handleChange(e);
                                  }}
                                >
                                  <option disabled={true} value="">
                                    ---ເລືອກ---
                                  </option>
                                  {selectBirthProvinceIndex > -1 &&
                                    LAO_ADDRESS[
                                      selectBirthProvinceIndex
                                    ].district_list.map((district, index) => (
                                      <option key={"birth-district" + index}>
                                        {district.district}
                                      </option>
                                    ))}
                                </Form.Control>
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className="form-group">
                                <label>ບ້ານ {valiDate()}</label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  placeholder="ກະລຸນາປ້ອນບ້ານ"
                                  name="birthVillage"
                                  value={values.birthVillage}
                                  onChange={handleChange}
                                />
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col sm={4} style={{ marginTop: 20 }}>
                      <div className="form-group">
                        <Form.Check
                          type="checkbox"
                          label="ທຶນແບ່ງປັນຈາກພາກສ່ວນ"
                          inline
                          value={false}
                          defaultChecked={false}
                          onClick={() => _selectScholarship()}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <div
                        className="heading"
                        style={{
                          display: clickCheckbox === true ? "flex" : "none",
                        }}
                      >
                        <b>ນັກສຶກສາ/ພະນັກງານ ທຶນແບ່ງປັນຈາກພາກສ່ວນ</b>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      marginTop: 22,
                      display: clickCheckbox === true ? "flex" : "none",
                    }}
                    disabled={true}
                  >
                    {studentType === "ນັກຮຽນຕ່າງປະເທດ" ? (
                      <>
                        <Col sm="12" style={{ marginTop: 20 }}>
                          <Form.Control
                            as="select"
                            name="scholarship"
                            value={values.scholarship}
                            isInvalid={errors.scholarship}
                            onChange={(e) => {
                              selectScholarship(e);
                              handleChange(e);
                            }}
                          >
                            <option value="">---ເລືອກ---</option>
                            <option value="NUOL_SCHOLARSHIP">
                              NUOL's talent student
                            </option>
                            <option value="ETHNIC_STUDENT_OF_PROVINCIAL">
                              Provincial ethnic student
                            </option>
                            <option value="RED_DIPLOMA_SCHOLARSHIP">
                              First-class honours
                            </option>
                            <option value="ANTIONAL_STUDENT_SCHOLARSHIP">
                              Outstanding student in general subject/talent
                              student
                            </option>
                            <option value="MINISTRY_OF_EDUCATION_AND_SPORTS">
                              Ministry of Education and Sports
                            </option>
                            <option value="OTHER_SHOLARSHIP">
                              Other scholarship
                            </option>
                          </Form.Control>
                        </Col>
                        <Col sm={3} style={{ marginTop: 20 }}>
                          <div className="form-group">
                            <label>ຈົບຈາກປະເທດ {valiDate()}</label>
                            <select
                              className="form-control"
                              name="graduatedCountry"
                              value={values.graduatedCountry}
                              onChange={handleChange}
                            >
                              <option value="">---ເລືອກປະເທດ---</option>
                              {COUNTRIES_LIST.map((countries, index) => (
                                <option
                                  key={"countries" + index}
                                  value={countries?.en_short_name}
                                >
                                  {countries?.en_short_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col sm={3} style={{ marginTop: 20 }}>
                          <div className="form-group">
                            <label>ຈົບຈາກແຂວງ {valiDate()}</label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              placeholder="ກະລຸນາປ້ອນແຂວງ"
                              name="graduatedFromProvince"
                              value={values.graduatedFromProvince}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>
                        <Col sm="3" style={{ marginTop: 20 }}>
                          <Form.Label>ຈົບ​ຈາກ​ໂຮງ​ຮຽນ {valiDate()}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ກະລຸນາປ້ອນ"
                            value={values.endStudy}
                            name="endStudy"
                            isInvalid={errors.endStudy}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col sm="3" style={{ marginTop: 20 }}>
                          <Form.Label>ສົກ​ສຶກ​ສາ {valiDate()}</Form.Label>
                          <Form.Control
                            as="select"
                            name="graduatedAccessMentYear"
                            value={values.graduatedAccessMentYear}
                            onChange={handleChange}
                          >
                            <option disabled={true} value="">
                              ---ກະລຸນາສົກຮຽນ---
                            </option>
                            {assessmentYear.map((x, yearIndex) => {
                              return (
                                <option key={yearIndex} value={x}>
                                  {x}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col sm="12" style={{ marginTop: 20 }}>
                          <Form.Control
                            as="select"
                            name="scholarship"
                            value={values.scholarship}
                            isInvalid={errors.scholarship}
                            onChange={(e) => {
                              selectScholarship(e);
                              handleChange(e);
                            }}
                          >
                            <option value="">---ເລືອກ---</option>
                            <option value="ANTIONAL_STUDENT_SCHOLARSHIP">
                              ນັກ​ຮຽນ​ເກັ່ງ​ລະ​ດັບ​ຊາດ
                            </option>
                            <option value="NUOL_SCHOLARSHIP">
                              ນັກ​ຮຽນ​ເສັງ​ໄດ້​ທຶນ ມ​ຊ
                            </option>
                            <option value="RED_DIPLOMA_SCHOLARSHIP">
                              ນັກ​ຮຽນ​ເກັ່ງ​ປ​ະ​ກາ​ສະ​ນິ​ຍະ​ບັດ​ແດງ
                            </option>
                            <option value="NO_ONE_STUDENT_OF_PROVINCIAL">
                              ນັກ​ຮຽນ​ເກັ່ງ​ເສັງ​ໄດ້​ທີ່ 1 ຂອງ​ແຂວງ
                            </option>
                            <option value="ETHNIC_STUDENT_OF_PROVINCIAL">
                              ນັກ​ຮຽນ​ຊົນ​ເຜົ່າ​ຂອ​ງ​ແຂວງ
                            </option>
                            <option value="EXCHANGE_STUDENT_SCHOLARSHIP">
                              ທຶນ​ແບ່ງ​ນັກ​ຮຽນ​ສາ​ມັນ
                            </option>
                            <option value="OFFICIAL_STAFF_SCHOLARSHIP">
                              ພະ​ນັກ​ງານ
                            </option>
                            <option value="OTHER_SHOLARSHIP">
                              ທຶນ​ແບ່ງ​ປັນ​ອື່ນໆ
                            </option>
                          </Form.Control>
                        </Col>
                        <Col sm="4" style={{ marginTop: 20 }}>
                          <Form.Label>ຈົບ​ຈາກແຂວງ {valiDate()}</Form.Label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              _selectComeFormProvince(e);
                              handleChange(e);
                            }}
                          >
                            <option value="">---ເລືອກແຂວງ---</option>
                            {LAO_ADDRESS.map((province, index) => (
                              <option key={"province" + index}>
                                {province?.province_name}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <Col sm="4" style={{ marginTop: 20 }}>
                          <Form.Label>ຈົບ​ຈາກ​ໂຮງ​ຮຽນ {valiDate()}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ກະລຸນາປ້ອນ"
                            value={values.endStudy}
                            name="endStudy"
                            isInvalid={errors.endStudy}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col sm="4" style={{ marginTop: 20 }}>
                          <Form.Label>ສົກ​ສຶກ​ສາ {valiDate()}</Form.Label>
                          <Form.Control
                            as="select"
                            name="graduatedAccessMentYear"
                            value={values.graduatedAccessMentYear}
                            onChange={handleChange}
                          >
                            <option disabled={true} value="">
                              ---ກະລຸນາສົກຮຽນ---
                            </option>
                            {assessmentYear.map((x, yearIndex) => {
                              return (
                                <option key={yearIndex} value={x}>
                                  {x}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </>
                    )}
                    <Col sm="6" style={{ marginTop: 20 }}>
                      <Form.Label>ເລກທີໃບນຳສົ່ງ {valiDate()}</Form.Label>
                      <Form.Control
                        type="text"
                        name="transferLetterNo"
                        placeholder="ກະ​ລຸ​ນາ​ ປ້ອນເລກທີໃບນຳສົ່ງ"
                        isInvalid={errors.transferLetterNo}
                        onChange={handleChange}
                        value={values.transferLetterNo}
                      />
                    </Col>
                    <Col sm="6" style={{ marginTop: 20 }}>
                      <Form.Label>ລົງ​ວັນ​ທີ່ {valiDate()}</Form.Label>
                      <Form.Control
                        type="date"
                        value={values.dateTransferLetterNo}
                        name="dateTransferLetterNo"
                        onChange={handleChange}
                        isInvalid={!!errors.dateTransferLetterNo}
                      />
                    </Col>
                    <Col sm="6" style={{ marginTop: 20 }}>
                      <Form.Label>
                        ເລກທີຂໍ້ຕົກລົງເສັງ​ໄດ້​ທຶນ ມ​ຊ {valiDate()}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="ເລກທີຂໍ້ຕົກລົງເສັງ​ໄດ້​ທຶນ ມ​ຊ"
                        name="scholarshipAgreementNo"
                        value={values.scholarshipAgreementNo}
                        isInvalid={errors.scholarshipAgreementNo}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col sm="6" style={{ marginTop: 20 }}>
                      <Form.Label>ລົງ​ວັນ​ທີ່ {valiDate()}</Form.Label>
                      <Form.Control
                        type="date"
                        name="dateScholarshipAgreementNo"
                        isInvalid={errors.dateScholarshipAgreementNo}
                        onChange={handleChange}
                        value={values.dateScholarshipAgreementNo}
                      />
                    </Col>
                    <Col sm="12" style={{ marginTop: 20 }}>
                      <Form.Label>ທຶນ​ແບ່ງ​ປັນອື່ນໆ {valiDate()}</Form.Label>
                      <Form.Control
                        type="text"
                        name="otherScholarship"
                        placeholder="ກະ​ລຸ​ນາ​ປ້ອນທຶນ​ແບ່ງ​ປັນ​ອື່​ນໆ"
                        isInvalid={errors.otherScholarship}
                        onChange={handleChange}
                        value={values.otherScholarship}
                        disabled={
                          scholarship === ""
                            ? true
                            : scholarship === "OTHER_SHOLARSHIP"
                            ? false
                            : true
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <div
                        className="heading"
                        style={{
                          display: clickCheckbox === false ? "flex" : "none",
                        }}
                      >
                        <b>ສຳລັບນັກສຶກສາຈ່າຍຄ່າຮຽນ</b>
                      </div>
                    </Col>
                  </Row>
                  {studentType === "ນັກຮຽນຕ່າງປະເທດ" ? (
                    <Row
                      style={{
                        display: clickCheckbox === false ? "flex" : "none",
                      }}
                    >
                      <Col sm={12}>
                        <Form.Control
                          as="select"
                          id="disabledSelect"
                          name="scholarship"
                          value={values.scholarship}
                          isInvalid={errors.scholarship}
                          onChange={(e) => {
                            selectScholarship(e);
                            handleChange(e);
                          }}
                        >
                          <option value="">ເລືອກທຶນ</option>
                          <option value="ORGANIZATION_IN_LAOPDR_MINISTRIES">
                            The organization in the Lao PDR(Ministries)
                          </option>
                          <option value="ORGANIZATION_IN_LAOPDR_PROVINCIAL_EDUCATION_DIVISION">
                            The organization in the Lao PDR(Provincial Education
                            Division)
                          </option>
                          <option value="HIGHER_EDUCATION_INSTITUTION_FORM_ABROAD">
                            Higher Education institution form abroad
                          </option>
                          <option value="PERSONAL_FINANCIAL">
                            Personal financial
                          </option>
                        </Form.Control>
                      </Col>
                      <Col sm={12}>
                        <Form.Control
                          type={
                            values.scholarship ===
                              "ORGANIZATION_IN_LAOPDR_MINISTRIES" ||
                            values.scholarship ===
                              "ORGANIZATION_IN_LAOPDR_PROVINCIAL_EDUCATION_DIVISION"
                              ? "text"
                              : "hidden"
                          }
                          placeholder="ກະລຸນາປ້ອນລາຍລະອຽດ"
                          value={values.scholarshipFrom}
                          name="scholarshipFrom"
                          isInvalid={errors.scholarshipFrom}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col sm={3}>
                        <div className="form-group">
                          <label>ຈົບຈາກປະເທດ {valiDate()}</label>
                          <select
                            className="form-control"
                            name="graduatedCountry"
                            value={values.graduatedCountry}
                            onChange={handleChange}
                          >
                            <option value="">---ເລືອກປະເທດ---</option>
                            {COUNTRIES_LIST.map((countries, index) => (
                              <option
                                key={"countries" + index}
                                value={countries?.en_short_name}
                              >
                                {countries?.en_short_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-group">
                          <label>ຈົບຈາກແຂວງ {valiDate()}</label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນແຂວງ"
                            name="graduatedFromProvince"
                            value={values.graduatedFromProvince}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-group">
                          <label>ຈົບຈາກໂຮງຮຽນ {valiDate()}</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="endStudy"
                            value={values.endStudy}
                            placeholder="ກະລຸນາປ້ອນ"
                          />
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-group">
                          <label>ສົກສຶກສາ {valiDate()}</label>
                          <Form.Control
                            as="select"
                            name="graduatedAccessMentYear"
                            value={values.graduatedAccessMentYear}
                            onChange={handleChange}
                          >
                            <option disabled={true} value="">
                              ---ກະລຸນາສົກຮຽນ---
                            </option>
                            {assessmentYear.map((x, year2Index) => {
                              return (
                                <option key={year2Index} value={x}>
                                  {x}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row
                      style={{
                        display: clickCheckbox === false ? "flex" : "none",
                      }}
                    >
                      <Col sm={4}>
                        <div className="form-group">
                          <label>ຈົບຈາກແຂວງ {valiDate()}</label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              _selectComeFormProvince(e);
                              handleChange(e);
                            }}
                          >
                            <option value="">---ເລືອກແຂວງ---</option>
                            {LAO_ADDRESS.map((province, index) => (
                              <option key={"province" + index}>
                                {province?.province_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="form-group">
                          <label>ຈົບຈາກໂຮງຮຽນ {valiDate()}</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            name="endStudy"
                            value={values.endStudy}
                            placeholder="ກະລຸນາປ້ອນ"
                          />
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="form-group">
                          <label>ສົກສຶກສາ {valiDate()}</label>
                          <Form.Control
                            as="select"
                            name="graduatedAccessMentYear"
                            value={values.graduatedAccessMentYear}
                            onChange={handleChange}
                          >
                            <option disabled={true} value="">
                              ---ກະລຸນາສົກຮຽນ---
                            </option>
                            {assessmentYear.map((x, year2Index) => {
                              return (
                                <option key={year2Index} value={x}>
                                  {x}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col sm={12}>
                      <div className="heading">
                        <b>ຕິດຕໍ່ສຸກເສີນ {valiDate()}</b>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <div className="form-group">
                        <label>ພົວພັນທ່ານ {valiDate()}</label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="ກະລຸນາປ້ອນຊື່ ແລະ ນາມສະກຸນ"
                          name="relationship"
                          value={values.relationship}
                          onChange={handleChange}
                          isInvalid={!!errors.relationship}
                        />
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="form-group">
                        <label>ເບີຕິດຕໍ່ {valiDate()}</label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="ກະລຸນາປ້ອນເບີຕິດຕໍ່"
                          name="parentPhone"
                          value={values.parentPhone}
                          onChange={handleChange}
                          isInvalid={!!errors.parentPhone}
                        />
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="form-group">
                        <Form.Check
                          inline
                          label="ອາໄສຢູ່ຮ່ວມກັນ(ປັດຈຸບັນ)"
                          type="checkbox"
                          name="isLiveTogether"
                          value={values.isLiveTogether}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="form-group">
                        <label>ໝາຍເຫດ</label>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          name="note"
                          value={values.note}
                          onChange={handleChange}
                          placeholder="ກະລຸນາປ້ອນໝາຍເຫດ"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ textAlign: "center" }}>
                    <Col sm={12}>
                      <Button
                        variant="outline-secondary"
                        className="btn secondary"
                        onClick={() =>
                          history.push(
                            Routers.STUDENT_LIST + Routers.PAGE_GINATION
                          )
                        }
                      >
                        ຍົກເລີກ
                      </Button>
                      &nbsp;
                      <Button className="btn btn-info" onClick={handleSubmit}>
                        ບັນທຶກ
                      </Button>
                      &nbsp;&nbsp;
                    </Col>
                  </Row>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </CustomContainer>
      {/* ------- AddConfirm Modal ------ */}
      <StudentAddConfirm
        showAddConfirmModal={showAddConfirmModal}
        _handleShowAddConfirmModalClose={_handleShowAddConfirmModalClose}
        param={formParam}
        facalty={facultyName}
        department={departmentName}
        major={majorName}
        classroom={classroomName}
      />
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
      >
        <Toast.Header style={{ backgroundColor: "#c0392b" }}>
          <strong className="mr-auto" style={{ color: "#fff" }}>
            ມີຂໍ້ຜິດພາດ
          </strong>
        </Toast.Header>
        <Toast.Body>ທ່ານຕື່ມຂໍ້ມູນບໍ່ຖືກຕ້ອງຕາມທີ່ລະບົບກໍານົດ</Toast.Body>
      </Toast>
    </div>
  );
}
export default StudentAdd;

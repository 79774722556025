import React, { useEffect, useState } from "react";
import { fetStatistichData } from "../Api";
import Loading from "../../../common/Loading";
import { Button, Typography } from "@mui/material";
import { SaveAlt as SaveAltIcon } from "@mui/icons-material";
import * as XLSX from "xlsx";
import "../index.css";
import { hasPermission } from "../../../helpers/CheckPermission";
// Utility function to get alternating colors
const getAlternateColor = (index) => {
  return index % 2 === 0 ? "#FFFFFF" : "#F0F0F0"; // White and light gray
};

const getStudentTypeDisplay = (type) => {
  return type === "OUT_PLAN" || type === "OUT_PLAN_EN"
    ? "ນອກເຜນ"
    : type === "IN_PLAN" || type === "IN_PLAN_EN"
      ? "ໃນເເຜນ"
      : type;
};

const StudentByYear = ({ faculty, department, major, studentType }) => {
  const [data, setData] = useState(null);

  const getData = async () => {
    setData();
    try {
      const result = await fetStatistichData(
        `allstudent?faculty=${faculty}&department=${department}&major=${major}&studentType=${studentType}`
      );
      setData(result);
      determineFacultyOrder(result.data);
    } catch (error) { }
  };

  useEffect(() => {
    getData();
  }, [faculty, department, major]);

  const determineFacultyOrder = (data) => {
    const order = [];
    data.forEach((item) => {
      if (!order.includes(item.faculty)) {
        order.push(item.faculty);
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const cellStyle = {
    width: "200px",
    height: "40px",
    textAlign: "center",
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.aoa_to_sheet(formatDataForExcel());
    const merges = generateMerges();
    ws["!merges"] = merges;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "StudentsByYear");
    XLSX.writeFile(wb, "StudentsByYear.xlsx");
  };

  const formatDataForExcel = () => {
    const headers = [
      [
        "ລຳດັບ",
        "ຄະນະວິຊາ",
        "ພາກວິຊາ",
        "ສາຂາວິຊາ",
        "ລະດັບຊັ້ນການຮຽນ",
        "ລະບົບການຮຽນ",
        ...["1", "2", "3", "4", "5", "6"].flatMap((year) => [
          `ປີ ${year}`,
          `ປີ ${year}`,
          `ປີ ${year}`,
        ]),
        "ລວມ",
        "ຍິງ",
        "ຊາຍ",
      ],
      [
        "",
        "",
        "",
        "",
        "",
        "",
        ...Array(18)
          .fill("")
          .flatMap((_, i) => (i % 3 === 0 ? ["ລວມ", "ຍິງ", "ຊາຍ"] : [])),
        "ລວມ",
        "ຍິງ",
        "ຊາຍ",
      ],
    ];

    const rows = data?.data?.map((item, index) => [
      index + 1,
      item?.faculty,
      item?.department,
      item?.major,
      item?.educationLevel,
      getStudentTypeDisplay(item?.studentType),
      ...["1", "2", "3", "4", "5", "6"].flatMap((year) => [
        item?.years[year]?.total || 0,
        item?.years[year]?.female || 0,
        item?.years[year]?.male || 0,
      ]),
      item?.total,
      Object.values(item?.years).reduce(
        (sum, year) => sum + (year?.female || 0),
        0
      ),
      Object.values(item?.years).reduce(
        (sum, year) => sum + (year?.male || 0),
        0
      ),
    ]);

    const overallTotals = [
      "ລວທທັງຫມົດ",
      "",
      "",
      "",
      "",
      "",
      ...["1", "2", "3", "4", "5", "6"].flatMap((year) => [
        data?.overallTotals[year]?.total || 0,
        data?.overallTotals[year]?.female || 0,
        data?.overallTotals[year]?.male || 0,
      ]),
      data?.overallTotals?.total,
      data?.overallTotals?.female,
      data?.overallTotals?.male,
    ];

    return [...headers, ...rows, overallTotals];
  };

  const generateMerges = () => {
    const merges = [
      { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
      { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
      { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } },
      { s: { r: 0, c: 3 }, e: { r: 1, c: 3 } },
      { s: { r: 0, c: 4 }, e: { r: 1, c: 4 } },
      { s: { r: 0, c: 5 }, e: { r: 1, c: 5 } },
      { s: { r: 0, c: 24 }, e: { r: 1, c: 24 } },
      { s: { r: 0, c: 25 }, e: { r: 1, c: 25 } },
      { s: { r: 0, c: 26 }, e: { r: 1, c: 26 } },
    ];

    for (let i = 0; i < 6; i++) {
      merges.push({ s: { r: 0, c: 6 + i * 3 }, e: { r: 0, c: 8 + i * 3 } });
    }

    return merges;
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div>
          <Typography variant="h5">
            {`ຈຳນວນນັກສຶກສາ ຕາມພາກວິຊາ/ສາຂາວິຊາ, ຕາມປີຮຽນ ${studentType === ""
                ? "(ລວມທັງ 2 ລະບົບ)"
                : studentType === "OUT_PLAN"
                  ? "(ນອກເເຜນ)"
                  : "ໃນເເຜນ"
              }`}
          </Typography>
        </div>
        <div>
          {hasPermission('STATISTIC', 'export') &&
            <Button
              variant="contained"
              color="success"
              startIcon={<i className="fa fa-file-excel"></i>}
              sx={{ textTransform: "none" }}
              onClick={exportToExcel}
            >
              Export
            </Button>
          }
        </div>
      </div>

      {data ? (
        <div style={{ overflowX: "auto" }}>
          <div
            style={{
              maxHeight: "80vh",
              overflowY: "auto",
              paddingBottom: "100px",
            }}
          >
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1,
                }}
              >
                <tr>
                  <th
                    rowSpan={2}
                    style={{
                      width: "100px",
                    }}
                  >
                    ລຳດັບ
                  </th>
                  <th rowSpan={2} style={cellStyle}>
                    ຄະນະວິຊາ
                  </th>
                  <th rowSpan={2} style={cellStyle}>
                    ພາກວິຊາ
                  </th>
                  <th rowSpan={2} style={cellStyle}>
                    ສາຂາວິຊາ
                  </th>
                  <th rowSpan={2} style={cellStyle}>
                    ລະດັບຊັ້ນການຮຽນ
                  </th>
                  <th rowSpan={2} style={cellStyle}>
                    ລະບົບການຮຽນ
                  </th>
                  {["1", "2", "3", "4", "5", "6"].map((year) => (
                    <th colSpan="3" key={year} style={cellStyle}>
                      ປີ {year}
                    </th>
                  ))}
                  <th colSpan="3" style={cellStyle}>
                    ລວມ
                  </th>
                </tr>
                <tr>
                  {["1", "2", "3", "4", "5", "6"].map((year) => (
                    <React.Fragment key={year}>
                      <th style={cellStyle}>ລວມ</th>
                      <th style={cellStyle}>ຍິງ</th>
                      <th style={cellStyle}>ຊາຍ</th>
                    </React.Fragment>
                  ))}
                  <th style={cellStyle}>ລວມ</th>
                  <th style={cellStyle}>ຍິງ</th>
                  <th style={cellStyle}>ຊາຍ</th>
                </tr>
              </thead>
              <tbody>
                {data?.data.map((item, index) => {
                  const backgroundColor = getAlternateColor(index);
                  return (
                    <tr key={index} style={{ backgroundColor, height: "50px" }}>
                      <td style={cellStyle}>{index + 1}</td>
                      <td
                        style={{ width: 300, height: 40, textAlign: "start" }}
                      >
                        {item?.faculty}
                      </td>
                      <td
                        style={{ width: 300, height: 40, textAlign: "start" }}
                      >
                        {item?.department}
                      </td>
                      <td
                        style={{ width: 300, height: 40, textAlign: "start" }}
                      >
                        {item?.major}
                      </td>
                      <td style={cellStyle}>{item?.educationLevel}</td>
                      <td style={cellStyle}>
                        {getStudentTypeDisplay(item?.studentType)}
                      </td>
                      {["1", "2", "3", "4", "5", "6"].map((year) => (
                        <React.Fragment key={year}>
                          <td style={cellStyle}>
                            {(item?.years && item?.years[year]?.total) || 0}
                          </td>
                          <td style={cellStyle}>
                            {(item?.years && item?.years[year]?.female) || 0}
                          </td>
                          <td style={cellStyle}>
                            {(item?.years && item?.years[year]?.male) || 0}
                          </td>
                        </React.Fragment>
                      ))}
                      <td style={cellStyle}>{item?.total}</td>
                      <td style={cellStyle}>
                        {/* {Object.values(item?.years).reduce(
                          (sum, year) => sum + (year?.female || 0),
                          0
                        )} */}
                      </td>
                      <td style={cellStyle}>
                        {/* {Object.values(item?.years).reduce(
                          (sum, year) => sum + (year?.male || 0),
                          0
                        )} */}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan="6" style={cellStyle}>
                    ລວມ
                  </td>
                  {["1", "2", "3", "4", "5", "6"].map((year) => (
                    <React.Fragment key={year}>
                      <td style={cellStyle}>
                        {(data?.overallTotals &&
                          data?.overallTotals[year]?.total) ||
                          0}
                      </td>
                      <td style={cellStyle}>
                        {(data?.overallTotals &&
                          data?.overallTotals[year]?.female) ||
                          0}
                      </td>
                      <td style={cellStyle}>
                        {(data?.overallTotals &&
                          data?.overallTotals[year]?.male) ||
                          0}
                      </td>
                    </React.Fragment>
                  ))}
                  <td style={cellStyle}>{data?.overallTotals?.total}</td>
                  <td style={cellStyle}>{data?.overallTotals?.female}</td>
                  <td style={cellStyle}>{data?.overallTotals?.male}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="bg-white">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default StudentByYear;

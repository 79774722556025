import React, { useEffect, useState } from "react";
import { fetchGet } from "../../../common/fetchAPI";
import useReactRouter from "use-react-router";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  Chip,
} from "@mui/material";
import Routers from "../../../consts/router";
import { Breadcrumb } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const PlaceStudyDetail = () => {
  const { history, location } = useReactRouter();
  const [placestudy, setPlaceStudy] = useState([]);
  const placeName = location?.state?.title;
  const placeId = location?.state?._id;
  const accessmentRedux = useSelector((state) => state.accessment.value);

  const fetchPlaceStudy = async () => {
    const response = await fetchGet(
      "placestudy/" + placeId + "?assessmentYear=" + accessmentRedux
    );
    if (response?.courses) {
      setPlaceStudy(response?.courses || []);
    }
  };

  useEffect(() => {
    fetchPlaceStudy();
  }, [accessmentRedux]);

  // Convert Data for Recharts
  const chartData = placestudy.map((course) => ({
    subject: course?.course?.subject?.title || course?.course?.subject?.titleEn,
    teachers: course?.teacher?.length || 0,
    classrooms: course?.classrooms?.length || 0,
    schedule: course?.timesStudy?.length || 0,
  }));

  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      {/* Breadcrumb Navigation */}
      <Breadcrumb>
        <Breadcrumb.Item href="" onClick={() => history.push("/setting-list")}>
          ຕັ້ງຄ່າ
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href=""
          onClick={() => history.push(Routers.PLACE_STUDY)}
        >
          ຈັດການສະຖານທີ່ຮຽນ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {"ລາຍລະອຽດສະຖານທີ່ຮຽນໃນສົກ " + accessmentRedux}
        </Breadcrumb.Item>
      </Breadcrumb>

      {/* Page Header */}
      <Typography variant="h4" fontWeight="bold" sx={{ mt: 2, mb: 3 }}>
        {"ລາຍລະອຽດການນຳໃຊ້ສະຖານທີ່ຮຽນ  " +
          placeName +
          " ໃນສົກ " +
          accessmentRedux}
      </Typography>

      {/* Grid Layout */}
      <Grid container spacing={3}>
        {/* Course Info Cards */}
        {Array.isArray(placestudy) && placestudy.length > 0 ? (
          placestudy.map((course, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card
                sx={{
                  borderRadius: 2,
                  boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.16)", // Custom shadow
                  p: 2,
                }}
              >
                <CardContent>
                  <Typography variant="h6" fontWeight="bold">
                    ວິຊາ:{" "}
                    {course?.course?.subject?.title ||
                      course?.course?.subject?.titleEn}
                  </Typography>

                  {/* Teachers */}
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    <strong>ອາຈານສອນ:</strong>{" "}
                    {course?.teacher?.length > 0
                      ? course.teacher.map((teacher, teacherIdx) => (
                          <Chip
                            key={teacherIdx}
                            label={`${teacher.firstname} ${teacher.lastname}`}
                            sx={{ mr: 1, mb: 1 }}
                          />
                        ))
                      : "ຍັງບໍ່ມີອາຈານ"}
                  </Typography>

                  {/* Classrooms */}
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    <strong>ຫ້ອງຮຽນ:</strong>{" "}
                    {course?.classrooms?.length > 0
                      ? course.classrooms.join(", ")
                      : "ຍັງບໍ່ມີຫ້ອງຮຽນ"}
                  </Typography>

                  {/* Schedule */}
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    <strong>ຕາຕະລາງ:</strong>
                  </Typography>
                  <Box
                    component="ul"
                    sx={{
                      pl: 2,
                    }}
                  >
                    {Array.isArray(course?.timesStudy) &&
                    course?.timesStudy?.length > 0 ? (
                      course?.timesStudy.map((scheduleItem, idx) => (
                        <li key={idx}>
                          <strong>ມື້:</strong> {scheduleItem?.day},{" "}
                          <strong>ເວລາ:</strong>{" "}
                          {scheduleItem?.times?.map((time, timeIdx) => (
                            <span key={timeIdx}>
                              {time?.time}
                              {timeIdx < scheduleItem?.times?.length - 1 &&
                                ", "}
                            </span>
                          ))}
                        </li>
                      ))
                    ) : (
                      <li>ຍັງບໍ່ມີຕາຕະລາງ</li>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" sx={{ mt: 2 }}>
            ຍັງບໍ່ມີວິຊາ
          </Typography>
        )}

        {/* Chart Visualization */}
        {placestudy.length > 0 && (
          <Grid item xs={12}>
            <Card
              sx={{
                borderRadius: 2,
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.16)", // Custom shadow
                mb: 5,
                p: 3,
              }}
            >
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
                📊 ພາບລວມວິຊາໃນສະຖານທີ່ຮຽນ
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={chartData}>
                  <XAxis dataKey="subject" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="teachers" fill="#8884d8" name="ອາຈານສອນ" />
                  <Bar dataKey="classrooms" fill="#82ca9d" name="ຫ້ອງຮຽນ" />
                  <Bar dataKey="schedule" fill="#ffc658" name="ຈຳນວນວັນສອນ" />
                </BarChart>
              </ResponsiveContainer>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PlaceStudyDetail;

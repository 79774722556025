import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";

import { Col, Row, Modal } from "react-bootstrap";
import { useMutation } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { UPDATE_STUDENT } from "./apollo/mutation";
import { CustomButton } from "../../common";
import { formatDate, formatDateDashDMY } from "../../common/super";
import Routers from "../../consts/router";
import {
  onConvertStudentInfo,
  onConvertEducationLevel,
  convertGender,
  convertGenderNam,
  convertGenderNamEN,
  getUserDataFromLCStorage,
  convertScholarship,
} from "../../helpers/user";
import { Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import addLogs from "../../helpers/AddLogs";
import { fetchPut } from "../../common/fetchAPI";
import AlertMessage from "../../common/AlertMessage";

// CourseAddConfirm
function RegistrationConfirm({
  data,
  showAddConfirmModal,
  _handleShowAddConfirmModalClose,
}) {
  const { history } = useReactRouter();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedApproveNote, setCheckedApproveNote] = useState(false);
  const [incorrectBirthday, setIncorrectBirthday] = useState(false);
  const [incorrectName, setIncorrectName] = useState(false);
  const [incorrectFile, setIncorrectFile] = useState(false);
  const [remark, setRemark] = useState();
  const [seeMore, setSeeMore] = useState(false);
  const [dataUser, setDataUser] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (getUserDataFromLCStorage()?.data) {
      setDataUser(getUserDataFromLCStorage()?.data);
    }
  }, []);

  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "",
  });
  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus({ ...actionStatus, open: false });
      }, 3000);
    }
  }, [actionStatus]);

  useEffect(() => {
    setCheckedApproveNote(data?.approveNote);
    setIncorrectBirthday(
      data?.approveNoteDetails?.incorrectBirthday === "" ? false : true
    );
    setIncorrectName(
      data?.approveNoteDetails?.incorrectName === "" ? false : true
    );
    setIncorrectFile(
      data?.approveNoteDetails?.incorrectFile === "" ? false : true
    );
    setRemark(data?.approveNoteDetails?.remark);
  }, [data]);

  const _handleSubmit = async () => {
    if (isSubmitting) return; // Prevent double submission
    setIsSubmitting(true);

    setIsLoading(true);

    if (checkedApproveNote) {
      data.approveNote = checkedApproveNote;
      data.approveNoteDetails = {
        incorrectBirthday:
          incorrectBirthday === true ? "ວັນເດືອນປີເກີດບໍ່ຖືກ" : "",
        incorrectName: incorrectName === true ? "ຊື່ ແລະ ນາມສະກຸນບໍ່ຖືກ" : "",
        incorrectFile: incorrectFile === true ? "File ເອກະສານບໍ່ຖືກ" : "",
        remark: remark,
      };
    } else {
      data.approveNote = false;
      data.approveNoteDetails = {};
    }

    if (dataUser.role === "ADMIN") {
      data.statusStep = "ADMIN_APPROVED";
    } else if (dataUser.role === "FAC_ADMIN") {
      data.statusStep = "APPROVED";
    }

    const _student = await fetchPut("student/approve/" + data._id, data);

    if (_student) {
      await addLogs({
        type: "APPROVE_STUDENT",
        status: "SUCCESS",
        data: _student,
        action: "UPDATE",
        student: data._id,
      });
      setActionStatus({
        open: true,
        message: "ຢືນຢັນນັກສຶກສາສຳເລັດ",
        type: "success",
      });
      setIsLoading(false);
      _handleShowAddConfirmModalClose();
    } else {
      setIsLoading(false);
      setActionStatus({
        open: true,
        message: "ຂໍອະໄພລະບົບມີບັນຫາ",
        type: "error",
      });
    }

    // setTimeout(() => {
    //   setShow(false);
    //   history.push(Routers.PRERE_STUDENT_LIST);
    //   window.location.reload();
    // }, 1000);
  };

  const handleApproveNote = (e) => {
    setCheckedApproveNote(e.target.checked);
    setIncorrectBirthday(false);
    setIncorrectName(false);
    setIncorrectFile(false);
    setRemark("");
  };

  const _onConvertMaritualStatus = (maritualStatus) => {
    let result;
    switch (maritualStatus) {
      case "SINGLE":
        result = "ໂສດ";
        break;
      case "MARRIAGE":
        result = "ແຕ່ງງານແລ້ວ";
        break;
      default:
        result = "ໂສດ";
    }
    return result;
  };

  const styleHide = {
    overflowX: "hidden",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  };

  // if(loading) return <Loading />

  return (
    <div>
      <AlertMessage
        handleClose={() => {
          setActionStatus({
            ...actionStatus,
            open: false,
          });
        }}
        type={actionStatus.type}
        open={actionStatus.open}
        message={actionStatus.message}
      />
      <Modal
        style={{ marginTop: 50 }}
        show={showAddConfirmModal}
        onHide={_handleShowAddConfirmModalClose}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton={!isLoading}>
          <Col
            style={{
              marginTop: 20,
              fontSize: 30,
              fontWeight: "bold",
              color: "#057CAE",
            }}
          >
            ກວດ​ສອບ​ຂໍ້ມູນລົງ​ທະ​ບຽນ
          </Col>
        </Modal.Header>
        {isLoading ? (
          <Modal.Body>
            <div
              style={{
                width: 800,
                minHeight: 350,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner animation="border" style={{ color: "#057CAE" }} />
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body style={{ minWidth: 800 }}>
            <Col
              style={{
                marginTop: 5,
                fontSize: 22,
                fontWeight: "bold",
                color: "red",
                textAlign: "center",
              }}
            >
              ກະລຸນາໝາຍໃສ່ໝາຍເຫດກໍລະນີຂໍ້ມູນນັກສຶກສາບໍ່ຖືກ
            </Col>
            <Row>
              <Col sm="2"></Col>
              <Col sm="8">
                <Col className="ml-2">
                  <input
                    type="checkbox"
                    value={checkedApproveNote}
                    checked={checkedApproveNote}
                    disabled={
                      dataUser?.role !== "ADMIN"
                        ? data?.approveNote === true
                        : false
                    }
                    onChange={handleApproveNote}
                  />
                  <label className="pl-2">
                    ໝາຍເຫດຂໍ້ມູນ​ນັກ​ສຶກ​ສາບໍ່ຖືກຕ້ອງ
                  </label>
                </Col>
                {checkedApproveNote ? (
                  <Row className="ml-2">
                    <Col sm={12}>
                      <input
                        type="checkbox"
                        value={incorrectBirthday}
                        checked={incorrectBirthday}
                        disabled={
                          dataUser?.role !== "ADMIN"
                            ? data?.approveNoteDetails?.incorrectBirthday !== ""
                            : false
                        }
                        onChange={(e) => setIncorrectBirthday(e.target.checked)}
                      />
                      <label className="pl-2">ວັນເດືອນປີເກີດບໍ່ຖືກ</label>
                    </Col>
                    <Col sm={12}>
                      <input
                        type="checkbox"
                        value={incorrectName}
                        checked={incorrectName}
                        disabled={
                          dataUser?.role !== "ADMIN"
                            ? data?.approveNoteDetails?.incorrectName !== ""
                            : false
                        }
                        onChange={(e) => setIncorrectName(e.target.checked)}
                      />
                      <label className="pl-2">ຊື່ ແລະ ນາມສະກຸນບໍ່ຖືກ</label>
                    </Col>
                    <Col sm={12}>
                      <input
                        type="checkbox"
                        value={incorrectFile}
                        checked={incorrectFile}
                        disabled={
                          dataUser?.role !== "ADMIN"
                            ? data?.approveNoteDetails?.incorrectFile !== ""
                            : false
                        }
                        onChange={(e) => setIncorrectFile(e.target.checked)}
                      />
                      <label className="pl-2">File ເອກະສານບໍ່ຖືກ</label>
                    </Col>
                    <Col sm={12}>
                      <label>ອື່ນໆ :</label>
                    </Col>
                    <Col sm={12}>
                      <textarea
                        value={remark}
                        disabled={
                          dataUser?.role !== "ADMIN"
                            ? data?.approveNoteDetails?.remark !== ""
                            : false
                        }
                        onChange={(e) => setRemark(e.target.value)}
                        rows="4"
                        cols="50"
                      />
                    </Col>
                  </Row>
                ) : null}
              </Col>
              <Col sm="2"></Col>
            </Row>
            <Row>
              <Col sm="8"></Col>
              <Col
                sm="4"
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                <u onClick={() => setSeeMore(!seeMore)}>
                  {seeMore ? "ເບິ່ງໜ້ອຍລົງ " : "ເບິ່ງເພີ່ມເຕີມ "}
                </u>
                <FontAwesomeIcon
                  icon={seeMore ? faCaretDown : faCaretUp}
                  style={{ color: "#057CAE", fontSize: 20 }}
                  onClick={() => setSeeMore(!seeMore)}
                />
              </Col>
            </Row>
            {seeMore ? (
              <>
                <Row>
                  <Col sm="2"></Col>
                  <Col sm="8">
                    <Col style={{ fontSize: 24 }}>
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        style={{ color: "#057CAE" }}
                      />{" "}
                      ຂໍ້ມູນ​ນັກ​ສຶກ​ສາ
                    </Col>
                    <Row style={{ marginLeft: 50 }}>
                      <Col sm="6">ລະຫັດນັກສຶກສາ</Col>
                      <Col sm="6">{data?.studentNumber}</Col>
                      <Col sm="6">ຄະ​ນ​ະ</Col>
                      <Col sm="6">{data?.faculty?.name}</Col>
                      <Col sm="6">ພາກວິຊາ</Col>
                      <Col sm="6">{data?.department?.name}</Col>
                      <Col sm="6">ສາ​ຂາ​ວິ​ຊາ</Col>
                      <Col sm="6">{data?.major?.name}</Col>
                      <Col sm="6">ຂໍ້​ມູນ​ນັກ​ສຶກ​ສາ</Col>
                      <Col sm="6">
                        {data &&
                          (data?.studentInfo
                            ? onConvertStudentInfo(data?.studentInfo)
                            : "-")}
                      </Col>
                      <Col sm="6">​ລະ​ດັບ</Col>
                      <Col sm="6">
                        {data &&
                          (data?.educationLevel
                            ? onConvertEducationLevel(data?.educationLevel)
                            : "-")}
                      </Col>
                    </Row>
                    <Col style={{ fontSize: 24 }}>
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        style={{ color: "#057CAE" }}
                      />{" "}
                      ຂໍ້ມູນ​ທົ່ວໄປ
                    </Col>
                    <Col className="row" style={{ marginLeft: 38 }}>
                      <Col sm="6"> ຊື່</Col>
                      <Col sm="6">
                        {convertGenderNam(data?.gender)}{" "}
                        {data?.firstNameL ?? "-"}
                      </Col>
                      <Col sm="6">ນາມ​ສະ​ກຸນ</Col>
                      <Col sm="6">{data?.lastNameL}</Col>
                      <Col sm="6">ຊື່ (​ພາ​ສາ​ອັງ​ກິດ)</Col>
                      <Col sm="6">
                        {convertGenderNamEN(data?.gender)}{" "}
                        {data?.firstNameE ?? "-"}
                      </Col>
                      <Col sm="6">ນາມ​ສະ​ກຸນ (​ພາ​ສາ​ອັງ​ກິດ)</Col>
                      <Col sm="6">{data?.lastNameE}</Col>
                      <Col sm="6">ວັນ​ເດືອນ​ປີ​ເກີດ</Col>
                      <Col sm="6">{formatDate(data?.birthday)}</Col>
                      <Col sm="6">ເພດ</Col>
                      <Col sm="6">
                        {data &&
                          (data?.gender ? convertGender(data?.gender) : "-")}
                      </Col>
                      <Col sm="6">ສະ​ຖາ​ນະ</Col>
                      <Col sm="6">
                        {_onConvertMaritualStatus(data?.maritualStatus) ?? "-"}
                      </Col>
                      <Col sm="6"> ສັນ​ຊາດ</Col>
                      <Col sm="6">{data?.nationality ?? "-"}</Col>
                      {data?.studentType === "IN_PLAN" ||
                      data?.studentType === "OUT_PLAN" ? (
                        <>
                          <Col sm="6"> ຊົນ​ເຜົ່າ</Col>
                          <Col sm="6">{data?.tribe?.trib_name ?? "-"}</Col>
                        </>
                      ) : (
                        <></>
                      )}
                      <Col sm="6">ສາ​ສະ​ໜາ</Col>
                      <Col sm="6">{data?.religion ?? "-"}</Col>
                      <Col sm="6">ເບີໂທເຮືອນ</Col>
                      <Col sm="6">{data?.phoneHome ?? "-"}</Col>
                      <Col sm="6">ເບີໂທລະສັບມືຖື</Col>
                      <Col sm="6">{data?.phoneMobile ?? "-"}</Col>
                      <Col sm="6">ອີເມວ</Col>
                      <Col sm="6">{data?.email}</Col>
                    </Col>
                    <Col style={{ fontSize: 24 }}>
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        style={{ color: "#057CAE" }}
                      />{" "}
                      ທີ່ຢູ່ປະຈຸບັນ
                    </Col>
                    <Row style={{ marginLeft: 50 }}>
                      {data?.studentType === "OUT_PLAN_EN" ||
                      data?.studentType === "IN_PLAN_EN" ? (
                        <>
                          <Col sm="6">ປະເທດ</Col>
                          <Col sm="6">{data?.address?.country ?? "-"}</Col>
                        </>
                      ) : null}
                      <Col sm="6">ແຂວງ</Col>
                      <Col sm="6">{data?.address?.province ?? "-"}</Col>
                      <Col sm="6">ເມືອງ</Col>
                      <Col sm="6">{data?.address?.district ?? "-"}</Col>
                      <Col sm="6">ບ້ານ</Col>
                      <Col sm="6">{data?.address?.village ?? "-"}</Col>
                    </Row>
                    <Col style={{ fontSize: 24 }}>
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        style={{ color: "#057CAE" }}
                      />{" "}
                      ສະຖານທີເກີດ
                    </Col>
                    <Col className="row" style={{ marginLeft: 38 }}>
                      {data?.bornAbroad === null ? (
                        <>
                          <Col sm="6">ປະເທດ</Col>
                          <Col sm="6">{data?.birthAddress?.country ?? "-"}</Col>
                          <Col sm="6">ແຂວງ</Col>
                          <Col sm="6">
                            {data?.birthAddress?.province ?? "-"}
                          </Col>
                          <Col sm="6">ເມືອງ</Col>
                          <Col sm="6">
                            {data?.birthAddress?.district ?? "-"}
                          </Col>
                          <Col sm="6">ບ້ານ</Col>
                          <Col sm="6">{data?.birthAddress?.village ?? "-"}</Col>
                        </>
                      ) : (
                        <>
                          <Col sm="6">ນັກສຶກສາເກີດໃນຕ່າງປະເທດ?</Col>
                          <Col sm="6">
                            {data?.bornAbroad === "" ? "ບໍ່ແມ່ນ" : "ແມ່ນ"}
                          </Col>
                          {data?.bornAbroad !== "" ? (
                            <>
                              <Col sm="6">ປະເທດ</Col>
                              <Col sm="6">
                                {data?.birthAddress?.country ?? "-"}
                              </Col>
                            </>
                          ) : null}
                          <Col sm="6">ແຂວງ</Col>
                          <Col sm="6">
                            {data?.birthAddress?.province ?? "-"}
                          </Col>
                          <Col sm="6">ເມືອງ</Col>
                          <Col sm="6">
                            {data?.birthAddress?.district ?? "-"}
                          </Col>
                          <Col sm="6">ບ້ານ</Col>
                          <Col sm="6">{data?.birthAddress?.village ?? "-"}</Col>
                        </>
                      )}
                    </Col>
                    {data?.studentType !== "IN_PLAN" &&
                    data?.studentType !== "IN_PLAN_EN" ? (
                      <div>
                        <Col style={{ fontSize: 24 }}>
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            style={{ color: "#057CAE" }}
                          />{" "}
                          ສຳຫຼັບນັກ​ສຶກ​ສາ​ຈ່າຍ​ຄ່າ​ຮຽນ
                        </Col>
                        <Row style={{ marginLeft: 50 }}>
                          {data?.studentType === "IN_PLAN_EN" ||
                          data?.studentType === "OUT_PLAN_EN" ? (
                            <>
                              <Col sm="6">ປະເທດ</Col>
                              <Col sm="6">
                                {data?.graduateFromCountry ?? "-"}
                              </Col>
                            </>
                          ) : null}
                          <Col sm="6">ຈົບ​ຈາກແຂວງ</Col>
                          <Col sm="6">{data?.graduateFromProvince ?? "-"}</Col>
                          <Col sm="6">ຈົບຈາກໂຮງຮຽນ</Col>
                          <Col sm="6">{data?.graduateFromSchool ?? "-"}</Col>
                          <Col sm="6">ສົກສຶກສາ</Col>
                          <Col sm="6">{data?.semesterYear ?? "-"}</Col>
                        </Row>
                      </div>
                    ) : (
                      <div>
                        <Col style={{ fontSize: 24 }}>
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            style={{ color: "#057CAE" }}
                          />{" "}
                          ສໍາຫຼັບນັກສຶກສາ/ພະນັກງານ ທືນແບ່ງປັນ
                        </Col>
                        <Row style={{ marginLeft: 50 }}>
                          <Col sm="6"> ທຶນແບ່ງປັນ</Col>
                          <Col sm="6">
                            {convertScholarship(data?.scholarship) ?? "-"}
                          </Col>
                          <Col sm="6"> ເລກທີໃບນຳສົ່ງ</Col>
                          <Col sm="6">{data?.transferLetterNo ?? "-"}</Col>
                          <Col sm="6">ລົງ​ວັນ​ທີ່</Col>
                          <Col sm="6">
                            {formatDate(data?.dateTransferLetterNo) ?? "-"}
                          </Col>
                          <Col sm="6">ເລກທີຂໍ້ຕົກລົງເສັງ​ໄດ້​ທຶນ ມ​ຊ</Col>
                          <Col sm="6">
                            {data?.scholarshipAgreementNo ?? "-"}
                          </Col>
                          <Col sm="6">ລົງ​ວັນ​ທີ່</Col>
                          <Col sm="6">
                            {formatDate(data?.dateScholarshipAgreementNo) ??
                              "-"}
                          </Col>
                          {data?.studentType === "IN_PLAN_EN" ||
                          data?.studentType === "OUT_PLAN_EN" ? (
                            <>
                              <Col sm="6">ປະເທດ</Col>
                              <Col sm="6">
                                {data?.graduateFromCountry ?? "-"}
                              </Col>
                            </>
                          ) : null}
                          <Col sm="6">ຈົບຈາກແຂວງ</Col>
                          <Col sm="6">{data?.graduateFromProvince ?? "-"}</Col>
                          <Col sm="6">ຈົບຈາກໂຮງຮຽນ</Col>
                          <Col sm="6">{data?.graduateFromSchool ?? "-"}</Col>
                          <Col sm="6">ສົກສຶກສາ</Col>
                          <Col sm="6">{data?.semesterYear ?? "-"}</Col>
                        </Row>
                      </div>
                    )}
                    {data?.educationLevel === "BACHELOR_CONTINUING" ? (
                      <Row style={{ marginLeft: 50 }}>
                        <Col>ຈົບສາຂາວິຊາ</Col>
                        <Col>{data?.graduatedMajor}</Col>
                      </Row>
                    ) : null}

                    <Col style={{ fontSize: 24 }}>
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        style={{ color: "#057CAE" }}
                      />{" "}
                      ຂໍ້​ມູນ​ຜູ້​ປົກ​ຄອງ
                    </Col>
                    <Row style={{ marginLeft: 50 }}>
                      <Col sm="6">ຊື່ ແລະ ນາມສະກຸນພໍ່</Col>
                      <Col sm="6">{data?.fatherNameAndSurname ?? "-"}</Col>
                      <Col sm="6">ຊື່ ແລະ ນາມສະກຸນແມ່</Col>
                      <Col sm="6">{data?.motherNameAndSurname ?? "-"}</Col>
                      <Col sm="6">ເບີໂທລະສັບພໍ່/ແມ່</Col>
                      <Col sm="6">{data?.parentTelephone ?? "-"}</Col>
                      {data?.studentType === "OUT_PLAN_EN" ||
                      data?.studentType === "IN_PLAN_EN" ? (
                        <>
                          <Col sm="6">ປະເທດ</Col>
                          <Col sm="6">
                            {data?.parentAddress?.country ?? "-"}
                          </Col>
                        </>
                      ) : null}
                      <Col sm="6">ແຂວງ</Col>
                      <Col sm="6">{data?.parentAddress?.province ?? "-"}</Col>
                      <Col sm="6">ເມືອງ</Col>
                      <Col sm="6">{data?.parentAddress?.district ?? "-"}</Col>
                      <Col sm="6">ບ້ານ</Col>
                      <Col sm="6">{data?.parentAddress?.village ?? "-"}</Col>
                    </Row>
                    <Col style={{ fontSize: 24 }}>
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        style={{ color: "#057CAE" }}
                      />{" "}
                      ກໍ​ລະ​ນີ​ສຸກ​ເສີນ​ຕິດ​ຕໍ່
                    </Col>
                    <Row style={{ marginLeft: 50 }}>
                      <Col sm="6">ພົວ​ພັນ​ທ່ານ</Col>
                      <Col sm="6">{data?.contactName ?? "-"}</Col>
                      <Col sm="6">​ເບີ​ໂທ​ຕິດ​ຕໍ່</Col>
                      <Col sm="6">{data?.emergencyPhone ?? "-"}</Col>
                      <Col sm="6">ອ​າ​ໄສ​ຢູ່​ຮ່ວມ​ກັນ</Col>
                      <Col sm="6">
                        {data?.stayTogether === true ? "ແມ່ນ" : "ບໍ່ແມ່ນ"}
                      </Col>
                      {data?.stayTogether !== true ? (
                        <>
                          {data?.studentType === "OUT_PLAN_EN" ||
                          data?.studentType === "IN_PLAN_EN" ? (
                            <>
                              <Col sm="6">ປະເທດ</Col>
                              <Col sm="6">
                                {data?.emergencyAddress?.country ?? "-"}
                              </Col>
                            </>
                          ) : null}
                          <Col sm="6">ແຂວງ</Col>
                          <Col sm="6">
                            {data?.emergencyAddress?.province ?? "-"}
                          </Col>
                          <Col sm="6">ເມືອງ</Col>
                          <Col sm="6">
                            {data?.emergencyAddress?.district ?? "-"}
                          </Col>
                          <Col sm="6">ບ້ານ</Col>
                          <Col sm="6">
                            {data?.emergencyAddress?.village ?? "-"}
                          </Col>
                        </>
                      ) : (
                        <></>
                      )}
                    </Row>
                    <Col style={{ fontSize: 24 }}>
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        style={{ color: "#057CAE" }}
                      />{" "}
                      ອັບໂຫຼດເອກະສານຄັດຕິດ
                    </Col>
                    {data?.studentType === "IN_PLAN_EN" ||
                    data?.studentType === "OUT_PLAN_EN" ? (
                      <Row style={{ marginLeft: 50, paddingBottom: 50 }}>
                        <Col sm="6">National ID or Passport</Col>
                        <Col sm="6" style={styleHide}>
                          {data?.passportUrl?.url ?? "-"}
                        </Col>
                        <Col sm="6">ໃບປະກາດ</Col>
                        <Col sm="6" style={styleHide}>
                          {data?.qualificationUrl?.url ?? "-"}
                        </Col>
                        <Col sm="6">ອື່ນໆ</Col>
                        <Col sm="6" style={styleHide}>
                          {data?.otherUrl?.url ?? "-"}
                        </Col>
                      </Row>
                    ) : (
                      <Row style={{ marginLeft: 50, paddingBottom: 50 }}>
                        <Col sm="6">
                          ບັດປະຈໍາຕົວ ຫຼື ສໍາມະໂນຄົວ (ພຣະ: ໜັງສືສຸທິ)
                        </Col>
                        <Col sm="6" style={styleHide}>
                          {data?.idCardOrFamilyBookUrl?.url ?? "-"}
                        </Col>
                        {/* <Col sm='6'>ໃບຄະແນນ</Col>
                  <Col sm='6' style={styleHide}>{data?.scorecardUrl?.url}</Col>*/}
                        <Col sm="6">ໃບປະກາດ</Col>
                        <Col sm="6" style={styleHide}>
                          {data?.certificateUrl?.url ?? "-"}
                        </Col>
                        <Col sm="6">ບັດເຂົ້າຫ້ອງເສັງ</Col>
                        <Col sm="6" style={styleHide}>
                          {data?.testCardUrl?.url ?? "-"}
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </>
            ) : null}
            <Row style={{ marginTop: 50, marginBottom: 30 }}>
              <Col sm="3"></Col>
              <Col sm="4">
                <CustomButton
                  type="submit"
                  title="ຍົກເລີກ"
                  onClick={() => _handleShowAddConfirmModalClose()}
                />
              </Col>
              <Col sm="4">
                <CustomButton
                  disabled={isSubmitting}
                  type="submit"
                  onClick={_handleSubmit}
                  title="ຢືນຢັນ"
                  confirm
                />
              </Col>
            </Row>
          </Modal.Body>
        )}
      </Modal>

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Body>
          <Col
            className="text-center"
            style={{ paddingTop: 40, paddingBottom: 50 }}
          >
            <div
              className="textfont-size72 text-bold"
              style={{ color: "#00A1DE" }}
            >
              ສໍາເລັດ
            </div>
            <FontAwesomeIcon
              icon={faCheckCircle}
              size="9x"
              style={{ color: "#00A1DE" }}
            />
            <div
              className="textfont-size19 text-bold"
              style={{ marginTop: 40 }}
            >
              ປັນທືກເປັນທີ່ຮຽບຮ້ອຍ
            </div>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RegistrationConfirm;

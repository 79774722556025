import React, { useEffect, useState } from "react";
import { fetStatistichData } from "../Api";
import Loading from "../../../common/Loading";
import { Button, Typography } from "@mui/material";
import { SaveAlt as SaveAltIcon } from "@mui/icons-material";
import * as XLSX from "xlsx";
import "../index.css";
import { useSelector } from "react-redux";
import { hasPermission } from "../../../helpers/CheckPermission";

const StudentByTribe = ({ faculty, department, major, studentType }) => {
  const [data, setData] = useState(null);
  const accessmentRedux = useSelector((state) => state.accessment.value);

  const getData = async () => {
    setData(null);
    try {
      const result = await fetStatistichData(
        `tstudent?faculty=${faculty}&department=${department}&major=${major}&assessmentYear=${accessmentRedux}&educationLevel=BACHELORDEGREE`
      );
      setData(result);
    } catch (error) { }
  };

  useEffect(() => {
    getData();
  }, [faculty, department, major, accessmentRedux]);

  const cellStyle = {
    width: "300px",
    height: "40px",
    textAlign: "center",
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.aoa_to_sheet(formatDataForExcel());
    const merges = generateMerges();
    ws["!merges"] = merges;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "StudentsByAge");
    XLSX.writeFile(wb, "StudentsByAge.xlsx");
  };

  const formatDataForExcel = () => {
    const formattedData = [];
    const headerRow1 = ["ລຳດັບ", "ຊົນເຜົ່າ", "ຄະນະ", "ພາກ", "ສາຂາ", "ລວມ", ""];
    const headerRow2 = ["", "", "", "", "", "ລວມ", ""];
    const headerRow3 = ["", "", "", "", "", "ລວມ", "ຍິງ"];

    for (let i = 1; i <= 5; i++) {
      headerRow1.push(`ປີ ${i}`, "", "", "");
      headerRow2.push("ໄດ້ຮັບທຶນ", "", "ຈ່າຍຄ່າຮຽນ", "");
      headerRow3.push("ລວມ", "ຍິງ", "ລວມ", "ຍິງ");
    }

    formattedData.push(headerRow1, headerRow2, headerRow3);

    data.forEach((item, index) => {
      item.faculties.forEach((faculty) => {
        faculty.departments.forEach((department) => {
          department.majors.forEach((major) => {
            const row = [
              index + 1,
              item._id,
              faculty.faculty,
              department.department,
              major.major,
              major?.total || 0,
              major?.female || 0,
            ];

            for (let i = 1; i <= 5; i++) {
              const yearLevel = major.yearLevels?.find(
                (yl) => yl.year === i.toString()
              ) || {
                studentTypes: [
                  { total: 0, female: 0 },
                  { total: 0, female: 0 },
                ],
              };

              row.push(
                yearLevel.studentTypes[0]?.total || 0,
                yearLevel.studentTypes[0]?.female || 0,
                yearLevel.studentTypes[1]?.total || 0,
                yearLevel.studentTypes[1]?.female || 0
              );
            }

            formattedData.push(row);
          });
        });
      });
    });

    return formattedData;
  };

  const generateMerges = () => {
    const merges = [
      { s: { r: 0, c: 0 }, e: { r: 2, c: 0 } },
      { s: { r: 0, c: 1 }, e: { r: 2, c: 1 } },
      { s: { r: 0, c: 2 }, e: { r: 2, c: 2 } },
      { s: { r: 0, c: 3 }, e: { r: 2, c: 3 } },
      { s: { r: 0, c: 4 }, e: { r: 2, c: 4 } },
      { s: { r: 0, c: 5 }, e: { r: 1, c: 6 } },
    ];

    for (let i = 0; i < 5; i++) {
      merges.push(
        { s: { r: 0, c: 7 + i * 4 }, e: { r: 0, c: 10 + i * 4 } },
        { s: { r: 1, c: 7 + i * 4 }, e: { r: 1, c: 8 + i * 4 } },
        { s: { r: 1, c: 9 + i * 4 }, e: { r: 1, c: 10 + i * 4 } }
      );
    }

    // Add merges for row spans in the body
    let startRow = 3;
    data.forEach((item, index) => {
      let rowSpan = item.faculties.reduce(
        (acc, fac) =>
          acc +
          fac.departments.reduce(
            (accDep, dep) => accDep + dep.majors.length,
            0
          ),
        0
      );

      merges.push({
        s: { r: startRow, c: 0 },
        e: { r: startRow + rowSpan - 1, c: 0 },
      });
      merges.push({
        s: { r: startRow, c: 1 },
        e: { r: startRow + rowSpan - 1, c: 1 },
      });

      item.faculties.forEach((faculty) => {
        let facultyRowSpan = faculty.departments.reduce(
          (acc, dep) => acc + dep.majors.length,
          0
        );
        merges.push({
          s: { r: startRow, c: 2 },
          e: { r: startRow + facultyRowSpan - 1, c: 2 },
        });

        faculty.departments.forEach((department) => {
          merges.push({
            s: { r: startRow, c: 3 },
            e: { r: startRow + department.majors.length - 1, c: 3 },
          });
          startRow += department.majors.length;
        });
      });
    });

    return merges;
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div>
          <Typography variant="h5">ຈຳນວນນັກສຶກສາແຍກຕາມຊົນເຜົ່າ​</Typography>
        </div>
        <div>
          {hasPermission('STATISTIC', 'export') &&
            <Button
              variant="contained"
              color="success"
              startIcon={<i className="fa fa-file-excel"></i>}
              sx={{ textTransform: 'none' }}
              onClick={exportToExcel}
            >
              Export
            </Button>
          }
        </div>
      </div>

      {data ? (
        <div style={{ overflowX: "auto" }}>
          <div
            style={{
              maxHeight: "80vh",
              overflowY: "auto",
              paddingBottom: "100px",
            }}
          >
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1,
                }}
              >
                <tr>
                  <th rowSpan={3} style={{ width: "100px" }}>
                    ລຳດັບ
                  </th>
                  <th rowSpan={3} style={{ width: "100px" }}>
                    ຊົນເຜົ່າ
                  </th>
                  <th rowSpan={3} style={cellStyle} >
                    ຄະນະ
                  </th>
                  <th rowSpan={3} style={cellStyle}>
                    ພາກ
                  </th>
                  <th rowSpan={3} style={cellStyle}>
                    ສາຂາ
                  </th>
                  <th rowSpan={2} colSpan={2} style={cellStyle}>
                    ລວມ
                  </th>
                  {Array.from({ length: 5 }).map((_, i) => (
                    <React.Fragment key={i}>
                      <th colSpan={4} style={cellStyle}>
                        ປີ {i + 1}
                      </th>
                    </React.Fragment>
                  ))}
                </tr>
                <tr>
                  {Array.from({ length: 5 }).map((_, i) => (
                    <React.Fragment key={i}>
                      <th colSpan={2} style={cellStyle}>
                        ໄດ້ຮັບທຶນ
                      </th>
                      <th colSpan={2} style={cellStyle}>
                        ຈ່າຍຄ່າຮຽນ
                      </th>
                    </React.Fragment>
                  ))}
                </tr>
                <tr>
                  <th style={cellStyle}>ລວມ</th>
                  <th style={cellStyle}>ຍິງ</th>
                  {Array.from({ length: 5 * 2 }).map((_, i) => (
                    <React.Fragment key={i}>
                      <th style={cellStyle}>ລວມ</th>
                      <th style={cellStyle}>ຍິງ</th>
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) =>
                  item.faculties.map((faculty, facIndex) =>
                    faculty.departments.map((department, depIndex) =>
                      department.majors.map((major, majIndex) => (
                        <tr
                          key={`${index}-${facIndex}-${depIndex}-${majIndex}`}
                        >
                          {facIndex === 0 &&
                            depIndex === 0 &&
                            majIndex === 0 && (
                              <>
                                <td
                                  rowSpan={item.faculties.reduce(
                                    (acc, fac) =>
                                      acc +
                                      fac.departments.reduce(
                                        (accDep, dep) =>
                                          accDep + dep.majors.length,
                                        0
                                      ),
                                    0
                                  )}
                                  style={cellStyle}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  rowSpan={item.faculties.reduce(
                                    (acc, fac) =>
                                      acc +
                                      fac.departments.reduce(
                                        (accDep, dep) =>
                                          accDep + dep.majors.length,
                                        0
                                      ),
                                    0
                                  )}
                                  style={cellStyle}
                                >
                                  {item._id}
                                </td>
                              </>
                            )}
                          {depIndex === 0 && majIndex === 0 && (
                            <td
                              rowSpan={faculty.departments.reduce(
                                (acc, dep) => acc + dep.majors.length,
                                0
                              )}
                              style={{ width: 300, height: 40, textAlign: "start" }}
                            >
                              {faculty.faculty}
                            </td>
                          )}
                          {majIndex === 0 && (
                            <td
                              rowSpan={department.majors.length}
                              style={{ width: 300, height: 40, textAlign: "start" }}
                            >
                              {department.department}
                            </td>
                          )}
                          <td style={{ width: 300, height: 40, textAlign: "start" }}>{major.major}</td>
                          <td style={cellStyle}>{major?.total}</td>
                          <td style={cellStyle}>{major?.female}</td>
                          {Array.from({ length: 5 }).map((_, i) => {
                            const yearLevel = major.yearLevels.find(
                              (yl) => yl.year === (i + 1).toString()
                            ) || {
                              total: 0,
                              female: 0,
                              studentTypes: [
                                { total: 0, female: 0 },
                                { total: 0, female: 0 },
                              ],
                            };
                            return (
                              <React.Fragment key={i}>
                                <td style={cellStyle}>
                                  {yearLevel.studentTypes[0]?.total || 0}
                                </td>
                                <td style={cellStyle}>
                                  {yearLevel.studentTypes[0]?.female || 0}
                                </td>
                                <td style={cellStyle}>
                                  {yearLevel.studentTypes[1]?.total || 0}
                                </td>
                                <td style={cellStyle}>
                                  {yearLevel.studentTypes[1]?.female || 0}
                                </td>
                              </React.Fragment>
                            );
                          })}
                        </tr>
                      ))
                    )
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="bg-white">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default StudentByTribe;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { API_KEY, NEW_API_URL } from "../../../common/contrant";
import { USER_KEY } from "../../../consts";
import { hasPermission } from "../../../helpers/CheckPermission";

const TimeStudy = () => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;
  const accessmentRedux = useSelector((state) => state.accessment.value);

  useEffect(() => {
    if (userData) {
      setFaculty(userData?.data?.faculty?._id);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [accessmentRedux]);

  //  const url = "http://18.218.102.83:8000/api/v1/pricecategory";
  const url = NEW_API_URL + "time";
  const [time, setTime] = useState("");
  const [hour, setHour] = useState("1");
  const [data, setData] = useState([]);
  const [faculty, setFaculty] = useState(null);
  const [titleLaError, setTitleLaError] = useState("");
  const [titleEnError, setTitleEnError] = useState("");

  const fetchData = async () => {
    try {
      setData([]);
      const facId = userData?.data?.faculty?._id || "";
      const response = await axios.get(`${url}/${facId}/${accessmentRedux}`, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });

      setData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const addData = async (data) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        setShowSuccess(true);
      } else {
        alert("ເກີດບັນຫາໃນການເພີ່ມ");
      }
    } catch (error) {
      alert("ເກີດບັນຫາໃນການເພີ່ມ");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async () => {
    setTitleLaError("");
    setTitleEnError("");

    let isValid = true;

    if (time.trim() === "") {
      setTitleLaError("ກະລຸນາປ້ອນຂໍ້ມຸນ");
      isValid = false;
    }

    if (isValid) {
      const newData = {
        time: time,
        hour: hour,
        faculty: faculty,
        assessmentYear: accessmentRedux,
      };

      await addData(newData);
      await fetchData();
      setTime("");
      setHour("");
    }
  };

  const [showDelete, setShowDelete] = useState(false);
  const [selectID, setSelectID] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  const deleteRow = async (id) => {
    try {
      const fullUrl = url + "/" + id;
      await axios.delete(fullUrl, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });

      await fetchData();
      setShowSuccess(true);
    } catch (error) {
      console.error(error);
    }
  };

  const PermissionDenine = () => (
    <div class="pn">
      <h1>ຂໍອະໄພ</h1>
      <p>ທ່ານບໍ່ມິສິດເຂົ້າຈັດການຫນ້ານີ້</p>
    </div>
  );

  const [selectedItem, setSelectedItem] = useState();

  const updateTime = async () => {
    try {
      const response = await axios.put(
        url + "/" + selectedItem._id,
        selectedItem,
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        setShowSuccess(true);
      } else {
        alert("ເເກ້ໄຂເວລາບໍ່ສຳເລັດ");
      }
    } catch (error) {
      console.error(error);
      alert("ເເກ້ໄຂເວລາບໍ່ສຳເລັດ");
    }
  };

  const onInputChange = (e) => {
    const inputText = e.target.value;

    setSelectedItem((item) => {
      return {
        ...item,
        time: inputText,
      };
    });
    // Regular expression to match "HH:mm" format
    // const timePattern = /^([01]\d|2[0-3]):([0-5]\d)-([01]\d|2[0-3]):([0-5]\d)$/;
  };

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
      }, 4000);
    }
  }, [showSuccess]);

  const ConfirmDialog = () => (
    <Dialog open={showDelete} onClose={() => setShowDelete(false)}>
      <DialogTitle>ຕ້ອງການລົບແທ້ບໍ່?</DialogTitle>
      <DialogActions>
        <Button onClick={() => setShowDelete(false)} color="primary">
          ຍົກເລີກ
        </Button>
        <Button
          onClick={() => {
            deleteRow(selectID);
            setShowDelete(false);
          }}
          color="error"
          autoFocus
        >
          ຕົກລົງ
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div className="w-100 mt-5 pt-5 bg-white fh">
      <div className="row justify-content-center">
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={showSuccess}
          autoHideDuration={5000} // Set the duration to 5 seconds (5000 milliseconds)
        >
          <Alert severity="success">ສຳເລັດ</Alert>
        </Snackbar>
        <ConfirmDialog />
        {faculty === undefined && <PermissionDenine />}
        {faculty !== undefined && (
          <div className="container text-center">
            <h3>ຈັດການເວລາຮຽນ</h3>
            <div className="row text-start">
              {hasPermission("TIME_STUDY", "create") ? (
                <div className="col-2 mx-5 ms-3">
                  <label htmlFor="titleLa" className="form-label">
                    ເວລາ
                  </label>
                  <input
                    type="text"
                    id="titleLa"
                    className="form-control"
                    value={time}
                    placeholder="HH:mm"
                    onChange={(e) => setTime(e.target.value)}
                  />
                  {titleLaError && (
                    <div className="text-danger">{titleLaError}</div>
                  )}

                  <label htmlFor="titleEn" className="form-label">
                    ຊົ່ວໂມງ
                  </label>
                  <select
                    id="titleEn"
                    className="form-control"
                    value={hour}
                    onChange={(e) => setHour(e.target.value)}
                  >
                    {[...Array(10)].map((_, index) => (
                      <option key={index + 1} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>

                  {titleEnError && (
                    <div className="text-danger">{titleEnError}</div>
                  )}
                  <div className="text-center mt-">
                    <button
                      type="submit"
                      onClick={() => handleSubmit()}
                      className="btn btn-primary m-3 px-4 py-2"
                    >
                      ເພີ່ມ
                    </button>
                  </div>
                </div>
              ) : null}
              <div
                className={hasPermission("TIME_STUDY", "create") ? "col-8" : ""}
              >
                <table className="table mt-4 table-striped">
                  <thead>
                    <tr>
                      <th
                        style={{
                          backgroundColor: "#057CAE",
                          color: "white",
                        }}
                        className="text-center"
                        colSpan={1}
                      >
                        ລຳດັບ
                      </th>
                      <th
                        style={{
                          backgroundColor: "#057CAE",
                          color: "white",
                        }}
                        className="text-center"
                        colSpan={2}
                      >
                        ເວລາຮຽນ
                      </th>
                      <th
                        style={{
                          backgroundColor: "#057CAE",
                          color: "white",
                        }}
                        className="text-center"
                        colSpan={2}
                      >
                        ຊົ່ວໂມງ
                      </th>
                      <th
                        style={{
                          backgroundColor: "#057CAE",
                          color: "white",
                        }}
                        className="text-center"
                        colSpan={2}
                      >
                        ສົກຮຽນ
                      </th>
                      {hasPermission("TIME_STUDY", "edit") ||
                      hasPermission("TIME_STUDY", "delete") ? (
                        <th
                          style={{
                            backgroundColor: "#057CAE",
                            color: "white",
                          }}
                          className="text-center"
                          colSpan={2}
                        >
                          ຈັດການ
                        </th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 &&
                      data.map((item, index) => (
                        <tr key={index}>
                          <td colSpan={1} className="text-center pt-4">
                            {index + 1}
                          </td>
                          <td colSpan={2} className="text-center pt-3">
                            {item._id === selectedItem?._id ? (
                              <input
                                className="form-control"
                                value={selectedItem.time}
                                onChange={onInputChange}
                              />
                            ) : (
                              <input
                                disabled={true}
                                className="form-control"
                                value={item.time}
                              />
                            )}
                          </td>
                          <td colSpan={2} className="text-center pt-3">
                            {selectedItem?._id === item._id ? (
                              <select
                                id="titleEn"
                                className="form-control"
                                value={selectedItem?.hour}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setSelectedItem((item) => {
                                    return {
                                      ...item,
                                      hour: value,
                                    };
                                  });
                                }}
                              >
                                {[...Array(10)].map((_, index) => (
                                  <option key={index + 1} value={index + 1}>
                                    {index + 1}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <label className="pt-2">{item.hour}</label>
                            )}
                          </td>
                          <td colSpan={2} className="text-center pt-4">
                            {item.assessmentYear}
                          </td>
                          {hasPermission("TIME_STUDY", "edit") ||
                          hasPermission("TIME_STUDY", "delete") ? (
                            <td colSpan={2}>
                              {selectedItem?._id !== item._id && (
                                <button
                                  className="btn btn-control"
                                  onClick={() => {
                                    setSelectedItem(item);
                                  }}
                                  hidden={!hasPermission("TIME_STUDY", "edit")}
                                >
                                  <i
                                    className="fa fa-pen"
                                    style={{ color: "#28a745" }}
                                  ></i>
                                </button>
                              )}
                              {selectedItem?._id === item._id && (
                                <button
                                  className="btn btn-control"
                                  onClick={async () => {
                                    await updateTime();
                                    await fetchData();
                                    setSelectedItem();
                                  }}
                                  hidden={!hasPermission("TIME_STUDY", "edit")}
                                >
                                  <i
                                    className="fa fa-check-circle"
                                    style={{ color: "#28a745" }}
                                  ></i>
                                </button>
                              )}

                              <button
                                className="btn btn-control"
                                onClick={() => {
                                  setShowDelete(true);
                                  setSelectID(item._id);
                                }}
                                hidden={!hasPermission("TIME_STUDY", "delete")}
                              >
                                <i
                                  className="fa fa-trash"
                                  style={{ color: "#B80000" }}
                                ></i>
                              </button>
                            </td>
                          ) : null}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeStudy;

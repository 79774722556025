import React, { useState, useEffect } from "react";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import Consts from "../consts";
import Routers from "../consts/router";
import "./sidenav.css";
import SideNav, {
  Toggle,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import { hasPermission } from "../helpers/CheckPermission";
import { fetchGet } from "./fetchAPI";
import ClickOutside from "./ClickOutSideNav";

function CustomSideNav({ location, history }) {
  const [selectStatus, setSelectStatus] = useState(
    location.pathname.split("/")[1].split("-")[0]
  );

  // const [expandedStatus, setExpandedStatus] = useState(false);
  const [dataUser, setDataUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState();
  const [ROLE, setROLE] = useState();
  const [expanded, setExpanded] = useState(false);
  // const [dummy, setDummy] = useState("");
  // on first load
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setDataUser(user.data);
    } else {
      history.push(`/`);
    }
  }, [history]);

  // filter data by users role
  useEffect(() => {
    // if(!dataUser)return ;
    let userRole = dataUser?.role;
    setROLE(dataUser?.role);
    // check role
    if (
      userRole !== "ADMIN" &&
      userRole !== "DEAN" &&
      userRole !== "FAC_ADMIN"
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
    if (dataUser?._id) {
      fectchIsUserPermissionChange();
    }
  }, [dataUser]);

  const fectchIsUserPermissionChange = async () => {
    const user = JSON.parse(localStorage.getItem("user")); // Retrieve the user from localStorage

    try {
      const newUserData = await fetchGet(`/users/${dataUser?._id}`); // Fetch updated user data
      if (newUserData) {
        const updatedUser = {
          ...user, // Keep the existing properties of user
          data: {
            ...user.data, // Keep the existing properties of user.data
            userGroup: newUserData?.userGroup, // Update only userGroup
          },
        };
        // Save the updated user object back to localStorage
        localStorage.setItem("user", JSON.stringify(updatedUser));
      }
    } catch (error) {
      console.log("error::", error);
    }
  };
  useEffect(() => {
    if (dataUser?._id) {
      fectchIsUserPermissionChange();
    }
  }, [selectStatus]);

  return (
    <ClickOutside onClickOutside={() => setExpanded(false)}>
      <SideNav
        onSelect={(selected) => {
          setSelectStatus(selected.split("-")[0]);
          if (selected === "subject-list") {
            selected = selected;
          } else if (selected === "course-list") {
            selected = selected + Routers.PAGE_GINATION;
          } else if (selected === "teacher-list") {
            selected = selected + Routers.PAGE_GINATION;
          } else if (selected === "student-list") {
            selected = selected + Routers.PAGE_GINATION;
          } else if (selected === "document-list") {
            selected = selected + Routers.PAGE_GINATION;
          } else if (selected === "newregister") {
            selected = selected;
          } else if (selected === "code-preregistration-list") {
            selected = selected + Routers.PAGE_GINATION;
          } else if (selected === "notice-list") {
            selected = selected + Routers.PAGE_GINATION;
          } else if (selected === "registration-list") {
            selected = selected + Routers.PAGE_GINATION;
          } else if (selected === "registration_student") {
            selected = selected + "/" + 1;
          } else if (selected === "students-prere") {
            selected = selected;
          } else if (selected === "students-prere") {
            selected = selected + Routers.PAGE_GINATION;
          } else if (selected === "assign-student-list") {
            selected = selected + Routers.PAGE_GINATION;
          } else if (selected === "grades-index") {
            selected = selected + "/" + 1;
          } else if (selected === "add-grade-for-student") {
            selected = selected + "/" + 1;
          } else if (selected === "sent-grades-list") {
            selected = selected + "/" + 1;
          } else if (selected === "dashboard-page") {
            selected = selected;
          } else if (selected === "timetable") {
            selected = selected;
          } else if (selected === "payment-list") {
            selected = selected;
          } else if (selected === "statistic") {
            selected = selected;
          } else if (selected === "evaluation") {
            selected = selected;
          } else if (selected === "graduated") {
            selected = selected;
          } else if (selected === "drop-out-list") {
            selected = selected;
          }

          const to = "/" + selected;
          if (location.pathname !== to) {
            history.push(to);
            // window.location.reload(true)
          }
        }}
        style={{
          position: "fixed",
          backgroundColor: "#FFFF",
          zIndex: 10001,
          overflowY: expanded ? "auto" : null, // Enables vertical scrolling
        }}
        expanded={expanded}
        onToggle={setExpanded}
      >
        <Toggle style={{ marginLeft: 6 }} />

        <SideNav.Nav defaultSelected={location.pathname.split("/")[1]}>
          {/* grades */}
          {/* <NavItem
          hidden={!isAdmin}
          eventKey="students-prere"
          active={selectStatus === "students-prere" ? true : false}
          style={{
            borderLeft:
              selectStatus === "students-prere"
                ? "6px solid #7BB500"
                : "6px solid #fff",
          }}
        >
          <NavIcon>
            <i
              className="fas fa-home"
              style={{ fontSize: "1.75em", marginLeft: -10 }}
            ></i>
          </NavIcon>
          <NavText
            style={{
              color: Consts.PRIMARY_COLOR,
              FontWeights: "200!important",
            }}
          >
            ໜ້າຫຼັກ
          </NavText>
        </NavItem> */}

          {/* --------> Dashboard <-------- */}
          {ROLE === "DEAN" ? (
            <NavItem
              hidden={isAdmin}
              eventKey="dashboard-page"
              active={selectStatus === "dashboard" ? true : false}
              style={{
                borderLeft:
                  selectStatus === "dashboard"
                    ? "6px solid #7BB500"
                    : "6px solid #fff",
              }}
            >
              <NavIcon>
                <i
                  className="fas fa-tachometer-alt"
                  style={{ fontSize: "1.75em" }}
                ></i>
              </NavIcon>
            </NavItem>
          ) : (
            ""
          )}

          <NavItem
            hidden={!hasPermission("ENTRANCE_STUDENT", "access")}
            eventKey="entrance"
            active={selectStatus === "entrance" ? true : false}
            style={{
              borderLeft:
                selectStatus === "entrance"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fa-solid fa-file-signature"
                aria-hidden="true"
                style={{ fontSize: "1.75em" }}
              />
            </NavIcon>
            <NavText
              style={{
                color: Consts.PRIMARY_COLOR,
                FontWeights: "200!important",
              }}
            >
              Entrance
            </NavText>
          </NavItem>

          <NavItem
            hidden={
              !hasPermission("USER", "access") &&
              !hasPermission("PRERE_STUDENT", "access") &&
              !hasPermission("CODE_STUDENT", "access") &&
              !hasPermission("STUDENT", "access")
            }
            eventKey="teacher-list"
            active={
              selectStatus === "teacher" ||
              selectStatus === "student" ||
              selectStatus === "registration_student" ||
              selectStatus === "students-prere" ||
              selectStatus === "preregistration" ||
              selectStatus === "code"
                ? true
                : false
            }
            style={{
              borderLeft:
                selectStatus === "teacher" ||
                selectStatus === "student" ||
                selectStatus === "registration_student" ||
                selectStatus === "students-prere" ||
                selectStatus === "preregistration" ||
                selectStatus === "code"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fas fa-users-cog"
                style={{ fontSize: "1.75em" }}
              ></i>
            </NavIcon>
            <NavText
              className="user-key"
              style={{
                color: Consts.PRIMARY_COLOR,
                FontWeights: "200!important",
              }}
            >
              &nbsp; ຈັດການຂໍ້ມູນຜູ້ໃຊ້
            </NavText>
            <NavItem
              hidden={!hasPermission("USER", "access")}
              eventKey="teacher-list"
              active={selectStatus === "teacher" ? true : false}
              style={{
                marginLeft: -15,
                borderLeft:
                  selectStatus === "teacher"
                    ? "6px solid #7BB500"
                    : "6px solid #fff",
              }}
            >
              <NavText
                style={{
                  padding: 10,
                  borderLeft:
                    selectStatus === "teacher" ? "6px solid #7BB500" : " ",
                }}
              >
                <i className="fas fa-user-tie fa-2x"></i> &nbsp;
                ຈັດການຂໍ້ມູນອາຈານ
              </NavText>
            </NavItem>
            <NavItem
              hidden={!hasPermission("STUDENT", "access")}
              eventKey="student-list"
              active={selectStatus === "student" ? true : false}
              style={{
                marginLeft: -15,
                borderLeft:
                  selectStatus === "student"
                    ? "6px solid #7BB500"
                    : "6px solid #fff",
              }}
            >
              <NavText
                style={{
                  padding: 10,
                  borderLeft:
                    selectStatus === "student" ? "6px solid #7BB500" : " ",
                }}
              >
                <i className="fas fa-user-graduate fa-2x"></i> &nbsp;
                ຈັດການຂໍ້ມູນນັກສຶກສາ
              </NavText>
            </NavItem>
            {dataUser?.role === "FAC_ADMIN" ? (
              <NavItem
                hidden={!hasPermission("PRERE_STUDENT", "access")}
                eventKey="students-prere"
                active={selectStatus === "students-prere" ? true : false}
                style={{
                  marginLeft: -15,
                  borderLeft:
                    selectStatus === "students-prere"
                      ? "6px solid #7BB500"
                      : "6px solid #fff",
                }}
              >
                <NavText
                  style={{
                    padding: 10,
                    borderLeft:
                      selectStatus === "students-prere"
                        ? "6px solid #7BB500"
                        : " ",
                  }}
                >
                  <i className="fas fa-file-medical fa-2x"></i> &nbsp;
                  ລົງທະບຽນຮຽນ
                </NavText>
              </NavItem>
            ) : (
              <NavItem
                hidden={!hasPermission("PRERE_STUDENT", "access")}
                eventKey="students-prere"
                active={selectStatus === "students-prere" ? true : false}
                style={{
                  marginLeft: -15,
                  borderLeft:
                    selectStatus === "students-prere"
                      ? "6px solid #7BB500"
                      : "6px solid #fff",
                }}
              >
                <NavText
                  style={{
                    padding: 10,
                    borderLeft:
                      selectStatus === "students-prere"
                        ? "6px solid #7BB500"
                        : " ",
                  }}
                >
                  <i className="fas fa-file-medical fa-2x"></i> &nbsp;
                  ລົງທະບຽນຮຽນ
                </NavText>
              </NavItem>
            )}
            {ROLE === "ADMIN" && (
              <NavItem
                hidden={!hasPermission("CODE_STUDENT", "access")}
                eventKey="code-preregistration-list"
                active={selectStatus === "code" ? true : false}
                style={{
                  marginLeft: -15,
                  borderLeft:
                    selectStatus === "code"
                      ? "6px solid #7BB500"
                      : "6px solid #fff",
                }}
              >
                <NavText
                  style={{
                    padding: 10,
                    borderLeft:
                      selectStatus === "code" ? "6px solid #7BB500" : " ",
                  }}
                >
                  <i className="fas fa-file-code fa-2x"></i> &nbsp;
                  ລະຫັດລົງທະບຽນເຂົ້າຮຽນ
                </NavText>
              </NavItem>
            )}
          </NavItem>

          <NavItem
            hidden={!hasPermission("COURSE", "access")}
            eventKey="course-list"
            active={
              selectStatus === "course"
                ? true
                : false
            }
            style={{
              borderLeft:
                selectStatus === "course"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fas fa-book-reader"
                style={{ fontSize: "1.75em", FontWeights: "200!important" }}
              ></i>
            </NavIcon>

            <NavText style={{ color: Consts.PRIMARY_COLOR }}>
              ຈັດການຫລັກສູດ
            </NavText>

            <NavItem
              hidden={!hasPermission("COURSE", "access")}
              eventKey="subject-list"
              active={selectStatus === "subject" ? true : false}
              style={{
                marginLeft: -15,
                borderLeft:
                  selectStatus === "subject"
                    ? "6px solid #7BB500"
                    : "6px solid #fff",
              }}
            >
              <NavText
                style={{
                  padding: 10,
                  borderLeft:
                    selectStatus === "subject" ? "6px solid #7BB500" : " ",
                }}
              >
                <i className="fas fa-book-medical fa-2x"></i> &nbsp;
                ຈັດການລາຍວິຊາ
              </NavText>
            </NavItem>

            <NavItem
              hidden={!hasPermission("COURSE", "access")}
              eventKey="course-list"
              active={selectStatus === "course" ? true : false}
              style={{
                marginLeft: -15,
                borderLeft:
                  selectStatus === "course"
                    ? "6px solid #7BB500"
                    : "6px solid #fff",
              }}
            >
              <NavText
                style={{
                  padding: 10,
                  borderLeft:
                    selectStatus === "course" ? "6px solid #7BB500" : " ",
                }}
              >
                <i className="fas fa-book fa-2x"></i> &nbsp; ຈັດການຫລັກສູດ
              </NavText>
            </NavItem>

            <NavText style={{ color: Consts.PRIMARY_COLOR }}>
              ຈັດການຕາຕະລາງ
            </NavText>

            <NavItem
              hidden={!hasPermission("COURSE", "access")}
              eventKey="timetable"
              active={selectStatus === "timetable" ? true : false}
              style={{
                marginLeft: -15,
                borderLeft:
                  selectStatus === "timetable"
                    ? "6px solid #7BB500"
                    : "6px solid #fff",
              }}
            >
              <NavText
                style={{
                  padding: 10,
                  borderLeft:
                    selectStatus === "timetable" ? "6px solid #7BB500" : " ",
                }}
              >
                <i className="fas fa-table fa-2x"></i> &nbsp; ຈັດການຕາຕະລາງ
              </NavText>
            </NavItem>
          </NavItem>

          {/* Register menu  */}

          <NavItem
            eventKey="registration-list"
            active={
              selectStatus === "registration" || selectStatus === "newregister"
                ? true
                : false
            }
            style={{
              borderLeft:
                selectStatus === "registration" || selectStatus === "newregister"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fas fa-address-card"
                style={{ fontSize: "1.75em", FontWeights: "200!important" }}
              ></i>
            </NavIcon>

            <NavText style={{ color: Consts.PRIMARY_COLOR }}>ລົງທະບຽນ</NavText>
            <NavItem
              hidden={!hasPermission("REGISTER_YEAR_ONE", "access")}
              eventKey="registration-list"
              active={selectStatus === "registration" ? true : false}
              style={{
                marginLeft: -15,
                borderLeft:
                  selectStatus === "registration"
                    ? "6px solid #7BB500"
                    : "6px solid #fff",
              }}
            >
              <NavText
                style={{
                  padding: 10,
                  borderLeft:
                    selectStatus === "registration" ? "6px solid #7BB500" : " ",
                }}
              >
                <i className="fas fa-user-plus fa-2x"></i> &nbsp;
                ລົງທະບຽນນັກສຶກສາປີ 1
              </NavText>
            </NavItem>

            <NavItem
              hidden={!hasPermission("REGISTER_YEAR_TWO_UP", "access")}
              eventKey="newregister"
              active={selectStatus === "newregister" ? true : false}
              style={{
                marginLeft: -15,
                borderLeft:
                  selectStatus === "newregister"
                    ? "6px solid #7BB500"
                    : "6px solid #fff",
              }}
            >
              <NavText
                style={{
                  padding: 10,
                  borderLeft:
                    selectStatus === "newregister" ? "6px solid #7BB500" : " ",
                }}
              >
                <i className="fas fa-user-edit fa-2x"></i> &nbsp;
                ລົງທະບຽນນັກສຶກສາປີ 2 ຂຶ້ນໄປ
              </NavText>
            </NavItem>
          </NavItem>

          <NavItem
            hidden={!hasPermission("REGISTER_PAYMENT", "access")}
            eventKey="payment-list"
            active={selectStatus === "payment-list" ? true : false}
            style={{
              borderLeft:
                selectStatus === "payment-list"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fas fa-coins"
                style={{ fontSize: "1.75em", marginLeft: -10 }}
              ></i>
            </NavIcon>

            {/* <NavIcon>
              <i class="fa-brands fa-bitcoin"></i>
               <i
                className="fa-solid fa-coins"
                aria-hidden="true"
                style={{ fontSize: "1.75em", marginLeft: -10 }}
              /> 
            </NavIcon> */}

            <NavText
              style={{
                color: Consts.PRIMARY_COLOR,
                FontWeights: "200!important",
              }}
            >
              ຈັດການການຈ່າຍເງິນ
            </NavText>
          </NavItem>

          <NavItem
            hidden={!hasPermission("EVALUATION", "access")}
            eventKey="evaluation"
            active={selectStatus === "evaluation" ? true : false}
            style={{
              borderLeft:
                selectStatus === "evaluation"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fa-solid fa-clipboard-check"
                aria-hidden="true"
                style={{ fontSize: "1.75em", marginLeft: -10 }}
              />
            </NavIcon>
            <NavText
              style={{
                color: Consts.PRIMARY_COLOR,
                FontWeights: "200!important",
              }}
            >
              ປະເມີນອາຈານ
            </NavText>
          </NavItem>

          {/* assign student to class */}
          <NavItem
            eventKey="assign-student-list"
            active={selectStatus === "assign" ? true : false}
            style={{
              borderLeft:
                selectStatus === "assign"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fas fa-chalkboard-teacher"
                style={{ fontSize: "1.75em", marginLeft: -10 }}
              ></i>
            </NavIcon>
            <NavText
              style={{
                color: Consts.PRIMARY_COLOR,
                FontWeights: "200!important",
              }}
            >
              ຈັດຫ້ອງໃຫ້ນັກສຶກສາໃໝ່
            </NavText>
          </NavItem>

          {/* grades */}
          <NavItem
            hidden={!hasPermission("GRADE", "access")}
            // selected={selectStatus == 'grades'?true:false}
            eventKey="grades-index"
            active={selectStatus === "grades" ? true : false}
            style={{
              borderLeft:
                selectStatus === "grades"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fas fa-star"
                style={{ fontSize: "1.75em", marginLeft: -10 }}
              ></i>
            </NavIcon>
            <NavText
              style={{
                color: Consts.PRIMARY_COLOR,
                FontWeights: "200!important",
              }}
            >
              ຈັດການຄະແນນ
            </NavText>
          </NavItem>

          <NavItem
            hidden={!hasPermission("E_LEARNING", "access")}
            eventKey="elearning"
            active={selectStatus === "elearning" ? true : false}
            style={{
              borderLeft:
                selectStatus === "elearning"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fa fa-laptop-house"
                aria-hidden="true"
                style={{ fontSize: "1.75em", marginLeft: -10 }}
              />
            </NavIcon>
            <NavText
              style={{
                color: Consts.PRIMARY_COLOR,
                FontWeights: "200!important",
              }}
            >
              ຮຽນອອນໄລນ
            </NavText>
          </NavItem>

          <NavItem
            // hidden={!hasPermission('E_LEARNING', 'access')}
            eventKey="drop-out-list"
            active={selectStatus === "drop-out-list" ? true : false}
            style={{
              borderLeft:
                selectStatus === "drop-out-list"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fa fa-graduation-cap"
                aria-hidden="true"
                style={{ fontSize: "1.75em", marginLeft: -10 }}
              />
            </NavIcon>
            <NavText
              style={{
                color: Consts.PRIMARY_COLOR,
                FontWeights: "200!important",
              }}
            >
                ໂຈະການຮຽນ
            </NavText>
          </NavItem>

          <NavItem
            hidden={!hasPermission('GRADUATED', 'access')}
            eventKey="graduated"
            active={selectStatus === "graduated" ? true : false}
            style={{
              borderLeft:
                selectStatus === "graduated"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fa fa-trophy"
                aria-hidden="true"
                style={{ fontSize: "1.75em", marginLeft: -10 }}
              />
            </NavIcon>
            <NavText
              style={{
                color: Consts.PRIMARY_COLOR,
                FontWeights: "200!important",
              }}
            >
              ສຳເລັດການສຶກສາ
              {/* graduated  */}
            </NavText>
          </NavItem>

          {/* <NavItem
            // hidden={isAdmin}
            eventKey="document-list"
            active={selectStatus === "document" ? true : false}
            style={{
              borderLeft:
                selectStatus === "document"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fas fa-layer-group"
                style={{ fontSize: "1.75em", marginLeft: -10 }}
              ></i>
            </NavIcon>
            <NavText
              style={{
                color: Consts.PRIMARY_COLOR,
                FontWeights: "200!important",
              }}
            >
              ຈັດການໄຟລ
            </NavText>
          </NavItem> */}

          <NavItem
            // hidden={isAdmin}
            eventKey="notice-list"
            active={selectStatus === "notice" ? true : false}
            style={{
              borderLeft:
                selectStatus === "notice"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fa fa-bullhorn"
                aria-hidden="true"
                style={{ fontSize: "1.75em", marginLeft: -10 }}
              />
            </NavIcon>
            <NavText
              style={{
                color: Consts.PRIMARY_COLOR,
                FontWeights: "200!important",
              }}
            >
              ແຈ້ງການ ແລະ ລະບຽບ
            </NavText>
          </NavItem>

          <NavItem
            hidden={!hasPermission("STATISTIC", "access")}
            eventKey="statistic"
            active={selectStatus === "statistic" ? true : false}
            style={{
              borderLeft:
                selectStatus === "statistic"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fa-solid fa-chart-line"
                aria-hidden="true"
                style={{ fontSize: "1.75em", marginLeft: -10 }}
              />
            </NavIcon>
            <NavText
              style={{
                color: Consts.PRIMARY_COLOR,
                FontWeights: "200!important",
              }}
            >
              ລາຍງານ
            </NavText>
          </NavItem>

          <NavItem
            // hidden={isAdmin}
            eventKey="setting-list"
            active={selectStatus === "setting" ? true : false}
            style={{
              borderLeft:
                selectStatus === "setting"
                  ? "6px solid #7BB500"
                  : "6px solid #fff",
            }}
          >
            <NavIcon>
              <i
                className="fa fa-cogs"
                aria-hidden="true"
                style={{ fontSize: "1.75em", marginLeft: -10 }}
              />
            </NavIcon>
            <NavText
              style={{
                color: Consts.PRIMARY_COLOR,
                FontWeights: "200!important",
              }}
            >
              ຕັ້ງຄ່າ
            </NavText>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    </ClickOutside>
  );
}

export default CustomSideNav;

import React, { useState } from "react";
import {
  Modal,
  Button,
  Typography,
  Box,
  CircularProgress,
  TextField,
} from "@mui/material";

const modalStyle = (theme) => ({
  position: "absolute",
  width: 400,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  outline: "none",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  border: `1px solid ${theme.palette.divider}`,
});

const buttonContainerStyle = {
  marginTop: 3,
  display: "flex",
  justifyContent: "center",
  gap: 2,
};

const ConfirmModal = ({
  open,
  title,
  message,
  onSubmit,
  onCancel,
  loading,
  hasInput = false,
  placeholder = "Enter value...",
}) => {
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState(false);

  const handleSubmit = () => {
    if (hasInput && inputValue.trim() === "") {
      setInputError(true);
      return;
    }
    setInputError(false);
    onSubmit(hasInput ? inputValue : undefined);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (inputError && e.target.value.trim() !== "") {
      setInputError(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onCancel}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        <Typography variant="h6" id="modal-title" gutterBottom>
          {title}
        </Typography>

        {Array.isArray(message) ? (
          message.map((text, index) => (
            <Typography
              variant="body2"
              color="textSecondary"
              mb={2}
              key={index}
            >
              {text?.message || text}
            </Typography>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary" mb={2}>
            {message}
          </Typography>
        )}

        {hasInput && (
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            placeholder={placeholder}
            value={inputValue}
            onChange={handleInputChange}
            error={inputError}
            helperText={inputError ? "ບໍ່ໃຫ້ວ່າງເ" : ""}
            sx={{ mb: 2 }}
          />
        )}

        <Box sx={buttonContainerStyle}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="error"
            sx={{ px: 4, py: 1.5, minWidth: 120 }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              "ຢືນຢັນ"
            )}
          </Button>
          <Button
            onClick={onCancel}
            variant="outlined"
            color="primary"
            sx={{ px: 4, py: 1.5 }}
            disabled={loading}
          >
            ຍົກເລີກ
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;

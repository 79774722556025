import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Chip,
  Stack,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  fetchGet,
  fetchPost,
  fetchDelete,
  fetchPut,
} from "../../common/fetchAPI";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import AlertMessage from "../../common/AlertMessage";
import ConfirmModal from "../../common/ConfirmDialog";
import EntranceBuilding from "./EntranceBuilding";
import EntranceClassRoom from "./EntranceClassRoom";

const EntranceSetting = () => {
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const [dateEntrance, setDateEntrance] = useState([]);
  const [placeEntrance, setPlaceEntrance] = useState([]);
  const [openDateDialog, setOpenDateDialog] = useState(false);
  const [openPlaceDialog, setOpenPlaceDialog] = useState(false);
  const [selectedDateId, setSelectedDateId] = useState("");
  const [selectedPlaceId, setSelectedPlaceId] = useState("");
  const [showConfrmModal, setShowConfirmModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("ສະຖານທີ");
  const tabs = ["ສະຖານທີ", "ຕຶກ", "ຫ້ອງ", "ວັນທີ"];
  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "",
  });

  const [dateForm, setDateForm] = useState({
    type: "EXAM",
    assessmentYear: accessmentRedux,
    entranceDate: "",
    entranceEndDate: "",
  });
  console.log("date::", dateForm)
  const [dateFormValidate, setDateFormValidate] = useState({
    type: "",
    assessmentYear: "",
    entranceDate: "",
    entranceEndDate: "",
  });

  const [placeForm, setPlaceForm] = useState({
    place: "",
    assessmentYear: accessmentRedux,
    placeInfo: "",
    placeUrl: "",
  });

  const [placeFormValidate, setPlaceFormValidate] = useState({
    place: "",
    assessmentYear: "",
    placeInfo: "",
    placeUrl: "",
  });

  const validatePlace = () => {
    const error = {};

    // Validation checks
    if (!placeForm.place) error.place = "ກະລຸນາປ້ອນຂໍ້ມຸນ";
    if (!placeForm.assessmentYear) error.assessmentYear = "ກະລຸນາປ້ອນຂໍ້ມຸນ";

    // Set validation errors
    setPlaceFormValidate(error);

    // Return validation result
    return Object.keys(error).length === 0;
  };

  const validateDate = () => {
    const error = {};

    // Validation checks
    if (!dateForm.assessmentYear) error.assessmentYear = "ກະລຸນາປ້ອນຂໍ້ມຸນ";
    if (!dateForm.entranceDate) error.entranceDate = "ກະລຸນາປ້ອນຂໍ້ມຸນ";
    if (!dateForm.entranceEndDate) error.entranceEndDate = "ກະລຸນາປ້ອນຂໍ້ມຸນ";

    // Set validation errors
    setDateFormValidate(error);

    // Return validation result
    return Object.keys(error).length === 0;
  };

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus({ ...actionStatus, open: false });
      }, 3000);
    }
  }, [actionStatus]);

  // Fetch existing entries on load
  useEffect(() => {
    fetchDate();
    fetchPlaces();
  }, [accessmentRedux]);

  const fetchDate = async () => {
    const dates = await fetchGet(
      "entrance/date?assessmentYear=" + accessmentRedux
    );
    if (dates) {
      setDateEntrance(dates);
    }
  };

  const fetchPlaces = async () => {
    const places = await fetchGet(
      "entrance/place?assessmentYear=" + accessmentRedux
    );
    if (places) {
      setPlaceEntrance(places);
    }
  };

  // Handle form input changes
  const handleInputChange = (event, formType) => {
    const { name, value } = event.target;
    formType === "date"
      ? setDateForm({ ...dateForm, [name]: value })
      : setPlaceForm({ ...placeForm, [name]: value });
  };

  // Handle close dialog

  const handleCloseDialog = () => {
    setOpenDateDialog(false);
    setOpenPlaceDialog(false);
    setSelectedDateId("");
    setSelectedPlaceId("");
    setDateForm({
      type: "EXAM",
      assessmentYear: accessmentRedux,
      entranceDate: "",
      entranceEndDate: "",
    });
    setDateFormValidate({
      type: "",
      assessmentYear: "",
      entranceDate: "",
      entranceEndDate: "",
    });
    setPlaceForm({
      place: "",
      assessmentYear: accessmentRedux,
      placeInfo: "",
      placeUrl: "",
    });
    setPlaceFormValidate({
      place: "",
      assessmentYear: "",
      placeInfo: "",
      placeUrl: "",
    });
  }

  // Submit new date entry

  const handleAddDate = async () => {
    if (!validateDate()) return; // Stop if date validation fails
    // check if update case
    const isEditing = Boolean(selectedDateId);
    // check exist each type
    const existingExamDates = dateEntrance?.filter((item) => item.type === "EXAM") || [];
    const existingFormDates = dateEntrance?.filter((item) => item.type === "FORM") || [];
// can not add existing type
    if (!isEditing) {
      if (existingExamDates.length >= 1 && dateForm.type === "EXAM") {
        setActionStatus({
          open: true,
          message: "ມີປະເພດການສອບເສັງແລ້ວ! ກະລຸນາໄປທີ່ແກ້ໄຂ",
          type: "warning",
        });
        return;
      }

      if (existingFormDates.length >= 1 && dateForm.type === "FORM") {
        setActionStatus({
          open: true,
          message: "ມີປະເພດສະໝັກຟອມເສັງແລ້ວ! ກະລຸນາໄປທີ່ແກ້ໄຂ",
          type: "warning",
        });
        return;
      }
    }

    try {

      const endpoint = isEditing ? `entrance/date/${selectedDateId}` : "entrance/date";
      const method = isEditing ? fetchPut : fetchPost;

      const response = await method(endpoint, dateForm);

      if (response) {
        setActionStatus({
          open: true,
          message: isEditing ? "ເເກ້ໄຂຂໍ້ມູນສຳເລັດ" : "ເພີ່ມຂໍ້ມູນສຳເລັດ",
          type: "success",
        });
        fetchDate(); // Refresh the list
      } else {
        throw new Error("API call failed");
      }
    } catch (error) {
      console.error("Error handling date operation:", error);
      setActionStatus({
        open: true,
        message: "ຂໍອະໄພ ລະບົບມິບັນຫາ",
        type: "error",
      });
    } finally {
      handleCloseDialog();
    }
  };

  // Submit new place entry
  const handleAddPlace = async () => {
    if (validatePlace()) {
      try {
        const isEditing = Boolean(selectedPlaceId);
        const endpoint = isEditing
          ? `entrance/place/${selectedPlaceId}`
          : "entrance/place";
        const method = isEditing ? fetchPut : fetchPost;
        const successMessage = "ເເກ້ໄຂຂໍ້ມຸນສຳເລັດ";
        const errorMessage = "ຂໍອະໄພ ລະບົບມິບັນຫາ";

        const response = await method(endpoint, placeForm);

        if (response) {
          setActionStatus({
            open: true,
            message: successMessage,
            type: "success",
          });
          fetchPlaces();
        } else {
          setActionStatus({
            open: true,
            message: errorMessage,
            type: "error",
          });
        }
      } catch (error) {
        setActionStatus({
          open: true,
          message: "ຂໍອະໄພ ລະບົບມິບັນຫາ",
          type: "error",
        });
      } finally {
        handleCloseDialog();
      }
    }
  };

  // Delete an entry

  const handleDelete = async (id, type) => {
    try {
      const url = `entrance/${type}/${id}`;
      const successMessage = "ລຶບຂໍ້ມູນສຳເລັດ";
      const errorMessage = "ຂໍອະໄພ ລະບົບມິບັນຫາ";

      const response = await fetchDelete(url);

      if (response) {
        setActionStatus({
          open: true,
          message: successMessage,
          type: "success",
        });

        type === "date"
          ? fetchDate()
          : fetchPlaces();
      } else {
        setActionStatus({
          open: true,
          message: errorMessage,
          type: "error",
        });
      }
    } catch (error) {
      console.error("Error deleting entry:", error);
      setActionStatus({
        open: true,
        message: "ຂໍອະໄພ ລະບົບມິບັນຫາ",
        type: "error",
      });
    } finally {
      setShowConfirmModal(false);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        sx={{ mb: 1, p: 3, backgroundColor: "#fff" }}
      >
        {tabs.map((tab, index) => (
          <Chip
            key={index}
            label={tab}
            clickable
            color={selectedTab === tab ? "primary" : "default"}
            sx={{
              backgroundColor: selectedTab === tab ? "#1976d2" : "#e9ecef",
              color: selectedTab === tab ? "#fff" : "#000",
              fontWeight: selectedTab === tab ? "bold" : "normal",
              fontSize: "1rem",
              borderRadius: 3,
              px: 4,
              paddingBottom: "20px",
              paddingTop: "20px",
              boxShadow:
                selectedTab === tab
                  ? "rgba(0, 0, 0, 0.08) 0px 6px 24px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px"
                  : "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: selectedTab === tab ? "#145ca8" : "#f9f9f9",
                color: selectedTab === tab ? "#fff" : "#000",
              },
            }}
            onClick={() => setSelectedTab(tab)}
          />
        ))}
      </Stack>
      {selectedTab === "ວັນທີ" && (
        <Box
          sx={{
            padding: 2, // Example additional styling
            px: 30,
            minHeight: "80vh",
            boxShadow:
              "0px 0px 5px 0px rgba(0, 0, 0, 0.1), 0px 0px 1px 0px rgba(0, 0, 0, 0.1)",
            borderRadius: 2, // Example rounded corners
            backgroundColor: "white", // Example background color
          }}
        >
          <Typography variant="h6">{"ລາຍການວັນທີ່"} </Typography>
          {dateEntrance?.length < 10 &&
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpenDateDialog(true)}
              sx={{ mt: 2 }}
            >
              ເພີ່ມວັນທີ
            </Button>
          }
          <TableContainer sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: "#edf2f4",
                      textAlign: "center",
                    }}
                  >
                    ປະເພດ
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#edf2f4",
                      textAlign: "center",
                    }}
                  >
                    ວັນທີ່ເປີດ
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#edf2f4",
                      textAlign: "center",
                    }}
                  >
                    ວັນທີປິດ
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#edf2f4",
                      textAlign: "center",
                    }}
                  >
                    ລົບ
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dateEntrance.map((entry) => (
                  <TableRow key={entry._id}>
                    <TableCell>
                      {entry.type === "FORM"
                        ? "ເປີດຮັບສະຫມັກຟອທເສັງ"
                        : "ມື້ສອບເສັງ"}
                    </TableCell>
                    <TableCell>
                      {new Date(entry.entranceDate).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {new Date(entry.entranceEndDate).toLocaleDateString()}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setSelectedDateId(entry._id);
                          setDateForm({
                            ...entry,
                            entranceDate: entry.entranceDate ? entry.entranceDate.split("T")[0] : "", // change date format
                            entranceEndDate: entry.entranceEndDate ? entry.entranceEndDate.split("T")[0] : "",// change date format
                          });
                          setOpenDateDialog(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => {
                          setShowConfirmModal(true);
                          setSelectedDateId(entry._id);
                          setSelectedPlaceId("");
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {selectedTab === "ສະຖານທີ" && (
        <Box
          sx={{
            padding: 2, // Example additional styling
            px: 30,
            minHeight: "80vh",
            boxShadow:
              "0px 0px 5px 0px rgba(0, 0, 0, 0.1), 0px 0px 1px 0px rgba(0, 0, 0, 0.1)",
            borderRadius: 2, // Example rounded corners
            backgroundColor: "white", // Example background color
          }}
        >
          <Typography variant="h6">ຂໍ້ມຸນລາຍການສະຖານທີ</Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenPlaceDialog(true)}
            sx={{ mt: 2 }}
          >
            ເພີ່ມສະຖານທີ
          </Button>
          <TableContainer sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: "#edf2f4",
                      textAlign: "center",
                    }}
                  >
                    ຫົວຂໍ້ສະຖານທີ
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#edf2f4",
                      textAlign: "center",
                    }}
                  >
                    ຂໍ້ມຸນສະຖານທີ
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#edf2f4",
                      textAlign: "center",
                    }}
                  >
                    ຕຳແໜ່ງສະຖານທີ
                  </TableCell>

                  <TableCell
                    sx={{
                      backgroundColor: "#edf2f4",
                      textAlign: "center",
                    }}
                  >
                    ຈັດການ
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {placeEntrance.map((entry) => (
                  <TableRow key={entry._id}>
                    <TableCell>{entry.place}</TableCell>
                    <TableCell>{entry.placeInfo}</TableCell>
                    <TableCell>
                      <a
                        href={entry.placeUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ເບີ່ງສະຖານທີ
                      </a>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setSelectedPlaceId(entry._id);
                          setOpenPlaceDialog(true);
                          setPlaceForm(entry);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => {
                          setShowConfirmModal(true);
                          setSelectedPlaceId(entry._id);
                          setSelectedDateId("");
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {selectedTab === "ຕຶກ" && (
        <EntranceBuilding />
      )}

      {selectedTab === "ຫ້ອງ" && <EntranceClassRoom />}

      {/* Date Dialog */}
      <Dialog open={openDateDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {selectedDateId ? "ແກ້ໄຂວັນທີເສັງ" : "ເພີ່ມວັນທີເສັງ"}
        </DialogTitle>
        <DialogContent>
          <TextField
            disabled
            margin="dense"
            label="ສົກຮຽນ"
            name="assessmentYear"
            value={accessmentRedux}
            fullWidth
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>ປະເພດ</InputLabel>
            <Select
              label="ປະເພດ"
              name="type"
              value={dateForm.type}
              onChange={(e) => handleInputChange(e, "date")}
            >
              <MenuItem value="EXAM">ການເປີດການເສັງ</MenuItem>
              <MenuItem value="FORM">ການເປີດສະຫມັກຟອມເສັງ</MenuItem>
            </Select>
          </FormControl>

          <TextField
            margin="dense"
            label="ວັນທີ່ເປີດ"
            name="entranceDate"
            type="date"
            value={dateForm.entranceDate}
            onChange={(e) => handleInputChange(e, "date")}
            fullWidth
            InputLabelProps={{ shrink: true }}
            error={dateFormValidate.entranceDate}
            helperText={dateFormValidate.entranceDate}
          />
          <TextField
            margin="dense"
            label="ວັນທີປິດ"
            name="entranceEndDate"
            type="date"
            value={dateForm.entranceEndDate}
            onChange={(e) => handleInputChange(e, "date")}
            fullWidth
            InputLabelProps={{ shrink: true }}
            error={dateFormValidate.entranceEndDate}
            helperText={dateFormValidate.entranceEndDate}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>ຍົກເລີກ</Button>
          <Button onClick={handleAddDate} variant="contained">
            ບັນທຶກ
          </Button>
        </DialogActions>
      </Dialog>

      {/* Place Dialog */}
      <Dialog open={openPlaceDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {selectedPlaceId ? "ແກ້ໄຂສະຖານທີເສັງ" : "ເພີ່ມສະຖານທີເສັງ"}
        </DialogTitle>
        <DialogContent>
          <TextField
            disabled
            margin="dense"
            label="ສົກຮຽນ"
            name="assessmentYear"
            value={placeForm.assessmentYear}
            fullWidth
            error={placeFormValidate.assessmentYear}
            helperText={placeFormValidate.assessmentYear}
          />
          <TextField
            margin="dense"
            label="ຫົວຂໍ້ສະຖານທີ"
            name="place"
            value={placeForm.place}
            onChange={(e) => handleInputChange(e, "place")}
            fullWidth
            error={placeFormValidate.place}
            helperText={placeFormValidate.place}
          />
          <TextField
            margin="dense"
            label="ລາຍລະອຽດສະຖານທີ"
            name="placeInfo"
            value={placeForm.placeInfo}
            onChange={(e) => handleInputChange(e, "place")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="ຕຳແໜ່ງສະຖານທີ"
            name="placeUrl"
            value={placeForm.placeUrl}
            onChange={(e) => handleInputChange(e, "place")}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>ຍົກເລີກ</Button>
          <Button onClick={handleAddPlace} variant="contained">
            ບັນທຶກ
          </Button>
        </DialogActions>
      </Dialog>
      <AlertMessage
        open={actionStatus.open}
        message={actionStatus.message}
        type={actionStatus.type}
      />
      <ConfirmModal
        onCancel={() => setShowConfirmModal(false)}
        open={showConfrmModal}
        title={selectedDateId ? "ລົບຂໍ້ມຸນວັນທີ່" : "ລົບຂໍ້ມຸນສະຖານທີສອບເສັງ"}
        message={
          selectedDateId
            ? ""
            : "ເມື່ອທ່ານລົບຂໍ້ມຸນສະຖານທີ່ແລ້ວ ຂໍ້ມຸນຕ່າງທີ່ຢູ່ໃນສະຖານທີ່ຈະຖືກລົບໄປນຳ"
        }
        onSubmit={async () => {
          if (selectedDateId) {
            await handleDelete(selectedDateId, "date");
          } else {
            await handleDelete(selectedPlaceId, "place");
          }
        }}
      />
    </Box>
  );
};

export default EntranceSetting;

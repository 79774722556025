import React from "react";
import {
  CircularProgress,
  Modal,
  Typography,
  IconButton,
  Fade,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const LoadingDialog = ({ open, message, showCancel = false, onCancel }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="loading-modal-title"
      aria-describedby="loading-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <div
          style={{
            position: "relative",
            backgroundColor: "#fff",
            padding: "2rem",
            borderRadius: "16px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: "300px",
          }}
        >
          {showCancel && (
            <IconButton
              onClick={onCancel}
              style={{ position: "absolute", top: "8px", right: "8px" }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <CircularProgress />
          <Typography
            variant="h6"
            style={{ marginTop: "1.5rem", textAlign: "center" }}
          >
            {message || "ກຳລັງໂຫລດ..."}
          </Typography>
        </div>
      </Fade>
    </Modal>
  );
};

export default LoadingDialog;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Paper,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import AlertMessage from "../../../common/AlertMessage";
import ConfirmModal from "../../../common/ConfirmDialog";
import { fetchGet, fetchDelete } from "../../../common/fetchAPI";
import { USER_KEY } from "../../../consts";
import AddIcon from "@mui/icons-material/Add";
import useReactRouter from "use-react-router";
import { hasPermission } from "../../../helpers/CheckPermission";

const UserGroupList = () => {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const role = userObject?.data?.role;
  const [userGroupdList, setUserGroupList] = useState([]);
  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "success",
  });
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [facultyList, setFacultyList] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState(userObject?.data?.role === "ADMIN" ? "" : userObject?.data?.faculty?._id);

  const { history } = useReactRouter();

  const fetchFaculty = async () => {
    try {
      const response = await fetchGet("/faculty");
      if (response.success) {
        setFacultyList(response?.data);
      }
    } catch (error) {
      console.error("Error fetching faculty:", error);
    }
  };

  const fetchUserGroup = async () => {
    const response = await fetchGet("usergroup?faculty=" + selectedFaculty);
    if (response?.userGroups) {
      setUserGroupList(response?.userGroups);
    }
  };

  useEffect(() => {
    fetchFaculty();
    fetchUserGroup();
  }, []);

  useEffect(() => {
    fetchUserGroup();
  }, [selectedFaculty]);

  const handleEdit = (id) => {
    history.push({
      pathname: "/setting-permission",
      state: { id }, // Sending the ID and additional data
    });
  };

  const handleDelete = async () => {
    if (selectedGroupId) {
      const response = await fetchDelete(`/usergroup/${selectedGroupId}`);
      if (response) {
        setActionStatus({
          open: true,
          message: "ລົບກຸ່ມຜູ້ໃຊ້ສຳເລັດ",
          type: "success",
        });
        fetchUserGroup();

        // Refresh the user group list after deletion
      } else {
        setActionStatus({
          open: true,
          message:
            "ລົບກຸ່ມຜູ້ໃຊ້ບໍ່ສຳເລັດ, ຖ້າກຸ່ມຜູ້ໃຊ້ນີ້ຜູກກັບຜູ້ໃຊ້ຄົນອື່ນແລ້ວຈະບໍ່ສາມາດລົບອອກໄດ້",
          type: "error",
        });
      }
      setConfirmDelete(false);
    }
  };

  const handleFacultyChange = (event) => {
    setSelectedFaculty(event.target.value);
  };

  const handleDeleteConfirmation = (id) => {
    setSelectedGroupId(id);
    setConfirmDelete(true);
  };

  useEffect(() => {
    if (actionStatus.open) {
      const timer = setTimeout(() => {
        setActionStatus({ ...actionStatus, open: false });
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [actionStatus]);

  return (
    <Box
      sx={{
        p: 4,
        backgroundColor: "white",
      }}
    >
      {/* Alert Message */}
      <AlertMessage
        open={actionStatus.open}
        message={actionStatus.message}
        type={actionStatus.type}
      />

      {/* Confirmation Modal */}
      <ConfirmModal
        title={"ຢືນຢັນການລົບກຸ່ມຜູ້ໃຊ້?"}
        open={confirmDelete}
        onSubmit={handleDelete}
        onCancel={() => setConfirmDelete(false)}
      />

      {/* User Group Table */}

      <Typography variant="h5" sx={{ p: 2 }}>
        ລາຍການກຸ່ມຜູ້ໃຊ້
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 2,
          mb: 3,
        }}
      >
        <FormControl sx={{ minWidth: 300 }} size="small">
          <InputLabel id="faculty-select-label">ຄະນະທັງຫມົດ</InputLabel>
          <Select
            disabled={role !== "ADMIN"}
            label="ຄະນະທັງຫມົດ"
            value={selectedFaculty}
            onChange={handleFacultyChange}
          >
            <MenuItem value="">
              <span>ຄະນະທັງຫມົດ</span>
            </MenuItem>
            {facultyList.map((faculty) => (
              <MenuItem key={faculty._id} value={faculty._id}>
                {faculty.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* {hasPermission('SETTING_PERMISSION', 'create') && ( */}
          <Button
            onClick={() => {
              history.push("/setting-permission");
            }}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            ເພີ່ມກຸ່ມຜູ້ໃຊ້ໃຫມ່
          </Button>
        {/* )} */}
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ color: "white", textAlign: "center", fontWeight: "bold" }}
            >
              ຊື່ກຸ່ມຜູ່ໃຊ້
            </TableCell>
            <TableCell
              sx={{ color: "white", textAlign: "center", fontWeight: "bold" }}
            >
              ຄະນະ
            </TableCell>
            <TableCell
              sx={{ color: "white", textAlign: "center", fontWeight: "bold" }}
            >
              ຜູ້ສ້າງ
            </TableCell>
            <TableCell
              sx={{ color: "white", textAlign: "center", fontWeight: "bold" }}
            >
              ວັນທີສ້າງ
            </TableCell>
            {hasPermission('SETTING_PERMISSION', 'edit') || hasPermission('SETTING_PERMISSION', 'delete') ?
              <TableCell
                sx={{
                  color: "white",
                  textAlign: "center",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                ຈັດການ
              </TableCell>
              : null
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {userGroupdList.length > 0 &&
            userGroupdList.map((row) => (
              <TableRow key={row.id}>
                <TableCell
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {row?.title}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {row?.faculty?.name}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {row?.createdBy?.firstname + " " + row?.createdBy?.lastname}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {new Date(row?.createdAt).toLocaleDateString()}
                </TableCell>
                {hasPermission('SETTING_PERMISSION', 'edit') || hasPermission('SETTING_PERMISSION', 'delete') ?
                  <TableCell sx={{ textAlign: "center" }}>
                    {hasPermission('SETTING_PERMISSION', 'edit') ?
                      <Tooltip title="Edit">
                        <IconButton
                          color="primary"
                          onClick={() => handleEdit(row._id)}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      : null
                    }
                    {hasPermission('SETTING_PERMISSION', 'delete') ?
                      <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteConfirmation(row._id)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                      : null
                    }
                  </TableCell>
                  : null
                }
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default UserGroupList;

import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import { Modal } from "react-bootstrap";
import Consts, { USER_KEY } from "../../consts";
import Routers from "../../consts/router";
import { convertGender, convertRole } from "../../helpers/user";
import { Button } from "@mui/material";
import axios from "axios";
import { API_KEY, NEW_API_URL } from "../../common/contrant";
import { toast } from "react-toastify";

function TeacherAddConfirm({
  showAddConfirmModal,
  _handleShowAddConfirmModalClose,
}) {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;
  const { history, location } = useReactRouter();
  const [dataFromStored, setDataFromStored] = useState();
  const teacherDataById = location?.state;
  useEffect(() => {
    const storedGeneral =
      JSON.parse(localStorage.getItem("generalInfo")) || null;
    const storedStatistic =
      JSON.parse(localStorage.getItem("statisticInfo")) || null;
    const storedPlaceOfBirth =
      JSON.parse(localStorage.getItem("placeOfBirthInfo")) || null;
    const combineData = {
      ...storedGeneral,
      ...storedStatistic,
      ...storedPlaceOfBirth,
    };
    setDataFromStored(combineData);
  }, [showAddConfirmModal]);
  const handleClose = () => {
    _handleShowAddConfirmModalClose();
  };
  const _confirmTeacherAdd = async () => {
    if (teacherDataById === undefined) {
      const body = { ...dataFromStored };
      delete body.facultyName;
      delete body.departmentName;
      delete body.userGroupName;
      delete body.tribeName;
      delete body.religionName;
      try {
        const response = await axios.post(NEW_API_URL + "users", body, {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 201) {
          toast.success("ເພິ່ມຂໍ້ມູນອາຈານສຳເລັດ");
          localStorage.removeItem("generalInfo");
          localStorage.removeItem("placeOfBirthInfo");
          localStorage.removeItem("statisticInfo");
          handleClose();
          history.push(Routers.TEACHER_LIST + Routers.PAGE_GINATION);
        }
      } catch (err) {
        if (err.response && err.response.status === 409) {
          toast.error(
            "ເພິ່ມຂໍ້ມູນອາຈານບໍ່ສຳເລັດ!, ເລກບັນຊີຜູ້ໃຊ້ມີຢູ່ໃນລະບົບແລ້ວ"
          );
        } else {
          toast.error("ເພິ່ມຂໍ້ມູນອາຈານບໍ່ສຳເລັດ!");
        }
      }
    } else {
      const body = { ...dataFromStored };
      delete body.facultyName;
      delete body.departmentName;
      delete body.userGroupName;
      delete body.tribeName;
      delete body.religionName;
      delete body.password;
      delete body.confirmPassword;
      try {
        const response = await axios.patch(
          NEW_API_URL + "users" + "?id=" + teacherDataById._id,
          body,
          {
            headers: {
              api_key: API_KEY,
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 201) {
          toast.success("ແກ້ໄຂຂໍ້ມູນອາຈານສຳເລັດ");
          localStorage.removeItem("generalInfo");
          localStorage.removeItem("placeOfBirthInfo");
          localStorage.removeItem("statisticInfo");
          handleClose();
          history.push(Routers.TEACHER_LIST + Routers.PAGE_GINATION);
        }
      } catch (err) {
        if (err.response && err.response.status === 409) {
          toast.error(
            "ເເກ້ໄຂຂໍ້ມູນອາຈານບໍ່ສຳເລັດ!, ເລກບັນຊີຜູ້ໃຊ້ມີຢູ່ໃນລະບົບແລ້ວ"
          );
        } else {
          toast.error("ແກ້ໄຂຂໍ້ມູນອາຈານບໍ່ສຳເລັດ!");
        }
      }
    }
  };
  return (
    <div>
      <Modal
        show={showAddConfirmModal}
        onHide={_handleShowAddConfirmModalClose}
        size="lg"
      >
        <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
          {teacherDataById === undefined
            ? "ຢືນຢັນການເພີ່ມອາຈານ"
            : "ຢືນຢັນການແກ້ໄຂອາຈານ"}
        </Modal.Title>
        <Modal.Body
          style={{
            // marginLeft: 50,
            // marginRight: 5,
            // color: Consts.SECONDARY_COLOR,
            padding: 50,
            paddingTop: 0,
            textAlign: "left",
          }}
        >
          <div style={{ backgroundColor: "#fff", marginTop: 20 }}>
            {/* Form container */}
            <div>
              <h4 style={{ fontWeight: "bold" }}>ຂໍ້ມູນທົ່ວໄປ</h4>
              <p>
                ຊື່*:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.firstname}
                </span>
              </p>
              <p>
                ນາມສະກຸນ*:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.lastname}
                </span>
              </p>
              <p>
                ເພດ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {convertGender(dataFromStored?.gender)}
                </span>
              </p>
              <p>
                ວັນ ເດືອນ ປີ ເກີດ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.dateOfBirth}
                </span>
              </p>
              <p>
                ສັນຊາດ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.nationality}
                </span>
              </p>
              <p>
                ຊົນເຜົ່າ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.tribeName}
                </span>
              </p>
              <p>
                ສາສະໜາ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.religionName}
                </span>
              </p>
              <p>
                ເບີໂທ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.phone}
                </span>
              </p>
              <p>
                email:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.email}
                </span>
              </p>
              <p>
                ອື່ນໆ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.emailOther}
                </span>
              </p>
              <p>
                ຄະນະ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.facultyName}
                </span>
              </p>
              <p>
                ສັງກັດຢູ່ພາກ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.departmentName}
                </span>
              </p>
              <p>
                ສິດການນໍາໃຊ້ລະບົບ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {convertRole(dataFromStored?.role)}
                </span>
              </p>
              <p>
                ກຸ່ມຜູ້ໃຊ້ລະບົບ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.userGroupName}
                </span>
              </p>
              <p>
                ເລກບັນຊີຜູ້ໃຊ້ :
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.userId}
                </span>
              </p>
              <p>
                ລະຫັດຜ່ານ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.password}
                </span>
              </p>
              <h4 style={{ fontWeight: "bold" }}>ຂໍ້ມູນການສຶກສາ</h4>
              <p>
                ເລືອກປະເພດຕາມຊັ້ນ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.level}
                </span>
              </p>
              <p>
                ສະຖານະ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.positionStatus}
                </span>
              </p>
              <p>
                ປະເພດພະນັກງານ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.staffType}
                </span>
              </p>
              <p>
                ສຶກສາສາມັນ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.secondaryYear}
                </span>
              </p>
              <p>
                ວຸດທິການສຶກສາ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.educationDegree}
                </span>
              </p>
              <p>
                ສາຂາວິຊາຈົບ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.graduatedMajor}
                </span>
              </p>
              <p>
                ວັນທິີເດືອນປີ ຍົກລະດັບການສຶກສາ:
                <span
                  style={{
                    color: Consts.PRIMARY_COLOR,
                    marginLeft: 10,
                    marginLeft: 10,
                  }}
                >
                  {dataFromStored?.startDateEnchance}
                </span>
              </p>
              <p>
                ວັນທີເດືອນປີ ສຳເລັດການສຶກສາ:
                <span
                  style={{
                    color: Consts.PRIMARY_COLOR,
                    marginLeft: 10,
                    marginLeft: 10,
                  }}
                >
                  {dataFromStored?.endDateEnchance}
                </span>
              </p>
              <p>
                ສັງກັດລັດ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.dateStartWork}
                </span>
              </p>
              <p>
                ຈົບຈາກປະເທດ:
                <span
                  style={{
                    color: Consts.PRIMARY_COLOR,
                    marginLeft: 10,
                    marginLeft: 10,
                  }}
                >
                  {dataFromStored?.graudatedCountry}
                </span>
              </p>
              <p>
                ຮຽນຕໍ່ລະດັບ ປ... ສາຂາວິຊາ:
                <span
                  style={{
                    color: Consts.PRIMARY_COLOR,
                    marginLeft: 10,
                    marginLeft: 10,
                  }}
                >
                  {dataFromStored?.enchanceDegree}
                </span>
              </p>
              <p>
                ທິດສະດີການເມືອງ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.civilLecture}
                </span>
              </p>
              <p>
                ວັນເດືອນປີ ຕິດຕາມຄອບຄົວ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.dateFamilyFollow}
                </span>
              </p>
              <p>
                ວັນເດືອນປີກັບມາຈາກການຕິດຕາມຄ/ຄ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.dateFamilyFollowBack}
                </span>
              </p>
              <p>
                ປະເທດ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.followCountry}
                </span>
              </p>
              <p>
                ຕໍາແໜ່ງວິຊາການ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.degree}
                </span>
              </p>
              <p>
                ວັນເດືອນປີແຕ່ງຕັ້ງວິຊາການ:
                <span
                  style={{
                    color: Consts.PRIMARY_COLOR,
                    marginLeft: 10,
                    marginLeft: 10,
                  }}
                >
                  {dataFromStored?.positionAdministerNameDate}
                </span>
              </p>
              <p>
                ຕຳແໜ່ງບໍລິຫານ:
                <span
                  style={{
                    color: Consts.PRIMARY_COLOR,
                    marginLeft: 10,
                    marginLeft: 10,
                  }}
                >
                  {dataFromStored?.positionAdminister}
                </span>
              </p>
              <p>
                ຊື່ ຕຳແໜ່ງບໍລິຫານ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.positionAdministerName}
                </span>
              </p>
              <p>
                ວັນເດືອນປີແຕ່ງຕັ້ງຕໍາແໜ່ງບໍລິຫານ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.positionAdministerNameDate}
                </span>
              </p>
              <p>
                ພາກສ່ວນຫ້ອງການສຳນັການ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.positionOffice}
                </span>
              </p>
              <p>
                ໝາຍເຫດ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.positionRemark}
                </span>
              </p>
              <h4 style={{ fontWeight: "bold" }}>ຂໍ້ມູນອົງການຈັດຕັ້ງມະຫາຊົນ</h4>
              <div >
                <label>
                  <input type="checkbox" checked={!!dataFromStored?.womenAssociation} readOnly className="mr-2" />
                  ເປັນສະຫະພັນແມ່ຍິງ
                </label>
                <br />
                <label>
                  <input type="checkbox" checked={!!dataFromStored?.partyAssociation} readOnly className="mr-2" />
                  ເປັນສະມາຊິກພັກ
                </label>
                <br />
                <label>
                  <input type="checkbox" checked={!!dataFromStored?.commiteeAssociation} readOnly className="mr-2" />
                  ເປັນກຳມະບານ
                </label>
                <br />
                <label>
                  <input type="checkbox" checked={!!dataFromStored?.youthAssociation} readOnly className="mr-2" />
                  ເປັນຊາວໜຸ່ມ
                </label>
              </div>
              <h4 style={{ fontWeight: "bold" }}>ສະຖານທີ່ເກີດ</h4>
              <p>
                ແຂວງເກີດ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.provinceBirth}
                </span>
              </p>
              <p>
                ເມືອງເກີດ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.districtBirth}
                </span>
              </p>
              <p>
                ບ້ານເກີດ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.villageBirth}
                </span>
              </p>
              <p>
                ແຂວງປັດຈຸບັນ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.provinceNow}
                </span>
              </p>
              <p>
                ເມືອງປັດຈຸບັນ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.districtNow}
                </span>
              </p>
              <p>
                ບ້ານປັດຈຸບັນ:
                <span style={{ color: Consts.PRIMARY_COLOR, marginLeft: 10 }}>
                  {dataFromStored?.villageNow}
                </span>
              </p>
            </div>
          </div>

          <div style={{ height: 20 }} />
          <div className="row">
            <div style={{ padding: 15 }} className="col">
              <Button
                fullWidth
                onClick={_handleShowAddConfirmModalClose}
                variant="contained"
                sx={{
                  marginRight: "30px",
                  backgroundColor: "darkgray",
                  "&:hover": {
                    backgroundColor: "gray",
                  },
                }}
              >
                ຍົກເລີກ
              </Button>
            </div>
            <div style={{ padding: 15 }} className="col">
              <Button
                fullWidth
                variant="contained"
                onClick={() => _confirmTeacherAdd()}
              >
                ຕົກລົງ
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TeacherAddConfirm;

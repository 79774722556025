import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Alert,
  IconButton,
} from "@mui/material";
import Refresh from "@mui/icons-material/Refresh";
import TCourseCard from "./TCourse";
import { fetchDelete, fetchGet, fetchPost } from "../../common/fetchAPI";
import Loading from "../../common/Loading";
import { useSelector } from "react-redux";
import AlertMessage from "../../common/AlertMessage";
import { Tabs, Tab, Box } from "@mui/material";
import InstructorProfileDetail from "./communities/insctructionDetail";
import TeacherFeed from "./poosts";
import { useParams } from "react-router-dom";
import { hasPermission } from "../../helpers/CheckPermission";

const ELearningPage = () => {
  const { id } = useParams();

  const [courses, setCourses] = useState([]);
  const [teachedCourses, setTeachedCourses] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [newCourse, setNewCourse] = useState({
    title: "",
    description: "",
    courseName: "",
    teacher: "",
  });

  const [deleteStatus, setDeleteStateus] = useState({
    open: false,
    message: "",
    type: "",
  });

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (deleteStatus.open) {
      setTimeout(() => {
        setDeleteStateus({ ...deleteStatus, open: false });
      }, 3000);
    }
  }, [deleteStatus]);

  const accessmentRedux = useSelector((state) => state.accessment.value);
  const [errors, setErrors] = useState({});
  const user = localStorage.getItem("@userKey");

  const fetchTeachedCourse = async () => {
    try {
      const userObject = JSON.parse(user);
      // const response = await fetchGet(
      //   `teacher/course?teacherId=${
      //     userObject?.data?.id
      //   }&assessmentYear=${"2024 - 2025"}&year=ທັງຫມົດ`
      // );

      const response = await fetchGet(
        `teacher/course?teacherId=${id}&assessmentYear=${"2024 - 2025"}&year=ທັງຫມົດ`
      );

      setTeachedCourses(response || []);
      setError(null);
    } catch (err) {
      setError("ລະບົບມີບັນຫາໃນການສະເເດງບົດສອນ");
    }
  };

  useEffect(() => {
    if (open) {
      const userObject = JSON.parse(user);
      setNewCourse((prevCourse) => ({
        ...prevCourse,
        ["teacher"]: userObject?.data?.id,
      }));
      fetchTeachedCourse();
    }
  }, [open]);

  const fetchCourses = async () => {
    setLoading(true);
    const userObject = JSON.parse(user);
    try {
      const response = await fetchGet(`tcourse/all/${userObject?.data?.id}`);
      // const response = await fetchGet(`tcourse/all/${id}`);

      if (response.length === 0) {
        setError("ຍັງບໍ່ມິບົດສອນ.");
      } else {
        setCourses(response);
        setError(null);
      }
    } catch (err) {
      setError("ລະບົບມີບັນຫາໃນການສະເເດງບົດສອນ");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const handleRefresh = () => {
    fetchCourses();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCourse((prevCourse) => ({
      ...prevCourse,
      [name]: value,
    }));
  };

  const addTCourse = async () => {
    try {
      handleClose();
      setLoading(true);
      await fetchPost("/tcourse", newCourse);
      setLoading(false);
    } catch (err) {
      setError("ລະບົບມີບັນຫາ");
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const validationErrors = {};
    if (!newCourse.title) {
      validationErrors.title = "ກະລຸນາໃສ່ຂໍ້ມຸນ";
    }
    if (!newCourse.course) {
      validationErrors.course = "ກະລຸນາໃສ່ຂໍ້ມຸນ";
    }
    if (!newCourse.teacher) {
      validationErrors.teacher = "ກະລຸນາໃສ່ຂໍ້ມຸນ";
    }
    if (Object.keys(validationErrors).length === 0) {
      await addTCourse();
      await fetchCourses();
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div className="bg-white m-0">
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            position: "relative",
            display: "inline-block",
            width: "100%", // Full width
          }}
        >
          <Tabs
            value={value}
            onChange={handleTabChange}
            centered
            sx={{
              width: "100%",
            }}
          >
            <Tab
              sx={{
                fontSize: "18px",
                minWidth: "150px",
                maxWidth: "150px",
              }}
              label="ບົດສອນ"
            />
            <Tab
              sx={{
                fontSize: "18px",
                minWidth: "150px",
                maxWidth: "150px",
              }}
              label="ໂປໄຟລ໌"
            />
          </Tabs>

          {/* Bottom-only shadow */}
          <Box
            sx={{
              content: '""',
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              height: "4px", // Adjust the thickness as needed
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Bottom shadow effect
            }}
          />
        </Box>

        {value === 0 && (
          <Box
            sx={{
              minHeight: "100vh",
              // py: 4,
              backgroundColor: "#f8f9fa",
              px: { xs: 1, sm: 1 },
            }}
          >
            <AlertMessage
              open={deleteStatus.open}
              message={deleteStatus.message}
              type={deleteStatus.type}
              handleClose={() =>
                setDeleteStateus({ ...deleteStatus, open: false })
              }
            />
            {hasPermission('E_LEARNING', 'create') &&
              <Box
                sx={{
                  pl: 2,
                  pt: 2,
                  backgroundColor: "#f8f9fa",
                  pb: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap", // Ensure elements wrap on smaller screens
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickOpen}
                  sx={{ mt: { xs: 2, sm: 0 }, borderRadius: 3 }}
                >
                  ເພີ່ມບົດສອນໃໝ່
                </Button>
              </Box>
            }
            {loading && <Loading />}
            {error && (
              <Alert
                severity="error"
                action={
                  <IconButton color="inherit" onClick={handleRefresh}>
                    <Refresh />
                  </IconButton>
                }
              >
                {error}
              </Alert>
            )}

            <Box
              sx={{
                backgroundColor: "#f8f9fa",
                minHeight: "90vh",
              }}
            >
              <Grid container spacing={2}>
                {courses.length === 0 && !loading && !error && (
                  <Typography
                    variant="h6"
                    sx={{ mt: 4, textAlign: "center", width: "100%" }}
                  >
                    ບໍ່ມີບົດສອນ
                  </Typography>
                )}
                {courses.map((course, index) => (
                  <Grid
                    spacing={1}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={index}
                  >
                    {" "}
                    {/* Responsive Grid */}
                    <TCourseCard
                      onDelete={async (id) => {
                        const response = await fetchDelete("/tcourse/" + id);
                        if (response) {
                          setDeleteStateus({
                            open: true,
                            message: "ລົບສຳເລັດ",
                            type: "success",
                          });
                          fetchCourses();
                        } else {
                          setDeleteStateus({
                            open: true,
                            message: "ເກີດບັນຫາໃນການລົບວິຊາ",
                            type: "error",
                          });
                        }
                      }}
                      id={course.course?._id}
                      students={course.students}
                      title={course.course?.title}
                      description={course?.course?.description}
                      courseName={course?.course?.name}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
              <DialogTitle>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#1976d2" }}
                >
                  ເພີ່ມບົດສອນໃໝ່
                </Typography>
              </DialogTitle>
              <DialogContent dividers>
                <DialogContentText sx={{ mb: 2, color: "text.secondary" }}>
                  ກະລຸນາໃສ່ລາຍລະອຽດສຳລັບບົດສອນໃຫມ່.
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  name="title"
                  label="ຫົວຂໍ້"
                  fullWidth
                  variant="outlined"
                  value={newCourse.title}
                  onChange={handleChange}
                  error={!!errors.title}
                  helperText={errors.title}
                  sx={{ mb: 2 }}
                />
                <TextField
                  margin="dense"
                  name="description"
                  label="ລາຍລະອຽດ"
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                  value={newCourse.description}
                  onChange={handleChange}
                  sx={{ mb: 2 }}
                />
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="course-select-label">ເລືອກວິຊາສອນ</InputLabel>
                  <Select
                    labelId="course-select-label"
                    value={newCourse.course}
                    label="ເລືອກວິຊາສອນ"
                    name="course"
                    onChange={handleChange}
                    variant="outlined"
                  >
                    {teachedCourses.map((course) => (
                      <MenuItem key={course._id} value={course._id}>
                        {course?.course?.subject?.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions sx={{ p: 2 }}>
                <Button
                  sx={{ borderRadius: 3 }}
                  onClick={handleClose}
                  variant="outlined"
                  color="secondary"
                >
                  ຍົກເລີກ
                </Button>
                <Button
                  sx={{ borderRadius: 3 }}
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                >
                  ເພີ່ມ
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}
        {value === 1 && <InstructorProfileDetail />}
      </Box>
    </div>
  );
};

export default ELearningPage;

import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetStatistichData } from "../Api";
import Loading from "../../../common/Loading";
import * as XLSX from "xlsx";
import { hasPermission } from "../../../helpers/CheckPermission";

const TeacherByTeachLevel = ({ faculty, department }) => {
  const [data, setData] = useState(null);

  const cellStyle = {
    width: "400px",
    height: "40px",
    textAlign: "center",
  };
  const getData = async () => {
    setData();
    try {
      const result = await fetStatistichData(
        `degreeteacher?faculty=${faculty}&department=${department}`
      );
      setData(result);
    } catch (error) { }
  };
  const generateMerges = () => {
    return [
      { s: { r: 0, c: 0 }, e: { r: 3, c: 0 } },
      { s: { r: 0, c: 1 }, e: { r: 3, c: 1 } },
      { s: { r: 0, c: 2 }, e: { r: 0, c: 5 } },
      { s: { r: 0, c: 6 }, e: { r: 0, c: 9 } },
      { s: { r: 0, c: 10 }, e: { r: 0, c: 13 } },
      // merge second rows
      { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
      { s: { r: 1, c: 4 }, e: { r: 1, c: 5 } },
      { s: { r: 1, c: 6 }, e: { r: 1, c: 7 } },
      { s: { r: 1, c: 8 }, e: { r: 1, c: 9 } },
      { s: { r: 1, c: 10 }, e: { r: 1, c: 11 } },
      { s: { r: 1, c: 12 }, e: { r: 1, c: 13 } },
      // merge third rows
      { s: { r: 2, c: 2 }, e: { r: 2, c: 3 } },
      { s: { r: 2, c: 4 }, e: { r: 2, c: 5 } },
      { s: { r: 2, c: 6 }, e: { r: 2, c: 7 } },
      { s: { r: 2, c: 8 }, e: { r: 2, c: 9 } },
      { s: { r: 2, c: 10 }, e: { r: 2, c: 11 } },
      { s: { r: 2, c: 12 }, e: { r: 2, c: 13 } },
    ];
  };

  const staffTypes = Array.from({ length: 4 }, (_, i) => (i + 1).toString());

  const formatDataForExcel = () => {
    const headers = [
      [
        "ລຳດັບ",
        "ລະດັບຊັ້ນວິຊາສະເພາະ",
        "ຈຳນວນຄູສອນ",
        "ຈຳນວນຄູສອນ",
        "ຈຳນວນຄູສອນ",
        "ຈຳນວນຄູສອນ",
        "ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານທີ່ໄດ້ສອນ",
        "ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານທີ່ໄດ້ສອນ",
        "ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານທີ່ໄດ້ສອນ",
        "ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານທີ່ໄດ້ສອນ",
        "ລວມຄູສອນ ແລະ ພະນັກງານບໍລິຫານທີ່ໄດ້ສອນທັງໝົດ",
        "ລວມຄູສອນ ແລະ ພະນັກງານບໍລິຫານທີ່ໄດ້ສອນທັງໝົດ",
        "ລວມຄູສອນ ແລະ ພະນັກງານບໍລິຫານທີ່ໄດ້ສອນທັງໝົດ",
        "ລວມຄູສອນ ແລະ ພະນັກງານບໍລິຫານທີ່ໄດ້ສອນທັງໝົດ",
      ],
      [
        "ລຳດັບ",
        "ລະດັບຊັ້ນວິຊາສະເພາະ",
        "1",
        "1",
        "2",
        "2",
        "3",
        "3",
        "4",
        "4",
        "1+3",
        "1+3",
        "2+4",
        "2+4",
      ],
      [
        "ຄູສອນປະຈຳ",
        "ຄູສອນປະຈຳ",
        "ຄູສອນປະຈຳ",
        "ຄູສອນປະຈຳ",
        "ຄູຮັບເຊີນ",
        "ຄູຮັບເຊີນ",
        "ພ/ງ ປະຈຳການ",
        "ພ/ງ ປະຈຳການ",
        "ພ/ງ ຮັບເຊີນ",
        "ພ/ງ ຮັບເຊີນ",
        "ພ/ງ ປະຈຳການ",
        "ພ/ງ ປະຈຳການ",
        "ພ/ງ ຮັບເຊີນ",
        "ພ/ງ ຮັບເຊີນ",
      ],
      [
        "ລວມ",
        "ຍິງ",
        "ລວມ",
        "ຍິງ",
        "ລວມ",
        "ຍິງ",
        "ລວມ",
        "ຍິງ",
        "ລວມ",
        "ຍິງ",
        "ລວມ",
        "ຍິງ",
        "ລວມ",
        "ຍິງ",
      ],
    ];

    let rows = [];
    let index = 1; // Define index outside of the map function

    degreeLevelList.map((degree) => {
      Object.entries(data.degree).map(([key, value]) => {
        if (key === degree) {
          rows.push([
            index,
            degree,
            ...staffTypes.flatMap((type) => [
              value[type].total ?? 0,
              value[type].female ?? 0,
            ]),
            (value["1"].total ?? 0) + (value["3"].total ?? 0),
            (value["1"].female ?? 0) + (value["3"].female ?? 0),
            (value["2"].total ?? 0) + (value["4"].total ?? 0),
            (value["2"].female ?? 0) + (value["4"].female ?? 0),
          ]);
          index++;
        }
      });
    });

    return [...headers, ...rows];
  };

  // export to excel
  const onExport = () => {
    const ws = XLSX.utils.aoa_to_sheet(formatDataForExcel());
    const merges = generateMerges();
    ws["!merges"] = merges;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "TeacherList");
    XLSX.writeFile(wb, "TeacherList.xlsx");
  };

  const degreeLevelList = [
    "ປະລິນຍາເອກ",
    "ປະລິນຍາໂທ",
    "ເໜືອປະລິນຍາຕີ",
    "ປະລິນຍາຕີ",
    "ອະນຸປະລິນຍາ (ຊັ້ນສູງ)",
    "ຊັ້ນກາງ",
    "ຊັ້ນຕົ້ນ",
  ];

  useEffect(() => {
    getData();
  }, [faculty, department]);

  let queue = 0;

  return (
    <div>
      <Grid container display={"flex"} justifyContent={"space-between"}>
        <Grid item lg={10}>
          <h5>ຈຳນວນຄູສອນ ແລະ ພະນັກງານບໍລິຫານທີ່ໄດ້ສອນຕາມລະບົບຊັ້ນສອນ</h5>
        </Grid>
        <Grid item lg={1}>
          {hasPermission('STATISTIC', 'export') &&
            <Button
              variant="contained"
              color="success"
              startIcon={<i className="fa fa-file-excel"></i>}
              sx={{ textTransform: "none" }}
              onClick={onExport}
            >
              Export
            </Button>
          }
        </Grid>
        <div
          style={{
            maxHeight: "80vh",
            overflowY: "auto",
            paddingBottom: "100px",
            marginTop: "20px",
          }}
        >
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
            }}
          >
            <thead
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "#fff",
                zIndex: 1,
              }}
            >
              <tr>
                <th
                  rowSpan={4}
                  style={{
                    width: "100px",
                  }}
                >
                  ລຳດັບ
                </th>
                <th
                  rowSpan={4}
                  style={{
                    width: "400px",
                  }}
                >
                  ລະດັບຊັ້ນວິຊາສະເພາະ
                </th>
                <th colSpan={4} style={cellStyle}>
                  ຈຳນວນຄູສອນ
                </th>
                <th colSpan={4} style={cellStyle}>
                  ຜູ້ບໍລິຫານ ແລະ ພະນັກງານບໍລິຫານທີ່ໄດ້ສອນ
                </th>
                <th colSpan={4} style={cellStyle}>
                  ລວມຄູສອນ ແລະ ພະນັກງານບໍລິຫານທີ່ໄດ້ສອນທັງໝົດ
                </th>
              </tr>
              <tr>
                <th colSpan={2} style={cellStyle}>
                  1
                </th>
                <th colSpan={2} style={cellStyle}>
                  2
                </th>
                <th colSpan={2} style={cellStyle}>
                  3
                </th>
                <th colSpan={2} style={cellStyle}>
                  4
                </th>
                <th colSpan={2} style={cellStyle}>
                  1 + 3
                </th>
                <th colSpan={2} style={cellStyle}>
                  2 + 4
                </th>
              </tr>
              <tr>
                <th colSpan={2} style={cellStyle}>
                  ຄູສອນປະຈຳ
                </th>
                <th colSpan={2} style={cellStyle}>
                  ຄູຮັບເຊີນ
                </th>
                <th colSpan={2} style={cellStyle}>
                  ພ/ງ ປະຈຳການ
                </th>
                <th colSpan={2} style={cellStyle}>
                  ພ/ງ ຮັບເຊີນ
                </th>
                <th colSpan={2} style={cellStyle}>
                  ພ/ງ ປະຈຳການ
                </th>
                <th colSpan={2} style={cellStyle}>
                  ພ/ງ ຮັບເຊີນ
                </th>
              </tr>
              <tr>
                <th colSpan={1} style={cellStyle}>
                  ລວມ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ຍິງ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ລວມ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ຍິງ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ລວມ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ຍິງ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ລວມ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ຍິງ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ລວມ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ຍິງ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ລວມ
                </th>
                <th colSpan={1} style={cellStyle}>
                  ຍິງ
                </th>
              </tr>
            </thead>
            {data ? (
              <tbody>
                {degreeLevelList.map((degree) => {
                  return Object.entries(data.degree).map(
                    ([key, value], index) => {
                      if (key == degree) {
                        queue++;
                        return (
                          <tr>
                            <td style={cellStyle}>{queue}</td>
                            <td>{degree}</td>
                            {staffTypes.map((type) => (
                              <React.Fragment key={type}>
                                <td style={cellStyle}>
                                  {value[type]?.total || 0}
                                </td>
                                <td style={cellStyle}>
                                  {value[type]?.female || 0}
                                </td>
                              </React.Fragment>
                            ))}
                            <td style={cellStyle}>
                              {value["1"].total + value["3"].total}
                            </td>
                            <td style={cellStyle}>
                              {value["1"].female + value["3"].female}
                            </td>
                            <td style={cellStyle}>
                              {value["2"].total + value["4"].total}
                            </td>
                            <td style={cellStyle}>
                              {value["2"].female + value["4"].female}
                            </td>
                          </tr>
                        );
                      } else return <tr></tr>;
                    }
                  );
                })}
              </tbody>
            ) : (
              <div className="bg-white">
                <Loading />
              </div>
            )}
          </table>
        </div>
      </Grid>
    </div>
  );
};

export default TeacherByTeachLevel;

import React from "react";
import { Modal, Button, Typography, Box, TextField } from "@mui/material";
import { Formik } from "formik";
import { USER_KEY } from "../../../consts";
import { fetchPost, fetchPut } from "../../../common/fetchAPI";
import { toast } from "react-toastify";

const modalStyle = (theme) => ({
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)", // Softer shadow
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius, // More rounded corners
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
});

const buttonContainerStyle = {
    marginTop: 3,
    display: "flex",
    justifyContent: "center",
    gap: 2,
};

const AddModal = ({ open, editData, setEditData, onCancel, fetchPlaceStudy }) => {
    const user = localStorage.getItem(USER_KEY);
    const userObject = JSON.parse(user);
    const facId = userObject?.data?.faculty?.id;

    return (
        <Box>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    title: editData?.title ?? "",
                    amount: editData?.amount ?? 0,
                    url: editData?.url ?? "",
                }}
                validate={(values) => {
                    const errors = {};
                    if (!values.title) {
                        errors.title = "Required";
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    let body = {
                        title: values.title,
                        amount: values.amount,
                        url: values.url,
                        faculty: facId
                    };

                    let response;

                    if (editData?._id) {
                        response = await fetchPut("/placestudy/" + editData?._id, body);
                    } else {
                        response = await fetchPost("/placestudy", body);
                    }
                    if (response) {
                        if (editData?._id) {
                            toast.success("ແກ້ໄຂສະຖານທີ່ຮຽນສຳເລັດ", {
                                autoClose: 3000, // Customize hide time to 5 seconds (5000ms)
                            });
                        } else {
                            toast.success("ເພິ່ມສະຖານທີ່ຮຽນສຳເລັດ", {
                                autoClose: 3000, // Customize hide time to 5 seconds (5000ms)
                            });
                        }
                        resetForm();
                        onCancel();
                        setEditData();
                        fetchPlaceStudy()
                    } else {
                        if (editData?._id) {
                            toast.error("ລະບົບມີບັນຫາ ແກ້ໄຂສະຖານທີ່ຮຽນບໍ່ສຳເລັດ", {
                                autoClose: 3000, // Customize hide time to 5 seconds (5000ms)
                            });
                        } else {
                            toast.error("ລະບົບມີບັນຫາ ເພີ່ມສະຖານທີ່ຮຽນບໍ່ສຳເລັດ", {
                                autoClose: 3000, // Customize hide time to 5 seconds (5000ms)
                            });
                        }
                    }
                    setSubmitting(false);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <Modal
                        open={open}
                        onClose={onCancel}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                    >
                        <Box sx={(theme) => modalStyle(theme)}>
                            <Typography
                                variant="h6"
                                id="modal-title"
                                gutterBottom
                                sx={{ fontWeight: 600 }} // Make title bold
                            >
                                {editData?._id ? "ແກ້ໄຂສະຖານທີ່ຮຽນ" : "ເພີ່ມສະຖານທີ່ຮຽນ"}
                            </Typography>
                            <Box display={"flex"} flexDirection={"column"} gap={2}>
                                <TextField
                                    fullWidth
                                    label="ຊື່ສະຖານທີ່ຮຽນ"
                                    type="text"
                                    name="title"
                                    onChange={handleChange}
                                    value={values.title}
                                    error={!!errors.title}
                                    helperText={errors.title}
                                    variant="outlined"
                                />
                                <TextField
                                    fullWidth
                                    label="ຈຳນວນນັກສຶກສາທີ່ຮອງຮັບ"
                                    type="number"
                                    name="amount"
                                    onChange={handleChange}
                                    value={values.amount}
                                    // error={!!errors.name}
                                    // helperText={errors.name}
                                    variant="outlined"
                                />
                                <TextField
                                    fullWidth
                                    label="ລີ້ງແຜ່ນທີ່ url"
                                    type="text"
                                    name="url"
                                    onChange={handleChange}
                                    value={values.url}
                                    // error={!!errors.url}
                                    // helperText={errors.url}
                                    variant="outlined"
                                />
                            </Box>
                            <Box sx={buttonContainerStyle}>
                                <Button
                                    sx={{
                                        px: 4,
                                        py: 1.5,
                                        borderRadius: 4,
                                        borderColor: (theme) => theme.palette.primary.main, // Modern outline
                                        color: (theme) => theme.palette.primary.main,
                                    }}
                                    onClick={handleSubmit}
                                    variant="outlined"
                                    disabled={isSubmitting}
                                >
                                    ບັນທຶກ
                                </Button>
                                <Button
                                    sx={{
                                        px: 4,
                                        py: 1.5,
                                        borderRadius: 4, // Rounded button
                                        boxShadow: "0px 4px 12px rgba(255, 0, 0, 0.2)", // Soft shadow for action button
                                    }}
                                    onClick={onCancel}
                                    variant="contained"
                                    color="error"
                                >
                                    ຍົກເລີກ
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                )
                }
            </Formik>
        </Box>

    );
};

export default AddModal;
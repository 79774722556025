import React, { useState, useEffect } from "react"
import { Breadcrumb } from "react-bootstrap";
import useReactRouter from "use-react-router"
import Nav from "react-bootstrap/Nav"
import { GRADE_UPGRADE_LIST, UPGRADE_HISTORY } from "../../../consts/router";
import { Box, Tab, Tabs } from "@mui/material";
import RegisterPrice from "./RegisterPrice";
import EntrancePrice from "./EntrancePrice";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}{" "}
      {/* Adjusted padding to 0 */}
    </div>
  );
};

export default function Price() {
  const { history, match } = useReactRouter()
  const url = match.url
  const defaultURL = url.split('/')[1]
  const [titleItem, setTitleItem] = useState("ລາຍການແກ້ເກຣດ")
  useEffect(() => {
    if (defaultURL === "grades-list/limit/20/skip/1") {
      setTitleItem("ຄະແນນທັງໝົດ");
    } else {
      setTitleItem("ລາຍການແກ້ເກຣດ")
    }
  }, [defaultURL])

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push("/setting-list")}>
          ຕັ້ງຄ່າ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          ຈັດການການຈ່າຍເງິນ
        </Breadcrumb.Item>
      </Breadcrumb>
      <Tabs
        value={value}
        onChange={handleChange}
        orientation="horizontal"
        sx={{ marginLeft: 1 }}
      >
        <Tab
          label="ຈັດການຄ່າລົງທະບຽນ"
          id="tab-0"
          sx={{
            backgroundColor: value === 0 ? "white" : "inherit",
            borderRadius: "5px 5px 0px 0px",
          }}
        />
        <Tab
          label="ຈັດການຄ່າລົງທະບຽນຂອງນັກສຶກສາ Entrance"
          id="tab-1"
          sx={{
            backgroundColor: value === 1 ? "white" : "inherit",
            borderRadius: "5px 5px 0px 0px",
          }}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <RegisterPrice />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EntrancePrice />
      </TabPanel>
    </div>
  );
}
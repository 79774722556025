import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Chip,
} from "@mui/material";
import { fetchGet } from "../../common/fetchAPI";

const StudentScoreDetail = ({ studentId }) => {
  const [scores, setScores] = useState(null);
  const [gradeTypes, setGradeTypes] = useState([]);

  useEffect(() => {
    fetchScore();
  }, []);

  const fetchScore = async () => {
    try {
      const response = await fetchGet(`score/studentid/${studentId}`);
      if (Array.isArray(response)) {
        setScores(groupByYearAndSemester(response));

        // Extract all unique grade types dynamically
        const uniqueGradeTypes = new Set();
        response.forEach((score) => {
          score.gradeType.forEach((grade) => {
            uniqueGradeTypes.add(grade.name);
          });
        });
        setGradeTypes([...uniqueGradeTypes]);
      } else {
        setScores([]);
        setGradeTypes([]);
      }
    } catch (error) {
      console.error("Error fetching scores:", error);
      setScores([]);
      setGradeTypes([]);
    }
  };

  return (
    <Box sx={{ width: "100%", mx: "auto", mt: 4, px: 5 }}>
      <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
        ຄະແນນນັກສຶກສາ
      </Typography>

      {scores === null ? (
        <Typography align="center">ກຳລັງໂຫລດ...</Typography>
      ) : scores.length === 0 ? (
        <Typography align="center" sx={{ color: "gray", fontStyle: "italic" }}>
          ບໍ່ມິຂໍ້ມູນ
        </Typography>
      ) : (
        scores.map(({ year, semesters }) => (
          <Card key={year} sx={cardStyle}>
            <CardContent>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
                ປີຮຽນ {year}
              </Typography>

              {semesters.map(({ semester, scores }) => (
                <Box key={semester} sx={{ mb: 3 }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{ mb: 1 }}
                  >
                    ພາກຮຽນ {semester}
                  </Typography>

                  <TableContainer>
                    <Box sx={{ overflowX: "auto" }}>
                      <Table>
                        <TableHead>
                          <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                            <TableCell
                              align="center"
                              sx={{ fontWeight: "bold" }}
                            >
                              ວິຊາ
                            </TableCell>

                            {/* Render all dynamic grade types as table headers */}
                            {gradeTypes.map((type) => (
                              <TableCell
                                key={type}
                                align="center"
                                sx={{ fontWeight: "bold" }}
                              >
                                {type}
                              </TableCell>
                            ))}

                            <TableCell
                              align="center"
                              sx={{ fontWeight: "bold" }}
                            >
                              ລວມ
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontWeight: "bold" }}
                            >
                              ເກຣດ
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontWeight: "bold" }}
                            >
                              ສະຖານະຄະເເນນ
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontWeight: "bold" }}
                            >
                              ອາຈານສອນ
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {scores.map((score) => (
                            <TableRow key={score._id}>
                              <TableCell align="center">
                                {score.course?.course?.subject?.title || "N/A"}
                              </TableCell>

                              {/* Render each grade type dynamically */}
                              {gradeTypes.map((type) => {
                                const grade = score.gradeType.find(
                                  (g) => g.name === type
                                );
                                return (
                                  <TableCell key={type} align="center">
                                    {grade?.score || "-"}
                                  </TableCell>
                                );
                              })}

                              <TableCell align="center">
                                {score.totalScore ?? "-"}
                              </TableCell>
                              <TableCell align="center">
                                <Chip
                                  label={score.grade || "N/A"}
                                  color={getGradeColor(score.grade)}
                                  sx={{ fontWeight: "bold" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Chip
                                  label={getGradeStatus(
                                    score.sendingGradeStatus
                                  )}
                                  color={
                                    score.sendingGradeStatus ===
                                      "FACULTY_SUBMITED" ||
                                    score.sendingGradeStatus ===
                                      "ADMIN_APPROVED"
                                      ? "success"
                                      : "warning"
                                  }
                                  sx={{ fontWeight: "bold" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                {score.course.teacher?.length > 0 ? (
                                  <>
                                    <Typography variant="body2">
                                      {score.course.teacher[0].firstname}{" "}
                                      {score.course.teacher[0].lastname}
                                    </Typography>
                                  </>
                                ) : (
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    N/A
                                  </Typography>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </TableContainer>
                </Box>
              ))}
            </CardContent>
          </Card>
        ))
      )}
    </Box>
  );
};

/* Function to group scores by Year and then Semester */
const groupByYearAndSemester = (scores) => {
  const grouped = {};

  scores.forEach((score) => {
    const { yearLevel, semester } = score;

    if (!grouped[yearLevel]) {
      grouped[yearLevel] = {};
    }

    if (!grouped[yearLevel][semester]) {
      grouped[yearLevel][semester] = [];
    }

    grouped[yearLevel][semester].push(score);
  });

  return Object.entries(grouped).map(([year, semesters]) => ({
    year,
    semesters: Object.entries(semesters).map(([semester, scores]) => ({
      semester,
      scores,
    })),
  }));
};

/* Function to return grade color */
const getGradeColor = (grade) => {
  switch (grade) {
    case "A":
      return "success";
    case "B":
      return "primary";
    case "C+":
      return "warning";
    case "C":
      return "error";
    case "D":
      return "secondary";
    default:
      return "default";
  }
};

/* Function to return grade status */
const getGradeStatus = (status) => {
  return ["FACULTY_SUBMITED", "ADMIN_APPROVED"].includes(status)
    ? "ຖືກຢືນຢັນແລ້ວ"
    : "ລໍຖ້າການຢືນຢັນ";
};

/* Card Styling */
const cardStyle = {
  p: 3,
  borderRadius: 3,
  boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
  mb: 3,
};

export default StudentScoreDetail;

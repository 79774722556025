import React, { useEffect, useState } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import EntranceStudentFilter from "../../common/EntranceFilter";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from "@mui/material";
import { fetchGet } from "../../common/fetchAPI";
import { useSelector } from "react-redux";
import { convertGenderNam, convertGenderNamEN } from "../../helpers/user";
import GetAppIcon from "@mui/icons-material/GetApp";
import "handsontable/dist/handsontable.full.min.css";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import PaymentDetailsModal from "./PaymentDetailModal";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const onExport = (studentList) => {
  if (!studentList || studentList.length === 0) {
    alert("No student data available to export.");
    return;
  }

  let queue = 0;

  const StudentExport = studentList.map((studentItem) => {
    queue++;
    return {
      ລຳດັບ: queue,
      "ຊື່ ແລະ ນາມສະກຸນ": `${studentItem?.student?.firstNameL || ""} ${
        studentItem?.student?.lastNameL || ""
      }`,
      ເພດ: studentItem?.student?.gender || "N/A",
      "ສາຍຫລັກ (ຄະນະ)": studentItem?.student?.faculty?.name || "N/A",
      "ສາຍຫລັກ (ພາກວິຊາ)": studentItem?.student?.department?.name || "N/A",
      "ສາຍຫລັກ (ສາຂາ)": studentItem?.student?.major?.name || "N/A",
      "ສາຍສຳຮອງ (ຄະນະ)": studentItem?.student?.faculty2?.name || "N/A",
      "ສາຍສຳຮອງ (ພາກວິຊາ)": studentItem?.student?.department2?.name || "N/A",
      "ສາຍສຳຮອງ (ສາຂາ)": studentItem?.student?.major2?.name || "N/A",
      ລະຫັດເຂົ້າຫ້ອງເສັງ: studentItem?.student?.examCode || "N/A",
      ສະຖານທີ່ເສັງ: studentItem?.student?.place?.place || "N/A",
      ຕຶກ: studentItem?.student?.building?.building || "N/A",
      ຫ້ອງເສັງ: studentItem?.student?.room?.name || "N/A",
      ເບີໂທລະສັບ: studentItem?.student?.phoneMobile || "N/A",
      ຫມວດສາຍຮຽນ: studentItem?.student?.group || "N/A",
      ວັນເດືອນປີເກີດ: studentItem?.student?.birthday
        ? new Date(studentItem.student.birthday).toLocaleDateString("la-LA")
        : "N/A",
      ແຂວງປັດຈຸບັນ: studentItem?.student?.address?.province || "N/A",
      ເມືອງປັດຈຸບັນ: studentItem?.student?.address?.district || "N/A",
      ບ້ານປັດຈຸບັນ: studentItem?.student?.address?.village || "N/A",
      ສັນຊາດ: studentItem?.student?.nationality || "N/A",
      ຊົນເຜົ່າ: studentItem?.student?.tribe?.name || "N/A",
      ຊື່ແມ່: studentItem?.student?.motherNameAndSurname || "N/A",
      ຊື່ພໍ່: studentItem?.student?.contactName || "N/A",
      ສະຖານະສຸຂະພາບ:
        studentItem?.student?.healthStatus === "GOOD"
          ? "ແຂງແຮງດີ"
          : "ບໍ່ແຂງແຮງດີ",
      "💰 ຈຳນວນເງິນທີ່ຕ້ອງຈ່າຍ (LAK)": studentItem?.totalAmount || 0,
      "✅ ຈຳນວນເງິນທີ່ຈ່າຍແລ້ວ (LAK)":
        studentItem?.priceList?.reduce((sum, price) => sum + price.amount, 0) ||
        0,
      "⚠️ ຄ້າງຈ່າຍ (LAK)":
        studentItem?.totalAmount -
        (studentItem?.priceList?.reduce(
          (sum, price) => sum + price.amount,
          0
        ) || 0),
    };
  });

  // Create Excel Sheet
  const ws = XLSX.utils.json_to_sheet(StudentExport);

  // Auto-size column width
  const columnWidths = Object.keys(StudentExport[0]).map((key) => ({
    wch: Math.max(15, key.length),
  }));
  ws["!cols"] = columnWidths;

  // Create Workbook
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Student Data");

  // Generate Excel File
  const xlsxBuffer = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array",
    mimeType:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a Blob and Download
  const blob = new Blob([new Uint8Array(xlsxBuffer)], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  saveAs(blob, "studentExport.xlsx");
};

const EntranceStudentPaymentList = ({ status }) => {
  const [filter, setFilter] = useState({});
  const [studentList, setStudentList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const [openPaymentDetail, setOpenPaymentDetail] = useState(false);

  const [selectedStudent, setSelectedStudent] = useState();

  useEffect(() => {
    setFilter((preData) => ({
      ...preData,
      assessmentYear: accessmentRedux,
    }));
  }, [accessmentRedux]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const highlightText = (text, filter) => {
    if (!text || typeof text !== "string") return text; // Ensure text is a string

    if (!filter?.title && !filter?.userKeywords) return text;

    // Combine both filters into a single regex pattern
    const keywords = [filter?.title, filter?.userKeywords]
      .filter(Boolean)
      .join("|");

    if (!keywords) return text;

    const regex = new RegExp(`(${keywords})`, "gi");

    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span
          key={index}
          style={{
            backgroundColor: "#4895ef",
            color: "white",
            fontWeight: "bold",
            padding: "2px 4px",
            borderRadius: "4px",
          }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const fetchData = async () => {
    const queryParams = {
      faculty: filter?.faculty,
      department: filter?.department,
      major: filter?.major,
      status: status,
      keywords: filter?.title,
      assessmentYear: filter?.assessmentYear,
      date: filter?.date,
      status: filter?.status,
      paymentMethod: filter?.paymentMethod,
      startDate: filter?.startDate,
      endDate: filter?.endDate,
      userKeywords: filter?.userKeywords,
    };

    const queryString = new URLSearchParams(queryParams).toString();

    const response = await fetchGet(
      `/entrance/payment?${queryString}&page=${page + 1}&limit=${rowsPerPage}`
    );

    if (response?.success) {
      setCount(response?.pagination?.total);
      // setPage(response?.page);
      setStudentList(response?.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter, rowsPerPage, page, openPaymentDetail]);

  // Handle modal open/close
  const handleOpen = () => setOpenPaymentDetail(true);

  return (
    <Box>
      <EntranceStudentFilter
        isPayment={true}
        status={status}
        onSelect={(data) => {
          setFilter(data);
        }}
      />

      <div
        className="d-flex py-3 bg-white justify-content-end m-0"
        alignItems="flex-end"
      >
        <Typography
          sx={{
            marginRight: "20px",
            paddingTop: "6px",
          }}
        >
          {"ຈຳນວນທັງຫມົດ " + count}
        </Typography>

        <Button
          onClick={() => {
            onExport(studentList);
          }}
          variant="contained"
          color="success"
          startIcon={<GetAppIcon />}
        >
          Export
        </Button>
      </div>

      <Paper elevation={0} className="pb-3 pt-3">
        <TableContainer>
          <Table sx={{ minWidth: 1200 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow className="thead-primary">
                <TableCell className="tablecell-head" width={70}>
                  ລຳດັບ
                </TableCell>
                {status === "PASSED" && (
                  <TableCell className="tablecell-head py-3">
                    ລະຫັດນັກສຶກສາ
                  </TableCell>
                )}
                <TableCell className="tablecell-head py-3" width={200}>
                  ຊື່ ແລະ ນາມສະກຸນ
                </TableCell>
                <TableCell className="tablecell-head" width={200}>
                  ສາຂາຫລັກ
                </TableCell>
                <TableCell className="tablecell-head">
                  ລະຫັດເຂົ້າຫ້ອງເສັງ
                </TableCell>
                <TableCell className="tablecell-head" width={200}>
                  ສະຖານະການຈ່າຍເງິນ
                </TableCell>
                <TableCell className="tablecell-head" width={200}>
                  ປະເພດການຈ່າຍເງິນ
                </TableCell>
                <TableCell className="tablecell-head" width={200}>
                  ຜູ້ດຳເນີນການຈ່າຍ
                </TableCell>
                <TableCell className="tablecell-head" width={200}>
                  ຍົກເລີກໂດຍ
                </TableCell>
                <TableCell className="tablecell-head" width={200}>
                  ວັນເດືອນປີສ້າງ
                </TableCell>
                <TableCell className="tablecell-head">
                  ວັນເດືອນປີແກ້ໄຂ
                </TableCell>
                <TableCell className="tablecell-head">ລາຍລະອຽດ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentList.length > 0 &&
                studentList.map((item, index) => {
                  const hasUnpaid = item?.priceList?.some(
                    (price) => price.status === "PENDING"
                  );

                  console.log("hasUnpaid => ", hasUnpaid);

                  const isPaid = item?.priceList?.every(
                    (price) => price.status === "PAID"
                  );

                  const isCancelled = item?.priceList?.every(
                    (price) => price.status === "CANCELLED"
                  );

                  const paymentMethod = item?.paymentMethod;

                  const createdBy = item?.createdBy
                    ? `${item.createdBy.firstname} ${item.createdBy.lastname}`
                    : "Unknown";

                  const cancelBy = item?.cancelBy
                    ? `${item.cancelBy.firstname} ${item.cancelBy.lastname}`
                    : "N/A";

                  return (
                    <TableRow key={item._id}>
                      <TableCell align="center">
                        {page * rowsPerPage + index + 1}
                      </TableCell>

                      {status === "PASSED" && (
                        <TableCell>{item?.studentNumber}</TableCell>
                      )}

                      <TableCell align="start">
                        {highlightText(
                          !item?.student?.firstNameL ||
                            item?.student?.firstNameL === ""
                            ? convertGenderNamEN(item?.student?.gender) +
                                " " +
                                item?.student?.firstNameE +
                                " " +
                                item?.student?.lastNameE
                            : convertGenderNam(item?.gender) +
                                " " +
                                item?.student?.firstNameL +
                                " " +
                                item?.student?.lastNameL,
                          filter
                        )}
                      </TableCell>

                      <TableCell align="start">
                        {item?.student?.major?.name || ""}
                      </TableCell>

                      <TableCell align="center">
                        {item?.student?.code}
                      </TableCell>

                      {(status === "APPROVED" || status === "PASSED") && (
                        <TableCell align="center">
                          {item?.student?.examCode}
                        </TableCell>
                      )}

                      {/* Payment Status Indicator */}
                      <TableCell align="center">
                        <Typography
                          variant="body2"
                          sx={{
                            color: isCancelled
                              ? "gray"
                              : hasUnpaid
                              ? "red"
                              : "green",
                            fontWeight: "bold",
                          }}
                        >
                          {isCancelled
                            ? "🚫 ຍົກເລີກ"
                            : hasUnpaid
                            ? "❌ ຍັງບໍ່ທັນຊຳລະ"
                            : "✅ ໍ ຊຳລະແລ້ວ"}
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        {item.paymentMethod === "CASH"
                          ? "ຈ່າຍເງີນສົດ"
                          : item.paymentMethod === "BANK_TRANSFER"
                          ? "ຈ່າຍໂອນ"
                          : ""}
                      </TableCell>

                      {/* Paid By Column */}
                      <TableCell align="center">
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          sx={{
                            color: isPaid
                              ? paymentMethod === "CASH"
                                ? "green" // 💚 Cash payments handled by admin
                                : "blue" // 🔵 Paid by student
                              : hasUnpaid
                              ? "red" // 🔴 Unpaid
                              : "orange", // 🟠 Pending
                          }}
                        >
                          {isPaid
                            ? paymentMethod === "CASH"
                              ? createdBy // Show admin who processed payment
                              : "ຈ່າຍໂດຍນັກສຶກສາ" // Paid by student
                            : hasUnpaid
                            ? "❌ ຍັງບໍ່ໄດ້ຈ່າຍ"
                            : "⏳ ລໍຖ້າການຊຳລະ"}
                        </Typography>
                      </TableCell>

                      {/* Cancelled By Column */}
                      <TableCell align="center">
                        <Typography variant="body2" fontWeight="bold">
                          {highlightText(cancelBy, filter)}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {new Date(item?.createdAt).toLocaleDateString("la-LA")}
                      </TableCell>
                      <TableCell align="center">
                        {new Date(item?.updatedAt).toLocaleDateString("la-LA")}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            handleOpen();
                            setSelectedStudent(item);
                          }}
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            <TablePagination
              rowsPerPageOptions={[
                10,
                20,
                50,
                { label: "ທັງຫມົດ", value: count },
              ]}
              colSpan={document.querySelectorAll(".tablecell-head").length} //auto width with cell
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              elevation={0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Table>
        </TableContainer>
      </Paper>

      <PaymentDetailsModal
        open={openPaymentDetail}
        handleClose={() => setOpenPaymentDetail(false)}
        student={selectedStudent}
      />
    </Box>
  );
};

export default EntranceStudentPaymentList;

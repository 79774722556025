import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Stack,
  Checkbox,
  FormControlLabel,
  TextField,
  Box,
  Divider,
} from "@mui/material";
import { Close, Payment, Cancel } from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import Consts, { USER_KEY } from "../../../consts";
import { API_KEY, NEW_API_URL } from "../../../common/contrant";

const ConfirmPaymentDialog = ({
  setOnSuccess,
  setShowConfirm,
  showConfirm,
  paymentID,
  amount,
  totalRemain,
}) => {
  const user = JSON.parse(localStorage.getItem(USER_KEY));
  const token = user?.accessToken;

  const [isPartialPayment, setIsPartialPayment] = useState(false);
  const [currentPaid, setCurrentPaid] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (totalRemain < amount) {
      setIsPartialPayment(true);
      setCurrentPaid("");
    } else {
      setIsPartialPayment(false);
      setCurrentPaid(amount);
    }
  }, [totalRemain, amount]);

  const handlePayment = async (id) => {
    setError("");
    if (
      isPartialPayment &&
      (!currentPaid || isNaN(currentPaid) || Number(currentPaid) <= 0)
    ) {
      toast.error("ກະລຸນາປ້ອນຈຳນວນເງິນທີ່ຖືກຕ້ອງ");
      return;
    }
    if (isPartialPayment && Number(currentPaid) > totalRemain) {
      setError(`ຈຳນວນເງິນທີ່ປ້ອນຕ້ອງບໍ່ຫຼາຍກວ່າ ${totalRemain} ກີບ`);
      return;
    }
    try {
      const res = await axios.post(
        `${NEW_API_URL}register/payment/${id}`,
        { currentPaid: isPartialPayment ? Number(currentPaid) : amount },
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setOnSuccess(true);
        setShowConfirm(false);
        toast.success("ຈ່າຍເງິນສຳເລັດ");
      }
    } catch {
      toast.error("ຈ່າຍເງິນບໍ່ສຳເລັດ");
    }
  };

  return (
    <Dialog
      open={showConfirm}
      onClose={() => setShowConfirm(false)}
      PaperProps={{
        sx: {
          p: 0,
          borderRadius: 4,
          width: "100%",
          maxWidth: 460,
          boxShadow: 20,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 3,
          borderBottom: "1px solid #eee",
        }}
      >
        <Typography variant="h6" fontWeight={700}>
          ຢືນຢັນການຊຳລະເງິນ
        </Typography>
        <IconButton onClick={() => setShowConfirm(false)}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        <Box
          sx={{
            p: 2,
            borderRadius: 2,
            bgcolor: "#f9f9f9",
            mb: 3,
            textAlign: "center",
          }}
        >
          <Typography variant="subtitle1">
            {amount === totalRemain
              ? "ຈຳນວນເງີນທີ່ຕ້ອງຈ່າຍ"
              : "ຈຳນວນເງີນທີ່ຍັງຄ້າງຈ່າຍ"}
          </Typography>
          <Typography variant="h5" fontWeight={700} color="error">
            {totalRemain.toLocaleString()} ກີບ
          </Typography>
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              checked={isPartialPayment}
              onChange={(e) => setIsPartialPayment(e.target.checked)}
              disabled={totalRemain < amount}
              sx={{ mr: 1 }}
            />
          }
          label="ຈ່າຍເງິນບາງສ່ວນ"
        />

        {isPartialPayment && (
          <TextField
            fullWidth
            margin="normal"
            label="ຈຳນວນເງິນທີ່ຈ່າຍ"
            type="number"
            value={currentPaid}
            onChange={(e) => setCurrentPaid(e.target.value)}
            placeholder="ປ້ອນຈຳນວນເງິນ"
            inputProps={{ min: 0, max: totalRemain }}
            error={Boolean(error)}
            helperText={error}
          />
        )}
      </DialogContent>

      <Divider />

      <DialogActions sx={{ p: 3, justifyContent: "center" }}>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Payment />}
            onClick={() => handlePayment(paymentID)}
            sx={{ borderRadius: 3, px: 4, py: 1.5, textTransform: "none" }}
          >
            ຢືນຢັນ
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<Cancel />}
            onClick={() => setShowConfirm(false)}
            sx={{ borderRadius: 3, px: 4, py: 1.5, textTransform: "none" }}
          >
            ຍົກເລີກ
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmPaymentDialog;

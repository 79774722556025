import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from 'react'
import { CustomContainer } from '../../common'
import { Breadcrumb } from 'react-bootstrap'
import Routers from '../../consts/router'
import useReactRouter from 'use-react-router'
import DropOutStatus from './DropOutStatus'
import { fetchDelete, fetchGet } from '../../common/fetchAPI'
import { convertDropTypeL, formatDateDashDMY } from '../../common/super'
import { convertGenderNam } from '../../helpers/user'
import { toast } from 'react-toastify';
import ConfirmModal from '../../common/ConfirmDialog';
import DropBackModal from './DropBackModal';

const DropOutDetail = () => {
    const { history, location } = useReactRouter();
    const _id = location?.state
    const [data, setData] = useState();
    const [showDelete, setShowDelete] = useState(false);
    const [showBackTo, setShowBackTo] = useState(false);

    useEffect(() => {
        fetchData();
    }, [_id]);

    const fetchData = async () => {
        try {
            // Create a new URLSearchParams object and append parameters
            const apiUrl = `studentdrop/${_id}`;
            const response = await fetchGet(apiUrl);
            console.log("response: ", response)
            setData(response);
            if (!response) {
                throw new Error();
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleDelete = async () => {
        // Show the delete modal here
        try {
            const response = await fetchDelete(`studentdrop/${_id}`);
            if (response) {
                toast.success("ລົບລາຍການຂໍໂຈະສຳເລັດ", {
                    autoClose: 3000, // Customize hide time to 5 seconds (5000ms)
                });
                setShowDelete(false);
                history.push(Routers.DROP_OUT_LIST);
            } else {
                throw new Error();
            }
        } catch (error) {
            toast.error("ລະບົບມີບັນຫາ ລົບບໍ່ສຳເລັດ", {
                autoClose: 3000, // Customize hide time to 5 seconds (5000ms)
            });
            console.log("error: ", error)
        }
    };

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => history.push(Routers.DROP_OUT_LIST)}>
                    ລາຍການນັກສຶກສາໂຈະການຮຽນ
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    ລາຍລະອຽດນັກສຶກສາໂຈະການຮຽນ
                </Breadcrumb.Item>
            </Breadcrumb>
            <CustomContainer style={{ position: "relative" }}>
                {data?.approvalStatus === "PENDING" ?
                    <Box
                        sx={{
                            position: "absolute",
                            top: 1, // Adjust as needed
                            right: 16, // Adjust as needed
                            zIndex: 30, // Ensure it appears above content
                        }}
                    >
                        <IconButton onClick={() => setShowDelete(true)} color="error">
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    : data?.status === "DROPING" ? <Box
                        sx={{
                            position: "absolute",
                            top: 5, // Adjust as needed
                            right: 16, // Adjust as needed
                            zIndex: 30, // Ensure it appears above content
                        }}
                    >
                        <Button variant='outlined' onClick={() => setShowBackTo(true)} color="success">
                            ກັບມາຮຽນຄືນ
                        </Button>
                    </Box>
                        : null
                }
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6}>
                        <Typography>
                            <b>ລະຫັດນັກສຶກສາ:</b> {data?.student?.studentNumber}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>
                            <b>ຊື່:</b> {convertGenderNam(data?.student?.gender)} {data?.student?.firstNameL || data?.student?.firstNameE}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>
                            <b>ນາມສະກຸນ:</b> {data?.student?.lastNameL || data?.student?.lastNameE}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>
                            <b>ຄະນະ:</b> {data?.faculty?.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>
                            <b>ພາກ:</b> {data?.department?.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>
                            <b>ສາຂາ:</b> {data?.major?.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>
                            <b>ປີຮຽນ:</b> {data?.yearLevel}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>
                            <b>ຫ້ອງຮຽນ:</b> {data?.classRoom?.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>
                            <b>ປະເພດການໂຈະ:</b> {convertDropTypeL(data?.dropType)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>
                            <b>ສາເຫດ:</b> {data?.reason}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>
                            <b>ລາຍລະອຽດ:</b> {data?.description}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>
                            <b>ວັນທີ່ເລິ່ມໂຈະ:</b> {formatDateDashDMY(data?.leaveStartDate)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>
                            <b>ວັນທີ່ສິ້ນສຸດການໂຈະ:</b> {formatDateDashDMY(data?.leaveEndDate)}
                        </Typography>
                    </Grid>
                </Grid>

            </CustomContainer>
            <CustomContainer>
                <Box flexDirection={'row'} whiteSpace={'nowrap'}>
                    <span style={{ fontWeight: "bold" }}>ສະຖານະ</span>
                    {data?.approvalStatus === "APPROVED" && (
                        data?.status === "DROPING" ? <span style={{ color: "red" }}>: ໂຈະການຮຽນ</span> : <span style={{ color: "green" }}>: ກັບມາຮຽນແລ້ວ</span>
                    )}
                </Box>

                <DropOutStatus
                    data={data}
                    onRefresh={fetchData}
                />
                {(data?.approvalStatus === "APPROVED" ||
                    data?.approvalStatus === "REJECTED") &&
                    data?.approvedBy && (
                        <Box
                            mt={2}
                        >
                            <Typography
                                sx={{ marginRight: "16px" }}
                                variant="subtitle1"
                                fontWeight="bold"
                            >
                                {data?.approvalStatus === "APPROVED"
                                    ? "ຢືນຢັນໂດຍ"
                                    : data?.approvalStatus === "REJECTED"
                                        ? "ປະຕີເສດໂດຍ"
                                        : ""}
                            </Typography>
                            <Typography
                                sx={{
                                    marginTop: "4px",
                                }}
                                variant="body2"
                            >
                                {data?.approvedBy?.gender === "FEMALE"
                                    ? `ທ່ານ ນາງ ${data?.approvedBy?.firstname ?? "---"
                                    } ${data?.approvedBy?.lastname ?? "---"}`
                                    : `ທ່ານ ${data?.approvedBy?.firstname ?? "---"} ${data?.approvedBy?.lastname ?? "---"
                                    }`}
                            </Typography>
                        </Box>
                    )}
            </CustomContainer>
            <ConfirmModal
                open={showDelete}
                message={"ທ່ານຕ້ອງການລົບແທ້ບໍ່?"}
                title={"ລົບລາຍການຂໍໂຈະ!"}
                onCancel={() => {
                    setShowDelete(false);
                }}
                onSubmit={handleDelete}
            />
            <DropBackModal
                open={showBackTo}
                data={data}
                onClose={() => setShowBackTo(false)}
                onRefresh={fetchData}
            />
        </div>
    )
}

export default DropOutDetail
import React, { useEffect, useState, useRef } from "react";
import DataFilter from "../../common/Filter";
import { Button, TablePagination, TableFooter, Box } from "@mui/material";

import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import { USER_KEY } from "../../consts";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Loading from "../../common/Loading";
import { fetchPost } from "../../common/fetchAPI";
import { hasPermission } from "../../helpers/CheckPermission";
import { CustomContainer } from "../../common";
import LoadingDialog from "../../common/LoadingDialog";
import EmptyCmomponent from "../../common/EmptyScreen";

const StudentHistoryList = () => {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const [facId, setFacId] = useState(userObject.data.faculty.id);
  const [depId, setDepId] = useState("");
  const [disableDep, setDisableDep] = useState(false);
  const [disableFact, setDisableFact] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const abortControllerRef = useRef(null);
  const [isCancelled, setIsCancelled] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const user = localStorage.getItem(USER_KEY);
    const userObject = JSON.parse(user);

    if (userObject) {
      if (userObject.data.role === "DEP_ADMIN") {
        setDisableDep(true);
        setDepId(userObject.data.department.id);
        setFacId(userObject.data.faculty.id);
        setDisableFact(true);
      }
      if (userObject.data.role === "FAC_ADMIN") {
        setDisableDep(false);
        setDepId("");
        setFacId(userObject.data.faculty.id);
        setDisableFact(true);
      }
      if (userObject.data.role === "ADMIN") {
        setDisableDep(false);
        setDisableFact(false);
        setDepId("");
        setFacId("");
      }
    }
  }, [user]);

  const onExport = async () => {
    let queue = 0;
    setExportLoading(true);
    setIsCancelled(false);
    abortControllerRef.current = new AbortController();

    try {
      // Fetch students with abortable signal
      const _studentList = await fetchStudentExport(studentCount || 0, {
        signal: abortControllerRef.current.signal,
      });

      if (isCancelled) return; // Cancel check after fetch

      const nreStudentExport = [];
      for (const student of _studentList) {
        if (isCancelled) return; // Cancel check during processing

        queue++;
        const gender = student.gender === "FEMALE" ? "ຍິງ" : "ຊາຍ";

        nreStudentExport.push({
          ລຳດັບ: queue,
          ລະຫັດ: student.userId,
          "ຊື່ ແລະ ນາມສະກຸນ": `${student.firstNameL} ${student.lastNameL}`,
          ເພດ: gender,
          ຄະນະ: student.faculty?.name || "",
          ພາກວິຊາ: student.department?.name || "",
          ສາຂາ: student.major?.name || "",
          ປິຮຽນ: student.yearLevel,
          ປະເພດນັກສຶກສາ:
            student.studentType === "IN_PLAN" ? "ໃນເເຜນ" : "ນອກເເຜນ",
          ລະດັບການສຶກສາ: student?.educaeducationLevel?.name || "",
          ເບີໂທລະສັບ: student?.phoneMobile || "",
          ບ້ານປັດຈຸບັນ: student?.address?.village || "",
          ເມຶອງປັດຈຸບັນ: student?.address?.district || "",
          ແຂວງປັດຈຸບັນ: student?.address?.province || "",
          ບ້ານເກີດ: student?.address?.village || "",
          ເມຶອງເກີດ: student?.address?.district || "",
          ແຂວງເກີດ: student?.address?.province || "",
          ວັນເດືອນປີເກີດ: new Date(student?.birthday).toLocaleString(),
          ສັນຊາດ: student?.nationality || "",
          ຊົນເຜົ່າ: student?.tribe?.name || "",
        });
      }

      if (isCancelled) return; // Final cancel check before export

      const ws = XLSX.utils.json_to_sheet(nreStudentExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Students");

      const xlsxBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });

      const blob = new Blob([new Uint8Array(xlsxBuffer)], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(blob, "students.xlsx");
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Export cancelled.");
      } else {
        console.error("Error during export:", error);
      }
    } finally {
      setExportLoading(false);
    }
  };

  const onCancel = () => {
    setIsCancelled(true);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setExportLoading(false);
  };

  const accessmentRedux = useSelector((state) => state.accessment.value);
  const onSelected = (data) => {
    setFilter(data);
  };

  const [filter, setFilter] = useState({});
  const [studentList, setStudentList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [studentCount, setStudentCount] = useState(0);

  const fetchStudentExport = async (limit) => {
    const body = {
      history: true,
      assessmentYear: accessmentRedux,
      faculty: filter?.faculty || facId,
      department: filter?.department,
      major: filter?.major,
      classroom: filter?.class,
      year: filter?.year,
      limit: limit, // limit all the studentcounts current filter
      page: page + 1,
    };
    try {
      const response = await fetchPost("getstudent", body);

      if (response.success) {
        return response.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  };

  const fetchStudent = async () => {
    const body = {
      history: true,
      assessmentYear: accessmentRedux,
      faculty: filter?.faculty || facId,
      department: filter?.department,
      major: filter?.major,
      classroom: filter?.class,
      year: filter?.year,
      limit: rowsPerPage,
      page: page + 1,
      keyword: keyword,
    };
    try {
      const response = await fetchPost("getstudent", body);

      if (response.success) {
        setStudentList(response.data);
        setStudentCount(response.count);
        return response?.data;
      } else {
        setStudentList([]);
        return [];
      }
    } catch (err) {
      setStudentList([]);
      return [];
    }
  };

  useEffect(() => {
    fetchStudent();
  }, [filter, accessmentRedux, facId, page, keyword, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Define the RowData component
  const RowData = ({
    id,
    queue,
    code,
    name,
    lastname,
    faculty,
    department,
    major,
    classroom,
    year,
    gender,
  }) => {
    return (
      <TableRow align="center">
        <TableCell colSpan={1} align="center" sx={{ whiteSpace: "wrap" }}>
          {queue + 20 * (page + 1 - 1)}
        </TableCell>
        <TableCell colSpan={2} align="center" sx={{ whiteSpace: "wrap" }}>
          {code}
        </TableCell>
        <TableCell colSpan={1} align="center" sx={{ whiteSpace: "wrap" }}>
          {gender === "FEMALE" ? "ຍິງ" : "ຊາຍ"}
        </TableCell>
        <TableCell colSpan={3} align="start" sx={{ whiteSpace: "wrap" }}>
          {(gender === "MALE"
            ? "ທ້າວ "
            : gender === "FEMALE"
            ? "ນາງ "
            : gender === "MONK"
            ? "ພຣະ "
            : gender === "NOVICE"
            ? "ຄູບາ "
            : "") +
            name +
            " " +
            lastname}
        </TableCell>
        <TableCell colSpan={3} align="center" sx={{ whiteSpace: "wrap" }}>
          {faculty}
        </TableCell>
        <TableCell colSpan={3} align="center" sx={{ whiteSpace: "wrap" }}>
          {department}
        </TableCell>
        <TableCell colSpan={3} align="center" sx={{ whiteSpace: "wrap" }}>
          {major}
        </TableCell>
        <TableCell colSpan={2} align="center" sx={{ whiteSpace: "wrap" }}>
          {classroom}
        </TableCell>
        <TableCell colSpan={1} align="center" sx={{ whiteSpace: "wrap" }}>
          {year}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div>
      <LoadingDialog
        showCancel
        onCancel={() => onCancel()}
        open={exportLoading}
        message={"ກຳລັງ Export..."}
      />
      <CustomContainer style={{ marginTop: -2 }}>
        <div className="row m-0 p-0">
          <div className="col-9">
            <DataFilter
              depId={depId}
              disableDep={disableDep}
              factId={facId}
              disableFact={disableFact}
              onSelected={onSelected}
            />
          </div>
          <div className="col-3 mt-5">
            <input
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="ຄົ້ນຫາ"
              className="newinput2 text-center"
            />
          </div>
        </div>
      </CustomContainer>
      <CustomContainer>
        <div className="row m-0 p-0 pl-5 mb-3">
          <div className="col-9 text-end pt-2">
            <p className="pe-3">ຈຳນວນນັກສຶກສາ : {studentCount}</p>
          </div>
          <div className="col-3">
            {hasPermission("STUDENT", "export") && (
              <Button
                sx={{
                  marginRight: 3,
                }}
                onClick={() => {
                  onExport();
                }}
                variant="contained"
                color="success"
                startIcon={<GetAppIcon />}
              >
                Export
              </Button>
            )}
          </div>
        </div>

        {studentList.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={1}
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    ລຳດັບ
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align="center"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    ລະຫັດ
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    align="center"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    ເພດ
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    align="center"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    ຊື່ ແລະ ນາມສະກຸນ
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    align="center"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    ຄະນະວິຊາ
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    align="center"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    ພາກວິຊາ
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    align="center"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    ສາຂາ
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={2}
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    ຫ້ອງຮຽນ
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    align="center"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    ປີຮຽນ
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentList.map((student, index) => (
                  <RowData
                    key={student._id}
                    id={student._id}
                    queue={index + 1}
                    code={student?.userId}
                    name={
                      !student?.firstNameL || student?.firstNameL === ""
                        ? student?.firstNameE
                        : student?.firstNameL
                    }
                    lastname={
                      !student?.firstNameL || student?.firstNameL === ""
                        ? student?.lastNameE
                        : student?.lastNameL
                    }
                    faculty={student?.faculty?.name}
                    department={student?.department?.name}
                    major={student?.major?.name}
                    classroom={student?.classRoom?.name}
                    year={student.yearLevel}
                    gender={student?.gender}
                  />
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell
                    colSpan={19}
                    align="right"
                    style={{ width: "100%" }}
                  >
                    <Box display="flex" justifyContent="flex-end" width="100%">
                      <TablePagination
                        rowsPerPageOptions={[10, 20, 50, 100, 500, 1000, 5000]}
                        count={studentCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        SelectProps={{
                          inputProps: { "aria-label": "rows per page" },
                          native: true,
                        }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) : studentList.length === 0 ? (
          <EmptyCmomponent height={400} />
        ) : (
          <div className="row p-0 m-0 px-4">
            <Loading />
          </div>
        )}
      </CustomContainer>
    </div>
  );
};

export default StudentHistoryList;

import { Box, Button, CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { generateAcademicYearsFromNextToFive } from '../../../helpers/GenerateAcadimicYears';
import { fetchPostV2 } from '../../../common/fetchAPI';
import { toast } from 'react-toastify';

const modalStyle = (theme) => ({
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)", // Softer shadow
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius, // More rounded corners
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
});

const buttonContainerStyle = {
    marginTop: 3,
    display: "flex",
    justifyContent: "center",
    gap: 2,
};
const CopyCourseModal = ({ open, onClose, departments, depId, currentYear }) => {
    const startYear = parseInt(currentYear?.split(" - ")[1], 10);// split last year in string
    const AssesmentYears = generateAcademicYearsFromNextToFive(startYear);
    const [selectNextYear, setSelectNextYear] = useState("");
    const [errors, setErrors] = useState({ depId: false, currentYear: false, selectNextYear: false });
    const [isSubmitting, setSubmitting] = useState(false);
    // Reset errors when modal opens
    useEffect(() => {
        if (open) {
            setErrors({ depId: false, currentYear: false, selectNextYear: false });
            setSelectNextYear(""); // Optional: Reset selected year as well
        }
    }, [open]);

    const handleSubmit = async () => {

        let validationErrors = {
            depId: !depId,
            currentYear: !currentYear,
            selectNextYear: !selectNextYear,
        };

        setErrors(validationErrors);

        if (Object.values(validationErrors).some((error) => error)) {
            console.error("Error: All fields are required!");
            return;
        }
        try {
            setSubmitting(true);
            const body = {
                department: depId,
                fromAssessmentYear: currentYear,
                nextAssessmentYear: selectNextYear
            }
            await fetchPostV2("course/migrate", body);
            onClose()
            toast.success("ສຳເນົາຫຼັກສູດສຳເລັດ", {
                autoClose: 3000, // Customize hide time to 5 seconds (5000ms)
            });
        } catch (error) {
            if (error?.response?.status === 400) {
                toast.error(
                    <>
                        {error?.response?.data?.message}
                        <br />
                        {error?.response?.data?.existingCourses.slice(0, 10).map((course, index) => (
                            <div key={index}>- {course?.subject?.title}</div>
                        ))}
                    </>,
                    {
                        autoClose: 5000, // Customize hide time to 5 seconds (5000ms)
                    });
            } else {
                toast.error("ລະບົບມີບັນຫາ ສຳເນົາຫຼັກສູດບໍ່ສຳເລັດ", {
                    autoClose: 3000, // Customize hide time to 5 seconds (5000ms)
                });
            }

        } finally {
            setSubmitting(false);
        }

    }

    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={(theme) => modalStyle(theme)}>
                    <Typography
                        variant="h6"
                        id="modal-title"
                        gutterBottom
                        sx={{ fontWeight: 600 }} // Make title bold
                    >
                        ສຳເນົາຫຼັກສູດໄປສົກຕໍ່ໄປ
                    </Typography>
                    <Box display={"flex"} flexDirection={"column"} gap={2}>
                        <FormControl fullWidth error={errors.depId}>
                            <InputLabel id="faculty-select-label">ພາກ</InputLabel>
                            <Select
                                disabled={true}
                                label="ພາກ"
                                value={depId}
                                fullWidth
                            >
                                {departments.map((dep) => (
                                    <MenuItem key={dep._id} value={dep._id}>
                                        {dep.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.depId && <FormHelperText>ກະລຸນາເລືອກພາກ</FormHelperText>}
                        </FormControl>
                        <Box display={"flex"} flexDirection={"row"} gap={2}>
                            <TextField
                                fullWidth
                                type="text"
                                label="ສົກປັດຈຸບັນ"
                                value={currentYear}
                                variant="outlined"
                                disabled={true}
                                error={errors.currentYear}
                                helperText={errors.currentYear ? "ກະລຸນາລະບຸສົກປັດຈຸບັນ" : ""}
                            />
                            <FormControl fullWidth error={errors.selectNextYear}>
                                <InputLabel id="faculty-select-label">ເລືອກສົກທີ່ຕ້ອງການສຳເນົາໄປ</InputLabel>
                                <Select
                                    label="ເລືອກສົກທີ່ຕ້ອງການສຳເນົາໄປ"
                                    onChange={(e) => setSelectNextYear(e.target.value)}
                                    fullWidth
                                >
                                    {AssesmentYears.map((year, i) => (
                                        <MenuItem key={i} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.selectNextYear && <FormHelperText>ກະລຸນາເລືອກສົກຮຽນ</FormHelperText>}
                            </FormControl>

                        </Box>
                    </Box>
                    <Box sx={buttonContainerStyle}>
                        <Button
                            sx={{
                                px: 4,
                                py: 1.5,
                                borderRadius: 4,
                                borderColor: (theme) => theme.palette.primary.main, // Modern outline
                                color: (theme) => theme.palette.primary.main,
                            }}
                            onClick={handleSubmit}
                            variant="outlined"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? <CircularProgress size={24} sx={{ color: "inherit" }} /> : "ບັນທຶກ"}
                        </Button>
                        <Button
                            sx={{
                                px: 4,
                                py: 1.5,
                                borderRadius: 4, // Rounded button
                                boxShadow: "0px 4px 12px rgba(255, 0, 0, 0.2)", // Soft shadow for action button
                            }}
                            onClick={onClose}
                            variant="contained"
                            color="error"
                        >
                            ຍົກເລີກ
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div >
    )
}

export default CopyCourseModal
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchGet } from "../../../common/fetchAPI";
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  CircularProgress,
  Alert,
  Chip,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";

const COLOR_SCHEMES = {
  genders: ["#3b82f6", "#f472b6", "#facc15", "#14b8a6"],
  tribes: [
    "#8b5cf6",
    "#ec4899",
    "#ef4444",
    "#f97316",
    "#22c55e",
    "#06b6d4",
    "#eab308",
  ],
  religions: [
    "#6366f1",
    "#d946ef",
    "#0ea5e9",
    "#10b981",
    "#f59e0b",
    "#ef4444",
    "#94a3b8",
  ],
};

const translateGender = (gender) => {
  switch (gender) {
    case "MALE":
      return "ຊາຍ";
    case "FEMALE":
      return "ຍິງ";
    default:
      return "ອື່ນໆ";
  }
};

const STATUS_CHIPS = [
  { label: "ນັກສຶກສາສະມັກເສັງ", value: "PENDING" },
  { label: "ນັກສຶກສາຖືກຢືນຢັນ", value: "APPROVED" },
  { label: "ນັກສຶກສາເສັງຜ່ານ", value: "PASSED" },
];

const StudentEntranceReportByReligion = () => {
  const accessmentRedux = useSelector((state) => state.accessment.value);

  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState("PENDING");

  useEffect(() => {
    const getData = async () => {
      const response = await fetchGet(
        `entrance/report/tribe/${accessmentRedux}?status=${status}`
      );
      if (response) {
        const localizedGenders = response.genders.map((item) => ({
          ...item,
          gender: translateGender(item.gender),
        }));
        setReport({ ...response, genders: localizedGenders });
      } else {
        setError("ລະບົບມີບັນຫາໃນການສະເເດງ");
      }
      setLoading(false);
    };

    getData();
  }, [accessmentRedux, status]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "60vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ my: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        bgcolor: "#f5f5f5",
        p: 4,
      }}
    >
      <Box sx={{ display: "flex", gap: 1, mb: 3 }}>
        {STATUS_CHIPS.map((chip) => (
          <Chip
            key={chip.value}
            label={chip.label}
            color={status === chip.value ? "primary" : "default"}
            onClick={() => setStatus(chip.value)}
          />
        ))}
      </Box>

      <Grid container spacing={2}>
        {[
          {
            title: "ລາຍງານຕາມເພດ",
            data: report?.genders,
            key: "gender",
            colors: COLOR_SCHEMES.genders,
          },
          {
            title: "ລາຍງານຕາມຊົນເຜົ່າ",
            data: report?.tribes,
            key: "tribe",
            colors: COLOR_SCHEMES.tribes,
          },
          {
            title: "ລາຍງານຕາມສາສະຫນາ",
            data: report?.religions,
            key: "religion",
            colors: COLOR_SCHEMES.religions,
          },
        ].map((section, idx) => (
          <Grid item xs={12} key={idx}>
            <Paper elevation={0} sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                {section.title}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  height: 350,
                  overflowX: "auto",
                  overflowY: "hidden",
                }}
              >
                <ResponsiveContainer
                  width={Math.max(section.data.length * 100, 500)}
                  height={350}
                >
                  <BarChart
                    data={section.data}
                    margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey={section.key}
                      angle={section.data.length > 5 ? -45 : 0}
                      textAnchor="end"
                      height={60}
                    />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count">
                      {section.data.map((_, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={section.colors[index % section.colors.length]}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default StudentEntranceReportByReligion;
